import React from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import InnerpageHeader from '../../../common/InnerpageHeader'
import Kycupload from './Kycupload'
import { kycApis } from '../../../hooks/apihooks/kycApis'
import ListMykyc from './ListMykyc'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
function Kyc() {
    const { t } = useTranslation()
    const loggedUsername = useSelector((state) => state.login.loggedUsername)
    const getCategories = kycApis.Getcategories()
    const getMykycs = kycApis.Getmykycs(loggedUsername)

    return (
        <div>

            <InnerpageHeader
                pageTitle="kyc"
               
            />
            <section className="inner_page_main_bx_section">
                <div className="container">
                    <div className="inner_page_main_box_view">
                        <div className="inner_page_main_bx_head">
                            {/* <h4></h4>
                            <a href="#/" className="inner_page_main_bx_filter_btn">
                                <i className="fa fa-add"></i> Add new
                            </a> */}
                        </div>

                        <div className="best_match_section">
                            <div className="row">
                                <Kycupload category={getCategories?.data} t={t}/>
                                <hr />
                                <ListMykyc
                                    t={t}
                                    Mykycs={getMykycs?.data}
                                    isLoading={getMykycs?.isLoading} />
                            </div>


                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default Kyc