import { useMutation } from "@tanstack/react-query"
import { postLocation } from "../../actions/Location/location"
export const locationApis={
    PostLocation:()=>{
        const response = useMutation({
            mutationKey:["post-location"],
            mutationFn: (data)=> postLocation(data)
        })
        return response
    }
}