import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  room_Id: '',
  apiCallingstatus: false,
  isRoomidReceived: true

}

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setRoomid: (state, action) => {
      state.room_Id = action.payload
    },
    setApicallingStatus: (state, action) => {
      state.apiCallingstatus = action.payload
    },
    setIsroomIdreceived: (state, action) => {
      state.isRoomidReceived = action.payload
    }

  }
})

export const { setRoomid, setApicallingStatus,setIsroomIdreceived } = ChatSlice.actions
export default ChatSlice.reducer
