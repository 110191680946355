import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { contactusPost,getCompanyDetails } from "../../actions/Contactus/contactus";

export const ContactUsapis = {
  SendContactus: () => {
    const response = useMutation({
      mutationFn: (data) => contactusPost(data),
    });
    return response;
  },

  GetcompanyDetails:()=>{
    const response=useQuery({
      queryKey:["get-company-details"],
      queryFn:()=>getCompanyDetails()
    })
    return response
  }
 
};
