import { MatchServices } from "../../services/matchesService"

export const postPreferncedata = async (data) => {
    try {
        const response = await MatchServices.filtersubmit(data)
        return response
    } catch (error) {
        return error
    }

}

export const getPreferenceoptions = async (type) => {
    try {
        const response = await MatchServices.getPreferenceOptions(type)
        return response
    } catch (error) {
        return error
    }

}

export const getmyPreference = async () => {
    try {
        const response = await MatchServices.getmyPreference()
        return response
    } catch (error) {
        return error
    }

}

export const getmyMatches = async () => {
    try {
        const response = await MatchServices.getmyMatches()
        return response
    } catch (error) {
        return error
    }

}

export const postLikedUsersIds = async (data) => {
    try {
        const response = await MatchServices.postLikedUsersIds(data)
        return response
    } catch (error) {
        return error
    }

}

export const postFavouritedUsersIds = async (data) => {
    try {
        const response = await MatchServices.postFavouritedUsersIds(data)
        return response
    } catch (error) {
        return error
    }

}

export const blockusers=async(data)=>{
    try {
        const response=await MatchServices.blockusers(data)
        return response
    } catch (error) {
        return error
    }
}

export const getSinglePagedata=async(id)=>{
    try {
        const response=await MatchServices.getSinglePagedata(id)
        return response
    } catch (error) {
        return error
    }
}


export const postRatingdata=async(data)=>{
    try {
        const response=await MatchServices.postRatingdata(data)
        return response
    } catch (error) {
        return error
    }
}
export const pokeSending=async(data)=>{
    try {
        const response=await MatchServices.pokeSending(data)
        return response
    } catch (error) {
        return error
    }
}