import React from 'react'
import userImg1 from '../../../assets/images/users/user_imag_1.jpg'
import userImg2 from '../../../assets/images/users/user_imag_2.jpg'
import userImg3 from '../../../assets/images/users/user_imag_3.jpg'
import profilePic from '../../../assets/images/pofile-image.jpg'
import Header from '../../Header'
import Footer from '../../Footer'
import InnerpageHeader from '../../../common/InnerpageHeader'
import { useLocation } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Chatapis } from '../../../hooks/apihooks/chatApis'
import { IMG_URL, WEB_SOCKET_URL } from '../../../config/config'
import chatImg from '../../../assets/images/Live Chat.gif'
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import EmojiPicker from 'emoji-picker-react';
import moment from 'moment'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { setRoomid } from '../../../store/reducers/chatReducer'
import { useDispatch } from 'react-redux'
import { setApicallingStatus, setIsroomIdreceived } from '../../../store/reducers/chatReducer'
import { showToastmsg } from '../../../common/ToastMsg'
import Skeleton from 'react-loading-skeleton';

function Chat({ isLoading,t }) {
    const { state } = useLocation()
    const loggeduser = useSelector((state) => state.login.loggedUsername)
    const loggedUserdetails = useSelector((state) => state.login.loggedUserdetails)
    const room_id = useSelector((state) => state.chat.room_Id)
    const postReceiverId = Chatapis.CreateChatroom()
    const getChatroom = Chatapis.Chatroomusers()
    const getStickers = Chatapis.GetStickers()
    const [receiverRoomId, setreceiverRoomId] = useState('')
    const [chat, setchat] = useState("")
    const [messages, setmessages] = useState([])
    const [chatOpenstatus, setchatOpenstatus] = useState(false)
    const [selectedChatuserid, setselectedChatuserid] = useState('')
    const getChats = Chatapis.Getchats(selectedChatuserid)
    const [selectedChatusername, setselectedChatusername] = useState('')
    const [selectedChatusers, setselectedChatusers] = useState({
        username: '',
        profilePic: ''
    })
    const [msgTYpe, setmsgTYpe] = useState('')
    const [file, setfile] = useState(null)
    const [chatfile, setchatfile] = useState(null)
    const [sticker, setsticker] = useState('')
    const [imojiShow, setimojiShow] = useState(false)
    const chatChangehandler = (e) => {
        const { value } = e.target
        setchat(value)
        setmsgTYpe('text')
    }
    const [chatUserStatus, setchatUserStatus] = useState({
        online: false,
        lastSeen: ''
    })
    const [stickerShow, setstickerShow] = useState(false)
    const dispatch = useDispatch()
    const wsRef = useRef(null);
    const chatAreaRef = useRef(null);
    const roomIdreceived = useSelector((state) => state.chat.isRoomidReceived)
    useEffect(() => {
        if (getChats.isSuccess) {
            const previousMessages = getChats?.data?.data
            setmessages(previousMessages)
        }
    }, [getChats?.data, selectedChatuserid])


    const sendHandler = async (data) => {
        console.log("data?.media", data?.media)
        const ws = wsRef.current;
        let currentUtcDateTime = moment();
        console.log("==WebSocket.OPEN==", WebSocket.OPEN)
        if (ws && ws.readyState === WebSocket.OPEN) {
            console.log("=====websocket connection established")

            if (!chat.trim() && !data?.media) {
                showToastmsg('error', 'Message cannot be empty', 'top')

                return;
            }
            let chatData = {
                message: chat,
                sender_user: loggeduser,
                room_id: receiverRoomId,
                received_user: selectedChatusername,
                sender_profile_pic: loggedUserdetails?.profile_picture ? loggedUserdetails?.profile_picture : '',
                receiver_profile_pic: selectedChatusers?.profilePic ? selectedChatusers?.profilePic : '',
                file: data?.media ? data.media : '',
                timestamp: moment().utc(currentUtcDateTime._d),


            };
            console.log("===chatdata====", chatData)

            ws.send(JSON.stringify(chatData));


            // ws.onmessage = (event) => {
            //     console.log("==event==",event)
            //     const receivedMessage = JSON.parse(event.data);
            //     setmessages([...messages, receivedMessage]);
            //     console.log('Received message:', receivedMessage);
            // };
            setchat("");
            setimojiShow(false)
        } else {
            console.error('WebSocket connection not open.');
        }
    };

    const createReceiverChatroom = async (username) => {
        try {
            const response = await postReceiverId.mutateAsync({ receiver_user: username });

            if (response.status) {
                setreceiverRoomId(response.data.room_id);
                establishWebSocketConnection(response.data.room_id)
                dispatch(setIsroomIdreceived(true))
                getChatroom.refetch()
            }
        } catch (error) {

        }
    };

    const establishWebSocketConnection = (roomId) => {

        if (wsRef.current) {

            wsRef.current.close();
        }


        const newWs = new WebSocket(`${WEB_SOCKET_URL}chat/${roomId}/`);
        // const newWs = new WebSocket(`ws://208.109.189.52:8000/ws/chat/${roomId}/`);

        newWs.onopen = () => {
            console.log('WebSocket connection opened for new user');
        };

        newWs.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        newWs.onclose = (event) => {
            console.log('WebSocket connection closed:', event);

        };

        newWs.onmessage = (event) => {
            const receivedMessage = JSON.parse(event.data);
            if (receivedMessage.type != "response_message") {
                setmessages((prevMessages) => [...prevMessages, receivedMessage]);
            }

            // setmessages([...messages,receivedMessage]);
            console.log('Received message=========:', receivedMessage);
        };
        wsRef.current = newWs;
    };

    const getChathandler = (data) => {
        console.log("===data=====", data)
        setselectedChatusers({
            ...selectedChatusers,
            username: data.username,
            profilePic: data.profile_pic
        })
        setchatUserStatus({
            ...chatUserStatus,
            online: data.active_status,
            lastSeen: data.last_login
        })
        setselectedChatusername(data.username)
        setchatOpenstatus(true)
        setselectedChatuserid(data.room_id)
        dispatch(setRoomid(data.room_id))
        dispatch(setApicallingStatus(true))
        setreceiverRoomId(data.room_id)
        // establishWebSocketConnection(roomId);


    }
    console.log("==roomIdreceived==", roomIdreceived)
    useEffect(() => {
        console.log("Effect is running");
        if (state?.key) {

            createReceiverChatroom(state?.key);

            // establishWebSocketConnection(state.roomId);
            setselectedChatusername(state?.key)
            setchatOpenstatus(true);

        }
    }, [state?.key]);

    useEffect(() => {
        if (selectedChatuserid) {
            establishWebSocketConnection(selectedChatuserid);
        }
    }, [selectedChatuserid]);

    const emojiPickerhandler = (emoji) => {
        console.log("=======emojisss====", emoji)
        const inputTextElement = document.getElementById('inputText');
        const currentText = inputTextElement.value;
        const newContent = currentText + emoji.emoji;
        inputTextElement.value = newContent;
        setchat(newContent)

    }
    const fileSelecthadnler = (e) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const fileData = event.target.result;
            console.log("==fileData====", fileData)
            setchatfile(fileData)
        };
        reader.readAsArrayBuffer(e.target.files[0]);
        const file = e.target.files[0]
        setfile(file)

    }
    const stickerHandler = () => {
        setstickerShow(!stickerShow)
    }
    const stickerSendhandler = async (data) => {
        setsticker(data.media)
        try {
            if (data) {
                await sendHandler(data)
                setstickerShow(false)
            }

        } catch (error) {
            console.log(error)
        }
    }
    console.log("==chat===", chat)
    const popover = (
        <Popover id="popover-basic">

            <Popover.Body className='stickercontainer'>
                <div className='row'>
                    {
                        getStickers?.data?.data?.map((items, index) => {
                            return (
                                <div className='col-lg-3 col-sm-2' >
                                    <img className="sticker-img" src={IMG_URL + items.media} alt='nopic' onClick={() => stickerSendhandler(items)} />
                                </div>
                            )
                        })
                    }

                </div>

            </Popover.Body>
        </Popover>
    );



    useEffect(() => {
        const chatRoom = document.getElementById('chat-room');
        console.log('chatRoom:', chatRoom);
        if (chatRoom) {
            chatRoom.scrollTo({ top: chatRoom.scrollHeight, behavior: "smooth" });
            console.log('scrollTop:', chatRoom.scrollTop);
        }
    }, [messages]);

    const scrollHandler = () => {
        dispatch(setApicallingStatus(false))
    }
    const emojiClickinghandler = () => {
        setimojiShow(!imojiShow)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            sendHandler();
        }
    };
    return (

        <div>

            <div className="my_account_right_head">
                {t('msg')}
            </div>
            <div className="my_account_right_box">
                <div className="message_top_users_view">

                    {isLoading ?
                        [1, 2, 3, 4].map((item, index) => {
                            return (
                                <div className='m-2'>
                                    <Skeleton circle={true} width={60} height={60} />
                                </div>
                            )
                        })

                        : getChatroom?.data?.data?.Rooms.map((items, index) => {
                            return (
                                <div className="message_top_users_box" onClick={() => getChathandler(items)}>
                                    <div className="message_count">{items.unread_messages}</div>
                                    <div className="message_top_users_box_img online_user">
                                        <img src={items?.profile_pic ? IMG_URL + items?.profile_pic : defaultProfilepic} alt="" />
                                    </div>
                                    <strong>{items.username}</strong>

                                </div>
                            )
                        })
                    }


                </div>
                <div className="chat_area_sec" >
                    <div className="chat-box">
                        {
                            !chatOpenstatus ?
                                <div className='chatImgdiv'>
                                    <img src={chatImg} alt="let's start chat" className='chatDefaultpic' />
                                </div>

                                : <>
                                    <div className="header">
                                        <div className="avatar-wrapper avatar-big">
                                            <img src={selectedChatusers.profilePic ? IMG_URL + selectedChatusers.profilePic : defaultProfilepic} alt="avatar" />
                                        </div>
                                        <span className="name">
                                            {selectedChatusername}
                                            <p>{chatUserStatus.online ? 'Online' : chatUserStatus.lastSeen &&
                                                'Last seen at ' +
                                                moment.utc(chatUserStatus.lastSeen).format('DD-MM-YYYY h:mm A')}</p>
                                        </span>

                                        {/* <span className="options">
                                            <i className="fas fa-ellipsis-h"></i>
                                        </span> */}
                                    </div>

                                    <div className="chat-room" id='chat-room' ref={chatAreaRef} onScroll={scrollHandler}>
                                        {
                                            messages.map((msg, index) => {
                                                console.log("====msgtimestamp", msg)
                                                return (

                                                    msg.received_user !== loggeduser ?
                                                        <div className="message message-right">
                                                            <div className="avatar-wrapper avatar-small">
                                                                <img src={msg.sender_profile_pic ? IMG_URL + msg.sender_profile_pic : defaultProfilepic} alt="avatar" />
                                                            </div>
                                                            {
                                                                msg.message && (
                                                                    <div className="bubble bubble-dark">
                                                                        {msg.message}
                                                                        <br />
                                                                        <div className='chatTime'>{moment.utc(msg.timestamp).format('h:mm A')}</div>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                msg.file && (
                                                                    <>
                                                                        <img src={IMG_URL + msg.file} style={{ width: '100px' }} />
                                                                        <br />
                                                                        <div className='chatTime'>{moment.utc(msg.timestamp).format('h:mm A')}</div></>
                                                                )
                                                            }


                                                        </div>

                                                        :

                                                        <div className="message message-left">
                                                            <div className="avatar-wrapper avatar-small">
                                                                <img src=
                                                                    {
                                                                        msg?.sender_profile_pic ?
                                                                            IMG_URL + msg.sender_profile_pic
                                                                            : defaultProfilepic
                                                                    } alt="avatar" />
                                                            </div>
                                                            {
                                                                msg.message && (
                                                                    <div className="bubble bubble-dark">
                                                                        {msg.message}
                                                                        <br />
                                                                        <span>{moment.utc(msg.timestamp).format('h:mm A')}</span>
                                                                    </div>
                                                                )
                                                            }
                                                            {
                                                                msg.file && (
                                                                    <>
                                                                        <img src={IMG_URL + msg.file} style={{ width: '100px' }} />
                                                                        <br />
                                                                        <span>{moment.utc(msg.timestamp).format('h:mm A')}</span></>


                                                                )
                                                            }

                                                        </div>


                                                )
                                            })
                                        }


                                    </div>
                                    {
                                        imojiShow &&
                                        <EmojiPicker onEmojiClick={emojiPickerhandler}
                                            searchDisabled={true}
                                            skinTonesDisabled={true}
                                            lazyLoadEmojis={false}
                                            emojiVersion="3.0"

                                            suggestedEmojisMode='recent'
                                        />
                                    }


                                    <div className="type-area">
                                        <div className="input-wrapper">
                                            <button className='chat-emoji-btn'><i class="fa-regular fa-face-smile" onClick={emojiClickinghandler}></i></button>

                                            <input type="text" id="inputText" placeholder="Type messages here..." onChange={chatChangehandler}
                                                onKeyDown={handleKeyDown}
                                                value={chat} />
                                        </div>
                                        <OverlayTrigger trigger="click" placement="top" overlay={popover} show={stickerShow}>
                                            <span className="button-add">
                                                <i class="fa-regular fa-note-sticky fa-sm" onClick={stickerHandler}></i>
                                                {/* <i className="fas fa-plus-circle"
                                                onClick={() => document.getElementById("file-upload").click()}
                                                > */}

                                                {/* <input type="file"
                                                    onChange={fileSelecthadnler}
                                                    style={{ display: 'none' }}
                                                    id="file-upload"
                                                /> */}
                                                {/* </i> */}
                                                <div className="others">
                                                    <span className="emoji-button">
                                                        <i className="far fa-laugh"></i>
                                                        <div className="emoji-box">
                                                            <span>&#x1f604;</span>
                                                            <span>😀</span>
                                                            <span>😂</span>
                                                            <span>😭</span>
                                                            <span>😍</span>
                                                            <span>🤮</span>
                                                            <span>🤑</span>
                                                            <span>😖</span>
                                                            <span>😷</span>
                                                        </div>
                                                    </span>
                                                    <span className="image-button">
                                                        <i className="far fa-image"></i>
                                                    </span>
                                                    <span>
                                                        <i className="fas fa-paperclip"></i>
                                                    </span>
                                                </div>
                                            </span>
                                        </OverlayTrigger>

                                        <button className="button-send" onClick={sendHandler}>
                                            <span>Send</span> <i className="fa-regular fa-paper-plane"></i></button>
                                    </div>


                                </>
                        }

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Chat