import React from "react";
import {
  Banner,
  UserProfile,
  Matches,
  Features,
  TravelCompanion,
  Download,
  TestimonialSection,
} from "../../components/homepage";
function HomePage() {
  return (
    <>
      <Banner />
      <UserProfile />
      <Matches />
      <Features />
      <TravelCompanion />
      <Download />
      <TestimonialSection />
    </>
  );
}

export default HomePage;
