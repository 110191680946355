import { forgotPswdServices,sendVerifyOtp } from "../../services/forgotpswdService"

export const forgotPswdsubmitHandler = async (data) => {
    try {
        const response = await forgotPswdServices.submit(data)
        return response
    } catch (error) {
        return error
    }
}
export const verifyOtp = async(data)=>{
    try {
        const response = await forgotPswdServices.sendVerifyOtp(data)
        return response
    } catch (error) {
        console.log(error);
    }
}

export const resendOtp = async(data)=>{
    try {
        const response = await forgotPswdServices.sendOtp(data)
        return response
    } catch (error) {
        console.log(error);
    }
}


export const ChangePassword = async(data)=>{
    try {
        const response = await forgotPswdServices.ChangePassword(data)
        return response
    } catch (error) {
        console.log(error);
    }
}

