import React from 'react'
import Header from '../../components/Header'
import InnerpageHeader from '../../common/InnerpageHeader'
import Footer from '../../components/Footer'
import nodataImg from '../../assets/images/nodata.jpg'
import TravelFilter from '../../components/travel/TravelFilter'
import { useState, useEffect } from 'react'
import { TravelApis } from '../../hooks/apihooks/travelApis'
import { IMG_URL, WEB_SOCKET_URL } from '../../config/config'
import defaultProfilepic from '../../assets/images/user-blue-gradient.png'
import { Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'
import moment from 'moment'
import { showToastmsg } from '../../common/ToastMsg'
import { useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { Link,useNavigate } from 'react-router-dom'
import CardSkeleton from '../../components/skeletons/CardSkeleton'
import Backbtn from '../../common/Backbtn'
import { useTranslation } from 'react-i18next'

function Travel() {
    const loggeduser = useSelector((state) => state.login.loggedUsername)
    const [selectedTab, setselectedTab] = useState("filter")
    const queryClient = useQueryClient()
    const {t}=useTranslation()
    const navigate=useNavigate();
    const [fullData, setfullData] = useState([])
    const [loadMore, setloadMore] = useState({
        status: false,
        limit: 8
    })
    const [data, setdata] = useState({
        looking_for: '',
        location: '',
        date: ''
    })
    const tab = [
        {
            id: 'filter_1',
            name: 'Filter',
            selectedTab: 'filter'
        },
    ]
    const [modal, setmodal] = useState({
        show: false,
        data: {}
    })
    const [filterModal, setfilterModal] = useState(false)
    const getTravelpartners = TravelApis.GetTravelPartners(data.looking_for, data.location, data.date)
    const sendTravelinterest = TravelApis.SendtravelInterest()

    const selectTabhandler = (tab) => {
        setselectedTab(tab)
    }
    const checkLoadmore = () => {

        if (getTravelpartners?.data?.data?.trip_list &&
            getTravelpartners?.data?.data?.trip_list?.length > loadMore.limit) {

            setloadMore((prevLoadMore) => ({
                ...prevLoadMore,
                status: true,
            }));
        } else {
            setloadMore((prevLoadMore) => ({
                ...prevLoadMore,
                status: false,
            }));
        }
    };

    useEffect(() => {

        setfullData(getTravelpartners?.data?.data?.trip_list)
        checkLoadmore()
    }, [getTravelpartners?.data])

    
    const handleLoadMoreClick = () => {
        const newDisplayLimit = loadMore.limit + 8;
        setloadMore({
            ...loadMore,
            limit: newDisplayLimit,
            status: newDisplayLimit < fullData.length,
        });
    };

    const tooltip = (tooltipContent) => {
        return (
            <Tooltip id="button-tooltip">
                {tooltipContent}
            </Tooltip>
        )
    }

    const viewDetailsHandler = (data) => {
        if (data) {
            setmodal({
                ...modal,
                show: true,
                data: data
            })
        }

    }
    const closeHandler = () => {
        setmodal({
            ...modal,
            show: false
        })
    }

    const getFilterData = (data) => {
       
        if (data) {
            setdata({
                ...data,
                location: data.location_name,
                looking_for: data.looking_for,
                date: data.date
            })
            if (getTravelpartners.isSuccess) {
                setfilterModal(false)
                checkLoadmore()
            }

        }
    }
    const filterHadnler = () => {
        setfilterModal(true)
    }
    const handleFiltermodalclose = () => {
        setfilterModal(false)
    }
    const TravelRequestHandler = async (trip_id, user_id) => {
        const response = await sendTravelinterest.mutateAsync({
            trip: trip_id
        })
        if (response.status) {

            queryClient.invalidateQueries({ queryKey: ["get-travelpartners"] })
            if (response?.data?.status === "request") {

                showToastmsg('success', t('request_sent_successfully'))
                const ws = new WebSocket(`${WEB_SOCKET_URL}notification/${user_id}/`);
                // const ws = new WebSocket(`ws://208.109.189.52:8000/ws/notification/${user_id}/`);

                ws.onopen = () => {
                    let socketLikedData = {
                        message: `${loggeduser} sent a travel request`,
                        username: loggeduser
                    }

                    ws.send(JSON.stringify(socketLikedData));
                };
            } else {
                showToastmsg('success', t('rqst_canceled'))
            }

        } else {
            showToastmsg('error', t("something_went_wrong"))
        }

    }
    return (
        <div>

            <InnerpageHeader pageTitle="Travel" />
            <section className="inner_page_main_bx_section">
                <div className="container">
                    <div className="inner_page_main_box_view">

                        <div className='mb-1'>

                            {/* <Backbtn path="profile/travel"/> */}
                        </div>
                        <div className="inner_page_main_bx_head">
                          
                            <h4>{t('adventure_and_affection_find_both_together')}</h4>

                            <a href="#/" className="inner_page_main_bx_filter_btn">
                                <i className="fa fa-filter" onClick={filterHadnler}></i>
                                <OverlayTrigger placement='top' overlay={<Tooltip>{t('add_my_trip')}</Tooltip>}>
                                <i class="fa-solid fa-globe" onClick={()=>navigate('/profile/travel')}></i>
                                </OverlayTrigger>
                                
                            </a>
                        </div>
                        {
                            getTravelpartners.isLoading ?
                                <CardSkeleton
                                    type={"travel"}
                                    iconsCount={1}
                                />
                                :
                                <div className="best_match_section">
                                    <div className="row">
                                        {
                                            getTravelpartners?.data?.data?.trip_list.length == 0 && (
                                                <center>
                                                    <img src={nodataImg} alt='nodata' className='w-25' />
                                                </center>
                                            )
                                        }


                                        {
                                            getTravelpartners?.data &&
                                            fullData?.slice(0, loadMore.limit).map((data, index) => {
                                                const locationParts = data.location.split(',');
                                                const location = locationParts[0].trim();

                                                return (
                                                    <div className="col-md-3">
                                                        <div className="profile_match_crsl_box">
                                                            <div className="profile_match_crsl_box_img">

                                                                <a className="dlete_action_btn" onClick={() => viewDetailsHandler(data)}>
                                                                    <i class="fa-solid fa-info"></i>
                                                                </a>

                                                                {
                                                                    data.request_status ?
                                                                        <OverlayTrigger placement="top" overlay={tooltip("Cancel request")}>
                                                                            <div className="profile_btn_row">
                                                                                <a href="#/" onClick={() => TravelRequestHandler(data.trip_id, data.user_id)}><div className='profile_act_btn prf_btn_4_travel'>
                                                                                    <i class="fa-regular fa-paper-plane"></i>
                                                                                </div></a>

                                                                            </div>
                                                                        </OverlayTrigger>
                                                                        :
                                                                        <OverlayTrigger placement="top" overlay={tooltip("Send your request")}>
                                                                            <div className="profile_btn_row">
                                                                                <a href="#/" onClick={() => TravelRequestHandler(data.trip_id, data.user_id)}><div className='profile_act_btn prf_btn_4'>
                                                                                    <i class="fa-regular fa-paper-plane"></i>
                                                                                </div></a>

                                                                            </div>
                                                                        </OverlayTrigger>
                                                                }


                                                                <div className="total_image_bar">
                                                                </div>

                                                                {/* <a href="profile-single.html"> */}
                                                                <div className="profile_image" >


                                                                    <img src={data.profile_picture ? IMG_URL + data.profile_picture : defaultProfilepic} alt="Profile Image" />



                                                                </div>

                                                                {/* </a> */}
                                                                <div className="img_btm_overlay"></div>
                                                            </div>
                                                            <a href=''>
                                                                <div className="profile_cnt_bx">
                                                                    {data.user}
                                                                    <span> <i class="fa-solid fa-location-dot"></i>&nbsp;{location}</span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                )

                                            })
                                        }




                                    </div>

                                    {
                                        loadMore.status && (
                                            <div className="loadmore_btn_section">
                                                <a href="#" className="loadmore_btn" onClick={handleLoadMoreClick}>{t('load_more')}</a>
                                            </div>
                                        )
                                    }





                                </div>
                        }


                    </div>
                </div>
            </section>


            <Modal centered show={filterModal} size='lg' onHide={handleFiltermodalclose}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>

                    <div className="filter_top_btn">

                        <div className="filter_top_btn">
                            {
                                tab.map((items, index) => {
                                    return (
                                        <a id={items.id} className={selectedTab == items.selectedTab ? "active" : ''} onClick={() => selectTabhandler(items.selectedTab)}>{items.name}</a>
                                    )
                                })
                            }


                        </div>


                    </div>

                    <div className="filter_view_box1">
                        <TravelFilter getFilterData={getFilterData} t={t} />

                    </div>






                </Modal.Body>

                {/* <div className="modal-footer">
                    <button type="button" className="btn regsiter_top_btn">Apply</button>
                </div> */}

            </Modal>
            <Modal onHide={closeHandler} show={modal.show} centered>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className='travel_dtl_pop_photo'>
                        <img src={modal.data.profile_picture ? IMG_URL + modal.data.profile_picture : defaultProfilepic} />
                    </div>

                    <div className='travel-partner'>
                        <label>{modal.data.user}</label>
                    </div>
                    <div className="travel_pop_dtl">
                        <div className='row text-center '>
                            <div className='col-md-12'>
                                <label>{t('travel_days')} :</label>
                                <strong>{modal.data.days}</strong>
                            </div>
                        </div>
                        <div className='row text-center'>
                            <div className='col-md-12'>
                                <label>{t('travel_date')} :</label>
                                <strong>{moment(modal.data.date).format('DD-MM-YYYY')}</strong>
                            </div>
                            <div className='col-md-12'>
                                {
                                    modal.data.description &&
                                    <>
                                        <label>{t('desc')} :</label>
                                        <strong>{modal.data.description}</strong></>

                                }

                            </div>
                        </div>

                    </div>


                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Travel