import { PaypalServices } from "../../services/paypalServices";

export const CreateOrder = async (data) => {
  try {
    const response = await PaypalServices.createOrder(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const CaptureOrder = async (data) => {
  try {
    const response = await PaypalServices.captureOrder(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const GetProducts = async () => {
  try {
    const response = await PaypalServices.getProducts();
    return response;
  } catch (error) {
    return error;
  }
};

export const GetClientId = async () => {
  try {
    const response = await PaypalServices.getClientId();
    return response;
  } catch (error) {
    return error;
  }
};

