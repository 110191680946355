import API from "../api/api"

export const resetPswdServices={
    submit:async(data)=>{
        try {
          const response=await API.patch('password-reset-complete',data)
          if(response.status){
            return response
          }else{
            return response
          }  
        } catch (error) {
           return error
        }
    }
}