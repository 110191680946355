import React, { useEffect } from 'react'
import { useState } from 'react'
import { MatchApis } from '../../../hooks/apihooks/matchApis'
import { useQueryClient } from '@tanstack/react-query'

function ProfileAction({ data,t }) {
    const [blockedBtnclick, setblockedBtnclick] = useState(true)
    const [blockedStatus, setblockedStatus] = useState()
    const queryClient = useQueryClient()
    const blockUser = MatchApis.BlockUsers()
    const blockHandler = async () => {
        setblockedBtnclick(!blockedBtnclick)
        if (blockedBtnclick) {
            setblockedStatus(true)
            let blockeduserId = {
                user: data.id
            }
            const response = await blockUser.mutateAsync(blockeduserId)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-singlepagedata"] })
            }

        } else {
            let unBlockeduserId = {
                user: data.id
            }
            const response = await blockUser.mutateAsync(unBlockeduserId)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-singlepagedata"] })
            }
            setblockedStatus(false)
        }
       
    }
    useEffect(()=>{
        setblockedStatus(data?.block_status)
    },[data])
    
    return (
        <div className="profile_action_btn_sec">
            
                <button className="profile_action_btn" onClick={blockHandler}>
                <div className="profile_action_btn_ico">
                    {
                        blockedStatus ? <i class="fa-solid fa-ban"></i> : <i className="fa-regular fa-circle-xmark"></i>
                    }

                </div>
                {
                    blockedStatus ?
                        <strong>
                            {t('u_blocked_the_account')}
                            <p>{t('click_to_unblock')}</p>
                        </strong> :
                        <strong>
                            {t('block_profile')}
                            <p>{t('block_msg')}</p>
                        </strong>

                }

            </button>
            
           
            {/* <button className="profile_action_btn">
                <div className="profile_action_btn_ico"><i className="fa-solid fa-share-nodes"></i></div>
                <strong>
                    Share Profile
                    <p>Share to friends this profile</p>
                </strong>
            </button> */}
        </div>
    )
}

export default ProfileAction