
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showToastmsg = (type, message,position) => {

    if (type === 'success') {
        toast.success(message, {
            position: position?toast.POSITION.TOP_CENTER:toast.POSITION.BOTTOM_CENTER,
            // position: toast.POSITION.BOTTOM_CENTER,
            icon: <i class="fa-solid fa-heart fa-beat" style={{ color: '#179ad7' }}></i>,
            className: 'toastMsg',
            autoClose:5000,
            

        });
    } else if (type === "error") {
        toast.error(message, {
            position: position?toast.POSITION.TOP_CENTER:toast.POSITION.BOTTOM_CENTER,
            // position: toast.POSITION.BOTTOM_CENTER,
            icon: <i class="fa-solid fa-heart-crack fa-beat" style={{ color: '#179ad7' }}></i>,
            className: 'toastMsg',
            autoClose:5000,
            
        })
    }
   

}