import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import Select from 'react-select';
import nodataIMg from '../../../assets/images/nodata.jpg'
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import { IMG_URL } from '../../../config/config';
import { Link } from 'react-router-dom';
import TableSkeleton from '../../skeletons/TableSkeleton';
function MyTrvlRequests({ data, isLoading }) {

  const headers = ["Username", "Location", "Days", "Date", "Status"]
  const options = [
    { value: 'pending', label: 'PENDING' },
    { value: 'accepted', label: 'ACCEPTED' },
    { value: 'rejected', label: 'REJECTED' },
  ];
  const [TableData, setTableData] = useState(data)
  const [selectedOption, setselectedOption] = useState()
  const [resetLoader, setresetLoader] = useState(false)
  const handleOptionSelect = (value) => {
    console.log("==value====", value)
    setselectedOption(value)
    const filteredData = data.filter((obj) => obj.status.toLowerCase() == value?.value)
    setTableData(filteredData)
  }
  const resetHandler = () => {

    if (selectedOption) {
      setresetLoader(true)
      setTableData(data)
      setselectedOption(null)
      setresetLoader(false)

    }


  }
  return (
    <div className=''>
      <div className='status-select'>
        <Select
          options={options}
          value={selectedOption}
          onChange={handleOptionSelect}
          styles={{
            container: (provided) => ({
              ...provided,
              marginRight: '10px', // Add space between select and button
            })
          }}
        />
        <button onClick={resetHandler}>
          {
            resetLoader ?
              <i class="fa-solid fa-rotate-right fa-spin"></i> : <i class="fa-solid fa-rotate-right"></i>
          }

        </button>
      </div>
      <TableContainer sx={{ height: "300px", overflow: 'auto' }}>


        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className='requestTableHeader'>
            <TableRow>
              {
                headers.map((headers) => (
                  <TableCell sx={{ fontSize: '15px', textTransform: 'uppercase', fontWeight: 'bold' }}>{headers}</TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody >
            {isLoading ?
              <TableSkeleton rowCount={4} colCount={5} />
              :
              TableData.length > 0 ?
                TableData.map((row, index) => (

                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" >
                      <Link to={`/singleProfile/${row.username}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <div className="tblProfileContainer">
                          <img
                            className='tablePrfilePic'
                            src={row.profile_picture ? IMG_URL + row.profile_picture : defaultProfilepic}
                            alt="profile"

                          />
                          {row.username}
                        </div>
                      </Link>


                    </TableCell>
                    <TableCell>{row.location}</TableCell>
                    <TableCell>{row.days}</TableCell>
                    <TableCell>{moment(row.travel_date).format('DD-MM-YYYY')}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>

                ))
                : <TableRow >
                  <TableCell colSpan={5}> <center><img src={nodataIMg} alt='nodata' className='w-25' /></center></TableCell>

                </TableRow>

            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default MyTrvlRequests