
import React from 'react'
import { IMG_URL } from '../../../config/config'
import { kycApis } from '../../../hooks/apihooks/kycApis'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import nodataIMg from '../../../assets/images/nodata.jpg'
import TableSkeleton from '../../skeletons/TableSkeleton'


function ListMykyc({ Mykycs, isLoading,t }) {
  const [modal, setmodal] = useState({
    show: false,
    data: ''
  })
  const deleteKyc = kycApis.Dltkyc()
  const queryClient = useQueryClient();

  const deleteHandler = async (id) => {
    try {
      const response = await deleteKyc.mutateAsync({ kyc_id: id })
      console.log("delete=====", response)
      if (response.status) {
        queryClient.invalidateQueries({ queryKey: ["get-mykyc"] })
      }
    } catch (error) {
      console.log(error)
    }
  }
  const viewHandler = (img) => {
    setmodal({
      ...modal,
      show: true,
      data: img
    })
  }

  const handleClose = () => {
    setmodal({
      ...modal,
      show: false
    })
  }
  return (
    <>

      {
        Mykycs?.data.length === 0 && (
          <center><img src={nodataIMg} alt='nodata' className='w-50' /></center>

        )
      }

      <table className='mt-3' style={{display:Mykycs?.data.length === 0?'none':''}}>
        <thead>
          <tr>
            <th>{t('document_name')}</th>
            <th>{t('status')}</th>
            <th>{t('document_files')}</th>
            <th>{t('action')}</th>
          </tr>
        </thead>
        {isLoading ? (
          <TableSkeleton colCount={4} rowCount={4} />
        ) : (
          <tbody>
            {Mykycs?.data?.map((items, index) => (
              <tr key={index}>
                <td>{t(items.type) ?? 'NA'}</td>
                <td>{t(items.status) ?? 'NA'}</td>
                <td>
                  <img className='kycPic' src={items.image ? IMG_URL + items.image : ''} alt={t(items.type)} onClick={() => viewHandler(items.image)} />
                </td>
                <td className='kyc-delete' onClick={() => deleteHandler(items.id)}>
                  <i className="fa-solid fa-trash"></i>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>

      <Modal show={modal.show} onHide={handleClose} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <div>
            <img src={IMG_URL + modal.data} alt='no image' />
          </div>
        </Modal.Body>

      </Modal>
    </>

  )
}

export default ListMykyc