import React from 'react'
import { kycApis } from '../../hooks/apihooks/kycApis'
import { useState } from 'react';
import { showToastmsg } from '../../common/ToastMsg';
import { useDispatch } from 'react-redux';
import { setPagination } from '../../store/reducers/paginationReducer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { IMG_URL } from '../../config/config';
import Pagination from '@mui/material/Pagination';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import nodataIMg from '../../assets/images/nodata.jpg'
import TableSkeleton from '../skeletons/TableSkeleton';

function KycApproval({ t }) {

    const dispatch = useDispatch()
    const [modal, setmodal] = useState({
        show: false,
        imgUrl: ''
    })
    const [searchVaribles, setsearchVaribles] = useState(
        {
            username: '',
            status: ''
        }
    )
    const [data, setdata] = useState({
        username: '',
        status: ''
    })
    const [showPopup, setshowPopup] = useState(false)
    const updateKycstatus = kycApis.UpdateKycstatus()
    const loggedUsername = useSelector((state) => state.login.loggedUsername)
    const { currentPage, totalPages, RowsperPage } = useSelector((state) => state.pagination.pagination)
    const getApprovaldata = kycApis.GetUploadedKycs(RowsperPage, currentPage, searchVaribles.username, searchVaribles.status)

    const [checkedRows, setCheckedRows] = useState([]);
    const [headerCheckboxIndeterminate, setHeaderCheckboxIndeterminate] = useState(false);

    const handleHeaderCheckboxChange = (event) => {
        const isChecked = event.target.checked;

        const allRows = getApprovaldata?.data?.data?.kyc_list;
        if (isChecked && !headerCheckboxIndeterminate) {
            setshowPopup(true)
            setCheckedRows(allRows.map((item) => item.id));
        } else if (isChecked && headerCheckboxIndeterminate) {
            setCheckedRows([]);
        } else {
            setCheckedRows([]);


        }

        setHeaderCheckboxIndeterminate(false);
    };

    const handleRowCheckboxChange = (event, id) => {
        const isChecked = event.target.checked;

        setCheckedRows((prevCheckedRows) => {
            if (isChecked) {
                setHeaderCheckboxIndeterminate(true)
                setshowPopup(true)
                return [...prevCheckedRows, id];


            } else {
                return prevCheckedRows.filter((rowId) => rowId !== id);
            }
        });

    };



    const handleChange = async (event, value) => {

        dispatch(setPagination({ totalPages: totalPages, currentPage: value, RowsperPage: RowsperPage }))


    };
    const rowPerpagehandler = async (e) => {
        const totalPages = Math.ceil(getApprovaldata?.data?.data?.kyc_count / e.target.value)
        dispatch(setPagination({ totalPages: totalPages, RowsperPage: e.target.value }))
    }

    const updateStatus = async (action) => {
        try {
            const response = await updateKycstatus.mutateAsync({ status: action, kyc_ids: checkedRows })
            if (response.status) {
                showToastmsg("success", t('status_updated_successfully'))
                setCheckedRows([])
                setHeaderCheckboxIndeterminate(false)
                setshowPopup(false)
                getApprovaldata.refetch({
                    queryKey: ["get-uploaded-kycs", RowsperPage, currentPage, loggedUsername]
                });

            } else {
                showToastmsg("error", "Failed")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const viewKychandler = (data) => {

        setmodal({
            ...modal,
            imgUrl: data,
            show: true
        })
    }
    const handleClose = () => {
        setmodal({
            show: false
        })
    }
    const changeHandler = (e) => {
        const { name, value } = e.target
        setdata({
            ...data,
            [name]: value
        })
    }
    const searchHandler = () => {
        setsearchVaribles({
            ...searchVaribles,
            username: data.username,
            status: data.status
        })

    }
    const ResetHandler = () => {
        setsearchVaribles({
            ...searchVaribles,
            username: '',
            status: 4
        })
        setdata({
            ...data,
            username: '',
            status: ''
        })
    }
    return (
        <div>
            <div className="my_account_right_head">
                {t('kyc_approval')}
            </div>
            <div className='searchArea'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <Form.Control type="text" name='username' value={data.username} placeholder={t('username')} onChange={changeHandler} />

                    </div>
                    <div className='col-lg-5'>
                        <Form.Select aria-label="Default select example"
                            onChange={changeHandler}
                            value={data.status}
                            name='status'
                        >
                            <option>{t('select_status')}</option>
                            <option value="0">{t('pending')}</option>
                            <option value="1">{t('approved')}</option>
                            <option value="2">{t('rejected')}</option>
                        </Form.Select>

                    </div>

                    <div className='col-lg-3'>
                        <button className="myaccount_profile_search_btn" onClick={searchHandler}>{t('search')}</button>
                        <button className="myaccount_profile_search_btn ml-1" onClick={ResetHandler}>{t('reset')}</button>
                    </div>
                    {/* <div className='col-lg-1'>
                        
                    </div> */}
                </div>

            </div>
            <hr />

            <div className="my_account_right_box">


                <>
                    <table>
                        <thead>
                            <th>
                                <FormControlLabel

                                    control={
                                        <Checkbox
                                            checked={checkedRows.length > 0 && checkedRows.length === getApprovaldata?.data?.data?.kyc_list?.length}
                                            indeterminate={headerCheckboxIndeterminate}
                                            onChange={handleHeaderCheckboxChange}
                                        />
                                    }
                                />
                            </th>
                            <th>{t('username')}</th>
                            <th>{t('doc_name')}</th>
                            <th>{t('doc_file')}</th>
                            <th>{t('status')}</th>


                        </thead>
                        {
                            getApprovaldata.isLoading ?
                                <TableSkeleton rowCount={4} colCount={5} />
                                :
                                <tbody>
                                    {
                                        getApprovaldata?.data?.data?.kyc_list.length > 0 ?
                                            getApprovaldata?.data?.data?.kyc_list.map((items, index) => {
                                                console.log("==testimonialdayta==", items)
                                                return (
                                                    <tr>
                                                        <td>
                                                            <FormControlLabel

                                                                control={
                                                                    <Checkbox
                                                                        checked={checkedRows.includes(items.id)}
                                                                        onChange={(event) => handleRowCheckboxChange(event, items.id)}


                                                                    />
                                                                }
                                                            />
                                                        </td>
                                                        <td>{items.username}</td>
                                                        <td>{t(items.type)}</td>
                                                        <td>
                                                            <img className='testimonial-profilePic' src={items.image ? IMG_URL + items.image : ''} onClick={() => viewKychandler(items.image)} />

                                                        </td>

                                                        <td style={{ color: items.status === "Approved" ? 'green' : items.status === "Rejected" ? '#d32f2f' : '' }}>{t(items.status)}</td>


                                                    </tr>
                                                )
                                            })
                                            : <tr>
                                                <td colSpan={4}>
                                                    <center><img src={nodataIMg} className='w-50' /></center>

                                                </td>
                                            </tr>
                                    }

                                </tbody>
                        }



                    </table>
                    <div className="row table_ftr_pagination">
                        <div className="col-md-4">
                            {t('rows_per_page')} : <select onChange={rowPerpagehandler} value={RowsperPage}>
                                <option>5</option>
                                <option>10</option>
                                <option>20</option>
                            </select>
                        </div>
                        <div className="col-md-8">
                            <Pagination count={totalPages} page={currentPage} color="primary" onChange={handleChange} />
                        </div>
                    </div>

                </>




                {
                    (checkedRows.length > 0 && showPopup) && (
                        <div className="popupButtonArea">
                            <ul>
                                <li>
                                    <span
                                    >
                                    </span>
                                </li>
                                <li>
                                    <span className="CountRound">
                                        {checkedRows.length}
                                    </span>
                                    <span className="spanText">
                                        {t('items_selected')}
                                    </span>


                                </li>
                                <li>
                                    <button variant="danger" className="CancelButton"
                                        onClick={() => updateStatus(1)}
                                    >
                                        {t("approve")}
                                    </button>
                                    <button variant="danger" className="CancelButton"
                                        onClick={() => updateStatus(2)}
                                    >
                                        {t("reject")}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )
                }

            </div>
            <Modal show={modal.show} onHide={handleClose} centered>
                <Modal.Header closeButton />
                <Modal.Body>
                    <div>
                        <img src={IMG_URL + modal.imgUrl} alt='no image' />
                    </div>
                </Modal.Body>

            </Modal>
        </div>



    )
}

export default KycApproval