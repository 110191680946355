import React from "react";
import _ from "lodash";
import { useState, useEffect } from "react";
import { settingsApis } from "../../hooks/apihooks/settingsApis";
import { useQueryClient } from "@tanstack/react-query";
import { showToastmsg } from "../../common/ToastMsg";
import Alert from "@mui/material/Alert";

function CompanyPolicy({ data ,t}) {
  const Tabs = [
    {
      title: "terms_and_conditions",
      value: "terms and conditions content",
      label: t("terms_and_conditions"),
    },
    {
      title: "privacy_policy",
      value: "privacy_policy content",
      label: t("privacy_policy"),
    },
  ];
  const queryClient = useQueryClient();
  const [content, setContent] = useState({
    privacy_policy: data?.privacy_policy ?? "",
    terms_and_conditions: data?.terms_and_conditions ?? "",
  });
  const [error, setError] = useState(false);

  const [editContent, setEditContent] = useState(false);
  const [selectedTab, setSelectedTab] = useState(Tabs[0].title);

  const submitData = settingsApis.PostCompanyPolicy();

  const userType = localStorage.getItem("userType");

  const setActiveTab = (tab) => {
    setDefaultContent();
    setSelectedTab(tab);
    setEditContent(false);
  };
  const validation = async () => {
    let status = true;
    if (!content[`${selectedTab}`]) {
      status = false;
    }
    setError(!status);
    return status;
  };
  const setDefaultContent = () => {
    queryClient.invalidateQueries({ queryKey: "get-company-policy" });
    setContent((prev) => ({
      ...prev,
      privacy_policy: data.privacy_policy,
      terms_and_conditions: data.terms_and_conditions,
    }));
  };
  const handleContent = (e) => {
    setContent({ ...content, [selectedTab]: e.target.value });
  };
  const handleCancel = () => {
    setEditContent(false);
    setDefaultContent();
  };
  const handleSave = async () => {
    const isValid = await validation();
    if (isValid) {
      const payload = content;
      const result = await submitData.mutateAsync(payload);
      if (result.status) {
        setEditContent(false);
        queryClient.invalidateQueries({
          queryKey: ["get-policy-content", selectedTab],
        });
      } else {
        showToastmsg("error", "failed", "top");
      }
    }
  };
  return (
    <>
      <div className="my_account_right_head">{t('cmpny_policy')}</div>
      <div className="my_account_right_box">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            {userType === "admin" && (
              <>
                {Tabs.map((tabs) => {
                  return (
                    <>
                      <button
                        className={
                          tabs.title === selectedTab
                            ? "nav-link active"
                            : "nav-link"
                        }
                        id="nav-profile-tab"
                        data-bs-toggle="tab"
                        data-bs-target={`#nav-photos-${tabs.title}`}
                        type="button"
                        role="tab"
                        aria-controls="nav-profile"
                        aria-selected="false"
                        onClick={() => setActiveTab(tabs.title)}
                      >
                        {tabs.label}
                      </button>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </nav>
        <div className="tab-content p-3 border" id="nav-tabContent">
          <div className="profile_right_intrst_cnt_sc">
            {!editContent && (
              <div className="edit_icon_mail">
                {" "}
                <button onClick={() => setEditContent(true)}>
                  <i className="fa fa-edit"></i>
                </button>
              </div>
            )}
            {
              <div class="row">
                <div class="row">
                  <div class="col-md-12 mt-4">
                    <textarea
                      readOnly={!editContent}
                      name=""
                      className={`${
                        error ? "testimonialErr " : ""
                      }form-control`}
                      id=""
                      cols="30"
                      rows="10"
                      value={content[`${selectedTab}`]}
                      onChange={handleContent}
                    ></textarea>
                    <br />

                    {error && (
                      <Alert severity="error">
                        {t('check_the_value_u_have_submitted')}
                      </Alert>
                    )}
                  </div>
                </div>
              </div>
            }
          </div>

          {editContent && (
            <>
              <button
                className="myaccount_profile_upgrad_btn"
                onClick={handleCancel}
              >
                {t("cancel")}
              </button>
              <button
                className="myaccount_profile_upgrad_btn m-2"
                onClick={handleSave}
              >
                {t("save")}
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default CompanyPolicy;
