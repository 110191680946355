import React from 'react'
import Select from 'react-select';
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import { MatchApis } from '../../../hooks/apihooks/matchApis';
import translateOptions from '../../../common/TranslateSelectOptions';


function NormalFilter({ filterSubmit, Mypreference, t }) {

    const getPreferenceOptions = MatchApis.GetPrefernceOptions("preference")
    const [distance, setDistance] = React.useState([10, 50]);
    const [age, setage] = useState([18, 50])
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [selectBoxvalues, setselectBoxvalues] = useState({
        location: [],
        lookingFor: Mypreference?.data?.relationship_choices ?? [],
        type: Mypreference?.data?.religion_choices ?? [],
        education: Mypreference?.data?.education_choices ?? [],
        drinking: Mypreference?.data?.drink_choices ?? [],
        smoking: Mypreference?.data?.smoke_choices ?? [],
        workout: Mypreference?.data?.workout_choices ?? [],
        familyPlan: Mypreference?.data?.family_choices ?? [],
        language: Mypreference?.data?.languages_choices ?? []
    })

    const handleChange = (event, newValue) => {
        setDistance(newValue);
    };

    const toggleInterest = (item) => {

        const isSelected = selectedInterests.some((interest) => interest.id === item.id);
        if (isSelected) {
            let filter = selectedInterests.filter((items) => {
                return items.id !== item.id
            })
            setSelectedInterests(filter)



        } else {

            setSelectedInterests([...selectedInterests, item]);


        }
    };
    const selectboxHandler = (value, type) => {
        setselectBoxvalues({
            ...selectBoxvalues,
            [type]: value
        })

    }

    const handleAgeChange = (event, newValue) => {

        setage(newValue)
    }

    const submitHandler = (action) => {
        let formData = {
            minimum_age: age[0],
            maximum_age: age[1],
            distance: distance,
            languages_choices: selectBoxvalues.language,
            location: selectBoxvalues.location,
            relationship_choices: selectBoxvalues.lookingFor,
            religion_choices: selectBoxvalues.type,
            education_choices: selectBoxvalues.education,
            drink_choices: selectBoxvalues.drinking,
            smoke_choices: selectBoxvalues.smoking,
            workout_choices: selectBoxvalues.workout,
            family_choices: selectBoxvalues.familyPlan,
            interests: selectedInterests

        }
        filterSubmit(formData)

    }
    const resetHandler = () => {
        setselectBoxvalues({
            ...selectBoxvalues,
            location: [],
            lookingFor: Mypreference?.data?.relationship_choices ?? [],
            type: Mypreference?.data?.religion_choices ?? [],
            education: Mypreference?.data?.education_choices ?? [],
            drinking: Mypreference?.data?.drink_choices ?? [],
            smoking: Mypreference?.data?.smoke_choices ?? [],
            workout: Mypreference?.data?.workout_choices ?? [],
            familyPlan: Mypreference?.data?.family_choices ?? [],
            language: Mypreference?.data?.languages_choices ?? []

        })
    }

    return (
        <div>
            {
                getPreferenceOptions.data &&
                <div className="row">
                    <div className="col-md-6">
                        {/* <div className="filter_box">
                        <div className="filter_box_head">Location</div>
                        <div className="filter_box_cnt">
                            <Select
                                defaultValue={selectBoxvalues.location}
                                onChange={(value) => selectboxHandler(value, 'location')}
                                options={options}
                                isMulti={true}
                                isSearchable={true}
                                name='languages'
                            />

                        </div>
                    </div> */}
                        <div className="filter_box">
                            <div className="filter_box_head">{t('distance')}</div>
                            <div className="filter_box_cnt">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div id="slider-range">
                                            <Slider
                                                getAriaLabel={() => 'Temperature range'}
                                                value={distance}
                                                onChange={handleChange}
                                                valueLabelDisplay="auto"
                                                min={10}
                                                max={100}
                                                sx={{ color: '#49BEFF' }}

                                            // getAriaValueText={valuetext}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row slider-labels">

                                    <div className="col-6 caption">
                                        <strong>{t('min')}:</strong> <span id="slider-range-value1">
                                            {distance[0]}
                                        </span>
                                    </div>
                                    <div className="col-6 text-end caption">
                                        <strong>{t('max')}:</strong> {distance[1]} <span id="slider-range-value2"></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <form>
                                            <input type="hidden" name="min-value" value="" />
                                            <input type="hidden" name="max-value" value="" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter_box">
                            <div className="filter_box_head">{t('looking_for')}</div>
                            <div className="filter_box_cnt">
                                <Select
                                    value={selectBoxvalues?.lookingFor}
                                    // defaultValue={selectBoxvalues.lookingFor}
                                    onChange={(value) => selectboxHandler(value, 'lookingFor')}
                                    options={translateOptions({ options: getPreferenceOptions?.data?.data?.relationship_goals, t })}
                                    isMulti={true}
                                    isSearchable={true}
                                    name='languages'
                                />
                                {/* <select className="form-control" name="" id="">
                                <option value="">Select</option>
                            </select> */}
                            </div>
                        </div>
                        <div className="filter_box">
                            <div className="filter_box_head">{t('age_range')}</div>
                            <div className="filter_box_cnt">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div id="slider-range2">
                                            <Slider
                                                getAriaLabel={() => 'Temperature range'}
                                                value={age}
                                                onChange={(event, value) => handleAgeChange(event, value)}
                                                valueLabelDisplay="auto"
                                                min={10}
                                                max={100}
                                                sx={{ color: '#49BEFF' }}

                                            // getAriaValueText={valuetext}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row slider-labels">
                                    <div className="col-6 caption">
                                        <strong>{t('min')}:</strong> <span id="slider-range-value3">
                                            {
                                                age[0]
                                            }
                                        </span>
                                    </div>
                                    <div className="col-6 text-end caption">
                                        <strong>{t('max')}:</strong> <span id="slider-range-value4">
                                            {
                                                age[1]
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <form>
                                            <input type="hidden" name="min-value1" value="" />
                                            <input type="hidden" name="max-value2" value="" />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="filter_box">
                            <div className="filter_box_cnt">
                                <div className="row filter_type_slct">
                                    <span><i class="fa-solid fa-church"></i> {t('religion')}</span>
                                    {/* <select className="form-control" name="" id="">
                                    <option value="">Select</option>
                                </select> */}
                                    <Select
                                        value={selectBoxvalues?.type}
                                        defaultValue={selectBoxvalues.type}
                                        onChange={(value) => selectboxHandler(value, 'type')}
                                        options={translateOptions({ options: getPreferenceOptions?.data?.data?.religions, t })}
                                        isMulti={true}
                                        isSearchable={true}
                                        name='languages'
                                    />
                                </div>
                                <div className="row filter_type_slct">
                                    <span><i className="fa-solid fa-graduation-cap"></i> {t('education')}</span>
                                    {/* <select className="form-control" name="" id="">
                                    <option value="">Select</option>
                                </select> */}
                                    <Select
                                        value={selectBoxvalues?.education}
                                        defaultValue={selectBoxvalues.education}
                                        onChange={(value) => selectboxHandler(value, 'education')}
                                        options={translateOptions({options:getPreferenceOptions?.data?.data?.education_types,t})}
                                        isMulti={true}
                                        isSearchable={true}
                                        name='languages'
                                    />
                                </div>
                                <div className="row filter_type_slct">
                                    <span><i class="fa-solid fa-language"></i> {t('languages')}</span>
                                    {/* <select className="form-control" name="" id="">
                                    <option value="">Select</option>
                                </select> */}
                                    <Select
                                        value={selectBoxvalues?.language}
                                        defaultValue={selectBoxvalues.language}
                                        onChange={(value) => selectboxHandler(value, 'language')}
                                        options={getPreferenceOptions?.data?.data?.languages}
                                        isMulti={true}
                                        isSearchable={true}
                                        name='languages'
                                    />
                                </div>
                                <div className="row filter_type_slct">
                                    <span><i className="fa-solid fa-champagne-glasses"></i> {t('drinking')}</span>
                                    {/* <select className="form-control" name="" id="">
                                    <option value="">Select</option>
                                </select> */}
                                    <Select
                                        value={selectBoxvalues?.drinking}
                                        defaultValue={selectBoxvalues.drinking}
                                        onChange={(value) => selectboxHandler(value, 'drinking')}
                                        options={translateOptions({ options: getPreferenceOptions?.data?.data?.drink_choices, t })}
                                        isMulti={true}
                                        isSearchable={true}
                                        name='languages'
                                    />
                                </div>
                                <div className="row filter_type_slct">
                                    <span><i className="fa-solid fa-smoking"></i> {t('smoking')}</span>
                                    {/* <select className="form-control" name="" id="">
                                    <option value="">Select</option>
                                </select> */}
                                    <Select
                                        value={selectBoxvalues?.smoking}
                                        defaultValue={selectBoxvalues.smoking}
                                        onChange={(value) => selectboxHandler(value, 'smoking')}
                                        options={translateOptions({ options: getPreferenceOptions?.data?.data?.smoke_choices, t })}
                                        isMulti={true}
                                        isSearchable={true}
                                        name='languages'
                                    />
                                </div>
                                <div className="row filter_type_slct">
                                    <span><i className="fa-solid fa-dumbbell"></i> {t('workout')}</span>
                                    {/* <select className="form-control" name="" id="">
                                    <option value="">Select</option>
                                </select> */}
                                    <Select
                                        value={selectBoxvalues?.workout}
                                        defaultValue={selectBoxvalues.workout}
                                        onChange={(value) => selectboxHandler(value, 'workout')}
                                        options={translateOptions({ options: getPreferenceOptions?.data?.data?.workouts, t })}
                                        isMulti={true}
                                        isSearchable={true}
                                        name='languages'
                                    />
                                </div>
                                <div className="row filter_type_slct">
                                    <span><i className="fa-solid fa-people-group"></i> {t('family_plan')}</span>
                                    {/* <select className="form-control" name="" id="">
                                    <option value="">Select</option>
                                </select> */}
                                    <Select
                                        value={selectBoxvalues?.familyPlan}
                                        defaultValue={selectBoxvalues.familyPlan}
                                        onChange={(value) => selectboxHandler(value, 'familyPlan')}
                                        options={translateOptions({ options: getPreferenceOptions?.data?.data?.family_plan_choices, t })}
                                        isMulti={true}
                                        isSearchable={true}
                                        name='languages'
                                    />
                                </div>
                                {/* <div className="row filter_type_slct">
                                <span><i className="fa-solid fa-people-group"></i> Family Plan</span>
                                
                                <Select
                                    // defaultValue={selectedOption}
                                    // onChange={(value) => selectLanguagehandler(value)}
                                    options={options}
                                    isMulti={true}
                                    isSearchable={true}
                                    name='languages'
                                />
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* <div className="row">
                <div className="col-md-12">
                    <div className="filter_box">
                        <div className="filter_box_head">Interest</div>
                        <div className="filter_box_cnt">
                            {
                                getPreferenceOptions?.data?.data?.interests?.map((item, index) => (

                                    <button
                                        key={item.id}
                                        className={`filter_box_intrstbx ${selectedInterests.some((interest) => interest.id === item.id) ? 'active' : ''}`}
                                        onClick={() => toggleInterest(item)}

                                    >
                                        {item.name}
                                    </button>
                                ))
                            }

                        </div>
                    </div>
                </div>
            </div> */}
            <div className="modal-footer">
                {/* <button type="button" className="btn regsiter_top_btn" onClick={()=>submitHandler("reset")}>Reset</button> */}
                <button type="button" className="btn regsiter_top_btn" onClick={() => submitHandler("submit")}>{t('apply')}</button>
            </div>

        </div>
    )
}

export default NormalFilter