
import { useMutation, useQuery } from "@tanstack/react-query";
import { sendReceiverId, getChatroomusers,getChats,getStickers } from "../../actions/Chat/chat";
import { useSelector } from "react-redux";
export const Chatapis = {
    CreateChatroom: () => {
      
        const response = useMutation({
            mutationFn: (data) => sendReceiverId(data),
           
        })
        return response
    },
    Chatroomusers: () => {
        const response = useQuery({
            queryFn: () => getChatroomusers(),
            queryKey: ["chat-room-users"]
        })
        return response
    },
    Getchats: (roomId) => {
        const room_id=useSelector((state)=>state.chat.room_Id)
        const apiCallingStatus=useSelector((state)=>state.chat.apiCallingstatus)
        console.log("apicallingstatus",apiCallingStatus)
        const response = useQuery({
            
            queryFn: () => getChats(roomId),
            queryKey: ["get-chats",roomId],
            enabled:(apiCallingStatus&&roomId)?true:false
        })
        return response
    },
    GetStickers:()=>{
        const response = useQuery({
            queryFn: () => getStickers(),
            queryKey: ["get-stickers"],
           
        })
        return response
    }
}
