import { testiMonialService } from "../../services/testimonialServices"



export const postTestimonial = async (data) => {
    try {
        const response = await testiMonialService.postTestimonialdata(data)
        return response
    } catch (error) {
        return error
    }
}

export const getTestimonialdata = async () => {
    try {
        const response = await testiMonialService.getTestimonialdata()
        return response
    } catch (error) {
        return error
    }
}
export const getpendingTestimonialdata = async (rowsperpage,page) => {
    try {
        const response = await testiMonialService.getpendingTestimonialdata(rowsperpage,page)
        return response
    } catch (error) {
        return error
    }
}

export const updateTestimonialstatus = async (data) => {
    try {
        const response = await testiMonialService.updateTestimonialstatus(data)
        return response
    } catch (error) {
        return error
    }
}