import React from 'react'
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from 'dayjs';
function DatePickerComponent({ className, handleDatechange, value,disabled }) {
    const dayjsDate = value ? dayjs(value) : null;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>

            <DemoContainer components={['DatePicker']}>
                <DatePicker
                    className={className}
                    onChange={handleDatechange}
                    value={dayjsDate}
                    disabled={disabled}

                />
            </DemoContainer>
        </LocalizationProvider>
    )
}

export default DatePickerComponent