import { contactusService } from "../../services/contactusServices"

export const contactusPost = async (data) => {
    try {
        const response = await contactusService.contactusPost(data)
        return response
    } catch (error) {
        return error
    }
}

export const getCompanyDetails=async()=>{
    try {
        const response = await contactusService.getCompanyDetails()
        return response
    } catch (error) {
        return error
    }
}