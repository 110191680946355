import React from 'react';
import Skeleton from 'react-loading-skeleton';

function TableSkeleton({ colCount, rowCount }) {
    console.log("=====kyc===")
    return (
        <tbody>
            {Array(rowCount).fill('').map((_, rowIndex) => (
                <tr key={rowIndex}>
                    {Array(colCount).fill('').map((_, colIndex) => (
                        <td key={colIndex}>
                            <Skeleton/>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
}

export default TableSkeleton;
