import { profileServices } from "../../services/profileServices"

export const UpdatePersonaldetails=async(data)=>{
    try {
        const response=await profileServices.updatePersonaldetails(data)
        return response
    } catch (error) {
        return error
    }

}