//local
// const BASE_URL = "http://192.168.21.15:8000/account/api/";
// const BASE="http://192.168.21.15:8000/"
// const IMG_URL="http://192.168.21.15:8000/media/"
// const FACEBOOK_APP_ID = "639260604956887"
// const WEB_SOCKET_URL="ws://192.168.21.15:8000/ws/"
// const MLM_ADMIN_URL=""
//localconnection
// const BASE_URL = "http://95.217.235.188:8000/account/api/";
// const BASE="http://95.217.235.188:8000"
// const FACEBOOK_APP_ID = "639260604956887"


//demo
const BASE_URL = "https://aleksandr.user.iossmlm.com/djangoapi/account/api/";
const BASE="https://aleksandr.user.iossmlm.com/djangoapi"
const IMG_URL="https://aleksandr.user.iossmlm.com/djangoapi/media"
const FACEBOOK_APP_ID = "639260604956887"
const WEB_SOCKET_URL="wss://aleksandr.user.iossmlm.com/djangoapi/ws/"
// const MLM_ADMIN_URL="http://192.168.21.32:8006/"
const MLM_ADMIN_URL="https://aleksandr.mlmadmin.iossmlm.com/"

//live

// const BASE_URL = "http://208.109.189.52:8000/account/api/";
// const BASE="http://208.109.189.52:8000"
// const IMG_URL="http://208.109.189.52:8000/media/"
// const FACEBOOK_APP_ID = "639260604956887"
// const WEB_SOCKET_URL="ws://208.109.189.52:8000/ws/"

export { BASE_URL,BASE,IMG_URL,FACEBOOK_APP_ID,WEB_SOCKET_URL,MLM_ADMIN_URL}