import API from "../api/api";
import { BASE_URL, MLM_ADMIN_URL } from "../config/config";
import axios from "axios";
export const profileServices = {
  getProfiledata: async () => {
    try {
      const response = await API.get("getUser-data");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  updatePersonaldetails: async (data) => {
    try {
      const response = await API.put("getUser-data", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },

  uploadPhoto: async (data) => {
    const customAxios = axios.create({
      baseURL: BASE_URL,
    });
    customAxios.defaults.headers.common["Authorization"] =
      "Bearer" + " " + localStorage.getItem("access-token") || "";

    customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    try {
      const response = await customAxios.put("upload-cover-photo", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },

  deletePhoto: async (id) => {
    try {
      const response = await API.put("delete-cover-photo", id);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  changeProfilepic: async (pic) => {
    const customAxios = axios.create({
      baseURL: BASE_URL,
    });
    customAxios.defaults.headers.common["Authorization"] =
      "Bearer" + " " + localStorage.getItem("access-token") || "";

    customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    try {
      const response = await customAxios.put("update-profile-photo", pic);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },

  dltInterests: async (data) => {
    try {
      const response = await API.put("remove_interest", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getsettingsData: async (type) => {
    try {
      const response = await API.get("get-preferences", {
        headers: {
          settingsType: type
        }
      });
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  saveSettings: async () => {
    try {
      const response = await API.put("endpoint");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getFavourites: async () => {
    try {
      const response = await API.get("follow/user/get-favorite-list");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  DltFavourite: async (id) => {
    try {
      const response = await API.post("/follow/user/add-remove-favorite", id);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getLikedusers: async (id) => {
    try {
      const response = await API.get("/follow/user/get-like-users", id);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  dltLikedusers: async (id) => {
    try {
      const response = await API.post("follow/user/like-dislike-profile", id);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  EnableTwoFA: async () => {
    try {
      return await API.patch("/enable-2fa");
    } catch (error) {
      return error;
    }
  },
  getblockedUsers: async () => {
    try {
      const response = await API.get("follow/user/get-blocked-users",);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },

  checkSponsorExist: async (data) => {
    const customAxios = axios.create({
      baseURL: MLM_ADMIN_URL,
    });

    try {
      const response = await customAxios.post(`api/sponsor-check`, data, { headers: { token: "jhj7!h8#G7%$hfGH7*HJJ7jhhggJ)6gf" } });
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }

};
