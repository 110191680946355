
import { useMutation } from "@tanstack/react-query";
import { resetPswdsubmitHandler } from "../../actions/Resetpswd/resetPswd";

export const ResetpaswdApis={ 
    SubmitResetdatas:()=>{
        const response=useMutation({
            mutationFn:(data)=>resetPswdsubmitHandler(data)
        })
        return response
    }
}
