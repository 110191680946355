import { createSlice } from "@reduxjs/toolkit";
const initialState={
    resetData:{
        password:'',
        confirmPswd:''
    },
    resetDataError:{
        password:'',
        confirmPswd:''
    }
}

export const  ResetpasswordSlice=createSlice({
    name:'resetPswd',
    initialState,
    reducers:{
        setResetdata:(state,action)=>{
            state.resetData={...state.resetData,...action.payload}
        },
        setResetdataError:(state,action)=>{
            state.resetDataError={...state.resetDataError,...action.payload}
        }
    }
})
export const {setResetdata,setResetdataError} = ResetpasswordSlice.actions
export default ResetpasswordSlice.reducer
