import React from 'react'
import nodataIMg from '../../../assets/images/nodata.jpg'
import { ProfileApis } from '../../../hooks/apihooks/profileApis'
import { IMG_URL } from '../../../config/config'
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import CardSkeleton from '../../skeletons/CardSkeleton'


function BlockedusersList({t}) {
    const getBlockeduser = ProfileApis.BlockedUsers()
    
    return (
        <div>
            <div className="my_account_right_head">
                {t('blocked')}
            </div>
            <div className="my_account_right_box">
                {
                    getBlockeduser.isLoading ?
                        <CardSkeleton type='block' iconsCount={0} />
                        :
                        getBlockeduser?.data?.data?.my_blocked_users_data.length > 0 ?
                            <div className="row">
                                {
                                    getBlockeduser?.data?.data?.my_blocked_users_data &&
                                    getBlockeduser?.data?.data?.my_blocked_users_data?.map((data, index) => {

                                        return (
                                            <div className="col-md-3">
                                                <div className="profile_match_crsl_box">
                                                    <div className="profile_match_crsl_box_img">
                                                        {/* <a className="dlete_action_btn">
                                                
                                            </a> */}
                                                        <a href={`/singleProfile/${data.user__username}`}>
                                                            <img src={data.profile_picture ? IMG_URL + data.profile_picture : defaultProfilepic} alt="" /></a>
                                                    </div>
                                                    <a href={`/singleProfile/${data.user__username}`}>
                                                        <div className="profile_cnt_bx">
                                                            {data.user__username} - {data.age ?? 25}
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                            :

                            <img src={nodataIMg} alt='nodata' className='w-50' />


                }

            </div>
        </div>
    )
}

export default BlockedusersList