import { travelServices } from "../../services/travelServices";
import { packageServices } from "../../services/packageServices";

export const getPackages = async () => {
  try {
    const response = await packageServices.getPackages();
    return response;
  } catch (error) {
    return error;
  }
};

export const addPackage = async (data) => {
  try {
    const response = await packageServices.addPackage(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const updatePackage = async (data) => {
  try {
    const response = await packageServices.updatePackage(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deletPackage = async (data) => {
  try {
    const response = await packageServices.deletePackage(data);
    return response;
  } catch (error) {
    return error;
  }
};
