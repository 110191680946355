import API from "../api/api";

export const PaypalServices = {
  createOrder: async (data) => {
    try {
      const response = await API.post("paypal-create-order",data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  captureOrder: async (data) => {
    try {
      const payload = {
        order_id: data,
      };
      const response = await API.post("paypal-capture-order", payload);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getProducts: async () => {
    try {
      const response = await API.get("packages");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getClientId:async () => {
    try {
      const response = await API.get("get-client-id");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
};
