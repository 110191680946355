import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  count:0,
  seenStatus:false
  }
  export const NotificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {  
      setNotificationCount: (state, action) => {
        state.count = action.payload
      },
      setNotiseenStatus:(state,action)=>{
        state.seenStatus=action.payload
      }
      
      
    },
  })

  export const { setNotificationCount,setNotiseenStatus} = NotificationSlice.actions

export default NotificationSlice.reducer