import API from "../api/api"

export const travelServices = {
  getTraveldata: async () => {
    try {
      const response = await API.get('travel/my-trip')
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  postTraveldata: async (data) => {
    try {
      const response = await API.post('travel/my-trip', data)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  updateTraveldata: async (data) => {
    try {
      const response = await API.put('travel/my-trip', data)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  cancelTrip: async (data) => {
    try {
      const response = await API.delete('travel/my-trip', {
        data: data
      })
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  getTravelpartners: async (looking_for,location,date) => {
    console.log("===traveldate===",date)
    try {
      const response = await API.get('travel/list-trips',{
        headers:{
          type:looking_for,
          location:location,
          tripdate:date
        }
      }
     )
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },

  SendTravelinterest: async (data) => {
    try {
      const response = await API.post('travel/request-trip',data)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  viewIncomingrequest:async (id) => {
    
    try {
      const response = await API.post('travel/requested-travels',id)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  travelRequestactions:async (data) => {
    
    try {
      const response = await API.patch('travel/requested-travels',data)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  getRequestedTrvl:async()=>{
    try {
      const response = await API.get('travel/requested-travels')
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  }

}