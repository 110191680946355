import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function CardSkeleton({ type, iconsCount }) {
    return (
        <div className="best_match_section">
            <div className='row'>
                {Array(4).fill('').map((_, index) => (
                    <div key={index} className="col-md-3">
                        <div className="profile_match_crsl_box">
                            <div className="profile_match_crsl_box_img">
                                {
                                    type != "matches" ?
                                        <div className="dlete_action_btn">
                                            <Skeleton circle={true} />
                                        </div>
                                        : ''
                                }
                                <Skeleton height={200} />
                                <div className="profile_btn_row">
                                    {
                                        Array(iconsCount).fill('').map(() => {
                                            return (

                                                <Skeleton circle={true} height={40} width={40} />
                                            )
                                        })
                                    }


                                </div>
                            </div>
                            <div className="profile_cnt_bx">
                                <Skeleton height={20} width={100} />
                                <Skeleton height={20} width={150} />
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CardSkeleton;
