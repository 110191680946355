import { chatServices } from "../../services/chatServices"

export const sendReceiverId = async (data) => {
    try {
        const response = await chatServices.sendReceiverId(data)
        return response
    } catch (error) {
        return error
    }
}
export const getChatroomusers=async()=>{
    try {
        const response=await chatServices.getChatroomusers()
        return response
    } catch (error) {
        return error
    }
}

export const getChats=async(roomId)=>{
    try {
        const response=await chatServices.Getchats(roomId)
        return response
    } catch (error) {
        return error
    }
}
export const getStickers=async()=>{
    try {
        const response=await chatServices.getStickers()
        return response
    } catch (error) {
        return error
    }
}

