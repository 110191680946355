import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';


function AboutmeSkeleton() {
    return (
        <div>
            {Array(5).fill('').map((_, index) => (
               <p><Skeleton key={index} /></p>
            ))}
        </div>
    )
}

export default AboutmeSkeleton