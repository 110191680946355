import { resetPswdServices } from "../../services/resetpswdService"

export const resetPswdsubmitHandler = async (data) => {
    try {
        const response = await resetPswdServices.submit(data)
        return response
    } catch (error) {
        return error
    }
}
