import React from 'react'
import { useTranslation } from 'react-i18next'

function InnerpageHeader(props) {
    const { t } = useTranslation()
    const { pageTitle } = props
    return (
        <section className="inner_page_header">
            <div className="container">
                <div className="inner_page_page_name">
                    <h5>{t(pageTitle)}</h5>
                    <span>
                        <a href="/">{t('home')}</a>
                        {' > '}
                        <a>{t(pageTitle)}</a>
                    </span>
                </div>
            </div>
        </section>
    )
}

export default InnerpageHeader