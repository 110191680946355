import React from 'react'

function UserProfile() {
    return (
        <section className="user_profile_view_sec">
            <div className="container">
                <div className="user_profile_view_box">
                    <div className="user_profile_view_frm_row">
                        <div className="user_profile_view_frm_box">
                            <label htmlFor="">Age</label>
                            <select name="" id="" className="form-control filter_field">
                                <option value="">22</option>
                            </select>
                        </div>
                        <div className="user_profile_view_frm_box">
                            <label htmlFor="">Looking for</label>
                            <select name="" id="" className="form-control filter_field">
                                <option value="">Female</option>
                            </select>
                        </div>
                        <div className="user_profile_view_frm_box">
                            <label htmlFor="">Interest</label>
                            <select name="" id="" className="form-control filter_field">
                                <option value="">Reading</option>
                            </select>
                        </div>
                        <div className="user_profile_view_frm_box_1">
                            <label htmlFor="">Location</label>
                            <input type="text" className="form-control filter_field" />
                        </div>
                        <div className="user_profile_view_frm_box">
                            <a href="/login" className="user_profile_view_frm_box_btn">Search</a>
                        </div>
                    </div>
                    <div className="user_profile_view_frm_row">
                        <a className="advanced_srch" href="#">Travel Companion Option <i className="fa fa-angle-down"></i></a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UserProfile