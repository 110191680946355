import React from 'react'
import InnerpageHeader from '../../common/InnerpageHeader'
import userImg1 from '../../assets/images/users/user_imag_1.jpg'
import ProfileInfo from '../../components/matches/SinglePage/ProfileInfo'
import Photos from '../../components/matches/SinglePage/Photos'
import ProfileAction from '../../components/matches/SinglePage/ProfileAction'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import useImg2 from '../../assets/images/users/user_imag_2.jpg'
import useImg3 from '../../assets/images/users/user_imag_3.jpg'
import useImg4 from '../../assets/images/users/user_imag_4.jpg'
import { MatchApis } from '../../hooks/apihooks/matchApis'
import { useParams } from 'react-router-dom'
import ProfileViewSkeleton from '../../components/skeletons/singleProfileView/ProfileViewSkeleton'
import AboutmeSkeleton from '../../components/skeletons/singleProfileView/AboutmeSkeleton'
import { SkeletonTheme } from 'react-loading-skeleton'
import PhotosSkeleton from '../../components/skeletons/singleProfileView/PhotosSkeleton'
import { useTranslation } from 'react-i18next'

function SingleProfile() {
    const { user_name } = useParams();
    const { t } = useTranslation()
    let getSinglepageData = MatchApis.MatchuserSinglepage(user_name)


    return (
        <div>
            <InnerpageHeader pageTitle="matches" />

            <section className="inner_page_main_bx_section">
                <div className="container">
                    <div className="inner_page_main_box_view">
                        {
                            getSinglepageData.isLoading ?
                                <ProfileViewSkeleton />
                                :
                                getSinglepageData?.data?.data && (
                                    <ProfileInfo t={t} data={getSinglepageData?.data?.data} />
                                )
                        }

                    </div>
                    <div className="profile_page_btm_more_dtl_box">
                        <div className="prfile_page_info_sec">
                            <div className="prfile_page_info_head">{t('about_me')}</div>
                            {
                                getSinglepageData.isLoading ?
                                    <AboutmeSkeleton />
                                    :
                                    getSinglepageData?.data?.data?.about_me ?
                                        <p dangerouslySetInnerHTML={{ __html: getSinglepageData?.data?.data?.about_me }} />
                                        :
                                        <p style={{ textAlign: 'center', color: 'gray' }}>{t('sry_no_data_found')}</p>
                            }

                            {/* <p>
                                {getSinglepageData?.data?.data?.about_me}
                            </p> */}
                        </div>
                        {
                            getSinglepageData.isLoading ?
                                <PhotosSkeleton />
                                :
                                getSinglepageData?.data?.data && (
                                    <Photos t={t} coverPhotos={getSinglepageData?.data?.data} />
                                )
                        }

                        <ProfileAction data={getSinglepageData?.data?.data} t={t} />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SingleProfile