import API from "../api/api"
import { BASE_URL } from "../config/config"
import axios from "axios"
export const kycServices = {
    getCategories: async () => {
        try {
            const response = await API.get('get-kyc-category')
            if (response.status) {
                return response
            } else {
                return response
            }
        } catch (error) {
            return error
        }
    },
    uploadKyc: async (data) => {
        const customAxios = axios.create({
            baseURL: BASE_URL,
        });
        customAxios.defaults.headers.common["Authorization"] =
            "Bearer" + " " + localStorage.getItem("access-token") || "";

        customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";
        try {
            const response = await customAxios.post('upload-kyc', data)
            if (response.status) {
                return response
            } else {
                return response
            }
        } catch (error) {
            return error
        }
    },
    getMykyc: async (username) => {
        try {
            const response = await API.get('upload-kyc',
                {
                    headers: {
                        username:'',
                        rowsperpage: 0,
                        page: 0,
                        status:4
                    }
                })
            if (response.status) {
                return response
            } else {
                return response
            }
        } catch (error) {
            return error
        }
    },
    Dlthandler: async (data) => {
        try {
            const response = await API.delete('upload-kyc', {
                data: data
            })
            if (response.status) {
                return response
            } else {
                return response
            }
        } catch (error) {
            return error
        }
    },
    getUploadedkyc: async (rowsperpage, page,searchedUsername,status) => {
        try {
            const response = await API.get('upload-kyc',
                {
                    headers: {
                        username: searchedUsername,
                        rowsperpage: rowsperpage,
                        page: page,
                        status:status?status:4
                    }
                })
            if (response.status) {
                return response
            } else {
                return response
            }
        } catch (error) {
            return error
        }
    },
    UpdateKycstatus: async (data) => {
        try {
            const response = await API.patch('upload-kyc', data)
            if (response.status) {
                return response
            } else {
                return response
            }
        } catch (error) {
            return error
        }
    },


}