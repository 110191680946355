import React from 'react'

import Footer from '../../components/Footer'
import InnerpageHeader from '../../common/InnerpageHeader'
import appStore from '../../assets/images/appstore.png'
import googlePlay from '../../assets/images/google_play.png'
import appView from '../../assets/images/app-view-mockup.png'
import simpleuseIcon from '../../assets/images/simple_use_ico.png'
import bannerImg from '../../assets/images/banner_image.png'
import { useSelector } from 'react-redux'
import AdminHeader from '../Admin/Header';
import UserHeader from '../../components/Header';
function Download() {
    const { isAdmin } = useSelector((state) => state.login)
    console.log("====isAdmin====", isAdmin)
    return (
        <div>
            {/* {
                isAdmin?<AdminHeader/>:<UserHeader/>
            } */}

          

            <InnerpageHeader pageTitle="Download" />
            <section className="inner_page_main_bx_section account_page">
                <div className="inner_page_cnt_option_two">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="app_download_section_cnt">
                                    <h5>
                                        <span>Download Now</span>
                                        Easy Connect To Everyone
                                    </h5>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                    <div className="app_download_section_btn">
                                        <a href="#/"><img src={appStore} alt="" /></a>
                                        <a href="#/"><img src={googlePlay} alt="" /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="app_download_view_img">
                                    <img src={appView} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="earnpoint_step">
                            <div className="home_headding_sec">
                                <h2>Option</h2>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <div className="our_feature_cnt_sec_box">
                                        <div className="our_feature_cnt_sec_box_ico"><img src={simpleuseIcon} alt="" /></div>
                                        <h5>Option 1</h5>
                                        <p>Lorem ipsum doler site amet</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="our_feature_cnt_sec_box">
                                        <div className="our_feature_cnt_sec_box_ico"><img src={simpleuseIcon} alt="" /></div>
                                        <h5>Option 2</h5>
                                        <p>Lorem ipsum doler site amet</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="our_feature_cnt_sec_box">
                                        <div className="our_feature_cnt_sec_box_ico"><img src={simpleuseIcon} alt="" /></div>
                                        <h5>Option 3</h5>
                                        <p>Lorem ipsum doler site amet</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="our_feature_cnt_sec_box">
                                        <div className="our_feature_cnt_sec_box_ico"><img src={simpleuseIcon} alt="" /></div>
                                        <h5>Option 4</h5>
                                        <p>Lorem ipsum doler site amet</p>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mt-5">
                                <div className="col-md-6">
                                    <div className="travel_companion_section_img"><img src={bannerImg} alt="" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="travel_companion_cnt">
                                        <h3>Download Now</h3>
                                        <p>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                        </p>
                                        <div className="app_download_section_btn">
                                            <a href="#/"><img src={appStore} alt="" /></a>
                                            <a href="#/"><img src={googlePlay} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    )
}

export default Download