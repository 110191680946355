import { kycServices } from "../../services/kycServices"

export const getCategories = async (username) => {
    try {
        const response = await kycServices.getCategories(username)
        return response
    } catch (error) {
        return error
    }
}
export const uploadKyc = async (data) => {
    try {
        const response = await kycServices.uploadKyc(data)
        return response
    } catch (error) {
        return error
    }
}
export const getMykyc = async () => {
    try {
        const response = await kycServices.getMykyc()
        return response
    } catch (error) {
        return error
    }
}
export const Dlthandler = async (data) => {
    try {
        const response = await kycServices.Dlthandler(data)
        return response
    } catch (error) {
        return error
    }
}
export const getUploadedkyc = async (rowsperpage,page,searchedUsername,status) => {
    try {
        const response = await kycServices.getUploadedkyc(rowsperpage,page,searchedUsername,status)
        return response
    } catch (error) {
        return error
    }
} 
export const UpdateKycstatus=async (data)=>{
    try {
      const response=await kycServices.UpdateKycstatus(data)  
      return response
    } catch (error) {
        return error
    }
}  