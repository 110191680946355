import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    data:{
        email:''
    },
    error:{
        field:'',
        errorMsg:''
    }
    
  }

  export const forgotPswdSlice=createSlice({
    name:'forgotPswd',
    initialState,
    reducers:{
        setForgotpswdDAta:(state,action)=>{
          
            state.data=action.payload
        },
        setError:(state,action)=>{
          
           state.error={...state.error,...action.payload}
        }
    }
  })

  export const {setForgotpswdDAta,setError} = forgotPswdSlice.actions
  export default forgotPswdSlice.reducer
  