import { travelServices } from "../../services/travelServices"

export const getTraveldata = async () => {
    try {
        const response = await travelServices.getTraveldata()
        return response
    } catch (error) {
        return error
    }
}


export const postTraveldata = async (data) => {
    try {
        const response = await travelServices.postTraveldata(data)
        return response
    } catch (error) {
        return error
    }
}

export const updateTraveldata = async (data) => {
    try {
        const response = await travelServices.updateTraveldata(data)
        return response
    } catch (error) {
        return error
    }
}

export const cancelTrip = async (data) => {
    try {
        const response = await travelServices.cancelTrip(data)
        return response
    } catch (error) {
        return error
    }
}
export const getTravelpartners = async (looking_for,location,date) => {
    try {
        const response = await travelServices.getTravelpartners(looking_for,location,date)
        return response
    } catch (error) {
        return error
    }
}
export const SendTravelinterest = async (data) => {
    try {
        const response = await travelServices.SendTravelinterest(data)
        return response
    } catch (error) {
        return error
    }
}

export const viewIncomingrequest=async(id)=>{
    try {
        const response = await travelServices.viewIncomingrequest(id)
        return response
    } catch (error) {
        return error
    }
}

export const travelRequestactions=async(data)=>{
    try {
        const response = await travelServices.travelRequestactions(data)
        return response
    } catch (error) {
        return error
    }
}
export const getRequestedTrvl=async()=>{
    try {
        const response = await travelServices.getRequestedTrvl()
        return response
    } catch (error) {
        return error
    }
}


