import React from 'react'
import bannerRad from '../../assets/images/banner-rad.png'
import bannerImg from '../../assets/images/banner_image.png'
import bannerDot from '../../assets/images/banner-dots.png'
import bannerObj1 from '../../assets/images/banner-object-1.png'
import bannerObj2 from '../../assets/images/banner-object-2.png'
import bannerObj3 from '../../assets/images/banner-object-3.png'
import bannerObj4 from '../../assets/images/banner-object-4.png'
import bannerObj5 from '../../assets/images/banner-object-5.png'
import bannerObj6 from '../../assets/images/banner-object-6.png'
function Banner() {
    return (
        <section className="banner_main_sec">
            <div className="container">
                <div className="banner_left_content_box">
                    <h1>Best Ways to Find Your True Soulmate</h1>
                    <p>
                        You find us, finally, and you are already in love. More than 4.000.000 around the world already shared the same experiences and use our system. Joining us today just got easier!
                    </p>
                    <a href="/register" className="banner_join_btn">+ Join Now</a>
                    <div className="banner_social_sec">
                        <a href="#">
                            <div className="banner_social_ico">
                                <div className="banner_social_img"><i className="fa-brands fa-facebook"></i></div>
                                <span>Facebook</span>
                            </div>
                        </a>
                        <a href="#">
                            <div className="banner_social_ico">
                                <div className="banner_social_img"><i className="fa-brands fa-instagram"></i></div>
                                <span>Instagram</span>
                            </div>
                        </a>
                        <a href="#">
                            <div className="banner_social_ico">
                                <div className="banner_social_img"><i className="fa-brands fa-twitter"></i></div>
                                <span>Twitter</span>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <div className="banner_right_img_sec">
                <div className="banner_right_bg"><img src={bannerRad}alt="" /></div>
                <div className="banner_right_img_bx"><img src={bannerImg} alt="" /></div>
                <div className="banner_right_dot"><img src={bannerDot} alt="" /></div>
                <div className="banner_right_obj_1"><img src={bannerObj1} alt="" /></div>
                <div className="banner_right_obj_6"><img src={bannerObj6} alt="" /></div>
                <div className="banner_right_obj_2"><img src={bannerObj2} alt="" /></div>
                <div className="banner_right_obj_3"><img src={bannerObj3} alt="" /></div>
                <div className="banner_right_obj_4"><img src={bannerObj3}alt="" /></div>
                <div className="banner_right_obj_5"><img src={bannerObj5} alt="" /></div>
                <div className="banner_right_obj_7"><img src={bannerObj4} alt="" /></div>
            </div>
        </section>
    )
}

export default Banner