import React from 'react'
import { Form } from 'react-bootstrap'
import { Pagination } from '@mui/material'
import nodataIMg from '../../assets/images/nodata.jpg'
import { userReportsApis } from '../../hooks/apihooks/userReportsApis'
import { useSelector } from 'react-redux'
import { IMG_URL } from '../../config/config'
import defaultProfilepic from '../../assets/images/user-blue-gradient.png'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setPagination } from '../../store/reducers/paginationReducer';
import TableSkeleton from '../skeletons/TableSkeleton'


function UserReports({t}) {
    const dispatch=useDispatch();
    const { currentPage, totalPages, RowsperPage } = useSelector((state) => state.pagination.pagination)
    const getUserReports = userReportsApis.GetjoiningReport(RowsperPage, currentPage)
    const handleChange = async (event, value) => {
        console.log(`Selected Page: ${value}`);

        dispatch(setPagination({ totalPages: totalPages, currentPage: value, RowsperPage: RowsperPage }))


    };
    const rowPerpagehandler = async (e) => {
        const totalPages = Math.ceil(getUserReports?.data?.data?.users_count / e.target.value)
        dispatch(setPagination({ totalPages: totalPages, RowsperPage: e.target.value }))
    }

    return (
        <div>
            <div className="my_account_right_head">
                {t('user_reports')}
            </div>

            <div className="my_account_right_box">
              

                <div className="my_account_right_box">
                    {
                        // getApprovaldata?.data?.data?.kyc_list &&
                        <>
                            <table>
                                <thead>
                                    <th>{t('username')}</th>
                                    <th>{t("gender")}</th>
                                    <th>{t('orientation')}</th>
                                    <th>{t('mail')}</th>
                                    <th>{t('location')}</th>
                                    <th>{t('date_of_joining')}</th>



                                </thead>
                                {
                                    getUserReports.isLoading?
                                    <TableSkeleton colCount={6} rowCount={4}/>
                                    :
                                    <tbody>
                                    {
                                        getUserReports?.data &&
                                        getUserReports?.data?.data?.users.map((items, index) => {
                                            
                                            return (
                                                <tr>
                                                    <td>
                                                        <img className='testimonial-profilePic' src={items.profile_picture ? IMG_URL + items.profile_picture.image : defaultProfilepic} />
                                                        {items.username}
                                                    </td>
                                                    <td>
                                                        {t(items.gender)??'NA'}
                                                    </td>
                                                    <td>
                                                        {t(items.orientation)??'NA'}
                                                    </td>
                                                    <td>
                                                        {items.email??'NA'}

                                                    </td>

                                                    <td>
                                                        {items.location??'NA'}
                                                    </td>

                                                    <td>
                                                        {moment(items.date_joined).format('DD-MM-YYYY')}
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>

                                }
                                

                            </table>
                            <div className="row table_ftr_pagination">
                                <div className="col-md-4">
                                    {t('rows_per_page')} : <select
                                        onChange={rowPerpagehandler}
                                        value={RowsperPage}
                                    >
                                        <option>5</option>
                                        <option>10</option>
                                        <option>20</option>
                                    </select>
                                </div>
                                <div className="col-md-8">
                                    <Pagination
                                        count={totalPages}
                                        page={currentPage}
                                        color="primary"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                        </>

                    }
                    {
                        getUserReports?.data?.data?.users?.length === 0 &&
                        <img src={nodataIMg} alt='nodata' className='w-50' />
                    }




                </div>

            </div>
        </div>
    )
}

export default UserReports