import { useMutation, useQuery } from "@tanstack/react-query";
import {
  CreateOrder,
  CaptureOrder,
  GetProducts,
  GetClientId,
} from "../../actions/Paypal/PaypalApi";
export const CreateOrderApis = {
  CreateOrder: () => {
    const response = useMutation({
      mutationFn: (data) => CreateOrder(data),
    });
    return response;
  },
};

export const CaptureOrderApi = {
  CaptureOrder: (data) => {
    const response = useMutation({
      mutationFn: (data) => CaptureOrder(data),
    });
    return response;
  },
};

export const GetProductsApi = {
  GetProducts: () => {
    const response = useQuery({
      queryKey: ["get-products"],
      queryFn: () => GetProducts(),
    });
    return response;
  },
};

export const GetClientIdApi = {
  GetClientId: () => {
    const response = useQuery({
      queryKey: ["get-client-id"],
      queryFn: () => GetClientId(),
    });
    return response;
  },
};
