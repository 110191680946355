import React from "react";
import Myaccount from "../../components/profile/Myaccount/Myaccount";
import InnerpageHeader from "../../common/InnerpageHeader";
import ProfilePic from "../../assets/images/pofile-image.jpg";
import Chat from "../../components/profile/Messages/Chat";
import { useState } from "react";
import Favourite from "../../components/profile/Favourite/Favourite";
import Liked from "../../components/profile/Liked/Liked";
import WhofavouritedU from "../../components/profile/WhofavtedU/WhofavouritedU";
import WholikedU from "../../components/profile/WholikedU/WholikedU";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { BASE, IMG_URL } from "../../config/config";
import { Modal, ModalBody, ModalHeader, Form } from "react-bootstrap";
import { ProfileApis } from "../../hooks/apihooks/profileApis";
import { useQueryClient } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import defaultProfilepic from "../../assets/images/user-blue-gradient.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/profile/Myaccount/Notification";
import Travel from "../../components/profile/Travel/Travel";
import Testimonial from "../../components/profile/Testimonial/Testimonial";
import { mlmApis } from "../../hooks/apihooks/mlmApis";
import moment from "moment";
import { showToastmsg } from "../../common/ToastMsg";
import BlockedusersList from "../../components/profile/Blockedusers/BlockedusersList";
import Kyc from "../../components/profile/Kyc/Kyc";
import notVerified from "../../assets/images/shield(1).png"
import verified from "../../assets/images/security.png"
import premiumAccount from '../../assets/images/premium.png'
import ChangePassword from "../../components/profile/Myaccount/ChangePassword";
import referralImg from '../../assets/images/referral.png'
import Loader from "react-js-loader";
import { Bars } from 'react-loader-spinner'
import Skeleton from 'react-loading-skeleton'
import ProfileSkeleton from "../../components/skeletons/ProfileSkeleton";
import { useTranslation } from "react-i18next";


function Profile() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const [modalShow, setmodalShow] = useState(false);
  const [selectedPic, setselectedPic] = useState(null);
  const [selectedFilename, setselectedFilename] = useState("");
  const profilePicChange = ProfileApis.Changeprofilepic();
  const CheckSponsorExist = mlmApis.ChecksponsorExist()
  const queryClient = useQueryClient();
  const getProfiledata = ProfileApis.GetProfileData();
  const [selectedTab, setselectedTab] = useState("my-account");
  const ActivateMlm = mlmApis.RegisterTomlm()
  const location = useLocation();
  const [selectedRdio, setselectedRdio] = useState("")
  const [referrerName, setreferrerName] = useState("")
  const [referrerErr, setreferrerErr] = useState("")
  const [sponsorExist, setsponsorExist] = useState(false)
  const profileDatas = useSelector((state) => state.profile.profileData);



  const rightProfilebars = [
    {
      path: "/profile/my-account",
      icon: "fa-regular fa-user",
      tab: t("my_account"),
      selectedTab: "my-account",
    },
    {
      path: "/kyc",
      icon: "fa-regular fa-address-card",
      tab: t("kyc"),
      selectedTab: "kyc",
    },
    {
      path: `${profileDatas.package_expired ? "/upgrade" : "/profile/chat"}`,
      icon: "fa-regular fa-message",
      tab: t("message"),
      selectedTab: "chat",
    },
    {
      path: "/profile/favourites",
      icon: "fa-regular fa-star",
      tab: t("favourite"),
      selectedTab: "favourites",
    },
    {
      path: "/profile/liked",
      icon: "fa-regular fa-heart",
      tab: t("liked"),
      selectedTab: "liked",
    },
    {
      path: "/profile/blockedusers",
      icon: "fa-solid fa-ban",
      tab: t("blocked_users"),
      selectedTab: "blockedusers",
    },
    {
      path: `${profileDatas.package_expired ? "/upgrade" : "/profile/who-favourite-me"}`,
      icon: "fa-regular fa-star",
      tab: t("who_fvrtd_u"),
      selectedTab: "who-favourite-me",
    },
    {
      path: `${profileDatas.package_expired ? "/upgrade" : "/profile/who-liked-me"}`,
      icon: "fa-regular fa-heart",
      tab: t("who_liked_u"),
      selectedTab: "who-liked-me",
    },
    {
      path: "/profile/testimonial",
      icon: "fa-regular fa-comments",
      tab: t("testimonial"),
      selectedTab: "testimonial",
    },
    {
      path: "/profile/travel",
      icon: "fa-regular fa-paper-plane",
      tab: t("travel"),
      selectedTab: "travel",
    },
    {
      path: "/profile/notification",
      icon: "fa-regular fa-bell",
      tab: t("notification"),
      selectedTab: "notification",
    },
    {
      path: "/change-password",
      icon: "fa-solid fa-key",
      tab: t("pswd"),
      selectedTab: "change-password",
    },


  ];

  const handleClose = () => {
    setmodalShow(false);
  };

  const photoSelecthandler = async (e) => {
    let formData = new FormData();
    setselectedPic(e.target.files[0]);
    setselectedFilename(e.target.files[0]?.name);
    formData.append("profile_picture", e.target.files[0]);
    const response = await profilePicChange.mutateAsync(formData);
    if (response) {
      queryClient.invalidateQueries({ queryKey: ["profileData"] });
      setmodalShow(false);
    } else {
      showToastmsg("error", "File upload failed")
    }
  };
  // const uploadHandler = async () => {
  //     try {
  //         let formData = new FormData();
  //         formData.append("profile_picture", selectedPic)
  //         const response = await profilePicChange.mutateAsync(formData)
  //         if (response) {
  //             queryClient.invalidateQueries({ queryKey: ["profileData"] });
  //             setmodalShow(false)
  //         }
  //     } catch (error) {
  //         console.log(error)
  //     }

  // }



  const selectedTabHandler = (tab) => {
    setselectedTab(tab);
    toast.dismiss();
  };

  useEffect(() => {
    if (location.pathname == "/profile") {
      setselectedTab("my-account");
    } else {
      const pathSegments = location.pathname.split("/");
      const lastPathSegment = pathSegments[pathSegments.length - 1];
      setselectedTab(lastPathSegment);
    }
  }, [location.pathname]);


  const changeProfilepic = () => {
    setmodalShow(true);
    setselectedFilename("");
  };

  const activateTomlmHandler = async () => {
    try {
      setmodalShow(true)

    } catch (error) {
      console.log(error)
    }
  }

  const refferalRadios = [
    {
      name: "referral-status",
      label: "Yes",
      type: "radio",
      value: "yes"
    },
    {
      name: "referral-status",
      label: "No",
      type: "radio",
      value: "no"
    },
  ]

  const radioChangeHandler = (e, selectedValue) => {
    setselectedRdio(selectedValue)
  }

  const activateMlm = async () => {
    try {

      const response = await ActivateMlm.mutateAsync({ sponsorName: referrerName })

      if (response.status) {
        setreferrerName("")
        setselectedRdio("")
        showToastmsg("success", "Successfully registered to mlm", "top")
        setmodalShow(false)
        await queryClient.invalidateQueries({
          queryKey: ["profileData"],
        });
      } else {
        setreferrerName("")
        setselectedRdio("")
        showToastmsg("error", "Registration failed", "top")
        setmodalShow(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const referrerInputhandler = (e) => {
    const { value } = e.target
    setreferrerErr("")
    setreferrerName(value)
  }

  const checkSponsorExist = async () => {

    try {
      if (selectedRdio === "yes" && !referrerName) {
        return setreferrerErr("Referrer name is required*")
      }

      const response = await CheckSponsorExist.mutateAsync({ sponsorName: referrerName })
      if (response.status) {
        setsponsorExist(true)
      } else {
        setreferrerErr("Invalid data*")
      }
    } catch (error) {

    }
  }


  return (
    <>
      <InnerpageHeader
        pageTitle='my_account' />

      <section className="inner_page_main_bx_section account_page">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">

              <div className="inner_page_main_box_view">
                <div className="myaccount_profile_section">
                  {/* <a className="myaccount_profile_top_edit" onClick={changeProfilepic}>Edit Profile
                                    </a> */}
                  <div className="myaccount_profile_box">
                    {
                      !profileDatas.package_expired &&
                      <div className="premium_sts_ico"><img src={premiumAccount} alt="" />
                      </div>
                    }

                    <div className="myaccount_profile_box_img">
                      <span className="account-verified">
                        {
                          profileDatas?.user?.is_verified ?
                            <img src={verified} /> : <img src={notVerified} />
                        }

                      </span>
                      <img
                        src={
                          profileDatas?.profile_picture
                            ? IMG_URL + profileDatas?.profile_picture
                            : defaultProfilepic
                        }
                        alt=""
                      />

                      <span className="editProfilepic">
                        <i
                          class="fa-solid fa-pen-to-square"
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                        >
                          <input
                            type="file"
                            onChange={photoSelecthandler}
                            style={{ display: "none" }}
                            id="file-input"
                          />
                        </i>
                      </span>
                    </div>
                    <div className="myaccount_profile_box_name">
                      <h5>{profileDatas?.user?.first_name}</h5>
                      <p>{profileDatas?.user?.username}</p>
                      <p> {profileDatas?.user?.package_name}</p>
                      {
                        profileDatas?.user?.package_validity &&
                        <p ><span>{t('expiry_on')} :</span> <span className="packageEXipry">{moment.utc(profileDatas?.user?.package_validity).format('DD-MM-YYYY')}</span></p>
                      }

                    </div>
                    {
                      (profileDatas?.user?.package_expired == false &&
                        profileDatas?.user?.mlm_status != "active")
                      &&
                      <button
                        className="myaccount_profile_upgrad_btn"
                        onClick={activateTomlmHandler}
                      >
                        {t('activate_mlm')}
                      </button>
                    }
                    {

                      // <button
                      //   className="myaccount_profile_upgrad_btn"
                      //   onClick={activateTomlmHandler}
                      // >
                      //   Activate MLM
                      // </button>
                    }
                    {
                      profileDatas.package_expired && (
                        <button
                          className="myaccount_profile_upgrad_btn"
                          onClick={() => {
                            navigate('/upgrade');
                          }}
                        >
                          {t('upgrade_profile')}
                        </button>
                      )
                    }

                    <div className="myaccount_profile_left_navigation">
                      <ul>
                        {rightProfilebars.map((items, index) => {

                          return (
                            <li
                              className={
                                selectedTab == items.selectedTab ? "active" : ""
                              }
                              key={index}
                            >

                              <NavLink
                                to={items.path}
                                className={({ isActive, isPending }) =>
                                  isPending
                                    ? "pending"
                                    : isActive
                                      ? "active"
                                      : ""
                                }
                              >
                                <div className="prfl_nav_ico">
                                  <i className={items.icon}></i>
                                </div>
                                <span>{items.tab}</span>
                              </NavLink>


                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-xl-9 col-lg-8">
              <div className="my_account_right_sec">
                {selectedTab == "my-account" ? (
                  getProfiledata.isLoading ?
                    <ProfileSkeleton /> :
                    getProfiledata?.data && (
                      <Myaccount profileData={getProfiledata?.data}
                        t={t}
                      />
                    )
                ) : selectedTab == "chat" ? (
                  <Chat
                    isLoading={getProfiledata.isLoading}
                    t={t}
                  />
                ) : selectedTab == "favourites" ? (
                  <Favourite t={t} />
                ) : selectedTab == "liked" ? (
                  <Liked t={t} />
                ) : selectedTab == "who-favourite-me" ? (
                  <WhofavouritedU t={t} />
                ) : selectedTab == "who-liked-me" ? (
                  <WholikedU t={t} />
                ) : selectedTab == "travel" ? (
                  <Travel t={t} />
                ) : selectedTab == "notification" ? (
                  <Notification t={t} />
                ) : selectedTab == "testimonial" ? (
                  <Testimonial t={t} />
                ) : selectedTab == "blockedusers" ?
                  <BlockedusersList t={t} />
                  : selectedTab == "kyc" ?
                    <Kyc /> :
                    selectedTab == "change-password" ?
                      <ChangePassword /> : (
                        ""
                      )}
              </div>
            </div>


          </div>
        </div>
      </section>

      <Modal
        show={modalShow}
        onHide={handleClose}
        backdrop="static"
        centered
      >
        <ModalHeader closeButton style={{ border: 'none' }} />
        <ModalBody className="p-0" >
          <form className="form-container" encType='multipart/form-data'>
            <div className="referral-modal-container">
              <div className="drag-file-area-referral">
                <span className="material-icons-outlined upload-icon">{t('do_u_have_referral')}</span>
                <img src={referralImg} />
                {
                  refferalRadios.map((items, index) => {
                    return (
                      <Form.Check
                        inline
                        label={items.label}
                        name={items.name}
                        type={items.type}
                        checked={items.value === selectedRdio}
                        onChange={(e) => radioChangeHandler(e, items.value)}
                      />
                    )

                  })
                }
              </div>
              {
                selectedRdio === "yes" &&
                <>
                  <div className="referrer-name-input">
                    <input
                      type="text"
                      className={`${referrerErr ? "erorInput " : ""
                        }form-control m-1`}
                      placeholder={t("nter_ur_referrer_name")}
                      onChange={referrerInputhandler}
                    />

                    {
                      sponsorExist ?
                        <span className="valid-icon"><i class="fa-solid fa-circle-check fa-2xl"></i></span>
                        : <button type="button" className="check-referrerbtn" onClick={checkSponsorExist}> {t("check")}</button>
                    }

                  </div>

                  {referrerErr &&
                    <p role="alert" className="formError">
                      <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                      &nbsp;{referrerErr}
                    </p>
                  }
                </>

              }

              <span className="cannot-upload-message">
                <span className="material-icons-outlined">error</span>
                {t("pls_slct_a_file_frst")}
                <span className="material-icons-outlined cancel-alert-button">cancel</span>
              </span>

              {
                (sponsorExist || selectedRdio == "no") &&
                <button type="button" className="referreral-submitbutton" disabled={ActivateMlm.isLoading} onClick={activateMlm} >
                  {
                    ActivateMlm.isLoading ?
                      <Bars
                        height="30"
                        width="30"
                        color="white"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                      : t("Submit")
                  }

                </button>
              }


            </div>

          </form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Profile;
