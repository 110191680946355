import React from 'react'
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { useState } from 'react';
import { ProfileApis } from '../../../hooks/apihooks/profileApis';
import { showToastmsg } from '../../../common/ToastMsg';
import { useQueryClient } from '@tanstack/react-query';


function About({aboutData,t}) {
    
    const queryClient=useQueryClient()
    const postAboutdata=ProfileApis.EditpersonalDetails()
    const [aboutContent, setaboutContent] = useState(aboutData??'')
    const [saveBtnstatus, setsaveBtnstatus] = useState(false)
    const handleProcedureContentChange = (content) => {
        setaboutContent(content)
      };
    var modules = {
        toolbar: [
            [{ size: ["small", false, "large", "huge"] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],

            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
                { align: [] }
            ],
            [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
        ]
    };

    var formats = [
        "header", "height", "bold", "italic",
        "underline", "strike", "blockquote",
        "list", "color", "bullet", "indent",
        "align", "size",
    ];

    const saveHandler=async()=>{
        let data={
            about_me:aboutContent
        }
        const response=await postAboutdata.mutateAsync(data)
        if(response.status){
            showToastmsg('success',t("data_saved_successfully"))
            queryClient.invalidateQueries({queryKey:["profileData"]})
        }else{
            
            showToastmsg('error',response?.about_me[0])
        }   
    }
   
    return (
        <div>
            <h5>{t('about')}</h5>
            <div className="profile_right_intrst_cnt_sc">
                
                <ReactQuill               
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder={t("write_ur_contnt")}
                    onChange={handleProcedureContentChange}
                    value={aboutContent}
                    style={{ height: "220px" }}
                    
                />
            </div>
            {/* <button className="myaccount_profile_upgrad_btn" onClick={editHandler}>{Isedit ? 'Cancel' : 'Edit'}</button> */}
            <button className="myaccount_profile_upgrad_btn" onClick={saveHandler}>{t('save')}</button>
        </div>
    )
}

export default About