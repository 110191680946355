import React from "react";
import flag from "../assets/images/uk_flagpng.png";
import logo from "../assets/images/logo.png";
import $ from "jquery";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import userProfilepic from "../assets/images/users/user_imag_1.jpg";
import { BASE, IMG_URL, WEB_SOCKET_URL } from "../config/config";
import { LoginApis } from "../hooks/apihooks/loginApis";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIslogged } from "../store/reducers/authReducer";
import { showToastmsg } from "../common/ToastMsg";
import { useState } from "react";
import { toast } from "react-toastify";
import { NotificationApis } from "../hooks/apihooks/notificationApis";
import defaultProfilepic from "../assets/images/user-blue-gradient.png";
import { Link } from "react-router-dom";
import moment from "moment";
import { setSelectedLanguage } from "../store/reducers/languageReducer";
import i18n from "../i18n";
import { languageApis } from "../hooks/apihooks/languageApis";
import { useTranslation } from "react-i18next";
import { useQueryClient } from '@tanstack/react-query';

function Header({ languages }) {

    const { t } = useTranslation()
    const queryClient=useQueryClient()
    const { isLogged } = useSelector((state) => state.login)
    const logout = LoginApis.Logout()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation()
    const notificationCount = useSelector((state) => state.notification.count)
    const seenStatus = useSelector((state) => state.notification.seenStatus)
    let getLoginUserdata = LoginApis.LoginUserdata()
    const getNotifications = NotificationApis.GetNotification()
    const postSeenStatus = NotificationApis.SeenNotifications()
    const [msgData, setmsgDAta] = useState("")
    const [SeenNoti, setSeenNoti] = useState(false)
    const selectedLan = useSelector((state) => state?.languages.language)
    const updateLan = languageApis.UpdateLanguage()

    useEffect(() => {
        const handleScroll = () => {
            var sticky = $('.sticky'),
                scroll = $(window).scrollTop();

            if (scroll >= 100) sticky.addClass('fixed');
            else sticky.removeClass('fixed');
        };
        $(window).on('scroll', handleScroll);
        return () => {
            $(window).off('scroll', handleScroll);
        };
    }, []);

    const logoutHandler = async () => {
        await logout.mutateAsync()
        localStorage.clear()
        dispatch(setIslogged(false))
        navigate('/login')
    }

    useEffect(() => {

        if (getLoginUserdata?.data?.data?.user_id) {
            // const ws = new WebSocket(`ws://192.168.21.15:8000/ws/notification/${getLoginUserdata?.data?.data.user_id}/`);
            // const ws = new WebSocket(`ws://95.217.235.188:8000/ws/notification/${getLoginUserdata?.data?.data.user_id}/`);
            const ws = new WebSocket(`${WEB_SOCKET_URL}notification/${getLoginUserdata?.data?.data.user_id}/`);
            // const ws = new WebSocket(`ws://208.109.189.52:8000/ws/notification/${getLoginUserdata?.data?.data.user_id}/`);
            ws.onopen = () => {
                ws.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };
                ws.onclose = (event) => {
                    console.log('WebSocket connection closed:', event);
                    // toast.dismiss()
                };

                console.log('WebSocket connection opened');

            };
            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                showToastmsg('success', data?.message, 'top')
                // toast.success(data?.message)             
            };

        }

    }, [getLoginUserdata?.data])

    const publicHeaders = [
        {
            titile: 'Home',
            path: '/',

        },
        {
            titile: 'Earn Point',
            path: '/earn-point',

        },
        {
            titile: 'Download',
            path: '/download',

        },
        {
            titile: 'Login',
            path: '/login',

        },

    ]

    const privateHeaders = [
        {
            titile: t('home'),
            path: '/profile',

        },
        {
            titile: t('matches'),
            path: '/matches',

        },
        {
            titile: t('travel'),
            path: '/travel',

        },
        {
            titile: t('message'),
            path: '/profile/chat'
        },

        {
            titile: t("download"),
            path: '/download',

        },
        {
            titile: t('earn_point'),
            path: '/earn-point',

        },


    ]
    const viewAllhandler = async () => {
        const response = await postSeenStatus.mutateAsync()
        if (response.status) {
            setSeenNoti(true)
            navigate('/profile/notification')

        }

    }

    const languageChangeHandler = async (items) => {
        try {

            const langCode = items?.language_code
            dispatch(setSelectedLanguage(items))
            i18n.changeLanguage(langCode)
         await updateLan.mutateAsync(langCode)
         queryClient.invalidateQueries({queryKey:["profileData"]})
        } catch (error) {

        }
    }
    console.log("==selectedLan===",selectedLan)
    return (
        <header className="main_header sticky">
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="#"><img src={logo} alt="" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            {
                                isLogged ? (
                                    privateHeaders.map((items, index) => {
                                        console.log("==items===", items)
                                        return (
                                            <li className="nav-item">
                                                <Link className={`nav-link ${items.path == location.pathname ? `active` : ''}`} to={items.path} isActive>{items.titile}</Link>

                                            </li>
                                        )
                                    })

                                )
                                    :
                                    publicHeaders.map((items, index) => {
                                        return (
                                            <li className="nav-item">
                                                <Link className={`nav-link ${items.path == location.pathname ? `active` : ''}`} to={items.path} isActive>{items.titile}</Link>

                                            </li>
                                        )
                                    })
                            }

                            {
                                isLogged &&
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <div className="lang_img_bx"><img src={IMG_URL + selectedLan.language_logo ?? flag} alt="" /></div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        {languages &&
                                            languages?.map((items, index) => (
                                                <li onClick={() => languageChangeHandler(items)}>
                                                    {/* <a className="dropdown-item" href="#">{items.site_language}</a> */}
                                                    <Link className={`${`dropdown-item ${items?.language_code === selectedLan?.language_code ? `active` : ``}`}`} to='#' style={{ textTransform: 'capitalize' }}>{items.site_language}</Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </li>
                            }

                            {
                                isLogged && (
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle notification_btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <span class="notification_ico">
                                                {
                                                    notificationCount &&
                                                        notificationCount > 0 ?
                                                        <i class="fa-regular fa-bell fa-shake"></i>
                                                        : <i class="fa-regular fa-bell"></i>
                                                }

                                                <span>
                                                    {
                                                        notificationCount &&
                                                        notificationCount
                                                    }

                                                </span>
                                            </span>
                                        </a>
                                        <ul class="dropdown-menu notification_drp_sec">
                                            <div class="user_prfl_top_hdr">
                                                <span>{t('notifications')}</span>
                                            </div>

                                            <div class="notification_scrl">
                                                {
                                                    !seenStatus ?
                                                        getNotifications?.data?.data?.notifications &&

                                                        getNotifications?.data?.data?.notifications.slice(0, 2).map((items, index) => {
                                                            return (
                                                                <li>
                                                                    <a className='notification_list_row_new' href={`/singleProfile/${items.from_user}`}>
                                                                        <div className="usr_prfl_box">
                                                                            {

                                                                                <img src={items.profile_picture ? IMG_URL + items.profile_picture : defaultProfilepic} alt="" />
                                                                            }
                                                                        </div>
                                                                        <div className="notification_list_txt">
                                                                            <p>{items.description}</p>
                                                                            <span>{
                                                                                moment(items.date_added).format('DD-MM-YYYY')
                                                                            }</span>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                            )
                                                        })
                                                        : <li>
                                                            <a href="#/">
                                                                <p>{t('no_new_noti')}</p>

                                                            </a>
                                                        </li>
                                                }


                                            </div>
                                            {
                                                !seenStatus && (
                                                    <li class="view_all_btn">
                                                        <a href="#" onClick={viewAllhandler}>{t('view_all')}</a>
                                                    </li>
                                                )
                                            }

                                        </ul>
                                    </li>
                                )
                            }
                            {
                                isLogged && (
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle user_prfl_top_hdr" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            <div class="usr_prfl_box">
                                                {
                                                    getLoginUserdata?.data &&
                                                    <img src={getLoginUserdata?.data?.data?.profile_picture ? IMG_URL + getLoginUserdata?.data?.data?.profile_picture : defaultProfilepic} alt="" />
                                                }
                                            </div>
                                            <span>
                                                {
                                                    getLoginUserdata?.data?.data &&
                                                    getLoginUserdata?.data?.data?.username

                                                }
                                            </span>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <div class="user_prfl_top_hdr">
                                                <div class="usr_prfl_box">
                                                    {
                                                        getLoginUserdata?.data &&
                                                        <img src={getLoginUserdata?.data?.data?.profile_picture ? IMG_URL + getLoginUserdata?.data?.data?.profile_picture : defaultProfilepic} alt="" />
                                                    }
                                                </div>
                                                <span>
                                                    {
                                                        getLoginUserdata?.data?.data &&
                                                        getLoginUserdata?.data?.data?.username

                                                    }
                                                </span>
                                            </div>
                                            <li>

                                                <Link class="dropdown-item" to="/profile">{t('profile')}</Link>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" href="#" onClick={logoutHandler}>{t('logout')}</a>
                                            </li>
                                        </ul>
                                    </li>
                                    // <li className="nav-item dropdown user_profile_top">
                                    //     <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    //         <div className="logined_user_prfl">
                                    //             <div className="logined_user_prfl_img">
                                    //                 {
                                    //                     getLoginUserdata?.data &&
                                    //                     <img src={IMG_URL + getLoginUserdata?.data?.data?.profile_picture} alt="" />
                                    //                 }

                                    //             </div>
                                    //             <div className="logined_user_prfl_txt">
                                    //                 {
                                    //                     getLoginUserdata?.data?.data &&
                                    //                     getLoginUserdata?.data?.data?.username

                                    //                 }

                                    //             </div>
                                    //         </div>
                                    //     </a>
                                    //     <ul class="dropdown-menu">
                                    //         <li><a className="dropdown-item" href="/profile">My Account</a></li>
                                    //     </ul>
                                    // </li>
                                )
                            }
                            {
                                !isLogged && (
                                    <li className="nav-item">
                                        <Link to="/register" className="nav-link regsiter_top_btn" >+ Register</Link>
                                    </li>
                                )
                            }

                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}
export default Header;
