import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import ListGroup from 'react-bootstrap/ListGroup';


function TravelFilter({ getFilterData,t }) {
    const [locationSuggestions, setlocationSuggestions] = useState([])
    const [loader, setloader] = useState(false)
    const [data, setdata] = useState({
        latitude: '',
        longitude: '',
        location_name: '',
        looking_for: '',
        date:''
    })

    const handleChangehandler = async (e) => {
        const { name, value } = e.target
        if (name === "location") {
            setdata({
                ...data,
                location_name: value
            })
            try {
                setloader(true)
                const response = await axios.get(
                    `https://nominatim.openstreetmap.org/search?format=json&q=${value}`
                );
                if (response.data) {
                    setlocationSuggestions(response.data);
                    setloader(false)
                }




            } catch (error) {
                console.error('Error fetching location data:', error);
            }
        }else{
            setdata({
                ...data,
                [name]:value
            })
        }
    }

    const selectHandler = (latitude, longitude, name) => {
        setdata({
            ...data,
            latitude: latitude,
            longitude: longitude,
            location_name: name

        })
       setlocationSuggestions([])
    }
    const filterSubmithandler = () => {
        getFilterData(data)
    }
   
    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    <div className="filter_box">
                        <div className="filter_box_head">{t('trvl_location')}</div>
                        <div className="filter_box_cnt">
                            <input type="text" className='form-control'
                                placeholder={t('trvl_location')}
                                name='location'
                                onChange={handleChangehandler}
                                value={data.location_name}
                                autoComplete="off"
                            />
                            <div className={`locationList-container ${locationSuggestions ? 'open' : ''}`}>
                                {
                                    locationSuggestions && (
                                        <ListGroup className='locationList'>
                                            {
                                                loader ? <center>
                                                    <span>{t('loading')}</span>
                                                </center>
                                                    :
                                                    locationSuggestions.map((locations, index) => {
                                                        return (
                                                            <ListGroup.Item onClick={() => selectHandler(locations.lat, locations.lon, locations.display_name)}>

                                                                <i class="fa-solid fa-location-dot"></i>&nbsp;
                                                                {locations.display_name}
                                                            </ListGroup.Item>
                                                        )
                                                    })

                                            }

                                        </ListGroup>

                                    )
                                }

                            </div>


                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="filter_box">
                        <div className="filter_box_head">{t('looking_for')}</div>
                        <div className="filter_box_cnt">
                            <select className="form-control" name="looking_for" id="" onChange={handleChangehandler}>
                                <option value="">{t('select')}</option>
                                <option value="dating">{t('dating')}</option>
                                <option value="family">{t('family')}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                        <div className="filter_box">
                            <div className="filter_box_head">{t('travel_date')}</div>
                            <div className="filter_box_cnt">
                                <input type="date" value={data.date}className='form-control' name='date' placeholder=""
                                    onChange={handleChangehandler}
                                />
                               
                            </div>
                        </div>
                    </div>
            </div>

            <div className="modal-footer">
                <button type="button" className="btn regsiter_top_btn" onClick={filterSubmithandler}>{t('apply')}</button>
            </div>

        </div>

    )
}

export default TravelFilter