import React, { useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { useState } from "react";
import userImg1 from "../../assets/images/users/user_imag_1.jpg";
import userImg2 from "../../assets/images/users/user_imag_2.jpg";
import userImg3 from "../../assets/images/users/user_imag_3.jpg";
import userImg4 from "../../assets/images/users/user_imag_4.jpg";
import { useForm } from "react-hook-form";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { showToastmsg } from "../../common/ToastMsg";
import { ToastContainer, toast } from "react-toastify";
// import 'bootstrap/dist/css/bootstrap.min.css';
import { registerApis } from "../../hooks/apihooks/registerApis";
import { useNavigate } from "react-router-dom";
// import { Alert } from 'react-bootstrap';
import Alert from "@mui/material/Alert";
import DatePicker from "../../common/DatePicker";
import DatePickerComponent from "../../common/DatePicker";
import moment from "moment";
import dayjs from "dayjs";
import { LineWave } from 'react-loader-spinner'
function RegisterForm() {
  const [page, setpage] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      username: "",
      // location: '',
      last_name: "",
      interest: [],
      photos: [],
      gender: "",
      orientation: "",
      date_of_birth: "",
    },
  });

  const regSubmitmutate = registerApis.RegisterSubmit();
  const { data } = registerApis.GetInterest();
  const CheckUserExist = registerApis.Checkuserexistance();
  console.log("===interstttt", data);
  const [regData, setregData] = useState([]);
  const [fileError, setfileError] = useState(false);
  const navigate = useNavigate();
  const nextHandler = async (data) => {
    // let usernameValid = await CheckUsername();
    // console.log("=======usernameValid", usernameValid);
    const isValid = await trigger();
    if (isValid) {
      setpage(1);
    }
  };

  const previousHandler = () => {
    setpage(0);
  };


  const formValues = watch();
  console.log("==formValues======", formValues)
  const onSubmit = async (data) => {
    try {
      const isValid = await trigger();
      if (isValid) {
        const formData = new FormData();
        // let registerData={}
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            if (key === "photos") {
              formData.append("profile_photo", data[key][0]);

              for (let i = 0; i < data[key].length; i++) {
                formData.append("cover_photos", data[key][i]);
              }
            } else if (key === "interest") {
              // for (let i = 0; i < data[key].length; i++) {
              formData.append("interests", JSON.stringify(data[key]));
              // }
            } else {
              formData.append(key, data[key]);
            }
          }
        }

        try {
          const response = await regSubmitmutate.mutateAsync(formData);

          if (response.status) {
            showToastmsg("success", "Registration successful");
            navigate("/login");
            // setTimeout(() => {
            //   navigate('/login')
            // }, 5000)
          } else {
            showToastmsg("error", "Registration failed");
          }
        } catch (error) {
          showToastmsg("error", "Registration failed: " + error.message);
        }
      }
    } catch (error) { }
  };

  const interests = [
    {
      id: 1,
      value: "abc",
    },
    {
      id: 2,
      value: "abc",
    },
    {
      id: 3,
      value: "abc",
    },
  ];
  const [selectedInterests, setSelectedInterests] = useState([]);

  const toggleInterest = (item) => {
    console.log("=====interest", item);
    // const isSelected = selectedInterests.includes(item.id);
    const isSelected = selectedInterests.some(
      (interest) => interest.id === item.id
    );
    console.log("=isSelected===", isSelected);

    if (isSelected) {
      let filter = selectedInterests.filter((items) => {
        return items.id !== item.id;
      });
      setSelectedInterests(filter);
      setValue("interest", filter, { shouldValidate: true });
      // setSelectedInterests(selectedInterests.filter(item => item !== id));
    } else {
      setSelectedInterests([...selectedInterests, item]);
      setValue("interest", [...formValues.interest, item.name], {
        shouldValidate: true,
      });
    }
  };

  const [selectedPhoto, setselectedPhoto] = useState([]);
  const [selectedPics, setselectedPics] = useState([]);
  const [selectedPicname, setselectedPicname] = useState([]);
  const [modalShow, setmodalShow] = useState(false);

  const photoSelecthandler = (e) => {
    // let file = e.target.files[0];
    let files = e.target.files;
    setselectedPhoto([...selectedPhoto, files]);
    // setselectedPicname(e.target.files[0].name);

    const selectedPicNames = Array.from(files).map((file) => file.name);
    setselectedPicname(selectedPicNames);

    setfileError(false);
  };

  const uploadHandler = (picsArray) => {

    if (picsArray && picsArray.length > 0) {
      const pics = picsArray[0];  // Extract the FileList from the array

      if (pics && pics.length > 0) {
        let imgId = selectedPics.length;

        Array.from(pics).forEach((pic, index) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            setselectedPics((prevPics) => [
              ...prevPics,
              { id: imgId + index + 1, pic, previewUrl: event.target.result },
            ]);
          };
          reader.readAsDataURL(pic);
        });

        setmodalShow(false);

        setValue("photos", [...formValues.photos, ...Array.from(pics)], { shouldValidate: true });
        setselectedPhoto([]);
      } else {
        setfileError(true);
      }
    } else {
      setfileError(true);
    }
  };


  const deletePichandler = (id) => {
    let undeletedPhotos = selectedPics.filter((obj) => {
      return obj.id != id;
    });
    setselectedPics(undeletedPhotos);
    const data = undeletedPhotos.map((item) => item.pic);
    setValue("photos", data, { shouldValidate: true });
  };
  const AddPichandler = async () => {
    try {
      setmodalShow(true);
      setselectedPicname("");
      
    } catch (error) {
      console.log(error)
    }


  };
  const handleClose = async () => {
    setmodalShow(false);
  };
  const Checkemail = async (email) => {
    // const { value } = e.target
    // let data = {
    //   email: value
    // }
    try {
      const response = await CheckUserExist.mutateAsync({ user_info: email });
      if (response.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const CheckUsername = async (username) => {
    // const { value } = e.target
    // let data = {
    //   email: value
    // }
    try {
      const response = await CheckUserExist.mutateAsync({ user_info: username });
      if (response.status) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  console.log("==formvalues==", formValues);
  console.log("=========errors==", errors);
  const ChangeHandler = async (name) => {
    await trigger(name);
  };
  const alertClosehandler = () => {
    setfileError(!fileError);
  };

  const handleDatechange = (value) => {
    const selectedDate = value.toDate();
    const currentDate = new Date();
    const minAgeDate = new Date(currentDate);
    minAgeDate.setFullYear(minAgeDate.getFullYear() - 18);
    if (selectedDate > minAgeDate) {
      setError("date_of_birth", { message: "Must be at least 18 years old" })

    } else {
      setValue("date_of_birth", moment(selectedDate).format('YYYY-MM-DD'), { shouldValidate: true })

    }
  }

  return (
    <>

      <ToastContainer />

      {page == 0 ? (
        <section className="register_login_section">
          <div className="container text-center">
            <div className="register_login_box step-2">
              <div className="register_login_box_head text-start">Register</div>
              <div className="register_login_box_cnt">
                <div className="register_login_box_cnt_subhead">
                  Personal Info
                </div>

                <div className="register_login_box_form">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">First Name</label>
                      <input
                        type="text"
                        className={`${errors.first_name ? "erorInput " : ""
                          }form-control`}
                        placeholder="Your Name"
                        {...register("first_name", {
                          required: {
                            value: true,
                            message: "First name is required*",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Special characters are not allowed",
                          },
                          maxLength: {
                            value: 20,
                            message: "Name cannot exceed 20 characters",
                          },

                          onChange: async (e) => {
                            await ChangeHandler(e.target.name);
                          },
                        })}
                      />

                      {errors.first_name && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;{errors?.first_name?.message}
                        </p>
                      )}
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Last Name</label>
                      <input
                        type="text"
                        className={`${errors.last_name ? "erorInput " : ""
                          }form-control`}
                        placeholder="Last Name"
                        {...register("last_name", {
                          required: {
                            value: true,
                            message: "last name is required*",
                          },
                          maxLength: {
                            value: 10,
                            message: "Last name cannot exceed 10 characters",
                          },
                          pattern: {
                            value: /^[a-zA-Z\s]*$/,
                            message: "Special characters are not allowed",
                          },

                          onChange: async (e) => {
                            await ChangeHandler(e.target.name);
                          },
                        })}
                        aria-invalid={errors.last_name ? "true" : "false"}
                      />

                      {errors.last_name && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;{errors?.last_name?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Gender</label>
                      <select
                        className={`${errors.gender ? "erorInput " : ""
                          }form-select`}
                        aria-label="Default select example"
                        {...register("gender", {
                          required: true,
                          onChange: async (e) => {
                            await ChangeHandler(e.target.name);
                          },
                        })}
                      >
                        <option value="">Select</option>
                        <option value="M">Male</option>
                        <option value="F">Female</option>
                        <option value="TM">Transgender Male</option>
                        <option value="TF">Transgender Female</option>
                      </select>

                      {errors?.gender?.type === "required" && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;Gender is required*
                        </p>
                      )}
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Your Email</label>
                      <input
                        type="email"
                        className={`${errors.email ? "erorInput " : ""
                          }form-control`}
                        placeholder="Email"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "email is required*",
                          },

                          pattern: {
                            value:
                              /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                            message: "Invalid mail format",
                          },
                          validate: async (value) => {
                            // Validate user email
                            const isAlredyexist = await Checkemail(value);

                            if (!isAlredyexist) {
                              return "Email already exist";
                            } else {
                              return true;
                            }
                          },
                          onChange: async (e) => {
                            await ChangeHandler(e.target.name);
                          },
                        })}
                      />

                      {errors.email && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;{errors.email.message}
                        </p>
                      )}

                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">username</label>
                      <input
                        type="text"
                        className={`${errors.username ? "erorInput " : ""
                          }form-control`}
                        placeholder="username"
                        {...register("username", {
                          required: {
                            value: true,
                            message: "Username is required*",
                          },
                          pattern: {
                            value:
                              /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/g,
                            message: "Invalid username format",
                          },
                          maxLength: {
                            value: 10,
                            message: "username cannot exceed 10 characters",
                          },
                          validate: async (value) => {
                            const isAlredyexist = await CheckUsername(value);
                            if (!isAlredyexist) {
                              return "Username already exist";
                            } else {
                              return true;
                            }
                          },
                          onChange: async (e) => {
                            await ChangeHandler(e.target.name);
                          },
                        })}
                      />
                      {errors.username && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;{errors.username.message}
                        </p>
                      )}


                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Password</label>
                      <input
                        type="password"
                        className={`${errors.password ? "erorInput " : ""
                          }form-control`}
                        placeholder="********"
                        {...register("password", {
                          required: true,
                          onChange: async (e) => {
                            await ChangeHandler(e.target.name);
                          },
                        })}
                      />
                      {errors?.password?.type === "required" && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;Password is required*
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Phone No</label>
                      <input
                        type="text"
                        className={`${errors.phone_number ? "erorInput " : ""
                          }form-control`}
                        placeholder="Phone No"
                        {...register("phone_number", {
                          required: true,
                          pattern: /^\+?[1-9][0-9]{7,14}$/,
                          onChange: async (e) => {
                            await ChangeHandler(e.target.name);
                          },
                        })}
                      />
                      {errors?.phone_number?.type === "required" && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;Phone is required*
                        </p>
                      )}
                      {errors?.phone_number?.type === "pattern" && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;Invalid phone no*
                        </p>
                      )}
                    </div>
                    <div className="col-md-6 mb-4">
                      <label htmlFor="">Orientation</label>
                      <select
                        className={`${errors.orientation ? "erorInput " : ""
                          }form-select`}
                        aria-label="Default select example"
                        {...register("orientation", { required: true })}
                      >
                        <option value="">Select</option>
                        <option value="Hetero">Hetrosexual</option>
                        <option value="Homo">Homo</option>
                        <option value="Pan">Pansexual</option>
                        <option value="Bi">Bisexual</option>
                      </select>

                      {errors?.orientation?.type === "required" && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;Orientation is required*
                        </p>
                      )}
                    </div>
                  </div>
                  <a>

                    <button
                      className="reg_login_sub_btn"
                      type="submit"
                      onClick={nextHandler}
                    >
                      Next
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className="register_login_section">
            <div className="container text-center">
              <div className="register_login_box step-2">
                <div className="register_login_box_head text-start">
                  Register
                </div>
                <div className="register_login_box_cnt">
                  <div className="register_login_box_form">
                    <div className="register_login_box_cnt_subhead">
                      Select your interest
                    </div>

                    <div
                      className="profile_right_intrst_cnt_sc"
                      {...register("interest", { required: true })}
                    >
                      {data?.data?.map((item, index) => (
                        <button
                          key={item.id}
                          // className={`filter_box_intrstbx ${selectedInterests.includes(item.id) ? 'active' : ''}`}
                          className={`filter_box_intrstbx ${selectedInterests.some(
                            (interest) => interest.id === item.id
                          )
                            ? "active"
                            : ""
                            }`}
                          onClick={() => toggleInterest(item)}
                        >
                          {item.name}
                        </button>
                      ))}
                    </div>

                    {errors.interest?.type === "required" && (
                      <p role="alert" className="formError">
                        <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                        &nbsp;Please select your intrests*
                      </p>
                    )}

                    <div className="register_datebirth_sec">
                      <div className="register_login_box_cnt_subhead">
                        Date of Birth
                      </div>
                      <div className="register_datebirth_sec_fld">
                        <div className="col-md-12">
                          {/* <label htmlFor="">Date of Birth</label> */}
                          {/* <input
                            type="date"
                            className={`${
                              errors.date_of_birth ? "erorInput " : ""
                            }form-control`}
                            placeholder=""
                            {...register("date_of_birth", {
                              required: {
                                value: true,
                                message: "Select your date of birth*",
                              },
                              pattern: {
                                value:
                                  /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                                message: "Invalid date format",
                              },
                              validate: (value) => {
                                // Validate that the user is at least 18 years old
                                const birthDate = new Date(value);
                                const currentDate = new Date();
                                const minAgeDate = new Date(currentDate);
                                minAgeDate.setFullYear(
                                  currentDate.getFullYear() - 18
                                );

                                if (birthDate > minAgeDate) {
                                  return "Must be at least 18 years old";
                                }

                                return true;
                              },
                              onChange: async (e) => {
                                await ChangeHandler(e.target.name);
                              },
                            })}
                          /> */}
                          <DatePickerComponent
                            className={`${errors.date_of_birth ? "erorInput " : ""
                              }form-control`}
                            handleDatechange={handleDatechange}
                            value={formValues.date_of_birth}
                            {...register("date_of_birth", {
                              required: {
                                value: true,
                                message: "Select your date of birth*",
                              },

                            })}

                          />
                          {errors.date_of_birth && (
                            <p role="alert" className="formError">
                              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                              &nbsp;{errors.date_of_birth.message}
                            </p>
                          )}

                        </div>
                      </div>
                    </div>
                    <div className="upload_photo_sec">
                      <div className="register_login_box_cnt_subhead">
                        Upload Photos
                        <button
                          // data-bs-toggle="modal"
                          // data-bs-target="#uploadphoto"
                          className="add_new_photo"
                          onClick={AddPichandler}
                        >
                          Add New
                        </button>
                      </div>
                      <div className="upload_photo_list" {...register("photos", { required: { value: true, message: "select your photo*" } })}>
                        <div className="row">
                          {selectedPics.map((img, index) => {
                            return (
                              <div className="col-md-3" key={index}>
                                <div
                                  className="upload_photo_box"

                                >
                                  <button
                                    className="dlete_phot"
                                    onClick={() => deletePichandler(img.id)}
                                  >
                                    <i className="fa fa-close"></i>
                                  </button>
                                  <img src={img.previewUrl} alt="" />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {errors.photos && (
                        <p role="alert" className="formError">
                          <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                          &nbsp;{errors.photos.message}
                        </p>
                      )}
                    </div>

                    <button
                      className="reg_login_sub_btn"
                      onClick={previousHandler}
                    >
                      previous
                    </button>
                    <button
                      className="reg_login_sub_btn"
                      type="submit"
                      disabled={regSubmitmutate.isLoading}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Submit

                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* fileupload model -------------------------------------------*/}
          {
            <Modal show={modalShow} onHide={handleClose}>
              <ModalHeader closeButton>
                <h5 className="modal-title" id="exampleModalLabel">

                </h5>
              </ModalHeader>
              <ModalBody>
                {fileError && (
                  <Alert severity="error">Please select a file !</Alert>
                )}

                <form className="form-container" encType="multipart/form-data">
                  <div className="upload-files-container">
                    <div className="drag-file-area">
                      <span className="material-icons-outlined upload-icon">
                        File Upload
                      </span>
                      {/* <h3 className="dynamic-message">Drag & drop any file here</h3> */}
                      <label className="label">
                        {" "}
                        or{" "}
                        <span className="browse-files">
                          <input
                            type="file"
                            className="default-file-input"
                            multiple={true}
                            onChange={photoSelecthandler}
                          />
                          <span className="browse-files-text">browse file</span>{" "}
                          <span>from device</span>
                        </span>{" "}

                      </label>
                      <span className="selected_files">
                        <ul style={{ listStyle: 'none' }}>
                          {selectedPicname &&
                            selectedPicname.map((item, index) => (
                              <li key={index} className="selected-file-item">
                                {/* <span className="select-file-remove" onClick={handleSelectImgremove}>
                                  <i className="fa-solid fa-circle-xmark"></i>
                                </span> */}
                                <span className="selectedFile">{item}</span>
                              </li>
                            ))}
                        </ul>
                      </span>

                    </div>
                    <span className="cannot-upload-message">
                      {" "}
                      <span className="material-icons-outlined">
                        error
                      </span>{" "}
                      Please select a file first{" "}
                      <span className="material-icons-outlined cancel-alert-button">
                        cancel
                      </span>{" "}
                    </span>
                    <div className="file-block">
                      <div className="file-info">
                        {" "}
                        <span className="material-icons-outlined file-icon">
                          description
                        </span>{" "}
                        <span className="file-name"> </span> |{" "}
                        <span className="file-size"> </span>{" "}
                      </div>
                      <span className="material-icons remove-file-icon">
                        delete
                      </span>
                      <div className="progress-bar"> </div>
                    </div>

                    <button
                      type="button"
                      className="upload-button"
                      onClick={() => uploadHandler(selectedPhoto)}
                    >
                      Upload
                    </button>
                  </div>
                </form>
              </ModalBody>
            </Modal>
          }
        </>
      )}


    </>
  );
}

export default RegisterForm;
