import API from "../api/api";
import { BASE_URL } from "../config/config";
import axios from "axios";

export const packageServices = {
  getPackages: async () => {
    try {
      const response = await API.get("packages");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  addPackage: async (data) => {
    const customAxios = axios.create({
      baseURL: BASE_URL,
    });
    customAxios.defaults.headers.common["Authorization"] =
      "Bearer" + " " + localStorage.getItem("access-token") || "";

    customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    try {
      const response = await customAxios.post("packages", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  updatePackage: async (data) => {
    const customAxios = axios.create({
      baseURL: BASE_URL,
    });
    customAxios.defaults.headers.common["Authorization"] =
      "Bearer" + " " + localStorage.getItem("access-token") || "";

    customAxios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    try {
      const response = await customAxios.put("packages", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  deletePackage: async (data) => {
    try {
      const response = await API.delete("packages", {
        data: {id:data},
      });
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
};
