import { useQuery, useMutation } from "@tanstack/react-query"
import { postTestimonial,getTestimonialdata,getpendingTestimonialdata,updateTestimonialstatus} from "../../actions/Testimonial/testimonial"
import { useDispatch } from "react-redux"
import { setPagination} from "../../store/reducers/testimonialReducer"
import { useSelector } from "react-redux"
export const testimonialApis = {
    PostTestimonialdata: () => {
        const response = useMutation({
            mutationKey: ["post-testimonial-data"],
            mutationFn: (data) => postTestimonial(data)
        })
        return response
    },
    GetTestimonialdata:()=>{
        const response=useQuery({
            queryKey:["get-uploaded-data"],
            queryFn:()=>getTestimonialdata()
        })
        return response
    },
    GetPendingtestimonialData:(rowsperpage,page)=>{
        const dispatch=useDispatch()
        const {currentPage,totalPages,RowsperPage}=useSelector((state)=>state.testimoni.pagination)
        console.log("===paginationData====",currentPage,totalPages,RowsperPage)
        const response=useQuery({
            queryKey:["get-pending-data",rowsperpage,page],
            queryFn:()=>getpendingTestimonialdata(rowsperpage,page),
            onSuccess:(response)=>{
                const totalPages = Math.ceil(response?.data?.count /RowsperPage)
                dispatch(setPagination({totalPages:Number(totalPages)}))
                
            }
        })
        return response
    },
    UpdateTsetimonialdata:()=>{
        const response=useMutation({
            mutationKey:["update-testimonial"],
            mutationFn:(data)=>updateTestimonialstatus(data)
        })
        return response
    }
}