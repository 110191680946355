import React from "react";
import Myaccount from "../../components/profile/Myaccount/Myaccount";
import InnerpageHeader from "../../common/InnerpageHeader";
import ProfilePic from "../../assets/images/pofile-image.jpg";
import Chat from "../../components/profile/Messages/Chat";
import { useState } from "react";
import Favourite from "../../components/profile/Favourite/Favourite";
import Liked from "../../components/profile/Liked/Liked";
import WhofavouritedU from "../../components/profile/WhofavtedU/WhofavouritedU";
import WholikedU from "../../components/profile/WholikedU/WholikedU";
import { UseSelector, useSelector } from "react-redux/es/hooks/useSelector";
import { BASE, IMG_URL } from "../../config/config";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { ProfileApis } from "../../hooks/apihooks/profileApis";
import { useQueryClient } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import defaultProfilepic from "../../assets/images/user-blue-gradient.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/profile/Myaccount/Notification";
import Travel from "../../components/profile/Travel/Travel";
import Testimonial from "../../components/profile/Testimonial/Testimonial";
import moment from "moment";
import TestimonialApproval from "../../components/adminprofile/TestimonialApproval";
import UserReports from "../../components/adminprofile/UserReports";
import KycApproval from "../../components/adminprofile/KycApproval";
import ProfileSkeleton from "../../components/skeletons/ProfileSkeleton";
import { useTranslation } from "react-i18next";


function AdminProfile() {
  const navigate = useNavigate();
  const [modalShow, setmodalShow] = useState(false);
  const [selectedPic, setselectedPic] = useState(null);
  const [selectedFilename, setselectedFilename] = useState("");
  const profilePicChange = ProfileApis.Changeprofilepic();
  const queryClient = useQueryClient();
  const getProfiledata = ProfileApis.GetProfileData();
  const [selectedTab, setselectedTab] = useState("my-account");
  const location = useLocation();
  const {t}=useTranslation()

  const rightProfilebars = [
    {
      path: "/profile/my-account",
      icon: "fa-regular fa-user",
      tab: t("my_account"),
      selectedTab: "my-account",
    },
    {
      path: "/profile/chat",
      icon: "fa-regular fa-message",
      tab: t('message'),
      selectedTab: "chat",
    },
    {
      path: "/profile/user-reports",
      icon: "fa-regular fa-star",
      tab: t('user_reports'),
      selectedTab: "user-reports",
    },
    {
      path: "/profile/kyc-approval",
      icon: "fa-regular fa-address-card",
      tab: t('kyc'),
      selectedTab: "kyc-approval",
    },

    {
      path: "/profile/testimonial-approval",
      icon: "fa-regular fa-comments",
      tab: t('testimonial'),
      selectedTab: "testimonial",
    },

    {
      path: "/change-password",
      icon: "fa-solid fa-key",
      tab: t('pswd'),
      selectedTab: "change-password",
    },
  ];


  const photoSelecthandler = async (e) => {
    let formData = new FormData();
    setselectedPic(e.target.files[0]);
    setselectedFilename(e.target.files[0].name);
    formData.append("profile_picture", e.target.files[0]);
    const response = await profilePicChange.mutateAsync(formData);
    if (response) {
      queryClient.invalidateQueries({ queryKey: ["profileData"] });
      setmodalShow(false);
    }
  };


  useEffect(() => {
    if (location.pathname == "/profile") {
      setselectedTab("my-account");
    } else {
      const pathSegments = location.pathname.split("/");
      const lastPathSegment = pathSegments[pathSegments.length - 1];
      setselectedTab(lastPathSegment);
    }
  }, [location.pathname]);
  const profileDatas = useSelector((state) => state.profile.profileData);

  return (
    <>
      <InnerpageHeader pageTitle="my_account" />

      <section className="inner_page_main_bx_section account_page">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="inner_page_main_box_view">
                <div className="myaccount_profile_section">
                  {/* <a className="myaccount_profile_top_edit" onClick={changeProfilepic}>Edit Profile
                                    </a> */}
                  <div className="myaccount_profile_box">
                    <div className="myaccount_profile_box_img">
                      <img
                        src={
                          profileDatas?.profile_picture
                            ? IMG_URL + profileDatas?.profile_picture
                            : defaultProfilepic
                        }
                        alt=""
                      />
                      <span className="editProfilepic">
                        <i
                          class="fa-solid fa-pen-to-square"
                          onClick={() =>
                            document.getElementById("file-input").click()
                          }
                        >
                          <input
                            type="file"
                            onChange={photoSelecthandler}
                            style={{ display: "none" }}
                            id="file-input"
                          />
                        </i>
                      </span>
                    </div>
                    <div className="myaccount_profile_box_name">
                      <h5>{profileDatas?.user?.first_name}</h5>
                      <p>{profileDatas?.user?.username}</p>
                      <p> {profileDatas?.user?.package_name}</p>
                      {
                        profileDatas?.user?.package_validity &&
                        <p ><span>{t('expiry_on')} :</span> <span className="packageEXipry">{moment.utc(profileDatas?.user?.package_validity).format('DD-MM-YYYY')}</span></p>
                      }

                    </div>


                    <div className="myaccount_profile_left_navigation">
                      <ul>
                        {rightProfilebars.map((items, index) => {
                        
                          return (
                            <li
                              className={
                                selectedTab == items.selectedTab ? "active" : ""
                              }
                              key={index}
                            >
                              <NavLink
                                to={items.path}
                                className={({ isActive, isPending }) =>
                                  isPending
                                    ? "pending"
                                    : isActive
                                      ? "active"
                                      : ""
                                }
                              >
                                <div className="prfl_nav_ico">
                                  <i className={items.icon}></i>
                                </div>
                                <span>{items.tab}</span>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="my_account_right_sec">
                {selectedTab == "my-account" ? (
                  getProfiledata.isLoading ?
                    <ProfileSkeleton />
                    :
                    getProfiledata?.data && (
                      <Myaccount t={t} profileData={getProfiledata?.data} />
                    )
                ) : selectedTab == "chat" ? (
                  <Chat t={t}/>
                ) : selectedTab == "favourites" ? (
                  <Favourite t={t}/>
                ) : selectedTab == "liked" ? (
                  <Liked t={t} />
                ) : selectedTab == "who-favourite-me" ? (
                  <WhofavouritedU t={t}/>
                ) : selectedTab == "who-liked-me" ? (
                  <WholikedU t={t}/>
                ) : selectedTab == "travel" ? (
                  <Travel t={t}/>
                ) : selectedTab == "notification" ? (
                  <Notification t={t}/>
                ) : selectedTab == "testimonial-approval" ? (
                  <TestimonialApproval t={t}/>
                ) : selectedTab == "user-reports" ? (
                  <UserReports t={t}/>
                ) : selectedTab == "kyc-approval" ? (
                  <KycApproval t={t}/>
                ) :
                  (
                    ""
                  )}
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  );
}

export default AdminProfile