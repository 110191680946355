import React from 'react'
import travelCompanion from '../../assets/images/travel_companion_image.png'
function TravelCompanion() {
    return (
        <section className="travel_companion_section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="travel_companion_section_img">
                            <img src={travelCompanion} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="travel_companion_cnt">
                            <h3>Travel Companion Option</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's
                                standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type
                                specimen book.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TravelCompanion