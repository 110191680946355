import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import forgotpSWD from "../../assets/images/forgotpSWD.jpg";
import { useDispatch } from "react-redux";
import { setForgotpswdDAta } from "../../store/reducers/forgotPswdReducer";
import { useSelector } from "react-redux";
import { setError } from "../../store/reducers/forgotPswdReducer";
import { ForgotpaswdApis } from "../../hooks/apihooks/forgotPswdApis";
import { showToastmsg } from "../../common/ToastMsg";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

function ForgotPassword() {
  const dispatch = useDispatch();
  const fieldValues = useSelector((state) => state?.forgotPswd?.data);
  const fieldErrors = useSelector((state) => state.forgotPswd.error);
  const forgotPswdsubmit = ForgotpaswdApis.Sendemail();
  const changeHandler = (e) => {
    const { name, value } = e.target;
    dispatch(setForgotpswdDAta({ [name]: value }));
    dispatch(setError({ field: name, errorMsg: "" }));
  };
  const navigate = useNavigate();

  const validation = async () => {
    const mailReg = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    if (!fieldValues.email) {
      dispatch(setError({ field: "email", errorMsg: "email is required" }));
      return false;
    } else if (!mailReg.test(fieldValues.email)) {
      dispatch(setError({ field: "email", errorMsg: "invalid mail format" }));
      return false;
    }
    return true;
  };

  const Pswdsubmithandler = async () => {
    try {
      const Isvalid = await validation();
      if (Isvalid) {
        let response = await forgotPswdsubmit.mutateAsync({
          email: fieldValues.email,
        });
        if (response.status) {
          showToastmsg(
            "success",
            response.data.success ?? "Mail send Successfully"
          );
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          showToastmsg("error", "some error occured");
        }
      }
    } catch (error) {}
  };

  return (
    <div>
      <ToastContainer />
      
      <section className="register_login_section">
        <div className="container text-center">
          <div className="register_login_box text-start login_box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="register_login_box_head">
                  Forgot Password
                  <p>Enter your email and send</p>
                </div>
                <div className="register_login_box_cnt">
                  <div className="register_login_box_form">
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <label htmlFor="">Email</label>
                        <input
                          type="email"
                          className={`${
                            fieldErrors.field === "email" &&
                            fieldErrors.errorMsg
                              ? "erorInput "
                              : ""
                          }form-control`}
                          name="email"
                          onChange={changeHandler}
                        />
                        {fieldErrors.field === "email" &&
                          fieldErrors.errorMsg && (
                            <p className="formError">
                              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                              &nbsp;{fieldErrors.errorMsg}*{" "}
                            </p>
                          )}
                      </div>
                      {/* <div className="col-md-12 mb-2">
                                                <label htmlFor="">Password</label>
                                                <input type="password" className="form-control" name='password' />
                                                {/* {
                                                    LoginError.password && (
                                                        <p className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{LoginError.password}* </p>
                                                    )
                                                } */}
                      {/* </div> */}
                    </div>
                  </div>
                  <a
                  // href="my-account.html"
                  >
                    <button
                      className="reg_login_sub_btn mt-3"
                      disabled={forgotPswdsubmit.isLoading ? true : false}
                      onClick={Pswdsubmithandler}
                    >
                      {forgotPswdsubmit.isLoading ? (
                        <div>
                          loading <i class="fa-solid fa-spinner fa-spin"></i>
                        </div>
                      ) : (
                        "send"
                      )}
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="login_right_sec">
                  {/* <h2>Best Ways to Find Your True Soul Mate</h2> */}
                  <img src={forgotpSWD} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    
    </div>
  );
}

export default ForgotPassword;
