import React from 'react'
import userImg1 from '../../../assets/images/users/user_imag_1.jpg'
import { ProfileApis } from '../../../hooks/apihooks/profileApis'
import { IMG_URL } from '../../../config/config'
import nodataIMg from '../../../assets/images/nodata.jpg'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import { showToastmsg } from '../../../common/ToastMsg'
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import CardSkeleton from '../../skeletons/CardSkeleton'


function Liked({t}) {
    const getLikeddatas = ProfileApis.LikedUsers()
    const dltLikedusers = ProfileApis.DltLikedUsers()
    const queryClient = useQueryClient();
   
    const dltLikehandler = async (id) => {
        let data = {
            user: id
        }
        let response = await dltLikedusers.mutateAsync(data)
        if (response) {
            queryClient.invalidateQueries(["liked-users"])
        } else {
            showToastmsg("error", "something went wrong...")
        }
    }
    return (
        <div>
            <div className="my_account_right_head">
                {t('liked')}
            </div>
            <div className="my_account_right_box">
                {
                    getLikeddatas.isLoading?
                    <CardSkeleton type={"like"} iconsCount={0}/>
                    :
                    getLikeddatas?.data?.data?.my_like_data.length > 0 ?
                        <div className="row">
                            {
                                getLikeddatas?.data?.data?.my_like_data &&
                                getLikeddatas?.data?.data?.my_like_data?.map((data, index) => {
                                    return (
                                        <div className="col-md-3">
                                            <div className="profile_match_crsl_box">
                                                <div className="profile_match_crsl_box_img">
                                                    <a className="dlete_action_btn">
                                                        <i className="fa fa-trash" onClick={() => dltLikehandler(data.user__id)}></i>
                                                    </a>
                                                    <a href={`/singleProfile/${data.user__username}`}>
                                                        <img src={data.profile_picture?IMG_URL + data.profile_picture:defaultProfilepic} alt="" /></a>
                                                </div>
                                                <a href={`/singleProfile/${data.user__username}`}>
                                                    <div className="profile_cnt_bx">
                                                        {data.user__username} - {data.age ?? 25}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        :

                        <img src={nodataIMg} alt='nodata' className='w-50' />


                }

            </div>
        </div>
    )
}

export default Liked