const translateOptions = ({ options, t }) => {
    console.log("===options====",options)
    return options.map((option) => {
        return {
            ...option,
            label: t(`${option.label}`)
        }
    })
}

export default translateOptions;