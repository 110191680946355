import React, { useState } from "react";
import _ from "lodash";
import {
  Offcanvas,
  Form,
  FloatingLabel,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { PackageApis } from "../../hooks/apihooks/pcakageApis";
import { showToastmsg } from "../../common/ToastMsg";
import Header from "../../views/Admin/Header";
import InnerpageHeader from "../../common/InnerpageHeader";
import Footer from "../../components/Footer";
import nodataImg from "../../assets/images/nodata.jpg";
import packageImg from "../../assets/images/package-gold.png";
import { IMG_URL } from "../../config/config";
import CardSkeleton from "../skeletons/CardSkeleton"
import PackagesSkeleton from "../skeletons/PackageSkeleton";
import Backbtn from "../../common/Backbtn";
import { useTranslation } from "react-i18next";

function Packages() {
  const { t } = useTranslation()
  const packagesList = PackageApis.GetPackages();
  const addPackage = PackageApis.AddPackage();
  const deletePackageCall = PackageApis.DeletePackage();
  const updatePackageCall = PackageApis.UpdatePackage();

  const [offcanvas, setoffcanvas] = useState({
    show: false,
  });
  const [action, setAction] = useState("");
  const [features, setFeatures] = useState([]);
  const [featureValue, setFeatureValue] = useState("");
  const [packageDetails, setPackageDetails] = useState({
    name: "",
    price: "",
    package_img: "",
    validity: "",
    type: "free",
  });
  const [formError, setFormError] = useState({
    status: false,
    name: false,
    price: false,
    package_img: false,
    validity: false,
    type: false,
    features: false,
  });
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteId, setDleteId] = useState("");

  const addNewpackageModal = () => {
    setAction("newPack");
    setoffcanvas({
      ...offcanvas,
      show: true,
    });
  };
  const submitHandler = async () => {
    const isValid = await formValidation("newPack");
    if (isValid) {
      let formData = new FormData();
      Object.entries(packageDetails).map(([key, value]) => {
        formData.append(`${key}`, value);
      });
      formData.append("features", JSON.stringify(features));
      const result = await addPackage.mutateAsync(formData);
      if (result.status) {
        packagesList.refetch();
        showToastmsg("success", t('package_created_successfully'), "top");
        handleClose();
      } else {
        showToastmsg("error", t('package_creation_failed'), "top");
      }
    }
  };
  const addFeature = async () => {
    if (featureValue && !_.includes(features, featureValue)) {
      setFormError((prev) => ({
        ...prev,
        features: false,
      }));
      setFeatures([...features, featureValue]);
    }
    setFeatureValue("");
  };
  const removeFeature = (index) => {
    const featuresRemoved = _.without(features, features[index]);
    setFeatures(featuresRemoved);
  };
  const updateModal = async (data) => {
    const { features } = data;
    setAction("update");
    setPackageDetails((prev) => ({
      ...prev,
      ...data,
    }));
    setFeatures(features);
    setoffcanvas({
      ...offcanvas,
      show: true,
    });
  };
  const updateHandler = async () => {
    const isValid = await formValidation("update");
    if (isValid) {
      let formData = new FormData();
      Object.entries(packageDetails).map(([key, value]) => {
        formData.append(`${key}`, value);
      });
      formData.append("features", JSON.stringify(features));
      const result = await updatePackageCall.mutateAsync(formData);
      if (result.status) {
        packagesList.refetch();
        showToastmsg("success", t('package_updated'), "top");
        handleClose();
      } else {
        showToastmsg("error", "Failed", "top");
      }
    }
  };
  const deletPackage = async (id) => {
    setDleteId(id);
    setDeleteConfirm(true);
    // const result = await deletePackageCall.mutateAsync(id);
    // if (result.status) {
    //   showToastmsg("success", "Package deleted", "top");
    //   packagesList.refetch();
    // } else {
    //   showToastmsg("error", "error", "top");
    // }
  };
  const confirmDelete = async () => {
    const result = await deletePackageCall.mutateAsync(deleteId);
    if (result.status) {
      setDleteId("");
      showToastmsg("success", t('package_dlted'), "top");
      packagesList.refetch();
    } else {
      setDleteId("");
      showToastmsg("error", "error", "top");
    }
  };
  const changeHandler = async (e) => {
    const { value, name } = e.target;
    if (name === "feature") {
      setFeatureValue(value);
    } else {
      let data = value;
      if (name === "package_img") data = e.target.files[0];
      if (data) {
        setFormError((prev) => ({
          ...prev,
          [name]: false,
        }));
      } else {
        setFormError((prev) => ({
          ...prev,
          status: true,
          [name]: true,
        }));
      }
      setPackageDetails((prev) => ({
        ...prev,
        [name]: data,
      }));
    }
  };
  const formValidation = async (action) => {
    let valid = true;
    const { name, price, package_img, validity, type } = packageDetails;
    if (
      (!package_img && action !== "update") ||
      !name.trim() ||
      !type ||
      !price ||
      isNaN(price) ||
      price < 0 ||
      !validity ||
      isNaN(validity) ||
      validity < 1 ||
      features.length < 1
    ) {
      valid = false;
    }

    setFormError((prev) => ({
      ...prev,
      status: !valid,
      name: !name.trim(),
      price: isNaN(price) || price < 0 || !price,
      package_img: action === "update" ? false : !package_img,
      validity: isNaN(validity) || validity < 1 || !validity,
      type: !type,
      features: features.length < 1 ? true : false,
    }));
    return valid;
  };
  const handleClose = () => {
    setoffcanvas({
      ...offcanvas,
      show: false,
    });
    setFeatureValue("");
    setFeatures([]);
    setPackageDetails({
      name: "",
      price: "",
      package_img: "",
      type: "free",
      validity: "",
    });
    setFormError({
      status: false,
      name: false,
      price: false,
      package_img: false,
      validity: false,
      type: false,
      features: false,
    });
  };
  return (
    <div>

      <InnerpageHeader pageTitle="package" />
      <section className="inner_page_main_bx_section">

        <div className="container">
          <div className="inner_page_main_box_view">
            <div className="inner_page_main_bx_head">
             <h2></h2>
              <div className="add_new_pack">
                <a
                  href="#/"
                  className="inner_page_main_bx_filter_btn"
                  onClick={addNewpackageModal}
                >
                  <i className="fa fa-add"></i> {t('add_package')}
                </a>
              </div>
            </div>
            {
              packagesList.isLoading ?
                <PackagesSkeleton />
                :
                <>
                  {packagesList?.data?.data?.length > 0 ? (
                    <div className="plan-options">
                      {packagesList?.data?.data?.map((item) => {
                        return (
                          <div className="plan">
                            <div className="plan-image">
                              <img
                                src={
                                  item?.package_img
                                    ? `${IMG_URL}${item?.package_img}`
                                    : packageImg
                                }
                              />
                            </div>
                            <div className="plan-header">
                              <h3 className="plan-heading">{item?.name}</h3>
                            </div>
                            <ul className="plan-feature">
                              {item?.features?.map((feature) => {
                                return <li>{feature}</li>;
                              })}
                            </ul>
                            <p>
                              $<span className="plan-amount">{item?.price}</span>{" "}
                              <span className="plan-duration">{`/${item?.validity} ${t("month")}`}</span>
                            </p>
                            <div className="package_buttons">
                              <div className="profile_act_btn prf_btn_edit">
                                <i
                                  className="fa fa-edit"
                                  onClick={() => updateModal(item)}
                                ></i>
                              </div>
                              <div className="profile_act_btn prf_btn_dlt">
                                <i
                                  className="fa fa-trash"
                                  onClick={() => deletPackage(item.id)}
                                ></i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <>
                      <div style={{ textAlign: "center" }}>
                        <img src={nodataImg} alt="no data" className="w-50" />
                      </div>
                    </>
                  )}
                </>

            }

            <div className="best_match_section"></div>
          </div>
        </div>
      </section>


      <Offcanvas show={offcanvas.show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {action === "newPack" ? `Add new package` : `Update details`}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <FloatingLabel
            controlId="floatingInput"
            label={t('package_name')}
            className="mb-3"
          >
            <Form.Control
              className={`${formError.name ? "erorInput " : ""}form-control`}
              type="text"
              placeholder="name"
              name="name"
              onChange={changeHandler}
              value={packageDetails.name}
            />
          </FloatingLabel>
          {formError.status && formError.name && (
            <p role="alert" className="formError">
              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
              &nbsp;{t('name_is_required')}
            </p>
          )}
          <FloatingLabel
            controlId="floatingInput"
            label={t('price')}
            className="mb-3"
          >
            <Form.Control
              className={`${formError.price ? "erorInput " : ""}form-control`}
              type="number"
              placeholder="price"
              name="price"
              onChange={changeHandler}
              value={packageDetails.price}
            />
          </FloatingLabel>
          {formError.status && formError.price && (
            <p role="alert" className="formError">
              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
              &nbsp;{t('invalid_price')}
            </p>
          )}

          <FloatingLabel
            controlId="floatingInput"
            label={t('validity_in_months')}
            className="mb-3"
          >
            <Form.Control
              className={`${formError.validity ? "erorInput " : ""
                }form-control`}
              type="number"
              placeholder={t('validity_in_months')}
              name="validity"
              onChange={changeHandler}
              value={packageDetails.validity}
            />
          </FloatingLabel>
          {formError.status && formError.validity && (
            <p role="alert" className="formError">
              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
              &nbsp;{t('invalid_validity')}
            </p>
          )}

          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Control
              className={`${formError.package_img ? "erorInput " : ""
                }form-control`}
              type="file"
              multiple
              name="package_img"
              onChange={changeHandler}
            />
          </Form.Group>
          {formError.status && formError.package_img && (
            <p role="alert" className="formError">
              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
              &nbsp;{t('img_is_required')}
            </p>
          )}

          <FloatingLabel controlId="type" label={t('select_type')} className="mb-3">
            <Form.Select
              aria-label="type"
              name="type"
              onChange={changeHandler}
              value={packageDetails.type}
              disabled={action === "update"}
            >
              <option value="free">Free Package</option>
              <option value="paid">Membership Package</option>
            </Form.Select>
          </FloatingLabel>
          {formError.status && formError.type && (
            <p role="alert" className="formError">
              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
              &nbsp;{t('type_is_required')}
            </p>
          )}
          {features?.map((item, index) => {
            return (
              <div className="feature_sticky_outline">
                <div className="filter_box_intrstbx_feature">
                  <div class="close-icon-feature">
                    <i
                      class="fas fa-times"
                      onClick={() => removeFeature(index)}
                    ></i>
                  </div>
                  {`${item}`}
                </div>
              </div>
            );
          })}
          <InputGroup className="mb-3">
            <Form.Control
              className={`${formError.features ? "erorInput " : ""
                }form-control`}
              placeholder={t('feature')}
              aria-label="Features"
              aria-describedby="basic-addon2"
              name="feature"
              value={featureValue}
              onChange={changeHandler}
            />
            <Button
              variant="outline-secondary"
              id="button-addon2"
              onClick={addFeature}
              disabled={!featureValue}
            >
              {t('add')}
            </Button>
          </InputGroup>
          {formError.status && formError.features && (
            <p role="alert" className="formError">
              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
              &nbsp;{t('min_one-feature_req')}
            </p>
          )}
          {action === "newPack" && (
            <button className="reg_login_sub_btn mt-3" onClick={submitHandler}>
              {t("submit")}
            </button>
          )}
          {action === "update" && (
            <button className="reg_login_sub_btn mt-3" onClick={updateHandler}>
              {t("update")}
            </button>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <>
        {deleteId && (
          <Modal
            show={deleteConfirm}
            onHide={() => setDeleteConfirm(false)}
            animation={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>{t('confirm_dlt')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t('u_cannot_undo_this_process')}!</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setDeleteConfirm(false)}
              >
                {t("cancel")}
              </Button>
              <Button variant="primary" onClick={() => confirmDelete()}>
                {t("confirm")}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </>
    </div>
  );
}

export default Packages;
