import { useQuery, useMutation } from "@tanstack/react-query"
import {
    getTraveldata, postTraveldata,
    updateTraveldata, cancelTrip, getTravelpartners, SendTravelinterest, viewIncomingrequest,
    travelRequestactions,
    getRequestedTrvl
}
    from "../../actions/Travel/Travel"
import { setIncomingTravelrqsts } from "../../store/reducers/travelReducer"
import { useDispatch } from "react-redux"
export const TravelApis = {

    GetTraveldata: () => {

        const response = useQuery({
            queryKey: ["get-tripdata"],
            queryFn: () => getTraveldata(),


        })
        return response
    },
    PostTraveldata: () => {

        const response = useMutation({
            mutationKey: ["post-data"],
            mutationFn: (data) => postTraveldata(data),


        })

        return response
    },
    UpdateTraveldata: () => {
        const response = useMutation({
            mutationKey: ["update-data"],
            mutationFn: (data) => updateTraveldata(data),
        })

        return response
    },

    CancelTrip: () => {
        const response = useMutation({
            mutationKey: ["cancel-trip"],
            mutationFn: (data) => cancelTrip(data),
        })

        return response
    },
    GetTravelPartners: (looking_for, location, date) => {
        const response = useQuery({
            queryKey: ["get-travelpartners", looking_for, location, date],
            queryFn: () => getTravelpartners(looking_for, location, date),


        })
        return response
    },
    SendtravelInterest: () => {
        const response = useMutation({
            mutationKey: ["send-travel-interest"],
            mutationFn: (data) => SendTravelinterest(data),
        })

        return response
    },
    IncomingRequestedTravel: () => {
        const dispatch = useDispatch()
        const response = useMutation({
            mutationKey: ["view-incoming-request"],
            mutationFn: (id) => viewIncomingrequest(id),
            onSuccess: (response) => {
                dispatch(setIncomingTravelrqsts(response.data))
                
            }

        })

        return response
    },
    RequsetActions: () => {
        const response = useMutation({
            mutationKey: ["travel-request-actions"],
            mutationFn: (data) => travelRequestactions(data),

        })

        return response
    },
    GetMytravelRqst:()=>{
        const response = useQuery({
            queryKey: ["get-requested-travel"],
            queryFn: () => getRequestedTrvl(),


        })
        return response
    }


}
