import React from 'react'
import Header from '../views/Admin/Header'
import Footer from '../components/Footer'
import AdminProfile from '../views/Profile/AdminProfile'
import { Outlet} from "react-router";
import { languageApis } from '../hooks/apihooks/languageApis'
import { useTranslation } from 'react-i18next';

function AdminLayout() {
    const {t}=useTranslation()
    const getLanguage = languageApis.GetLanguages()
    return (
        <div>
            <Header languages={getLanguage.data} t={t}/>
            <Outlet />
            <Footer />
        </div>
    )
}

export default AdminLayout