import API from "../api/api";

export const getLanguages = async () => {
    try {
        const response = await API.get('site-language-details');

        if (response.status) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        return error;
    }
};
export const updateLanguage = async (data) => {
    try {
        const response = await API.put('site-language-details', { language_code: data });

        if (response.status) {
            return response.data;
        } else {
            return response;
        }
    } catch (error) {
        return error;
    }
}
