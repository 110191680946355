import React, { useEffect } from 'react'
import Header from '../../Header';
import InnerpageHeader from '../../../common/InnerpageHeader';
import Footer from '../../Footer';
import { useSelector } from 'react-redux';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { IMG_URL } from '../../../config/config';
import nodataImg from '../../../assets/images/nodata.jpg'
import { TravelApis } from '../../../hooks/apihooks/travelApis';
import { showToastmsg } from '../../../common/ToastMsg';
import { Link, useLocation } from 'react-router-dom';
function RequestedTrips() {
    const IncomingRqstedTravels = useSelector((state) => state?.travel?.incomingTRavelrqst)
    const Requestactions = TravelApis.RequsetActions()
    const getData = TravelApis.IncomingRequestedTravel()
    const location = useLocation()
    console.log("======location====", location.state)

    const RequestActionhandler = async (data, status) => {
        try {
            console.log("data and status", data, status)
            const postData = {
                trip_request_id: data.id,
                trip_status: status,
                trip_id: data.trip_id
            }
            const response = await Requestactions.mutateAsync(postData)
            if (response.status) {
                showToastmsg("success", response.data)
                await getRequestDatas(data.trip_id)

            }
        } catch (error) {

        }


    }

    const getRequestDatas = async (tripId) => {
        try {
            await getData.mutateAsync({ tripId: tripId })
        } catch (error) {

        }
    }

    useEffect(() => {
        getRequestDatas(location?.state?.key)
    }, [location.state])
    return (
        <div>
         
            <InnerpageHeader pageTitle="Travel Request" />
            <section className="inner_page_main_bx_section">
                <div className="container">
                    <div className="inner_page_main_box_view">
                    <div className="inner_page_main_bx_head">
                    <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/my-trips"><i class="fa-solid fa-angle-left" /></Link>
                            
                        </div>
                        

                        <div className="best_match_section">
                            {IncomingRqstedTravels&&
                                IncomingRqstedTravels?.length > 0 ?
                                    <List>
                                        {
                                            IncomingRqstedTravels?.map((items, index) => {
                                                return (
                                                    <>
                                                        <Link to={`/singleProfile/${items.username}`}>
                                                            <ListItem alignItems="flex-start">
                                                                <ListItemAvatar>
                                                                    <Avatar alt={items.username.toUpperCase().charAt(0)} src={IMG_URL + items.profile_pic} />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={items.username}
                                                                    secondary={
                                                                        <React.Fragment>
                                                                            <Typography
                                                                                sx={{ display: 'inline', }}
                                                                                component="span"
                                                                                variant="body2"
                                                                                color="text.primary"
                                                                            >
                                                                                {items.username}
                                                                            </Typography>
                                                                            {" — I am interested to travel ✈️ with you💕"}
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                                {
                                                                    items.status === "ACCEPTED" &&
                                                                    <span className='travel-request-accept'>Accepted <i class="fa-solid fa-check"></i></span>


                                                                }
                                                                {
                                                                    items.status === "REJECTED" &&
                                                                    <span className='travel-request-reject'>Rejected <i class="fa-solid fa-close"></i> </span>


                                                                }
                                                                {
                                                                    items.status === "PENDING" &&
                                                                    <>
                                                                        <button className="myaccount_profile_upgrad_btn" onClick={() => RequestActionhandler(items, "ACCEPTED")} style={{marginRight:"10px"}}>

                                                                            Accept
                                                                        </button>
                                                                        <button className="myaccount_profile_upgrad_btn" onClick={() => RequestActionhandler(items, "REJECTED")}>Reject</button></>

                                                                }




                                                            </ListItem>
                                                        </Link>

                                                        <Divider variant="inset" component="li" /></>

                                                )
                                            })
                                        }


                                    </List>
                                    : <center>
                                        <img src={nodataImg} alt='nodata' className='w-25' />
                                    </center>
                            }








                        </div>
                    </div>
                </div>
            </section>
          


        </div>
    )
}

export default RequestedTrips