import { useQuery } from "@tanstack/react-query"
import { getUserjoiningReport } from "../../actions/Reports/Reports"
import { useDispatch } from "react-redux"
import { setPagination } from "../../store/reducers/paginationReducer"
import { useSelector } from "react-redux"
export const userReportsApis={
    GetjoiningReport:(rowsperpage,page)=>{
        const dispatch=useDispatch()
        const {currentPage,totalPages,RowsperPage}=useSelector((state)=>state.pagination.pagination)
        const response = useQuery({            
            queryKey:["get-userjoining-reports",rowsperpage,page],
            queryFn: ()=> getUserjoiningReport(rowsperpage,page),
            onSuccess:(response)=>{
                console.log("======joiningreport==",response?.data?.users_count)
                const totalPages = Math.ceil(response?.data?.users_count /RowsperPage)
               
                dispatch(setPagination({totalPages:Number(totalPages)}))                
            }
        })
        return response
    }
}