import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function PackagesSkeleton() {
    return (
        <div className="plan-options">
            {
                [1, 2].map((_, index) => (
                    
                        <div className="plan" key={index}>
                            <div className="plan-image">
                                <Skeleton height={100} />
                            </div>
                            <div className="plan-header">
                                <h3 className="plan-heading">
                                    <Skeleton width={200} />
                                </h3>

                            </div>
                            <ul className="plan-feature">
                                {
                                    [1, 2].map((_, index) => {
                                        return <li key={index}><Skeleton width={40} /></li>
                                    })
                                }
                            </ul>
                            <p>
                                <Skeleton />
                                <Skeleton />
                            </p>
                           
                            <div className="package_buttons" style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
                               
                                <div className='profile_act_btn'>
                                    <Skeleton  height={40}width={40}/>
                                </div>
                                <div className='profile_act_btn'>
                                    <Skeleton  height={40}width={40}/>
                                </div>
                            </div>
                        </div>
                    

                ))
            }
        </div>
    )
}

export default PackagesSkeleton