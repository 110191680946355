import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   matchUsers:[]
  }
  export const matchSlice = createSlice({
    name: 'match',
    initialState,
    reducers: {  
      setMatchdata: (state, action) => {
        state.matchUsers = action.payload
      },
      
      
    },
  })

  export const { setMatchdata} = matchSlice.actions

export default matchSlice.reducer