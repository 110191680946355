import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function TabContentSkeleton({ tabCount }) {
  return (
    <div className="my_account_right_box">
      <nav>
        <div className="nav nav-tabs" role="tablist">
          {Array(tabCount).fill('').map((_, index) => (
            <button
              key={index}
              className="nav-link"
              type="button"
              role="tab"
              aria-controls={`nav-tab-${index}`}
              aria-selected="false"
            >
              <Skeleton width={100} height={30} />
            </button>
          ))}
        </div>
      </nav>
      <div className="tab-content p-3 border">
        <div className="profile_right_intrst_cnt_sc">
          <div className="edit_content_warning">
            <Skeleton width={200} height={20} />
          </div>
          <div className="edit_icon_mail">
            <Skeleton width={50} height={20} />
          </div>
          <div>
            <Skeleton count={5} />
          </div>
        </div>
        <div className="button-group mt-3">
          <Skeleton width={80} height={30} />
          <Skeleton width={80} height={30} className="ml-2" />
        </div>
      </div>
    </div>
  );
}

export default TabContentSkeleton;
