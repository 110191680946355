import React, { useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import InnerpageHeader from '../../common/InnerpageHeader'
import onBoardimg from '../../assets/images/undraw-onboarding-o8mv-1.jpg'
function Help() {
   
    return (
        <div>
          
            <InnerpageHeader pageTitle="Help" />
            <section className="inner_page_main_bx_section help_page">
                <div className="inner_page_cnt_option_two">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h1>Let's get you<br /> some help!</h1>
                                <h6>Have any issue? Send us an <span className="email-us">email.</span></h6>
                                <div className="img-wrapper">
                                    <img src={onBoardimg} alt="undraw-onboarding-o8mv-1" border="0" />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="list">What list are you looking for ?</label>
                                        <input type="text" className="form-control" id="list" aria-describedby="emailHelp" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="tags">Please Few Primary Tags ?</label>
                                        <input type="text" className="form-control" id="tags" />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="describe">Please describe your list needs ?</label>
                                        <textarea className="form-control" id="describe" rows="5"></textarea>
                                    </div>

                                    <button type="button" className="btn btn-primary"><span>Submit</span> <i className="fas fa-long-arrow-alt-right"></i></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
         
        </div>
    )
}

export default Help