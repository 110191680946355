import { profileServices } from "../../services/profileServices";

export const getProfile = async () => {
  try {
    const response = await profileServices.getProfiledata();
    return response;
  } catch (error) {
    return error;
  }
};

export const uploadCoverphoto = async (data) => {
  try {
    const response = await profileServices.uploadPhoto(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCoverphoto = async (id) => {
  try {
    const response = await profileServices.deletePhoto(id);
    return response;
  } catch (error) {
    return error;
  }
};

export const changeProfilepic = async (pic) => {
  try {
    const response = await profileServices.changeProfilepic(pic);
    return response;
  } catch (error) {
    return error;
  }
};

export const dltInterests = async (data) => {
  try {
    const response = await profileServices.dltInterests(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getSettingsdata = async (type) => {
  try {
    const response = await profileServices.getsettingsData(type);
    return response;
  } catch (error) {
    return error;
  }
};
export const Savesettings = async (data) => {
  try {
    const response = await profileServices.saveSettings(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFavouriesList = async () => {
  try {
    const response = await profileServices.getFavourites();
    return response;
  } catch (error) {
    return error;
  }
};

export const dltFavourite = async (id) => {
  try {
    const response = await profileServices.DltFavourite(id);
    return response;
  } catch (error) {
    return error;
  }
};

export const enableTwoFA = async () => {
  try {
    const response = await profileServices.EnableTwoFA();
    return response;
  } catch (error) {
    return error;
  }
};

export const getLikedusers = async () => {
  try {
    const response = await profileServices.getLikedusers();
    return response;
  } catch (error) {
    return error;
  }
};

export const dltLikedusers = async (data) => {
  try {
    const response = await profileServices.dltLikedusers(data);
    return response;
  } catch (error) {
    return error;
  }
};
export const getblockedUsers = async () => {
  try {
    const response = await profileServices.getblockedUsers();
    return response;
  } catch (error) {
    return error;
  }
};

export const checkSponsorExist=async(data)=>{
  try {
    const response = await profileServices.checkSponsorExist(data);
    return response;
  } catch (error) {
    return error
  }
}
