import { useMutation, useQuery } from "@tanstack/react-query";
import { getProfile } from "../../actions/Profile/getProfile";
import { useDispatch } from "react-redux";
import { setProfiledata } from "../../store/reducers/profileReducer";
import { useNavigate } from "react-router-dom";
import { UpdatePersonaldetails } from "../../actions/Profile/updatePersonaldetails";
import { uploadCoverphoto } from "../../actions/Profile/getProfile";
import { deleteCoverphoto } from "../../actions/Profile/getProfile";
import { changeProfilepic } from "../../actions/Profile/getProfile";
import { dltInterests } from "../../actions/Profile/getProfile";
import { getSettingsdata } from "../../actions/Profile/getProfile";
import { Savesettings } from "../../actions/Profile/getProfile";
import { setIslogged } from "../../store/reducers/authReducer";
import {
  getFavouriesList,
  dltFavourite,
  getLikedusers,
  dltLikedusers,
  enableTwoFA,
  getblockedUsers
} from "../../actions/Profile/getProfile";
import { setSelectedLanguage } from "../../store/reducers/languageReducer";

export const ProfileApis = {
  GetProfileData: () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const response = useQuery({
      queryKey: ["profileData"],
      queryFn: () => getProfile(),
      onSuccess: (response) => {
        console.log("onsuccess====", response);
        if (response.status) {
          dispatch(setProfiledata(response?.data));
          localStorage.setItem('lang', response?.data?.user?.site_language)
          const useLan = { language_code: response?.data?.user?.site_language, language_logo: response?.data?.user?.site_language_logo }
          dispatch(setSelectedLanguage(useLan))
        } else {
          localStorage.clear();
          dispatch(setIslogged(false));
          navigate("/login");
        }
      },
    });

    return response;
  },

  EditpersonalDetails: () => {
    const response = useMutation({
      mutationKey: ["updatePersonaldetails"],
      mutationFn: (data) => UpdatePersonaldetails(data),
    });

    return response;
  },
  EnableTwoFA: () => {
    const response = useMutation({
      mutationKey: ["twoFA"],
      mutationFn: () => enableTwoFA(),
    });
    return response;
  },
  Uploadcoverphoto: () => {
    const response = useMutation({
      mutationKey: ["uploadCoverphoto"],
      mutationFn: (data) => uploadCoverphoto(data),
    });

    return response;
  },
  Dltcoverphoto: () => {
    const response = useMutation({
      mutationKey: ["dltCoverphoto"],
      mutationFn: (data) => deleteCoverphoto(data),
    });

    return response;
  },
  Changeprofilepic: () => {
    const response = useMutation({
      mutationKey: ["changeprofilePic"],
      mutationFn: (data) => changeProfilepic(data),
    });

    return response;
  },
  Dltinterests: () => {
    const response = useMutation({
      mutationKey: ["dltInterest"],
      mutationFn: (data) => dltInterests(data),
    });

    return response;
  },
  GetSettingsOptionsdata: (type) => {
    const response = useQuery({
      queryKey: ["settingsDAta"],
      queryFn: () => getSettingsdata(type),
    });
    return response;
  },
  SaveSettings: () => {
    const response = useMutation({
      mutationKey: ["addsettingsdata"],
      mutationFn: (data) => Savesettings(data),
    });

    return response;
  },
  GetFavourites: () => {
    const response = useQuery({
      queryKey: ["favourites"],
      queryFn: () => getFavouriesList(),
    });
    return response;
  },
  DltFavourites: () => {
    const response = useMutation({
      mutationKey: ["dlt-favourites"],
      mutationFn: (data) => dltFavourite(data),
    });
    return response;
  },
  LikedUsers: () => {
    const response = useQuery({
      queryKey: ["liked-users"],
      queryFn: () => getLikedusers(),
    });
    return response;
  },
  DltLikedUsers: () => {
    const response = useMutation({
      mutationKey: ["dltliked-users"],
      mutationFn: (data) => dltLikedusers(data),
    });
    return response;
  },
  BlockedUsers: () => {
    const response = useQuery({
      queryKey: ["get-blocked-users"],
      queryFn: () => getblockedUsers(),
    });
    return response;
  },


};
