import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import { settingsApis } from "../../hooks/apihooks/settingsApis";
import { showToastmsg } from "../../common/ToastMsg";

function CompanyDetails({ data,t }) {
 
  const queryClient = useQueryClient();
  const submitDetails = settingsApis.PostCompanyDetails();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      company_name: data?.company_name ?? "",
      company_mail: data?.company_mail ?? "",
      address: data?.address ?? "",
      email_host_user: data?.email_host_user ?? "",
      email_host_password: data?.email_host_password ?? "",
      email_host: data?.email_host,
      email_port: data?.email_port,
      company_address: data?.company_address ?? "",
      // terms: data?.terms ?? "",
    },
  });
  const [edit, setEdit] = useState(false);
  const changeEditMode = (value) => {
    setEdit(value);
  };
  const handleCancel = () => {
    reset({
      company_name: data?.company_name ?? "",
      company_mail: data?.company_mail ?? "",
      address: data?.address ?? "",
      email_host_user: data?.email_host_user ?? "",
      email_host_password: data?.email_host_password ?? "",
      email_host: data?.email_host,
      email_port: data?.email_port,
      company_address: data?.company_address ?? "",
      // terms: data?.terms ?? "",
    });
    changeEditMode(false);
    setDefaultContent();
  };
  const submitHandler = async (data) => {
    const isValid = await trigger();
    if (isValid) {
     
      const response = await submitDetails.mutateAsync(data);
      if (response.status) {
        setDefaultContent();
        changeEditMode(false);
      } else {
        showToastmsg("error", "failed", "top");
      }
    }
  };
  const setDefaultContent = () => {
    queryClient.invalidateQueries({
      queryKey: ["get-company-details"],
    });
  };
  return (
    <div>
      <div className="my_account_right_head">{t('cmpny_dtails')}</div>
      <div className="my_account_right_box">
        <p>{t('cmpny_info')}</p>
        <div className="row">
          <div className="col-md-6">
            <div className="filter_box">
              <div className="filter_box_head">{t('name')}</div>
              <div className="filter_box_cnt">
                <input
                  type="text"
                  disabled={!edit}
                  className={`${
                    errors.company_name ? "erorInput " : ""
                  }form-control`}
                  name="company_name"
                  {...register("company_name", {
                    required: {
                      value: true,
                      message: t('name_is_req'),
                    },
                    onChange: async (e) => {
                      await trigger(e.target.name);
                    },
                  })}
                />
                {errors.name && (
                  <p role="alert" className="formError">
                    <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                    &nbsp;{errors?.company_name?.message}*
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="filter_box">
              <div className="filter_box_head">{t('email')}</div>
              <div className="filter_box_cnt">
                <input
                  type="text"
                  disabled={!edit}
                  className={`${
                    errors.company_mail ? "erorInput " : ""
                  }form-control`}
                  name="company_mail"
                  placeholder=""
                  {...register("company_mail", {
                    required: {
                      value: true,
                      message: t("email_is_required"),
                    },

                    pattern: {
                      value:
                        /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                      message: t("invalid_mail_format"),
                    },
                    onChange: async (e) => {
                      await trigger(e.target.name);
                    },
                  })}
                />
                {errors.email && (
                  <p role="alert" className="formError">
                    <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                    &nbsp;{errors?.company_mail?.message}*
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="filter_box">
              <div className="filter_box_head">{t('address')}</div>
              <div className="filter_box_cnt">
                <textarea
                  name="company_address"
                  disabled={!edit}
                  className={`${
                    errors.company_address ? "erorInput " : ""
                  }form-control`}
                  {...register("company_address", {
                    required: {
                      value: true,
                      message: t('address_is_required'),
                    },
                    onChange: async (e) => {
                      await trigger(e.target.name);
                    },
                  })}
                ></textarea>
                {errors.company_address && (
                  <p role="alert" className="formError">
                    <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                    &nbsp;{errors?.company_address?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <p>{t('mail_config')}</p>
        <div className="row">
          <div className="col-md-6">
            <div className="filter_box">
              <div className="filter_box_head">{t('smtp_host')}</div>
              <div className="filter_box_cnt">
                <input
                  type="text"
                  name="email_host"
                  disabled={!edit}
                  className={`${
                    errors.email_host ? "erorInput " : ""
                  }form-control`}
                  {...register("email_host", {
                    required: {
                      value: true,
                      message: t("smtp_host_is_required"),
                    },
                    onChange: async (e) => {
                      await trigger(e.target.name);
                    },
                  })}
                />
                {errors.email_host && (
                  <p role="alert" className="formError">
                    <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                    &nbsp;{errors?.email_host?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="filter_box">
              <div className="filter_box_head">{t('smtp_port')}</div>
              <div className="filter_box_cnt">
                <input
                  type="text"
                  name="email_port"
                  disabled={!edit}
                  className={`${
                    errors.email_port ? "erorInput " : ""
                  }form-control`}
                  {...register("email_port", {
                    required: {
                      value: true,
                      message: t("smtp_port_is_required"),
                    },
                    onChange: async (e) => {
                      await trigger(e.target.name);
                    },
                  })}
                />
                {errors.email_port && (
                  <p role="alert" className="formError">
                    <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                    &nbsp;{errors?.email_port?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="filter_box">
              <div className="filter_box_head">{t('smtp_username')}</div>
              <div className="filter_box_cnt">
                <input
                  type="text"
                  name="email_host_user"
                  disabled={!edit}
                  className={`${
                    errors.email_host_user ? "erorInput " : ""
                  }form-control`}
                  {...register("email_host_user", {
                    required: {
                      value: true,
                      message: t("smtp_username_is_required"),
                    },
                    onChange: async (e) => {
                      await trigger(e.target.name);
                    },
                  })}
                />
                {errors.email_host_user && (
                  <p role="alert" className="formError">
                    <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                    &nbsp;{errors?.email_host_user?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="filter_box">
              <div className="filter_box_head">{t('smtp_pswd')}</div>
              <div className="filter_box_cnt">
                <input
                  type={edit ? "text" : "password"}
                  name="email_host_password"
                  disabled={!edit}
                  className={`${
                    errors.email_host_password ? "erorInput " : ""
                  }form-control`}
                  {...register("email_host_password", {
                    required: {
                      value: true,
                      message: t("smtp_password_is_required"),
                    },
                    onChange: async (e) => {
                      await trigger(e.target.name);
                    },
                  })}
                />
                {errors.email_host_password && (
                  <p role="alert" className="formError">
                    <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                    &nbsp;{errors?.email_host_password?.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          {edit && (
            <>
              <button
                type="button"
                className="btn regsiter_top_btn"
                onClick={handleCancel}
              >
                {t('cancel')}
              </button>
              <button
                type="button"
                className="btn regsiter_top_btn m-2"
                onClick={handleSubmit(submitHandler)}
              >
                {t('save')}
              </button>
            </>
          )}
          {!edit && (
            <button
              type="button"
              className="btn regsiter_top_btn m-2"
              onClick={() => changeEditMode(true)}
            >
              {t('edit')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyDetails;
