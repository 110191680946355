import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import twoFA from "../../assets/images/twofa.jpg";
import { useDispatch } from "react-redux";
import { ForgotpaswdApis } from "../../hooks/apihooks/forgotPswdApis";
import { showToastmsg } from "../../common/ToastMsg";
import { setIslogged } from "../../store/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Watch, ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";

const COUNTDOWN = 15;

function TwoFactorAuthentication() {
  const location = useLocation();
  const dispatch = useDispatch();
  const naviage = useNavigate();

  const submitOtp = ForgotpaswdApis.VerifyOtp();
  const resendOtp = ForgotpaswdApis.ResendOtp();

  const [resendStatus, setResendStatus] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(COUNTDOWN);

  useEffect(() => {
    let totalSeconds = minutes * 60 + seconds;
    if (totalSeconds > 0 && resendStatus) {
      const timer = setInterval(() => {
        totalSeconds -= 1;
        const newMinutes = Math.floor(totalSeconds / 60);
        const newSeconds = totalSeconds % 60;

        setMinutes(newMinutes);
        setSeconds(newSeconds);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [minutes, seconds, resendStatus]);

  const [data, setData] = useState({
    otp: "",
    error: false,
    erroMsg: "",
  });
  const changeHandler = async (e) => {
    setData((prev) => ({
      ...prev,
      otp: e.target.value,
      error: false,
      erroMsg: "",
    }));
  };

  console.log("2factoremail====",location)
  const resendHandler = async () => {
    setResendStatus(true);
    let resendOtpResponse = await resendOtp.mutateAsync({
      type: "login",
      method: "email",
      email: location.state.email,
    });
    if (resendOtpResponse.status) {
      showToastmsg(
        "success",
        resendOtpResponse?.message ?? "OTP Sent Successfully"
      );
    } else {
      showToastmsg("error", resendOtpResponse ?? "Failed");
    }
    setTimeout(() => {
      setResendStatus(false);
      setSeconds(COUNTDOWN);
    }, COUNTDOWN * 1000);
  };
  const submitHandler = async () => {
    if (await validation()) {
      let response = await submitOtp.mutateAsync({
        otp: data.otp,
        email: location.state.email,
        type: "login",
      });
      console.log("response ===>>> ", response);
      if (response.status) {
        dispatch(setIslogged(true));
        localStorage.setItem("logingstatus", true);
        localStorage.setItem("access-token", response.data.access_token);
        localStorage.setItem("refersh-token", response.data.refresh_token);
        naviage("/profile");
      } else {
        setData((prev) => ({
          ...prev,
          error: true,
          erroMsg: response ?? "Invalid OTP",
        }));
      }
    }
  };
  const validation = async () => {
    let isValid = true;
    if (data.otp === "" || !data.otp) {
      isValid = false;
      setData((prev) => ({
        ...prev,
        error: true,
        erroMsg: "Enter OTP",
      }));
    } else {
      setData((prev) => ({
        ...prev,
        error: false,
        erroMsg: "",
      }));
    }
    return isValid;
  };
  return (
    <div>
      <ToastContainer />
    
      <section className="register_login_section">
        <div className="container text-center">
          <div className="register_login_box text-start login_box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="register_login_box_head">
                  OTP Verification
                  <p>Enter your OTP</p>
                </div>
                <div className="register_login_box_cnt">
                  <div className="register_login_box_form">
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        {/* <label htmlFor="">OTP</label> */}
                        <input
                          type="text"
                          className={`${
                            data.error ? "erorInput " : ""
                          }form-control`}
                          name="otp"
                          onChange={changeHandler}
                        />
                        {data.error && (
                          <p className="formError">
                            <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                            &nbsp;{data.erroMsg}*{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <a>
                    <button
                      className="reg_login_sub_btn mt-3"
                      disabled={submitOtp.isLoading ? true : false}
                      onClick={submitHandler}
                    >
                      {submitOtp.isLoading ? (
                        <div>
                          loading <i class="fa-solid fa-spinner fa-spin"></i>
                        </div>
                      ) : (
                        "verify"
                      )}
                    </button>
                    <div>
                      <button
                        className={
                          resendStatus
                            ? "reg_login_sub_btn_disabled mt-3"
                            : "reg_login_sub_btn mt-3"
                        }
                        disabled={resendStatus ?? true}
                        onClick={resendHandler}
                      >
                        {resendStatus ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <strong>
                              {`${String(minutes).padStart(2, "0")}:${String(
                                seconds
                              ).padStart(2, "0")}`}
                            </strong>
                          </div>
                        ) : (
                          "Resend"
                        )}
                      </button>
                    </div>
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="login_right_sec">
                  <img src={twoFA} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
}

export default TwoFactorAuthentication;
