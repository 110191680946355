import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import InnerpageHeader from '../../common/InnerpageHeader'
import { useForm } from "react-hook-form"
import { ContactUsapis } from '../../hooks/apihooks/contactUsapi'
import { showToastmsg } from '../../common/ToastMsg'
function Contactus() {

    const { register, handleSubmit, formState: { errors }, reset, trigger, watch,
    } = useForm({
        defaultValues: {
            email: '',
            message: ''

        }
    })
    const postContact = ContactUsapis.SendContactus()
    const getCompanydetails=ContactUsapis.GetcompanyDetails()
    const onSubmit = async (data) => {
        try {
            const isValid = await trigger();
            
            if (isValid) {
                const response = await postContact.mutateAsync(data)
                if (response.status) {
                    showToastmsg("success","Data send successfully")
                    reset({
                        email: "",
                        message:""
                      })
                }else{
                    showToastmsg("error","Something went wrong..")
                }
            }
        } catch (error) {

        }
    }

    return (
        <div>
            
            <InnerpageHeader pageTitle="Contact Us" />
            <section className="inner_page_main_bx_section about_page_cnt">
                <section>
                    <div className="inner_page_cnt_option_two">
                        <div className="container">
                            <div className="row justify-content-between align-items-center">

                                <div className="col-md-6">
                                    <div className="earnpoint_box about_box">
                                        <h3>
                                            <span>Contact Us</span>
                                            Get in touch with us
                                        </h3>
                                        <div className="contact_lft_box">
                                            <ul>
                                                <li><a href="#"><i className="fa-regular fa-envelope"></i> {getCompanydetails?.data?.data?.Email}</a></li>
                                                <li><a href="#"><i className="fa-solid fa-location-dot"></i> {getCompanydetails?.data?.data?.Location}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 text-center">
                                    <div className="contact_right_box">
                                        <h5>Enquire Now</h5>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row">
                                                {/* <div className="col-md-12">
                                                    <div className="inout-group mb-3">
                                                        <label htmlFor="">Your Name</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <div className="inout-group mb-3">
                                                        <label htmlFor="">Your Email</label>
                                                        <input type="text" className={`${errors.email ? 'erorInput ' : ''}form-control`}
                                                            {...register("email",
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: "email is required*"
                                                                    },

                                                                    pattern: {
                                                                        value: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/,
                                                                        message: "Invalid mail format"
                                                                    },
                                                                    onChange: async (e) => {
                                                                        await trigger(e.target.name)
                                                                    }
                                                                })} />
                                                        {errors.email && (
                                                            <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.email?.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                {/* <div className="col-md-12">
                                                    <div className="inout-group mb-3">
                                                        <label htmlFor="">Your Location</label>
                                                        <input type="text" className="form-control" />
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <div className="inout-group mb-3">
                                                        <label htmlFor="">Your Message</label>
                                                        form-control
                                                        <textarea name="" className={`${errors.message ? 'erorInput ' : ''}form-control`} id="" cols="30" rows="7"
                                                            {...register("message",
                                                                {
                                                                    required: {
                                                                        value: true,
                                                                        message: "message is required*"
                                                                    },


                                                                    onChange: async (e) => {
                                                                        await trigger(e.target.name)
                                                                    }
                                                                })}>

                                                        </textarea>
                                                        {errors.message && (
                                                            <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.message?.message}</p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button className="regsiter_top_btn" type='submit'> Send </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
            
        </div>
    )
}

export default Contactus