import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  loginResponse: {},
  isLogged: false,
  loginData: {
    username: '',
    password: ''
  },
  loginError: {
    username: '',
    password: ''
  },
  loggedUsername: '',
  loggedUserdetails: {},
  isAdmin: false


}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {

    setLoginresponse: (state, action) => {
      state.loginResponse = action.payload
    },
    setIslogged: (state, action) => {
      state.isLogged = action.payload
    },
    setLOgindata: (state, action) => {
      state.loginData = { ...state.loginData, ...action.payload };
    },
    setLoginErr: (state, action) => {
      const { field, errorMsg } = action.payload
      state.loginError[field] = errorMsg

    },
    setLoggeduserId: (state, action) => {
      state.loggedUsername = action.payload
    },
    setLoggeduserdetails: (state, action) => {
      state.loggedUserdetails = action.payload
    },
    setIsadmin: (state, action) => {
      state.isAdmin = action.payload
    }

  },
})

// Action creators are generated for each case reducer function
export const { setLoginresponse, setIslogged, setLOgindata, setLoginErr, setLoggeduserId, setLoggeduserdetails ,setIsadmin} = loginSlice.actions

export default loginSlice.reducer