import { useMutation, useQuery } from "@tanstack/react-query"
import { getLanguages, updateLanguage } from '../../services/languageService'
export const languageApis = {
    GetLanguages: () => {
        const response = useQuery({
            queryKey: "get-language",
            queryFn: () => getLanguages()
        })
        return response;
    },
    UpdateLanguage:()=>{
        const response=useMutation({
            mutationFn:(data)=>updateLanguage(data)
        })
        return response;
    }
}