import React from 'react'
import userImg1 from '../../../assets/images/users/user_imag_1.jpg'
import { ProfileApis } from '../../../hooks/apihooks/profileApis'
import { IMG_URL } from '../../../config/config';
import { useQueryClient } from '@tanstack/react-query';
import { showToastmsg } from '../../../common/ToastMsg';
import nodataIMg from '../../../assets/images/nodata.jpg';
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import CardSkeleton from '../../skeletons/CardSkeleton';
function Favourite({t}) {
    const getFavourites = ProfileApis.GetFavourites();
    const dltFavourite = ProfileApis.DltFavourites()
    const queryClient = useQueryClient();


    const dltFavouriteHandler = async (id) => {
        let data = {
            user: id
        }
        const response = await dltFavourite.mutateAsync(data)
        if (response.status) {
            queryClient.invalidateQueries(["favourites"])
        } else {
            showToastmsg("error", "something went wrong...")
        }
    }
    return (
        <div>
            <div className="my_account_right_head">
               {t('fvrtd')}
            </div>
            <div className="my_account_right_box">
                {
                    getFavourites.isLoading ?
                        <CardSkeleton type={"fvrts"} iconsCount={0} />
                        :
                        getFavourites?.data?.data?.my_favorite_data.length > 0 ?
                            <div className="row">
                                {
                                    getFavourites?.data?.data?.my_favorite_data && (
                                        getFavourites?.data?.data?.my_favorite_data.map((items, index) => {
                                            return (
                                                <div className="col-md-3">
                                                    <div className="profile_match_crsl_box">
                                                        <div className="profile_match_crsl_box_img">
                                                            <a className="dlete_action_btn">
                                                                <i className="fa fa-trash" onClick={() => dltFavouriteHandler(items.user__id)}></i>
                                                            </a>
                                                            <a href={`/singleProfile/${items.user__username}`}>
                                                                <img src={items.profile_picture ? IMG_URL + items.profile_picture : defaultProfilepic} alt="" /></a>
                                                        </div>
                                                        <a href={`/singleProfile/${items.user__username}`}>
                                                            <div className="profile_cnt_bx">
                                                                {items.user__username} - {items.age ?? 25}
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                }

                            </div>
                            :

                            <img src={nodataIMg} alt='nodata' className='w-50' />


                }

            </div>
        </div>
    )
}

export default Favourite