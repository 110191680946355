import React from "react";
import flag from "../../assets/images/uk_flagpng.png";
import logo from "../../assets/images/logo.png";
import $ from "jquery";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import userProfilepic from "../../assets/images/users/user_imag_1.jpg";
import { BASE, IMG_URL } from "../../config/config";
import { LoginApis } from "../../hooks/apihooks/loginApis";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIslogged } from "../../store/reducers/authReducer";
import { showToastmsg } from "../../common/ToastMsg";
import { useState } from "react";
import { toast } from "react-toastify";
import { NotificationApis } from "../../hooks/apihooks/notificationApis";
import defaultProfilepic from "../../assets/images/user-blue-gradient.png";
import { setSelectedLanguage } from "../..//store/reducers/languageReducer";
import i18n from "../../i18n";
import moment from "moment";
import { languageApis } from "../../hooks/apihooks/languageApis";


function Header({ languages,t }) {

    const { isLogged } = useSelector((state) => state.login)
    const logout = LoginApis.Logout()
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const location = useLocation()
    const notificationCount = useSelector((state) => state.notification.count)
    const seenStatus = useSelector((state) => state.notification.seenStatus)
    let getLoginUserdata = LoginApis.LoginUserdata()
    const getNotifications = NotificationApis.GetNotification()
    const postSeenStatus = NotificationApis.SeenNotifications()
    const [msgData, setmsgDAta] = useState("")
    const [SeenNoti, setSeenNoti] = useState(false)
    const selectedLan = useSelector((state) => state?.languages.language)
    const updateLan = languageApis.UpdateLanguage()
    
    
    useEffect(() => {
        const handleScroll = () => {
            var sticky = $('.sticky'),
                scroll = $(window).scrollTop();

            if (scroll >= 100) sticky.addClass('fixed');
            else sticky.removeClass('fixed');
        };
        $(window).on('scroll', handleScroll);
        return () => {
            $(window).off('scroll', handleScroll);
        };
    }, []);

    const logoutHandler = async () => {
        await logout.mutateAsync()
        localStorage.clear()
        dispatch(setIslogged(false))
        navigate('/login')
    }

    const viewAllhandler = async () => {
        const response = await postSeenStatus.mutateAsync()
        if (response.status) {
            setSeenNoti(true)
            navigate('/profile/notification')

        }

    }
    const privateHeaders = [
        {
            titile: t('home'),
            path: '/profile',

        },
        {
            titile: t('package'),
            path: '/package',

        },
        {
            titile: t('settings'),
            path: '/settings',

        },
        {
            titile: t('download'),
            path: '/download',

        },
        {
            titile: t('earn_point'),
            path: '/earn-point',

        },


    ]
    const languageChangeHandler = async (items) => {
        try {
            console.log("==items=====", items)
            const langCode = items?.language_code
            dispatch(setSelectedLanguage(items))
            i18n.changeLanguage(langCode)
            const response = await updateLan.mutateAsync(langCode)
        } catch (error) {

        }
    }
    return (
        <header className="main_header sticky">

            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <a className="navbar-brand" href="#"><img src={logo} alt="" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            {

                                privateHeaders.map((items, index) => {
                                    return (
                                        <li className="nav-item">
                                            <Link className={`nav-link ${items.path == location.pathname ? `active` : ''}`} to={items.path} isActive>{items.titile}</Link>

                                            {/* <a className={`nav-link ${items.path == location.pathname ? `active` : ''}`} aria-current="page" href={items.path} >{items.titile}</a> */}
                                        </li>
                                    )
                                })



                            }


                            <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div className="lang_img_bx"><img src={IMG_URL + selectedLan.package_img ?? flag} alt="" /></div>
                                </a>
                                <ul className="dropdown-menu">

                                    {languages &&
                                        languages?.map((items, index) => (
                                            <li onClick={() => languageChangeHandler(items)}>
                                                <Link className={`${`dropdown-item ${items?.language_code === selectedLan?.language_code ? `active` : ``}`}`} to='#' style={{ textTransform: 'capitalize' }}>{items.site_language}</Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </li>
                            {

                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle notification_btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="notification_ico">
                                            {
                                                notificationCount &&
                                                    notificationCount > 0 ?
                                                    <i class="fa-regular fa-bell fa-shake"></i>
                                                    : <i class="fa-regular fa-bell"></i>
                                            }

                                            <span>
                                                {
                                                    notificationCount &&
                                                    notificationCount
                                                }

                                            </span>
                                        </span>
                                    </a>
                                    <ul class="dropdown-menu notification_drp_sec">
                                        <div class="user_prfl_top_hdr">
                                            <span>{t('notifications')}</span>
                                        </div>

                                        <div class="notification_scrl">
                                            {
                                                !seenStatus ?
                                                    getNotifications?.data?.data?.notifications &&

                                                    getNotifications?.data?.data?.notifications.slice(0, 2).map((items, index) => {
                                                        return (
                                                            <li>
                                                                <a className='notification_list_row_new' href={`/singleProfile/${items.from_user}`}>
                                                                    <div className="usr_prfl_box">
                                                                        {

                                                                            <img src={items.profile_picture ? IMG_URL + items.profile_picture : defaultProfilepic} alt="" />
                                                                        }
                                                                    </div>
                                                                    <div className="notification_list_txt">
                                                                        <p>{items.description}</p>
                                                                        <span>{
                                                                            moment(items.date_added).format('DD-MM-YYYY')
                                                                        }</span>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        )
                                                    })
                                                    : <li>
                                                        <a href="#/">
                                                            <p>{t('no_new_noti')}</p>

                                                        </a>
                                                    </li>
                                            }


                                        </div>
                                        {
                                            !seenStatus && (
                                                <li class="view_all_btn">
                                                    <a href="#" onClick={viewAllhandler}>{t('view_all')}</a>
                                                </li>
                                            )
                                        }

                                    </ul>
                                </li>

                            }

                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle user_prfl_top_hdr" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <div class="usr_prfl_box">
                                        {
                                            getLoginUserdata?.data &&
                                            <img src={getLoginUserdata?.data?.data?.profile_picture ? IMG_URL + getLoginUserdata?.data?.data?.profile_picture : defaultProfilepic} alt="" />
                                        }
                                    </div>
                                    <span>
                                        {
                                            getLoginUserdata?.data?.data &&
                                            getLoginUserdata?.data?.data?.username

                                        }
                                    </span>
                                </a>
                                <ul class="dropdown-menu">
                                    <div class="user_prfl_top_hdr">
                                        <div class="usr_prfl_box">
                                            {
                                                getLoginUserdata?.data &&
                                                <img src={getLoginUserdata?.data?.data?.profile_picture ? IMG_URL + getLoginUserdata?.data?.data?.profile_picture : defaultProfilepic} alt="" />
                                            }
                                        </div>
                                        <span>
                                            {
                                                getLoginUserdata?.data?.data &&
                                                getLoginUserdata?.data?.data?.username

                                            }
                                        </span>
                                    </div>
                                    <li>

                                        <Link class="dropdown-item" to="/profile">{t('profile')}</Link>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="#" onClick={logoutHandler}>{t('logout')}</a>
                                    </li>
                                </ul>
                            </li>




                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header