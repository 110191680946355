import { useMutation,useQuery } from "@tanstack/react-query";
import { forgotPswdsubmitHandler } from "../../actions/Forgotpasswd/forgotpswd";
import { getmyPreference, postPreferncedata,getmyMatches,postLikedUsersIds ,postFavouritedUsersIds} from "../../actions/Matches/matchPreference";
import { getPreferenceoptions } from "../../actions/Matches/matchPreference";
import { useDispatch } from "react-redux";
import { setMatchdata } from "../../store/reducers/matchReducer";
import { blockusers } from "../../actions/Matches/matchPreference";
import { getSinglePagedata,postRatingdata ,pokeSending} from "../../actions/Matches/matchPreference";

export const MatchApis={ 
    FilterSubmit:()=>{
        const response=useMutation({
            mutationFn:(data)=>postPreferncedata(data)
        })
        return response
    },
    GetPrefernceOptions:(type)=>{
        const response=useQuery({
            queryKey:["prefernce-options"],
            queryFn:()=>getPreferenceoptions(type),
           
        })
        return response
        
    },
    GetmyPrefernce:()=>{
        const response=useQuery({
            queryKey:["get-my-prefernce"],
            queryFn:()=>getmyPreference(),
           
        })
        return response
        
    },
    Getmatches:()=>{
        const dispatch=useDispatch();
        const response=useQuery({
            queryKey:["get-my-matches"],
            queryFn:()=>getmyMatches(),
            onSuccess:(response)=>{
                dispatch(setMatchdata(response))
            }
           
        })
        return response
        
    },
    PostLikedDetail:()=>{
        const response=useMutation({
            mutationKey:["post-likeduser-id"],
            mutationFn:(data)=>postLikedUsersIds(data),
           
        })
        return response
        
    },
    PostFavouritedDetails:()=>{
        const response=useMutation({
            mutationKey:["post-favouriteDetails"],
            mutationFn:(data)=>postFavouritedUsersIds(data),
           
        })
        return response
        
    },
    BlockUsers: () => {
        const response = useMutation({
            mutationKey: ["block-users"],
            mutationFn: (data) => blockusers(data)
        })
        return response
    },
    MatchuserSinglepage: (user_id) => {
        const response = useQuery({
            queryKey: ["get-singlepagedata",user_id],
            queryFn: () => getSinglePagedata(user_id)
        })
        return response
    },
    CoverpicsRating: () => {
        const response = useMutation({
            mutationKey: ["post-rating"],
            mutationFn: (data) => postRatingdata(data)
        })
        return response
    },
    SendPoke: () => {
        const response = useMutation({
            mutationKey: ["send-poke"],
            mutationFn: (data) => pokeSending(data)
        })
        return response
    }




}
