import API from "../api/api"

export const reportServices = {
  getUserjoiningReport: async (rowsperpage, page) => {
    try {
      const response = await API.get('admin_user_report', {
        headers: {
          rowsperpage: rowsperpage,
          page: page,
        }
      })
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },


}