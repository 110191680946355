import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
function ProfileViewSkeleton() {
    return (
        <div className="row">
            <div className="col-md-4">
                <div className="profile_page_pro_image">
                    <Skeleton circle={true} height={300} width={300} />
                </div>
            </div>
            <div className="col-md-8">
                <div className="profile_page_right_cnt_sec">
                    <div className="profile_page_right_name">
                        <Skeleton width={200} height={30} />
                        <div className="profile_page_right_action_btn_sec">
                            <Skeleton circle={true} height={40} width={40} />
                          
                        </div>
                    </div>
                    <div className="prfl_location">
                        <Skeleton width={150} height={30} />
                    </div>
                    <div className="profile_right_intrst_sec">
                        <Skeleton width={100}/>
                        <div className="profile_right_intrst_cnt_sc d-flex align-items-center">
                            {Array(5).fill('').map((_, index) => (
                                <Skeleton key={index} width={80} height={30} className="filter_box_intrstbx" />
                            ))}
                        </div>
                    </div>
                    <div className="profile_btn_row">
                        {Array(4).fill('').map((_, index) => (
                            <Skeleton key={index} circle={true} height={60} width={60} className="m-1" />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileViewSkeleton