import React from 'react'
import userImg1 from '../../../assets/images/users/user_imag_1.jpg'
import { IMG_URL, BASE, WEB_SOCKET_URL } from '../../../config/config'
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import { MatchApis } from '../../../hooks/apihooks/matchApis'
import { useQueryClient } from '@tanstack/react-query'
import { showToastmsg } from '../../../common/ToastMsg'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Chatapis } from '../../../hooks/apihooks/chatApis'
import { useDispatch } from 'react-redux'
import { setIsroomIdreceived } from '../../../store/reducers/chatReducer'


function ProfileInfo({ data,t }) {
    const loggeduser = useSelector((state) => state.login.loggedUsername)
    const loginUserDetails = useSelector((state) => state.login.loggedUserdetails)
    const [blockIds, setblockIds] = useState([])
    const [blockedStatus, setblockedStatus] = useState(data?.block_status)
    const [likeStatus, setlikeStatus] = useState(true)
    const [fvrtedStatus, setfvrtStatus] = useState(true)
    const [blockedBtnclick, setblockedBtnclick] = useState(true)
    const { user_name } = useParams()
    const queryClient = useQueryClient()
    const postLikesandDislike = MatchApis.PostLikedDetail()
    const postFvrtedandUnfvrted = MatchApis.PostFavouritedDetails()
    const postReceiverId = Chatapis.CreateChatroom()
    const postPoke = MatchApis.SendPoke()
    const blockUser = MatchApis.BlockUsers()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const tooltip = (

        <Tooltip id="button-tooltip">
           {t('clck_to_unblock')}
        </Tooltip>
    );


    const blockHandler = async () => {
        setblockedBtnclick(!blockedBtnclick)
        if (blockedBtnclick) {
            setblockedStatus(true)
            let blockeduserId = {
                user: data.id
            }
            const response = await blockUser.mutateAsync(blockeduserId)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-singlepagedata"] })
            }

        } else {
            let unBlockeduserId = {
                user: data.id
            }
            const response = await blockUser.mutateAsync(unBlockeduserId)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-singlepagedata"] })
            }
            setblockedStatus(false)
        }

    }
    const likeHandler = async () => {
        setlikeStatus(!likeStatus)
        if (likeStatus) {
            let likedData = {
                user: data.id
            }
            const response = await postLikesandDislike.mutateAsync(likedData)
            if (response.status) {

                queryClient.invalidateQueries({ queryKey: ["get-singlepagedata"] })
            }
        } else {
            let dislikedData = {
                user: data.id
            }
            const response = await postLikesandDislike.mutateAsync(dislikedData)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-singlepagedata"] })
            }
        }
    }

    const FvrtHandler = async () => {
        setfvrtStatus(!fvrtedStatus)
        if (fvrtedStatus) {
            let fvrtData = {
                user: data.id
            }
            const response = await postFvrtedandUnfvrted.mutateAsync(fvrtData)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-singlepagedata"] })

            }
        } else {
            let dislikedData = {
                user: data.id
            }
            const response = await postFvrtedandUnfvrted.mutateAsync(dislikedData)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-singlepagedata"] })
            }
        }
    }
    const Pokehandler = async () => {
        let pokeData = {
            user: data.id
        }
        const response = await postPoke.mutateAsync(pokeData)

        if (response.status) {

            showToastmsg('success', response?.data?.description)
            const handIcon = document.querySelector('.profile_act_btn.prf_btn_5 i');
            handIcon.classList.add('poke');
            setTimeout(() => {
                handIcon.classList.remove('poke');
            }, 500);
            const ws = new WebSocket(`${WEB_SOCKET_URL}notification/${data.id}/`);
            // const ws = new WebSocket(`ws://208.109.189.52:8000/ws/notification/${data.id}/`);
            ws.onopen = () => {
                let socketLikedData = {
                    message: `${loggeduser} poked you`,
                    username: loggeduser
                }

                ws.send(JSON.stringify(socketLikedData));
            };
        } else {

        }

    }

    const moveTochatHandler = async () => {
        if(loginUserDetails.package_expired){
            navigate('/upgrade')
        }else{
            dispatch(setIsroomIdreceived(false))
            navigate("/profile/chat", { state: { key: data.username, profilePic: data.profile_picture } });
        }

       
    }
    return (
        <div className="row">
            <div className="col-md-4">
                <div className="profile_page_pro_image">
                    {
                        data.profile_picture ?
                            <img src={IMG_URL + data.profile_picture} alt="" />
                            : <img src={defaultProfilepic} alt="" />
                    }

                </div>
            </div>
            <div className="col-md-8">
                <div className="profile_page_right_cnt_sec">
                    <div className="profile_page_right_name">
                        <strong>{data.username} - <span>{data.age}</span></strong>
                        <div className="profile_page_right_action_btn_sec">
                            {/* <a href=''> */}
                            <button className="profile_page_right_action_btn"  onClick={moveTochatHandler}><i className="fa-regular fa-message"></i></button>
                            {/* </a> */}
                            {/* <button className="profile_page_right_action_btn"><i className="fa-solid fa-video"></i></button>
                            <button className="profile_page_right_action_btn"><i className="fa-solid fa-phone"></i></button> */}
                        </div>
                    </div>
                    <div className="prfl_location"><i className="fa-solid fa-location-dot"></i>{data.distance} {t('away')}</div>
                    <div className="profile_right_intrst_sec">
                        <strong>{t('interest')}</strong>
                        <div className="profile_right_intrst_cnt_sc">
                            {
                                data.interests.map((items, index) => {
                                    return (
                                        <button className="filter_box_intrstbx">{t(items.name)}</button>
                                    )
                                })
                            }


                        </div>
                    </div>
                    <div className="profile_btn_row">
                        {/* <a href="#/"><div className="profile_act_btn prf_btn_1">
                            <i className="fa fa-refresh"></i>
                        </div></a> */}
                        {
                            data.block_status ?
                                <OverlayTrigger placement="top" overlay={tooltip}>
                                    <a href="#/"><div className=' profile_act_btn prf_btn_2'>
                                        <i class="fa-solid fa-ban" onClick={blockHandler}></i>


                                    </div></a>
                                </OverlayTrigger>

                                : <a href="#/"><div className="profile_act_btn prf_btn_2">
                                    <i className="fa fa-close" onClick={() => blockHandler(data.id)}></i>
                                </div></a>

                        }

                        <a href="#/"><div className={`${(data.like_status) ? `profile_act_btn prf_btn_3_liked` : `profile_act_btn prf_heartbtn_3`}`}>
                            <i className="fa fa-heart" onClick={likeHandler}></i>
                        </div></a>
                        <a href="#/"><div className={`${data.favorite_status ? `profile_act_btn prf_btn_4_fvrted` : `profile_act_btn prf_btn_4`}`}>
                            <i className="fa fa-star" onClick={FvrtHandler}></i>
                        </div></a>
                        <a href="#/"><div className="profile_act_btn prf_btn_5">
                            <i className="fa fa-hand" onClick={Pokehandler}></i>
                        </div></a>
                        <a href="#/"><div className="profile_act_btn prf_btn_6" onClick={moveTochatHandler}>
                        <i class="fa-solid fa-message"></i>
                        </div></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileInfo