import i18n from "i18next";
import { initReactI18next } from "react-i18next";

//english
import enprofile from '../src/languages/en/profile.json'
import enkyc from '../src/languages/en/kyc.json'
import enUpgrade from '../src/languages/en/upgradePlan.json'
import enMatches from '../src/languages/en/matches.json'
import enSettings from '../src/languages/en/settings.json'

//chinese
import zhProfile from '../src/languages/zh/profile.json'
import zhkyc from '../src/languages/zh/kyc.json'
import zhUpgrade from '../src/languages/zh/upgradePlan.json'
import zhMatches from '../src/languages/zh/matches.json'
import zhSettings from '../src/languages/zh/settings.json'

//spanish
import esProfile from '../src/languages/es/profile.json'
import eskyc from '../src/languages/es/kyc.json'
import esUpgrade from '../src/languages/es/upgradePlan.json'
import esMatches from '../src/languages/es/matches.json'
import esSettings from '../src/languages/es/settings.json'

//russian
import ruProfile from '../src/languages/ru/profile.json'
import rukyc from '../src/languages/ru/kyc.json'
import ruUpgrade from '../src/languages/ru/upgradePlan.json'
import ruMatches from '../src/languages/ru/matches.json'
import ruSettings from '../src/languages/ru/settings.json'


const userDefaultLan=localStorage.getItem('lang')
const resources = {
    en: {
        translation: {
            ...enprofile,
            ...enkyc,
            ...enUpgrade,
            ...enMatches,
            ...enSettings
            
        }
    },
    zh: {
        translation: {
            ...zhProfile,
            ...zhkyc,
            ...zhUpgrade,
            ...zhMatches,
            ...zhSettings
        }
    },
    es:{
        translation: {
            ...esProfile,
            ...eskyc,
            ...esUpgrade,
            ...esMatches,
            ...esSettings
        } 
    },
    ru:{
        translation: {
            ...ruProfile,
            ...rukyc,
            ...ruUpgrade,
            ...ruMatches,
            ...ruSettings
        } 
    },

};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: userDefaultLan??"en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;