import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import InnerpageHeader from '../../common/InnerpageHeader'
import faq from '../../assets/images/faq.png'
import { useEffect } from 'react'
function Faq() {
    
    useEffect(() => {
        const items = document.querySelectorAll(".accordion button");
    
        function toggleAccordion() {
            const itemToggle = this.getAttribute('aria-expanded') === 'false';
    
            items.forEach(item => item.setAttribute('aria-expanded', 'false'));
    
            if (itemToggle) {
                this.setAttribute('aria-expanded', 'true');
            }
        }
    
        items.forEach(item => item.addEventListener('click', toggleAccordion));
    
        // Cleanup event listeners when component is unmounted
        return () => {
            items.forEach(item => item.removeEventListener('click', toggleAccordion));
        };
    }, []);
    return (
        <div>
           
            <InnerpageHeader pageTitle="Faq" />
            <section className="inner_page_main_bx_section help_page">
                <div className="inner_page_cnt_option_two">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-5">
                                <img src={faq} alt="" />
                            </div>
                            <div className="col-md-7">
                                <h2>Frequently Asked Questions</h2>
                                <div className="accordion mt-3">
                                    <div className="accordion-item">
                                        <button id="accordion-button-1" aria-expanded="false">
                                            <span className="accordion-title">
                                                Why is the moon sometimes out during the day?
                                            </span>
                                            <span className="icon" aria-hidden="true"></span>
                                        </button>
                                        <div className="accordion-content">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Elementum sagittis vitae et leo duis ut. Ut tortor pretium
                                                viverra suspendisse potenti.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <button id="accordion-button-2" aria-expanded="false">
                                            <span className="accordion-title">Why is the sky blue?</span>
                                            <span className="icon" aria-hidden="true"></span>
                                        </button>
                                        <div className="accordion-content">
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Elementum sagittis vitae et leo duis ut. Ut tortor pretium
                                                viverra suspendisse potenti.
                                            </p>
                                        </div>
                                    </div>
                                    {/* Additional accordion items go here */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

           
        </div>
    )
}

export default Faq