import React, { useState } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import { showToastmsg } from '../../../common/ToastMsg'
import { useQueryClient } from '@tanstack/react-query'
import { TravelApis } from '../../../hooks/apihooks/travelApis';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, Form, ListGroup } from 'react-bootstrap';
import axios from 'axios'
import Tooltip from '@mui/material/Tooltip';
import { motion } from 'framer-motion';
import Typography from '@mui/material/Typography';
import DatePickerComponent from '../../../common/DatePicker';
import Skeleton from 'react-loading-skeleton';
import nodataImg from '../../../assets/images/nodata.jpg'


function ScheduledTripList({ TripDetails, isLoading }) {
    const [modal, setmodal] = useState({
        data: {},
        show: false
    })
    const navigate = useNavigate()
    const [loader, setloader] = useState(false)
    const [locationSuggestions, setlocationSuggestions] = useState([])
    const [data, setdata] = useState({
        latitude: '',
        longitude: '',
        location_name: '',
        looking_for: '',
        date: '',
        days: 0,
        description: '',
        trip_id: 0
    })
    const [formError, setformError] = useState({
        location_name: '',
        looking_for: '',
        type: '',
        date: '',
        days: '',
        description: ''
    })
    const queryClient = useQueryClient();
    const cancelTrip = TravelApis.CancelTrip()
    const updateData = TravelApis.UpdateTraveldata()
    const checkIncomingTravelrequest = TravelApis.IncomingRequestedTravel()

    const deleteHandler = async (tripId) => {

        const response = await cancelTrip.mutateAsync({ trip_id: tripId })
        if (response.status) {
            queryClient.invalidateQueries({ queryKey: ["get-tripdata"] })
        } else {
            showToastmsg("error", "Something went wrong..")
        }
    }

    const handleChangehandler = async (e) => {
        const { name, value } = e.target
        setformError({
            ...formError,
            location_name: '',
            looking_for: '',
            type: '',
            date: ''
        })
        if (name === "location") {
            setdata({
                ...data,
                location_name: value
            })
            try {
                setloader(true)
                const response = await axios.get(
                    `https://nominatim.openstreetmap.org/search?format=json&q=${value}`
                );
                if (response.data) {
                    setlocationSuggestions(response.data);
                    setloader(false)
                }




            } catch (error) {
                console.error('Error fetching location data:', error);
            }
        } else {
            setdata({
                ...data,
                [name]: value
            })
        }
    }

    const selectHandler = (latitude, longitude, name) => {
        setdata({
            ...data,
            latitude: latitude,
            longitude: longitude,
            location_name: name

        })
        setlocationSuggestions([])
    }
    const validation = async () => {
        let dateRegexp = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(1000|1[0-9]{3}|[2-9][0-9]{3})$/
        try {
            if (!data.location_name) {
                setformError({
                    ...formError,
                    location_name: 'Location name  is required'
                })
                return false
            } else if (!data.days) {
                console.log("===typeerror===")
                setformError({
                    ...formError,
                    days: 'Days is required'
                })
                return false
            } else if (!data.date) {

                setformError({
                    ...formError,
                    date: 'Travel date is required'
                })
                return false
            } else if (!data.description) {
                setformError({
                    ...formError,
                    description: 'Description is required'
                })
            } else if (dateRegexp.test(data.date)) {

                setformError({
                    ...formError,
                    date: 'Invalid formatt'
                })
                return false
            }
            return true
        } catch (error) {

        }
    }

    const UpdatetravelHandler = async () => {
        try {
            const isValid = await validation()
            if (isValid) {
                let travelData = {
                    latitude: Number(data.latitude).toFixed(6),
                    longitude: Number(data.longitude).toFixed(6),
                    travel_date: data.date,
                    days: data.days,
                    location: data.location_name.toLowerCase(),
                    description: data.description,
                    looking_for: data.looking_for,
                    trip_id: data.trip_id
                }
                const response = await updateData.mutateAsync(travelData)

                if (response.status) {
                    queryClient.invalidateQueries({ queryKey: ["get-tripdata"] })
                    setmodal({
                        show: false
                    })

                } else {
                    showToastmsg("error", "Something went wrong..")
                }
            } else {
                // showToastmsg("error", "Check your values")
            }
        } catch (error) {

        }
    }
    const editHandler = (data) => {

        setformError({
            ...formError,
            location_name: '',
            looking_for: '',
            type: '',
            date: ''
        })
        if (data) {
            setmodal({
                ...modal,
                show: true,
                data: data
            })


            setdata({
                ...data,
                location_name: data.location,
                date: moment(data.travel_date).format('YYYY-MM-DD'),
                looking_for: data.looking_for,
                days: data.days,
                description: data.description,
                trip_id: data.id
            })
        }
    }
    const handleClose = () => {
        setmodal(
            {
                ...modal,
                show: false
            }
        )
    }

    const viewIncomigRequestTravel = async (travelId) => {
        console.log("travelids==", travelId)
        try {
            if (travelId) {
                console.log("entered====here==")
                const response = await checkIncomingTravelrequest.mutateAsync({ tripId: travelId })
                if (response.status) {

                    navigate('/incoming-request-travels', { state: { key: travelId } })
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='scheduledTripContainer'>

            {/* <div class="wrapper">
               
                <ul>
                    {TripDetails&&
                        TripDetails.map((itm,index)=>{
                            return(
                                <li><span style={{
                                    display: 'inline-block',
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '50%',
                                    backgroundColor: '#007bff',
                                    color: '#fff',
                                    textAlign: 'center',
                                    lineHeight: '30px',
                                    marginRight: '10px',
                                    fontWeight: 'bold'
                                }}>
                                    <i class="fa-solid fa-calendar-check"></i>
                                    
                                </span>
                                <div className='icons'>
                                <i class="fa-solid fa-xmark fa-lg"></i>
                                {/* <i class="fa-solid fa-calendar-check"></i>
                                <i class="fa-solid fa-calendar-check"></i> */}
                                    {/* </div> */}
                                {/* // The bigger the tank or aquarium the better. Most fish may be small in size, but they still need plenty of room to swim, especially if you have decided to get more than one.</li> */}
                            {/* )
                        })
                    }
                    */}
                    {/* <li>Think about where you place your tank. Keep it out of direct sunlight, away from windows and heating. The last thing you want is for the water in the tank to heat up out of your control.</li>
                    <li>Invest in a decent filter. This will keep the water in the tank cleaner for longer, removing any debris, pollutants and waste.</li>
                    <li>Add an air pump. This will keep the water in the tank oxygenated and keep it moving which is great for your fish. Pumps come in different sizes depending on the amount of litres your tank can hold.</li> */}
                {/* </ul>
            </div>  */}

             <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {
                    isLoading ? (
                        <div className='row'>
                            {Array(4).fill('').map((_, index) => (
                                <React.Fragment key={index}>
                                    <div className='col-lg-10'>
                                        <Skeleton height={40} />
                                    </div>
                                    <div className='col-lg-2 d-flex align-items-center'>
                                        <Skeleton circle={true} width={40} height={40} />
                                        <Skeleton circle={true} width={40} height={40} className="mx-2" />
                                        <Skeleton circle={true} width={40} height={40} />
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    ) : (
                        TripDetails && TripDetails.length > 0 ? (
                            TripDetails.map((value, index) => (
                                <motion.div
                                    key={value.id}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                    viewport={{ once: true }}
                                >
                                    <ListItem
                                        disableGutters
                                        alignItems="flex-start"
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#eefcff' : '',
                                            padding: index % 2 === 0 ? '10px 7px 10px' : '10px 7px 10px'
                                        }}
                                        secondaryAction={
                                            <>
                                                <Tooltip title="Delete" placement="top">
                                                    <IconButton aria-label="delete" onClick={() => deleteHandler(value.trip)}>
                                                        <i className="fa fa-trash"></i>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Details" placement="top">
                                                    <IconButton aria-label="edit" onClick={() => editHandler(value)}>
                                                        <i className="fa-solid fa-circle-info" style={{ color: "#368a7e" }}></i>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Received requests" placement="top">
                                                    <IconButton aria-label="send" onClick={() => viewIncomigRequestTravel
                                                        (value.trip)}>
                                                        <i className="fa-regular fa-paper-plane" />
                                                        <span className='requestCount'>{value.request_count}</span>
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }
                                    >
                                        <ListItemText
                                            primary={
                                                <span>
                                                    <span style={{
                                                        display: 'inline-block',
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        backgroundColor: '#007bff',
                                                        color: '#fff',
                                                        textAlign: 'center',
                                                        lineHeight: '30px',
                                                        marginRight: '10px',
                                                        fontWeight: 'bold'
                                                    }}>
                                                        <i class="fa-solid fa-calendar-check"></i>
                                                    </span>
                                                    Scheduled a trip to
                                                    <Typography component="span" style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                                                        {value.country}
                                                    </Typography>
                                                    {` on `}
                                                    <Typography component="span" style={{ fontWeight: 'bold' }}>
                                                        {moment(value.travel_date).format('DD-MM-YYYY')}
                                                    </Typography>
                                                </span>
                                            }
                                        />
                                    </ListItem>
                                </motion.div>
                            ))
                        ) : (
                            <center>
                                <img src={nodataImg} alt='nodata' className='w-25' />
                            </center>
                        )
                    )
                }
            </List> 
            {/* Modal */}
            <Modal
                show={modal.show}
                onHide={handleClose}
                centered
            >
                <ModalHeader closeButton>
                    <h5 className="modal-title">Travel Details</h5>
                </ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="filter_box">
                                <div className="filter_box_head">Travel Location</div>
                                <div className="filter_box_cnt">
                                    <input type="text" className={`${formError.location_name ? 'erorInput ' : ''}form-control`}
                                        placeholder="Travel location"
                                        name='location'
                                        onChange={handleChangehandler}
                                        value={data.location_name}
                                        autoComplete="off"
                                    />
                                    {formError.location_name &&
                                        <p role="alert" className='formError'><i className="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.location_name}*</p>
                                    }
                                    <div className={`locationList-container ${locationSuggestions ? 'open' : ''}`}>
                                        {locationSuggestions && (
                                            <ListGroup className='locationList'>
                                                {loader ? (
                                                    <center>
                                                        <span>loading.............</span>
                                                    </center>
                                                ) : (
                                                    locationSuggestions.map((locations, index) => (
                                                        <ListGroup.Item key={index} onClick={() => selectHandler(locations.lat, locations.lon, locations.display_name)}>
                                                            <i className="fa-solid fa-location-dot"></i>&nbsp;
                                                            {locations.display_name}
                                                        </ListGroup.Item>
                                                    ))
                                                )}
                                            </ListGroup>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="filter_box">
                                <div className="filter_box_head">Looking For</div>
                                <div className="filter_box_cnt">
                                    <select className={`${formError.looking_for ? 'erorInput ' : ''}form-control`}
                                        name="looking_for"
                                        onChange={handleChangehandler}
                                        value={data.looking_for}
                                    >
                                        <option value="">Select</option>
                                        <option value="dating">Dating</option>
                                        <option value="family">Family</option>
                                    </select>
                                    {formError.looking_for &&
                                        <p role="alert" className='formError'><i className="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.looking_for}*</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="filter_box">
                                <div className="filter_box_head">Days</div>
                                <div className="filter_box_cnt">
                                    <input type="text" className={`${formError.days ? 'erorInput ' : ''}form-control`} name='days' placeholder=""
                                        onChange={handleChangehandler}
                                        value={data.days}
                                    />
                                    {formError.days &&
                                        <p role="alert" className='formError'><i className="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.days}*</p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="filter_box">
                                <div className="filter_box_head">Travel Date</div>
                                <div className="filter_box_cnt">
                                    <div className='date-picker'>
                                        <DatePickerComponent
                                            className={`${formError.date ? 'erorInput ' : ''}form-control`} name='date'
                                            onChange={handleChangehandler}
                                            value={data.date}
                                        />
                                    </div>
                                    {formError.date &&
                                        <p role="alert" className='formError'><i className="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.date}*</p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="filter_box">
                                <div className="filter_box_head">Description</div>
                                <div className="filter_box_cnt">
                                    <Form.Control as="textarea"
                                        onChange={handleChangehandler}
                                        name='description'
                                        value={data.description}
                                        rows={3}
                                        placeholder='Describe your trip...' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn regsiter_top_btn" onClick={UpdatetravelHandler}>Edit</button>
                    </div>
                </ModalBody>
            </Modal>
        </div>

    )
}

export default ScheduledTripList