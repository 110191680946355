import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import {
  forgotPswdsubmitHandler,
  verifyOtp,
  resendOtp,
  ChangePassword
} from "../../actions/Forgotpasswd/forgotpswd";

export const ForgotpaswdApis = {
  Sendemail: () => {
    const response = useMutation({
      mutationFn: (data) => forgotPswdsubmitHandler(data),
      onError: (error) => {
        // An error happened!
        console.log(
          `rolling back optimistic update with id`
        );
      },
    });
    return response;
  },
  VerifyOtp:() => {
    const response = useMutation({
      mutationFn: (data) => verifyOtp(data),
     
    });
    return response;
  },
  ResendOtp:() => {
    const response = useMutation({
      mutationFn: (data) => resendOtp(data),
     
    });
    return response;
  },
  Changepassword:()=>{
    const response=useMutation({
      mutationFn:(data)=>ChangePassword(data)
    })
    return response
  }
};
