import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { showToastmsg } from "../../common/ToastMsg";
import {
  CreateOrderApis,
  CaptureOrderApi,
  GetClientIdApi,
} from "../../hooks/apihooks/paypal";
import { useNavigate } from "react-router-dom";

function Paypal({ successPath, clientId,productDetails }) {
  console.log("==productDetails===",productDetails)
  const [loader, setLoader] = useState(false);
  const createOrder = CreateOrderApis.CreateOrder();
  const captureOrder = CaptureOrderApi.CaptureOrder();
  const initialOptions = {
    "client-id": clientId,
    // "client-id":"AUOCdFYdYDW6GF5vAYNeNE5-zV0jrQen_GtID1Cvs9I0UmODkjW4Iosbesmfc2pN0maIaJTYnJKeZFH8",
    // "AUOCdFYdYDW6GF5vAYNeNE5-zV0jrQen_GtID1Cvs9I0UmODkjW4Iosbesmfc2pN0maIaJTYnJKeZFH8",
    // "enable-funding": "paylater,venmo",
    "data-sdk-integration-source": "integrationbuilder_sc",
    // "disable-funding": "card",
  };
  const navigate = useNavigate();
  const LoadingMsg = (
    <div>
      <i
        className="fa-solid fa-spinner fa-spin"
        style={{ color: "#384f75" }}
      ></i>{" "}
      Initiating Payment...
    </div>
  );
  return (
    <>
      {clientId && (
        <div className="paypal">
          <h2>{loader ? LoadingMsg : "Complete your payment"}</h2>
          <PayPalScriptProvider options={initialOptions}>
            {
              <PayPalButtons
                style={{
                  shape: "pill",
                  //color:'blue' change the default color of the buttons
                  layout: "vertical",
                }}
                createOrder={async () => {
                  try {
                    setLoader(true);
                    const response = await createOrder.mutateAsync({price:productDetails.price,product_id:productDetails.product_id});
                    setLoader(false);
                    if (
                      response.status == 200 &&
                      response.data.id &&
                      response.data.status === "CREATED"
                    ) {
                      return response.data.id;
                    } else {
                      const errorDetail = response?.data?.details?.[0];
                      console.log("orderErrorPaypal:");
                      showToastmsg(
                        "error",
                        `Order Creation Failed : ${errorDetail?.description}`,
                        "top"
                      );
                      throw new Error(response.error);
                    }
                  } catch (error) {
                    setLoader(false);
                    console.error(error);
                    showToastmsg("error", "Paypal Error", "top");
                  }
                }}
                onApprove={async (data, actions) => {
                  try {
                    const response = await captureOrder.mutateAsync(
                      data.orderID
                    );
                    const errorDetail = response?.data?.details?.[0];
                    if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
                      showToastmsg("error", "Instrumental Error", "top");
                      return actions.restart();
                    } else if (errorDetail) {
                      showToastmsg("error", "Capture Failed", "top");
                      throw new Error(response.error);
                    } else if (response?.data?.status === "COMPLETED") {
                      showToastmsg("success", "Successfull", "top");
                      navigate(successPath ?? "/profile");
                    } else {
                      showToastmsg("error", "Unkwon error", "top");
                      throw new Error(response.error);
                    }
                  } catch (error) {
                    showToastmsg("error", "Paypal Error", "top");
                    console.error(error);
                  }
                }}
              />
            }
          </PayPalScriptProvider>
        </div>
      )}
    </>
  );
}

export default Paypal;
