import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { ProfileApis } from "../../../hooks/apihooks/profileApis";
import { useQueryClient } from "@tanstack/react-query";
import { ToastContainer, toast } from "react-toastify";
import { showToastmsg } from "../../../common/ToastMsg";

function Settings({ settingsDatas, packageExpired, t }) {
  const getSettingsdata = ProfileApis.GetSettingsOptionsdata("settings");
  const postSettingsdata = ProfileApis.EditpersonalDetails();
  const getPostedSettingsdata = ProfileApis.GetProfileData();
  const post2fastatus = ProfileApis.EnableTwoFA();
  const queryClient = useQueryClient();

  const [twoFA, setTwoFA] = useState(
    settingsDatas?.data?.user?.has_2fa_enabled ?? false
  );
  const [data, setdata] = useState({
    relationship_goals: settingsDatas?.data?.relationship_goals ?? "",
    height: {
      feet: settingsDatas?.data?.height.feet ?? 0,
      inches: settingsDatas?.data?.height.inches ?? 0,
      cm: settingsDatas?.data?.height.cm ?? 0,
    },
    family_plan: settingsDatas?.data?.family_plan ?? "",
    languages: settingsDatas?.data?.languages ?? [],
    education: settingsDatas?.data?.education ?? "",
    smoke: settingsDatas?.data?.smoke ?? "",
    drink: settingsDatas?.data?.drink ?? "",
    workout: settingsDatas?.data?.workout ?? "",
    job_title: settingsDatas?.data?.job_title ?? "",
    religion: settingsDatas?.data?.religion ?? "",
    company: settingsDatas?.data?.company ?? "",
    livingIn: "",
    // gender: '',

    showAge: settingsDatas?.data?.user?.showAge ?? false,
    showDistance: settingsDatas?.data?.user?.showDistance ?? false,
    // twoFA: settingsDatas?.data?.user?.has_2fa_enabled ?? false,
  });
  const [HeightinCm, setHeightinCm] = useState(true);

  const [selectedOption, setSelectedOption] = useState(null);
  const [isEditable, setisEditable] = useState(true);
  const [btnStatus, setbtnStatus] = useState(false);



  useEffect(() => {
    if (getPostedSettingsdata?.data?.data?.is_edited) {
      setisEditable(false);
      setbtnStatus(true);
    }
  }, []);

  const changeHandler = (e) => {

    const { name, value, checked } = e.target;
    if (name === "feet" || name === "inches") {
      setdata({
        ...data,
        height: {
          ...data.height,
          [name]: value,
        },
      });
    } else if (name === "height") {
      if (checked) {
        setHeightinCm(true);
      } else {
        setHeightinCm(false);
      }
    } else if (name === "cm") {
      setdata({
        ...data,
        height: {
          ...data.height,
          cm: Number(value),
        },
      });
    } else if (name === "showAge") {
      if (checked) {
        setdata({
          ...data,
          [name]: checked,
        });
      } else {
        setdata({
          ...data,
          [name]: false,
        });
      }
    } else if (name === "showDistance") {
      if (checked) {
        setdata({
          ...data,
          [name]: checked,
        });
      } else {
        setdata({
          ...data,
          [name]: false,
        });
      }
    } else if (name === "twoFA") {
      if (checked) {
        setdata({
          ...data,
          [name]: checked,
        });
      } else {
        setdata({
          ...data,
          [name]: false,
        });
      }
    } else {
      setdata({
        ...data,
        [name]: value,
      });
    }
  };
  const changeHandlerTwoFa = async (e) => {
    const { name, value, checked } = e.target;
    if (checked) {
      setTwoFA(checked);
    } else {
      setTwoFA(false);
    }
    post2fastatus.mutate();
  };
  const selectLanguagehandler = (value) => {
    setSelectedOption(value);
    setdata({
      ...data,
      languages: value,
    });
  };
  const saveHandler = async () => {
    const response = await postSettingsdata.mutateAsync(data);
    if (response.status) {
      showToastmsg('success', t("details_added"));
      const queryResponse = await queryClient.invalidateQueries({
        queryKey: ["profileData"],
      });

      setisEditable(false);
      setbtnStatus(true);
    } else {
      showToastmsg("error", t("something_went_wrong"));
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    }
  };

  const editHandler = () => {
    queryClient.invalidateQueries({ queryKey: ["profileData"] });
    setisEditable(!isEditable);
  };

  return (
    <div>

      {/* <button
        // data-bs-toggle="modal"
        // data-bs-target="#uploadphoto"
        className="add_new_photo"
        // onClick={AddPichandler}
      >
        <i class="fa-solid fa-pencil"></i>
      </button> */}
      <h5>{t('settings')}</h5>

      <div className="row">
        <div className="col-md-6">
          <div className="filter_box">
            <div className="filter_box_head">{t('relationship_goal')}</div>
            <div className="row filter_type_slct">
              <span>{t('looking_for')}</span>
              <select
                className="form-control short_term_slct"
                name="relationship_goals"
                id=""
                onChange={changeHandler}
                disabled={isEditable ? false : true}
                value={data.relationship_goals}
              >
                <option value="">{t('select')}</option>
                {getSettingsdata?.data?.data &&
                  getSettingsdata?.data?.data?.relationship_goals?.map(
                    (items, index) => {
                      return <option value={items.id}>{t(items.name)}</option>;
                    }
                  )}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="filter_box filter_hd2">
            <div className="filter_box_head">
              <span>{t('height')}</span>
              <div className="toggle_btn_type">
                ft/in
                <input
                  type="checkbox"
                  id="toggle-btn"
                  name="height"
                  checked={HeightinCm ? true : false}
                  onChange={changeHandler}
                  disabled={isEditable ? false : true}
                />
                <label htmlFor="toggle-btn"></label>
                cm
              </div>
            </div>
            <div className="row filter_type_slct">
              {HeightinCm ? (
                <span>
                  <label htmlFor="">Cm</label>
                  <input
                    type="text"
                    name="cm"
                    onChange={changeHandler}
                    disabled={isEditable ? false : true}
                    value={data.height.cm}
                  />
                </span>
              ) : (
                <>
                  <span>
                    <label htmlFor="">{t('feet')}</label>
                    <input
                      type="text"
                      name="feet"
                      onChange={changeHandler}
                      disabled={isEditable ? false : true}
                      value={data.height.feet}
                    />
                  </span>
                  <span>
                    <label htmlFor="">{t('inches')}</label>
                    <input
                      type="text"
                      name="inches"
                      onChange={changeHandler}
                      disabled={isEditable ? false : true}
                      value={data.height.inches}
                    />
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="filter_box">
            <div className="filter_box_head">{t('family_plan')}</div>
            <div className="row filter_type_slct">
              {/* <span>Open to</span> */}
              <select
                className="form-control"
                name="family_plan"
                id=""
                onChange={changeHandler}
                value={data.family_plan}
                disabled={isEditable ? false : true}
              >
                <option value="">{t('select')}</option>
                {getSettingsdata?.data?.data &&
                  getSettingsdata?.data?.data?.family_plan_choices?.map(
                    (items, index) => {
                      return <option value={items.id}>{t(items.name)}</option>;
                    }
                  )}
              </select>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="filter_box">
            <div className="filter_box_head">{t('languages')}</div>
            <div className="row filter_type_slct">
              {/* <span>Open to</span> */}
              <Select
                value={data.languages}
                // defaultInputValue={selectedOption}
                onChange={(value) => selectLanguagehandler(value)}
                // onChange={setSelectedOption}
                options={getSettingsdata?.data?.data?.languages}
                isMulti={true}
                isSearchable={true}
                isDisabled={!isEditable}
                name="languages"
              />
              {/* <select className="form-control" name="languages" id="">
                                <option value="">Select</option>
                            </select> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="filter_box">
            <div className="filter_box_head">{t('basic')}</div>
            <div className="row">
              <div className="col-md-6">
                <div className="row filter_type_slct">
                  <span>
                    <i className="fa-solid fa-graduation-cap"></i> {t('education')}
                  </span>
                  <select
                    className="form-control"
                    name="education"
                    id=""
                    onChange={changeHandler}
                    disabled={isEditable ? false : true}
                    value={data.education}
                  >
                    <option value="">{t('select')}</option>
                    {getSettingsdata?.data?.data &&
                      getSettingsdata?.data?.data?.education_types?.map(
                        (items, index) => {
                          return <option value={items.id}>{t(items.name)}</option>;
                        }
                      )}

                    <option value="degree">{t('degree')}</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row filter_type_slct">
                  <span>
                    <i className="fa-solid fa-champagne-glasses"></i> {t('drinking')}
                  </span>
                  <select
                    className="form-control"
                    name="drink"
                    id=""
                    onChange={changeHandler}
                    disabled={isEditable ? false : true}
                    value={data.drink}
                  >
                    <option value="">{t('select')}</option>
                    {getSettingsdata?.data?.data &&
                      getSettingsdata?.data?.data?.drink_choices?.map(
                        (items, index) => {
                          return <option value={items.id}>{t(items.name)}</option>;
                        }
                      )}
                    <option value="no">{t("no")}</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row filter_type_slct">
                  <span>
                    <i class="fa-solid fa-church"></i>{t('religion')}
                  </span>
                  <select
                    className="form-control"
                    name="religion"
                    id=""
                    onChange={changeHandler}
                    disabled={isEditable ? false : true}
                    value={data.religion}
                  >
                    <option value="">{t('select')}</option>
                    {getSettingsdata?.data?.data &&
                      getSettingsdata?.data?.data?.religions?.map(
                        (items, index) => {
                          return <option value={items.id}>{t(items.name)}</option>;
                        }
                      )}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row filter_type_slct">
                  <span>
                    <i className="fa-solid fa-dumbbell"></i> {t('workout')}
                  </span>
                  <select
                    className="form-control"
                    name="workout"
                    id=""
                    onChange={changeHandler}
                    disabled={isEditable ? false : true}
                    value={data.workout}
                  >
                    <option value="">{t('select')}</option>
                    {getSettingsdata?.data?.data &&
                      getSettingsdata?.data?.data?.workouts?.map(
                        (items, index) => {
                          return <option value={items.id}>{t(items.name)}</option>;
                        }
                      )}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row filter_type_slct">
                  <span>
                    <i className="fa-solid fa-smoking"></i> {t('smoking')}
                  </span>
                  <select
                    className="form-control"
                    name="smoke"
                    id=""
                    onChange={changeHandler}
                    disabled={isEditable ? false : true}
                    value={data.smoke}
                  >
                    <option value="">{t('select')}</option>
                    {getSettingsdata?.data?.data &&
                      getSettingsdata?.data?.data?.smoke_choices?.map(
                        (items, index) => {
                          return <option value={items.id}>{t(items.name)}</option>;
                        }
                      )}
                  </select>
                </div>
              </div>
              {/* <div className="col-md-6">
                                <div className="row filter_type_slct">
                                    <span><i className="fa-solid fa-dumbbell"></i> Workout</span>
                                    <select className="form-control" name="" id="">
                                        <option value="">Select</option>
                                    </select>
                                </div>
                            </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="filter_box">
            <div className="filter_box_head">{t('jobTitle')}</div>
            <div className="filter_box_cnt">
              {/* <select className="form-control" name="" id="">
                                <option value="">Select</option>
                            </select> */}
              <input
                type="text"
                placeholder={t("add")}
                name="job_title"
                className="form-control"
                onChange={changeHandler}
                disabled={isEditable ? false : true}
                value={data.job_title}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="filter_box">
            <div className="filter_box_head">{t('company')}</div>
            <div className="filter_box_cnt">
              <input
                type="text"
                placeholder={t('add')}
                className="form-control"
                name="company"
                onChange={changeHandler}
                disabled={isEditable ? false : true}
                value={data.company}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-md-4">
                    <div className="filter_box">
                        <div className="filter_box_head">School</div>
                        <div className="filter_box_cnt">
                            <input type="text" placeholder="Add" className="form-control" />
                        </div>
                    </div>
                </div> */}
        {/* <div className="col-md-6">
                    <div className="filter_box">
                        <div className="filter_box_head">Living in</div>
                        <div className="filter_box_cnt">
                            <input type="text" placeholder="Add" className="form-control" name='livingIn' onChange={changeHandler}
                                disabled={isEditable ? false : true}
                            />
                        </div>
                    </div>
                </div> */}
        {/* <div className="col-md-6">
                    <div className="filter_box">
                        <div className="filter_box_head">Gender</div>
                        <div className="filter_box_cnt">
                            <select className="form-control" name="gender" id="" onChange={changeHandler}>
                                <option value="">Select</option>
                                <option value="f">female</option>
                            </select>
                        </div>
                    </div>
                </div> */}
      </div>
      <div className={`${packageExpired? 'premium_div_disabled' : ''} col-md-12`}>
        <div className="filter_box">
          <div className="filter_box_head">
            {t('cntrl_profile')} <span className="premium_plan_info">{t('premium')}</span>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row filter_type_slct">
                <span>{t('dont_show_my_age')}</span>
                <div className="toggle_btn_type">
                  <input
                    type="checkbox"
                    id="toggle-btn1"
                    name="showAge"
                    value={data.showAge}
                    checked={data.showAge ? data.showAge : false}
                    onChange={changeHandler}
                    disabled={isEditable ? false : true}
                  />
                  <label htmlFor="toggle-btn1"></label>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row filter_type_slct">
                <span>{t('dont_show_my_distance')}</span>
                <div className="toggle_btn_type">
                  <input
                    type="checkbox"
                    id="toggle-btn2"
                    name="showDistance"
                    value={data.showDistance}
                    checked={data.showDistance ? data.showDistance : false}
                    onChange={changeHandler}
                    disabled={isEditable ? false : true}
                  />
                  <label htmlFor="toggle-btn2"></label>
                </div>
              </div>
            </div>
            {
              settingsDatas?.data?.user?.auth_provider === "email" &&
              <div className="col-md-6">
                <div className="row filter_type_slct">
                  <span>{t("enable_2fa")}</span>
                  <div className="toggle_btn_type">
                    <input
                      type="checkbox"
                      id="toggle-btn3"
                      name="twoFA"
                      value={twoFA}
                      checked={twoFA ?? false}
                      onChange={changeHandlerTwoFa}
                      disabled={isEditable ? false : true}
                    />
                    <label htmlFor="toggle-btn3"></label>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
      </div>


      {btnStatus && (
        <button className="myaccount_profile_upgrad_btn" onClick={editHandler}>
          {isEditable ? t("cancel") : t("edit")}
        </button>
      )}

      <button className="myaccount_profile_upgrad_btn" onClick={saveHandler}>
        {t('save')}
      </button>
    </div>
  );
}

export default Settings;
