import React from 'react'
import { NotificationApis } from '../../../hooks/apihooks/notificationApis'
import moment from 'moment'
import { useQueryClient } from '@tanstack/react-query'
import nodataIMg from '../../../assets/images/nodata.jpg'
import { useNavigate } from 'react-router-dom'
import { IMG_URL, BASE } from '../../../config/config'
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import Skeleton from 'react-loading-skeleton';


function Notification({t}) {
    const getNotification = NotificationApis.GetNotification()
    const clearAllnotification = NotificationApis.ClearAllNotifications()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const clearNotifications = async () => {
        const response = await clearAllnotification.mutateAsync()
        if (response.status) {
            getNotification.refetch()
            // queryClient.invalidateQueries({ queryKey: ["get-notifications"] })
        }
    }

    const moveTosinglepageView = (username, type) => {

        navigate(`/singleProfile/${username}`)
    }
    return (
        <div class="my_account_right_sec">
            <div class="my_account_right_head">
               {t('notifications')}
                {
                    getNotification?.data?.data?.notifications.length > 0 &&
                    <button class="clear_all_btn" onClick={clearNotifications}>

                        {t('clear_all')}
                    </button>
                }

            </div>
            {
                getNotification?.data?.data?.notifications.length === 0 &&
                <img src={nodataIMg} alt='nodata' className='w-50' />
            }
            {
                getNotification.isLoading ?
                    <div className="my_account_right_box_noti">
                        <div className="notification_list">
                            {[1, 2, 3, 4].map((itm, index) => (
                                <div className="notification_list_row" key={index}>
                                    <div className="usr_prfl_box_noti">
                                        <Skeleton circle={true} height={40} width={40} />

                                    </div>
                                    <p><Skeleton width={150} /></p>
                                    <span><Skeleton width={100} /></span>
                                </div>
                            ))}
                        </div>
                    </div>

                    : getNotification?.data?.data?.notifications.length > 0 &&
                    <div class="my_account_right_box_noti">
                        <div class="notification_list">
                            {
                                getNotification?.data?.data?.notifications.map((items, index) => {
                                    return (
                                        <div class="notification_list_row" onClick={() => moveTosinglepageView(items.from_user, items.type)}>
                                            <div className="usr_prfl_box_noti">
                                                {

                                                    <img src={items.profile_picture ? IMG_URL + items.profile_picture : defaultProfilepic} alt="" />
                                                }
                                            </div>
                                            <p>{items.description}</p>
                                            <span>{moment(items.date_added).format('DD-MM-YYYY')}</span>
                                        </div>
                                    )
                                })
                            }



                        </div>
                    </div>
            }

        </div>
    )
}

export default Notification