import React from "react";
import _ from "lodash";
import TextField from "./TextField";
import { useState, useEffect } from "react";
import { settingsApis } from "../../hooks/apihooks/settingsApis";
import { useQueryClient } from "@tanstack/react-query";
import { showToastmsg } from "../../common/ToastMsg";
import TabContentSkeleton from "../../components/skeletons/TabContentSkeleton";

function MailConfig({t}) {
  const queryClient = useQueryClient();

  const [mailContent, setMailContent] = useState("");
  const [editContent, setEditContent] = useState(false);
  const [selectedTab, setSelectedTab] = useState("reset_password");

  const updateMail = settingsApis.UpdateMailContent();
  const mailConentResult = settingsApis.GetMailContent(selectedTab);
  const Tabs = [
    {
      title: "reset_password",
      value: "reset password content",
      label: t("reset_pswd"),
    },
    {
      title: "register",
      value: "regisration content",
      label: t("reg"),
    },
    {
      title: "otp",
      value: "otp content",
      label: t("otp"),
    },
  ];
  const userType = localStorage.getItem("userType");

  const setActiveTab = (tab) => {
    setDefaultContent(tab);
    setSelectedTab(tab);
    setEditContent(false);
  };
  const setDefaultContent = () => {
    mailConentResult.refetch();
    setMailContent(mailConentResult?.data?.data?.content);
  };
  const handleContent = (content) => {
    setMailContent(content);
  };
  const handleCancel = () => {
    setEditContent(false);
    setDefaultContent(selectedTab);
  };
  const handleSave = async () => {
    const payload = {
      type: selectedTab,
      content: mailContent,
    };
    const result = await updateMail.mutateAsync(payload);
    if (result.status) {
      setEditContent(false);
      queryClient.invalidateQueries({
        queryKey: ["get-mail-content", selectedTab],
      });
    } else {
      showToastmsg("error", "failed", "top");
    }
  };
  useEffect(() => {
    setMailContent(mailConentResult?.data?.data?.content);
  }, [mailConentResult?.data]);
  return (
    <>


      <>
        <div className="my_account_right_head">{t('mail_details')}</div>
        <div className="my_account_right_box">
          {
            mailConentResult.isLoading ?
              <TabContentSkeleton tabCount={3} />
              :
              mailConentResult.data &&
              <>
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    {userType === "admin" && (
                      <>
                        {Tabs.map((tabs) => {
                          return (
                            <>
                              <button
                                className={
                                  tabs.title === selectedTab
                                    ? "nav-link active"
                                    : "nav-link"
                                }
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                data-bs-target={`#nav-photos-${tabs.title}`}
                                type="button"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                                onClick={() => setActiveTab(tabs.title)}
                              >
                                {tabs.label}
                              </button>
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                </nav>
                <div className="tab-content p-3 border" id="nav-tabContent">
                  <div className="profile_right_intrst_cnt_sc">
                    <div className="edit_content_warning">
                      <i class="fa-solid fa-triangle-exclamation fa-fade"></i>
                      <strong> {t('caution')} :</strong>
                      {` ${t('caution_msg')} ({{ }}). `}
                    </div>
                    {!editContent && (
                      <div className="edit_icon_mail">
                        {" "}
                        <button onClick={() => setEditContent(true)}>
                          <i className="fa fa-edit"></i>
                        </button>
                      </div>
                    )}
                    {
                      <TextField
                        content={mailContent}
                        editContent={editContent}
                        handleContent={handleContent}
                        handleCancel={handleCancel}
                        setEditContent={setEditContent}
                      />
                    }
                  </div>

                  {editContent && (
                    <>
                      <button
                        className="myaccount_profile_upgrad_btn"
                        onClick={handleCancel}
                      >
                        {t('cancel')}
                      </button>
                      <button
                        className="myaccount_profile_upgrad_btn m-2"
                        onClick={handleSave}
                      >
                        {t('save')}
                      </button>
                    </>
                  )}
                </div>
              </>





          }

        </div>
      </>

    </>
  );
}

export default MailConfig;
