import axios from "axios";
import { BASE_URL } from "../config/config";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response;
    }
  },
  (error) => {
    console.log("axios=====bcvnn",error.response.status)
    if (error.response && error.response.status === 401) {
      console.log("==entered401")
      
      if (error.response.data.data.data.code === 1042) {
        console.log("inside 1042 ====");
        return error.response.data.data;
      }
      localStorage.clear();
      window.location.href = "/logout"; // Change the URL as needed
      return Promise.reject(error);
    } else {
      return error.response.data;
    }
  }
);

instance.interceptors.request.use((config) => {
  config.headers["Accept"] = "application/json";
  config.headers["Content-Type"] = "application/json";
  // config.headers["api-key"] = localStorage.getItem("api-key") || DEFAULT_KEY;
  config.headers["Authorization"] = localStorage.getItem("access-token")?'Bearer'+' '+localStorage.getItem("access-token"): "No auth";
  return config;
});

const API = instance;

export default API;
