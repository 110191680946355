import React from 'react'
import { useState } from 'react'
import { testimonialApis } from '../../../hooks/apihooks/testimonialApis'
import { showToastmsg } from '../../../common/ToastMsg'
import Alert from '@mui/material/Alert';


function Testimonial({t}) {
    const [testimonialData, settestimonialData] = useState('')
    const [testimonialError, settestimonialError] = useState('')
    const postTestimonialdata = testimonialApis.PostTestimonialdata()
    const testimonialChangehandler = (e) => {
        settestimonialData(e.target.value)
    }

    const postDatahandler = async () => {
        
        try {
            if (testimonialData.length > 1000) {
                settestimonialError(t("length_cannot_exceed_1000_char"))
            } else {
                settestimonialError("")
                const response = await postTestimonialdata.mutateAsync({ description: testimonialData })
                if (response.status) {
                    settestimonialData('')
                    showToastmsg('success', t('data_upload_successfully'))
                } else {
                    showToastmsg('error', 'data_upload_failed')
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <div class="my_account_right_head">
                {t('testimonial')}
            </div>
            <div class="my_account_right_box">

                <div class="row">
                    <div class="row">
                        <div class="col-md-12 mt-4">
                            <label for="">{t('testimonial')}</label>
                            <p>{t('nter_ur_website_success_stories')}</p>
                            <textarea name=""
                               className={`${testimonialError ? 'testimonialErr ' : ''}form-control`}
                                id="" cols="30"
                                rows="10"
                                value={testimonialData}
                                onChange={testimonialChangehandler}>

                            </textarea>
                            <br/>
                            {
                                testimonialError&&
                                <Alert severity="error">{testimonialError}!</Alert>
                            }
                        </div>
                        <button class="myaccount_profile_upgrad_btn" onClick={postDatahandler}>{t('save')}</button>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default Testimonial