import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getPackages,
  addPackage,
  updatePackage,
  deletPackage,
} from "../../actions/Packages/PackagesApi";
export const PackageApis = {
  GetPackages: () => {
    const response = useQuery({
      queryKey: ["get-package"],
      queryFn: () => getPackages(),
    });
    return response;
  },
  AddPackage: () => {
    const response = useMutation({
      mutationKey: ["add-package"],
      mutationFn: (data) => addPackage(data),
    });

    return response;
  },
  UpdatePackage: () => {
    const response = useMutation({
      mutationKey: ["update-package"],
      mutationFn: (data) => updatePackage(data),
    });

    return response;
  },

  DeletePackage: () => {
    const response = useMutation({
      mutationKey: ["delete-package"],
      mutationFn: (data) => deletPackage(data),
    });
    return response;
  },
};
