import API from "../api/api";

export const forgotPswdServices = {
  submit: async (data) => {
    try {
      const response = await API.post("changePassword", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  sendOtp: async (data) => {
    try {
      const response = await API.put("send-otp", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  sendVerifyOtp: async (data) => {
    try {
      const response = await API.post("verify-account", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  ChangePassword:async (data) => {
    try {
      const response = await API.put("reset-password", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
};
