import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

function CompanyDetailsSkeleton() {
    return (
        <div className="my_account_right_box">
            <p><Skeleton width={80} /></p>
            <div className='row'>
                {
                    [1, 2].map(() => (
                        <div className='col-lg-6'>
                            <div className="filter_box">
                                <div className="filter_box_head">
                                    <Skeleton width={100} />
                                </div>
                                <Skeleton width="100%" height="38px" />
                            </div>
                        </div>
                    ))
                }


            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className="filter_box">
                        <div className="filter_box_head"><Skeleton width={100} /></div>
                        <div className="filter_box_cnt">
                            <Skeleton height="100px" />
                        </div>
                    </div>
                </div>
            </div>
            <p><Skeleton width={80} /></p>
            <div className='row'>
                {
                    [1, 2, 3, 4].map(() => (
                        <div className='col-lg-6'>
                            <div className="filter_box">
                                <div className="filter_box_head">
                                    <Skeleton width={100} />
                                </div>
                                <Skeleton width="100%" height="38px" />
                            </div>
                        </div>
                    ))
                }


            </div>
        </div>
    )
}

export default CompanyDetailsSkeleton