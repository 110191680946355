import React from 'react'
import Footer from '../../components/Footer'
import InnerpageHeader from '../../common/InnerpageHeader'
import Earnpoint from '../../assets/images/earnpoint.png'
import simpleUseicon from '../../assets/images/simple_use_ico.png'
import AdminHeader from '../Admin/Header';
import UserHeader from '../../components/Header';
import { useSelector } from 'react-redux'
function EarnPoint() {
    const { isAdmin } = useSelector((state) => state.login)
    return (
        <div>
            {/* {
                isAdmin?<AdminHeader/>:<UserHeader/>
            } */}
            <InnerpageHeader pageTitle="Earn Point" />
            <section className="inner_page_main_bx_section account_page">
                <div className="inner_page_cnt_option_two">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="earnpoint_box">
                                    <h3>How to Earn Point</h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>

                                    <div className="earnpoint_box_option">
                                        <ul>
                                            <li>
                                                Lorem ipusm doler
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </li>
                                            <li>
                                                Lorem ipusm doler
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 text-center">
                                <div className="earnpoint_img"><img src={Earnpoint} alt="" /></div>
                            </div>
                        </div>

                        <div className="earnpoint_step">
                            <div className="home_headding_sec">
                                <h2>Steps</h2>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-3">
                                    <div className="our_feature_cnt_sec_box">
                                        <div className="our_feature_cnt_sec_box_ico"><img src={simpleUseicon} alt="" /></div>
                                        <h5>Option 1</h5>
                                        <p>Lorem ipsum doler site amet</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="our_feature_cnt_sec_box">
                                        <div className="our_feature_cnt_sec_box_ico"><img src={simpleUseicon} alt="" /></div>
                                        <h5>Option 2</h5>
                                        <p>Lorem ipsum doler site amet</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="our_feature_cnt_sec_box">
                                        <div className="our_feature_cnt_sec_box_ico"><img src={simpleUseicon} alt="" /></div>
                                        <h5>Option 3</h5>
                                        <p>Lorem ipsum doler site amet</p>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="our_feature_cnt_sec_box">
                                        <div className="our_feature_cnt_sec_box_ico"><img src={simpleUseicon} alt="" /></div>
                                        <h5>Option 4</h5>
                                        <p>Lorem ipsum doler site amet</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    )
}

export default EarnPoint