import { useQuery, useMutation } from "@tanstack/react-query";
import { registerSubmithandler } from "../../actions/Register/registerSubmit";
import { getINtersts } from "../../actions/Register/register";
import {
  checkUserexist,
  sendGmailToken,sendFacebookData
} from "../../actions/Register/register";
import { data } from "jquery";
export const registerApis = {
  RegisterSubmit: () => {
    const response = useMutation({
      mutationFn: (data) => registerSubmithandler(data),
    });
    return response;
  },
  GetInterest: () => {
    const response = useQuery({
      queryKey: ["getInterest"],
      queryFn: () => getINtersts(),
    });
    return response;
  },
  Checkuserexistance: () => {

    const response = useMutation({
      mutationFn: (data) => checkUserexist(data),
    });
    return response;
  },
  SendGmailToken: () => {
    const response = useMutation({
      mutationFn: (data) => sendGmailToken(data),
    });
    return response;
  },
  SendfacebookVerification: () => {
    const response = useMutation({
      mutationFn: (data) => sendFacebookData(data),
    });
    return response;
  },
};
