import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loginright from "../../assets/images/login_right_img.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLoginresponse } from "../../store/reducers/authReducer";
import { setIslogged } from "../../store/reducers/authReducer";
import { setLOgindata } from "../../store/reducers/authReducer";
import { useNavigate } from "react-router-dom";
import { setLoginErr } from "../../store/reducers/authReducer";
import { LoginApis } from "../../hooks/apihooks/loginApis";
import { useGoogleLogin } from "@react-oauth/google";
import { registerApis } from "../../hooks/apihooks/registerApis";
import { showToastmsg } from "../../common/ToastMsg";
import GoogleButton from "react-google-button";
import google from "../../assets/images/google.svg";
import fb from "../../assets/images/facebook_reg.svg";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { setIsadmin } from "../../store/reducers/authReducer";
import { FACEBOOK_APP_ID } from "../../config/config";
import { useState } from "react";
import { Link } from "react-router-dom";
import Tab from '@mui/material/Tab';
function Login() {
  const SendGmailToken = registerApis.SendGmailToken();
  const SendFacebookData = registerApis.SendfacebookVerification();

  const dispatch = useDispatch();
  const naviage = useNavigate();
  const logindata = useSelector((state) => state.login.loginData);
  const loginMutation = LoginApis.Login();
  const [showEye, setshowEye] = useState(false)
  // const {isLoading}=LoginApis.Login();
  // console.log("=============isLoading",isLoading)

  const loginWithGmail = useGoogleLogin({
    onSuccess: async (cridentials) => {
      console.log("cridentials", cridentials);
      let response = await SendGmailToken.mutateAsync({
        auth_token: cridentials.access_token,
      });
      console.log(" response === ", response);
      if (response.status) {
        console.log("success ---- login ");
        dispatch(setIslogged(true));
        dispatch(setIsadmin(false));
        localStorage.setItem("userType", "user");
        localStorage.setItem("logingstatus", true);
        localStorage.setItem("access-token", response.data.access_token);
        localStorage.setItem("refersh-token", response.data.refresh_token);

        naviage("/profile");
      } else {
        showToastmsg("error", "Login Failed");
      }
    },
    onError: (error) => {
      console.log("errir===", error);
      showToastmsg("error", "some error occured");
    },
  });
  const LoginFacebook = async (response) => {
    if (response?.error) {
      showToastmsg("error", "Login Failed");
    } else {
      let result = await SendFacebookData.mutateAsync({
        auth_token: JSON.stringify(response),
      });
      if (
        result.status &&
        result.data.data.access_token &&
        result.data.data.refresh_token
      ) {
        dispatch(setIslogged(true));
        dispatch(setIsadmin(false));
        localStorage.setItem("userType", "user");
        localStorage.setItem("logingstatus", true);
        localStorage.setItem("access-token", result.data.data.access_token);
        localStorage.setItem("refersh-token", result.data.data.refresh_token);

        naviage("/profile");
      } else {
        showToastmsg("error", "Login Failed");
      }
    }
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      let isValid = await validation();
      if (isValid) {
        let loginData = {
          username: logindata.username,
          password: logindata.password,
        };
        let response = await loginMutation.mutateAsync(loginData);

        if (response.status) {
          if (!response.data.has_2fa_enabled) {
            dispatch(setIslogged(true));
            localStorage.setItem("logingstatus", true);
            localStorage.setItem("access-token", response.data.access);
            localStorage.setItem("refersh-token", response.data.refresh);
            naviage("/profile");
          } else {
            naviage("/two-factor-authentication", {
              state: { email: response.data.email },
            });
          }
        } else {
          dispatch(
            setLoginErr({
              field: "password",
              errorMsg: "Invalid password or username",
            })
          );
          naviage("/login");
        }
      }
    } catch (error) { }
  };

  const validation = async () => {
    try {
      if (!logindata.username) {
        dispatch(
          setLoginErr({ field: "username", errorMsg: "Username is required" })
        );
        return false;
      } else if (!logindata.password) {
        dispatch(
          setLoginErr({ field: "password", errorMsg: "Password is required" })
        );
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    dispatch(setLOgindata({ [name]: value }));
    dispatch(setLoginErr({ field: name, errorMsg: "" }));
  };

  let LoginError = useSelector((state) => state.login.loginError);
  const Eyehandler = () => {
    setshowEye(!showEye)
  }
  return (
    <>
   
      <section className="register_login_section">
        <div className="container text-center">
          <div className="register_login_box text-start login_box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <form onSubmit={loginHandler}>
                  <div className="register_login_box_head">
                    Welcome back
                    <p>Enter your credentials and login</p>
                  </div>
                  <div className="register_login_box_cnt">
                    <div className="register_login_box_form">
                      <div className="row">
                        <div className="col-md-12 mb-2">
                          <label htmlFor="">User Name</label>
                          <input
                            type="text"
                            className={`${LoginError.username ? "erorInput " : ""
                              }form-control`}
                            name="username"
                            onChange={changeHandler}
                          />
                          {LoginError.username && (
                            <p className="formError">
                              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                              &nbsp;{LoginError.username}*{" "}
                            </p>
                          )}
                        </div>
                        <div className="col-md-12 mb-2 password_fld">
                          <label htmlFor="">Password</label>
                          <input
                            type={showEye ? 'text' : 'password'}
                            className={`${LoginError.password ? "erorInput " : ""
                              }form-control`}
                            name="password"
                            onChange={changeHandler}
                          />
                          <span className="showPassword" onClick={Eyehandler}>
                            {
                              showEye ?
                                <i class="fa-solid fa-eye"></i> : <i class="fa-solid fa-eye-slash"></i>
                            }

                          </span>





                          {LoginError.password && (
                            <p className="formError">
                              <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                              &nbsp;{LoginError.password}*{" "}
                            </p>
                          )}
                        </div>
                        <p className="">
                          <Link to="/forgot-password">forgot password</Link>
                        </p>
                      </div>
                    </div>
                    <div /*style={{ display: "flex" }}*/>
                      <a
                      // href="my-account.html"
                      >
                        <button
                          type="submit"
                          className="reg_login_sub_btn mt-3"
                          //  onSubmit={loginHandler}
                          disabled={loginMutation.isLoading ? true : false}
                        >
                          Login&nbsp;
                          {loginMutation.isLoading && (
                            <i class="fa-solid fa-spinner fa-spin-pulse"></i>
                          )}
                        </button>
                      </a>
                      <div>
                        <div className="center-container">
                          <div className="center-line"></div>
                          <span className="center-text">or</span>
                          <div className="center-line"></div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            width: "175px",
                          }}
                        >
                          <div
                            className="login_google_ico"
                            onClick={() => {
                              loginWithGmail();
                            }}
                            style={{ width: "50px" }}
                          >
                            <img src={google} alt="" />
                          </div>
                          <div>
                            <FacebookLogin
                              appId={FACEBOOK_APP_ID}
                              autoLoad={false}
                              fields="name,email,picture"
                              callback={LoginFacebook}
                              render={(renderProps) => (
                                <div
                                  className="login_google_ico"
                                  onClick={renderProps.onClick}
                                >
                                  <img src={fb} alt="" />
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        {/* <strong>Sign in</strong> */}
                        {/* </button> */}
                      </div>
                    </div>

                    <p className="already_registrd_reg">
                      Not registered? <Link to="/register">Register Now</Link>
                    </p>
                  </div>
                </form>
              </div>

              <div className="col-md-6">
                <div className="login_right_sec">
                  <h2>Best Ways to Find Your True Soulmate</h2>
                  <img src={Loginright} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
}

export default Login;
