import API from "../api/api"

export const chatServices={
    sendReceiverId:async(data)=>{
        try {
          const response=await API.post('chat/user-room',data)
          if(response.status){
            return response
          }else{
            return response
          }  
        } catch (error) {
           return error
        }
    },
    getChatroomusers:async()=>{
      try {
        const response=await API.get('chat/get-chat-rooms')
          if(response.status){
            return response
          }else{
            return response
          }  
      } catch (error) {
        return error
      }
    },
    Getchats:async(roomId)=>{
      try {
        const response=await API.get(`chat/user-room?room_id=${roomId}`)
          if(response.status){
            return response
          }else{
            return response
          }  
      } catch (error) {
        return error
      }
    },
    getStickers:async()=>{
      try {
        const response=await API.get('chat/custom-stickers')
          if(response.status){
            return response
          }else{
            return response
          }  
      } catch (error) {
        return error
      }
    }
    
  }