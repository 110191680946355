import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { publicRoutes, privateRoutes, adminRoutes } from './routes/router';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsadmin, setIslogged } from './store/reducers/authReducer';
import NotFoundPage from './views/NotFoundPage/NotFoundPage';
import AdminProfile from './views/Profile/AdminProfile';
import MainLayout from './layouts/MainLayout';
import AuthLayout from './layouts/AuthLayout';
import AdminLayout from './layouts/AdminLayout';


function App() {
  const { isLogged } = useSelector((state) => state.login);

  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("logingstatus") && localStorage.getItem('access-token')) {
      dispatch(setIslogged(true))
      let userType = localStorage.getItem("userType");
      if (userType === "admin") {
        dispatch(setIsadmin(true))
      } else {
        dispatch(setIsadmin(false))
      }

    }
  }, []);

  const { isAdmin } = useSelector((state) => state.login)
  let userType = localStorage.getItem("userType");
  console.log("loging status", isLogged);

  return (
    <BrowserRouter>
      <Routes>
        {isLogged ? (
          <React.Fragment>
            {isAdmin ? (
              <Route path='/' element={<AdminLayout />}>
                {
                  adminRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element}>
                      {route.children &&
                        route.children.map((childRoute, childIndex) => (
                          <Route
                            key={childIndex}
                            index={childRoute.index}
                            path={childRoute.path}
                            element={childRoute.element}
                          />
                        ))}
                    </Route>
                  ))
                }
              </Route>

            ) : (
              <Route path='/' element={<MainLayout />}>
                {
                  privateRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element}>
                      {route.children &&
                        route.children.map((childRoute, childIndex) => (
                          <Route
                            key={childIndex}
                            index={childRoute.index}
                            path={childRoute.path}
                            element={childRoute.element}
                          />
                        ))}
                    </Route>
                  ))
                }
              </Route>

            )}
          </React.Fragment>
        ) : (
          <Route path="/" element={<AuthLayout />}>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Route>
        )}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}


export default App;
