import { createSlice } from '@reduxjs/toolkit'


const initialState = {
  incomingTRavelrqst:[]

}

export const TravelSlice = createSlice({
  name: 'travel',
  initialState,
  reducers: {
    setIncomingTravelrqsts: (state, action) => {
      state.incomingTRavelrqst = action.payload
    },
   

  }
})

export const { setIncomingTravelrqsts} = TravelSlice.actions
export default TravelSlice.reducer
