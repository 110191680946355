import React from 'react'
import userImg1 from '../../../assets/images/users/user_imag_1.jpg'
import { ProfileApis } from '../../../hooks/apihooks/profileApis'
import { IMG_URL } from '../../../config/config'
import nodataIMg from '../../../assets/images/nodata.jpg'
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import CardSkeleton from '../../skeletons/CardSkeleton'


function WholikedU({t}) {
    const getWholiked = ProfileApis.LikedUsers()
    
    return (
        <div>
            <div className="my_account_right_head">
               {t('who_liked_u')}
            </div>

            <div className="my_account_right_box">
                {
                    getWholiked.isLoading?
                    <CardSkeleton type="whoLikedme" iconsCount={0}/>
                    :
                    getWholiked?.data?.data?.my_admires_users.length > 0 ?
                        <div className="row">
                            {
                                getWholiked?.data?.data &&
                                getWholiked?.data?.data?.my_admires_users.map((items, index) => {
                                    return (
                                        <div className="col-md-3">
                                            <div className="profile_match_crsl_box">
                                                <div className="profile_match_crsl_box_img">
                                                <a className="dlete_action_btn" href={`/singleProfile/${items.user__username}`}>
                                                        <i class="fa-solid fa-eye"></i>
                                                    </a>
                                                    <a href="#/">
                                                        <img src={items.profile_picture?IMG_URL + items.profile_picture:defaultProfilepic} alt="" /></a>
                                                </div>
                                                <a href="#/">
                                                    <div className="profile_cnt_bx">
                                                        {items.user__username
                                                        } - {items.age ?? 0}
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </div>
                        :
                        <img src={nodataIMg} alt='nodata' className='w-50' />
                }


            </div>
        </div>
    )
}

export default WholikedU