import React from 'react'
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
function PhotosSkeleton() {
    return (
        <div className="prfile_page_info_sec">
            
            <div className="row">
                {Array(4).fill('').map((_, index) => (
                    <div key={index} className="col-md-3">
                        <div className="prfile_page_photo">
                            <Skeleton height={200} />
                            <div className="rating-group mt-2">
                                <Skeleton width={150} height={30} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PhotosSkeleton