import React from 'react'
import pagenotFoundimg from '../../assets/images/404.png'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
function NotFoundPage() {
  const { isLogged } = useSelector((state) => state.login)
  const navigate=useNavigate()
  const backTohomepage=()=>{
    if(isLogged){
      navigate('/profile')
    }else{
      localStorage.clear()
      navigate('/login')
    }
  }
  return (
    <>
      <Header />
      <section className="error_page_sec">
        <div className="container">
          <div className="error_page_img"><img src={pagenotFoundimg} alt="" /></div>
          <p>Ooops!!! The page you are looking for is not found</p>
         
          <button className="back_home_btn" onClick={backTohomepage}>Back to Home Page</button>
        </div>
      </section>
      <Footer />
    </>

  )
}

export default NotFoundPage