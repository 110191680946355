import { useMutation, useQuery } from "@tanstack/react-query";
import {
  updateMailContent,
  getMailContent,
  getCompanyDetails,
  postCompanyDetails,
  getCompanyPolicy,
  postCompanyPolicy,
} from "../../actions/Settings/settingsApi";
export const settingsApis = {
  UpdateMailContent: () => {
    const response = useMutation({
      mutationKey: ["update-mail-content"],
      mutationFn: (data) => updateMailContent(data),
    });
    return response;
  },
  GetMailContent: (type) => {
    const response = useQuery({
      queryKey: ["get-mail-content", type],
      queryFn: () => getMailContent(type),
    });
    return response;
  },
  GetCompanyDetails: () => {
    const response = useQuery({
      queryKey: ["get-company-details"],
      queryFn: () => getCompanyDetails(),
    });
    return response;
  },
  PostCompanyDetails: () => {
    const response = useMutation({
      queryKey: ["post-company-details"],
      mutationFn: (data) => postCompanyDetails(data),
    });
    return response;
  },
  GetCompanyPolicy: () => {
    const response = useQuery({
      queryKey: ["get-company-policy"],
      queryFn: () => getCompanyPolicy(),
    });
    return response;
  },
  PostCompanyPolicy: () => {
    const response = useMutation({
      queryKey: ["post-company-policy"],
      mutationFn: (data) => postCompanyPolicy(data),
    });
    return response;
  },
};
