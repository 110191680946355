import React from 'react'
import { useState } from 'react'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import ListGroup from 'react-bootstrap/ListGroup';
import { Form } from 'react-bootstrap';
import { TravelApis } from '../../../hooks/apihooks/travelApis';
import { showToastmsg } from '../../../common/ToastMsg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import DatePickerComponent from '../../../common/DatePicker';
import moment from 'moment';


function Travel({t}) {
    const [locationSuggestions, setlocationSuggestions] = useState([])
    const postTraveldata = TravelApis.PostTraveldata()
    const [Btnshow, setBtnshow] = useState(false)
    const [loader, setloader] = useState(false)
    const navigate = useNavigate()
    const [data, setdata] = useState({
        latitude: '',
        longitude: '',
        location_name: '',
        looking_for: '',
        date: '',
        days: '',
        description: ''
    })
    const [formError, setformError] = useState({
        location_name: '',
        looking_for: '',
        type: '',
        date: '',
        days: '',
        description: ''
    })
    const tooltip = (

        <Tooltip id="button-tooltip">
           {t('my_trips')}
        </Tooltip>
    );
    const handleChangehandler = async (e) => {
        const { name, value } = e.target
        setformError({
            ...formError,
            location_name: '',
            looking_for: '',
            type: '',
            date: '',
            days:''
        })
        if (name === "location") {
            setdata({
                ...data,
                location_name: value
            })
            try {
                setloader(true)
                const response = await axios.get(
                    `https://nominatim.openstreetmap.org/search?format=json&q=${value}`
                );
                if (response.data) {
                    setlocationSuggestions(response.data);
                    setloader(false)
                }




            } catch (error) {
                console.error('Error fetching location data:', error);
            }
        } else {
            setdata({
                ...data,
                [name]: value
            })
        }
    }

    const selectHandler = (latitude, longitude, name) => {
        setdata({
            ...data,
            latitude: latitude,
            longitude: longitude,
            location_name: name

        })
        setlocationSuggestions([])
    }

    
    const validation = async () => {
        try {
            const dateRegexp = /^(?!0000)[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

            if (!data.location_name) {
                setformError((prevErrors) => ({ ...prevErrors, location_name: 'Location name is required' }));
                return false;
            }

            if (!data.days) {
                setformError((prevErrors) => ({ ...prevErrors, days: 'Days is required' }));
                
                return false;
            }

            if (!data.date) {
                setformError((prevErrors) => ({ ...prevErrors, date: 'Travel date is required' }));
                return false;
            }

            // Validate the date format
            if (!dateRegexp.test(data.date)) {
                setformError((prevErrors) => ({ ...prevErrors, date: 'Invalid format' }));
                return false;
            }

            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0); 

            const inputDateParts = data.date.split('-');
            const inputDate = new Date(inputDateParts[0], inputDateParts[1] - 1, inputDateParts[2]);
            inputDate.setHours(0, 0, 0, 0); 

            if (inputDate < currentDate) {
                setformError((prevErrors) => ({ ...prevErrors, date: 'Date cannot be in the past' }));
                return false;
            }

            return true;
        } catch (error) {
            console.error('Error in validateForm:', error);
            return false;
        }
    };


    const TravelSubmithandler = async () => {
        try {
            const isValid = await validation()
            if (isValid) {
                let travelData = {
                    latitude: Number(data.latitude).toFixed(6),
                    longitude: Number(data.longitude).toFixed(6),
                    travel_date: data.date,
                    days: data.days,
                    location: data.location_name.toLowerCase(),
                    description: data.description,
                    looking_for: data.looking_for
                }
                const response = await postTraveldata.mutateAsync(travelData)

                if (response.status) {
                    showToastmsg("success", t('data_added'))
                    setdata({
                        ...data,
                        location_name: '',
                        description: '',
                        days: '',
                        date: '',
                        looking_for: ''
                    })
                } else {
                    showToastmsg("error", t('something_went_wrong'))
                }
            } else {
                // showToastmsg("error", "Check your values")
            }
        } catch (error) {

        }
    }
    const moveToMytriphandler = () => {
        navigate('/my-trips')
    }

    const handleDatechange = async (value) => {
        setformError({
            ...formError,
            date: ""
        })
        const selectedDate = value.toDate();
        const formatedDate = moment(selectedDate).format('YYYY-MM-DD')
       
        setdata({
            ...data,
            date: formatedDate
        })
        
        

    }
    return (
        <div>
            <div className="my_account_right_head">
               {t('travel')}
            </div>
            <div className="my_account_right_box">
                <div className="register_login_box_cnt_subhead">
                    {/* <OverlayTrigger placement="top" overlay={tooltip}> */}
                        <button

                            className="add_new_photo_travel"
                            onClick={moveToMytriphandler}

                        ><i class="fa-solid fa-suitcase"></i>
                        <span className="my_trip_text">{t('my_trips')}</span>
                        </button>
                    {/* </OverlayTrigger> */}

                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="filter_box">
                            <div className="filter_box_head">{t('travel_location')}</div>
                            <div className="filter_box_cnt">
                                <input type="text" className={`${formError.location_name ? 'erorInput ' : ''}form-control`}
                                    placeholder={t('trvl_location')}
                                    name='location'
                                    onChange={handleChangehandler}
                                    value={data.location_name}
                                    autoComplete="off"
                                />
                                {formError.location_name &&
                                    <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.location_name}*</p>
                                }
                                <div className={`locationList-container ${locationSuggestions ? 'open' : ''}`}>
                                    {
                                        locationSuggestions && (
                                            <ListGroup className='locationList'>
                                                {
                                                    loader ? <center>
                                                        <span>{t('loading')}</span>
                                                    </center>
                                                        :
                                                        locationSuggestions.map((locations, index) => {
                                                           
                                                            return (
                                                                <ListGroup.Item onClick={() => selectHandler(locations.lat, locations.lon, locations.display_name)}>

                                                                    <i class="fa-solid fa-location-dot"></i>&nbsp;
                                                                    {locations.display_name}
                                                                </ListGroup.Item>
                                                            )
                                                        })

                                                }

                                            </ListGroup>

                                        )
                                    }

                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="filter_box">
                            <div className="filter_box_head">{t('looking_for')}</div>
                            <div className="filter_box_cnt">
                                <select value={data.looking_for} className={`${formError.looking_for ? 'erorInput ' : ''}form-control`} name="looking_for" id="" onChange={handleChangehandler}>
                                    <option value="">{t('select')}</option>
                                    <option value="dating">{t('dating')}</option>
                                    <option value="family">{t('family')}</option>
                                </select>
                                {formError.looking_for &&
                                    <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.looking_for}*</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="filter_box">
                            <div className="filter_box_head">{t('days')}</div>
                            <div className="filter_box_cnt">
                                <input type="number" value={data.days} className={`${formError.days ? 'erorInput ' : ''}form-control`} name='days' placeholder=""
                                    onChange={handleChangehandler}
                                    min={0}
                                />
                                {formError.days &&
                                    <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.days}*</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="filter_box">
                            <div className="filter_box_head">{t('trvl_date')}</div>
                            <div className="filter_box_cnt">
                                <div className='travel-date-picker'>
                                    <DatePickerComponent
                                        className={`${formError.date ? 'erorInput ' : ''}form-control`}
                                        handleDatechange={handleDatechange}
                                        value={data.date}
                                    />
                                </div>

                                {/* <input type="date" value={data.date}className={`${formError.date ? 'erorInput ' : ''}form-control`} name='date' placeholder=""
                                    onChange={handleChangehandler}
                                /> */}
                                {formError.date &&
                                    <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.date}*</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="filter_box">
                            <div className="filter_box_head">{t('desc')}</div>
                            <div className="filter_box_cnt">
                                <Form.Control as="textarea"
                                    onChange={handleChangehandler}
                                    value={data.description}
                                    name='description'
                                    rows={3}
                                    placeholder={t('describe_ur_trvl')}/>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="modal-footer">
                    <button type="button" className="btn regsiter_top_btn" onClick={TravelSubmithandler}>{t('add')}</button>
                </div>
            </div>


        </div>
    )
}

export default Travel