import React from 'react'
import Appstore from '../../assets/images/appstore.png'
import googlePlay from '../../assets/images/google_play.png'
import appView from '../../assets/images/app-view-mockup.png'
function Download() {
    return (
        <section className="app_download_section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="app_download_section_cnt">
                            <h5>
                                <span>Download Now</span>
                                Easy Connect To Everyone
                            </h5>
                            <div className="app_download_section_btn">
                                <a href="#/">
                                    <img src={Appstore} alt="" />
                                </a>
                                <a href="#/">
                                    <img src={googlePlay} alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="app_download_view_img">
                            <img src={appView} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Download