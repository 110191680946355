import React from 'react'
import { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import AddinterstModal from './AddinterstModal';
import { useSelector } from 'react-redux';
import { ProfileApis } from '../../../hooks/apihooks/profileApis';
import { showToastmsg } from '../../../common/ToastMsg';
import { ToastContainer, toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { registerApis } from '../../../hooks/apihooks/registerApis';
import { MultiSelect } from "react-multi-select-component";
import nodataIMg from '../../../assets/images/nodata.jpg'


function Interests({ t }) {
    const [modalShow, setmodalShow] = useState(false)
    const [addIntrestStatus, setaddIntrestStatus] = useState(false)
    const [selectedInterests, setSelectedInterests] = useState([]);
    const [newIntrests, setnewIntrests] = useState('')
    const addnewIntestapi = ProfileApis.EditpersonalDetails()
    const dltInterestapi = ProfileApis.Dltinterests()
    const GetInterestapi = registerApis.GetInterest()
    const [popupClose, setpopupClose] = useState(false)
    const profile_data = useSelector((state) => state.profile.profileData)
    const queryClient = useQueryClient();
    const [selected, setSelected] = useState([]);
    const [options, setoptions] = useState([])
    const [Error, setError] = useState({
        Status: false,
        errorMsg: ''
    })

    useEffect(() => {

        const interestsOPtions = GetInterestapi?.data?.data?.map(item => ({
            label: t(item.name),
            value: item.id
        }));

        setoptions(interestsOPtions)
    }, [GetInterestapi?.data])



    const toggleInterest = (item) => {

        const isSelected = selectedInterests.some((interest) => interest.id === item.id);
        if (isSelected) {
            let filter = selectedInterests.filter((items) => {
                return items.id !== item.id
            })
            setSelectedInterests(filter)
        } else {

            setSelectedInterests([...selectedInterests, item]);
        }


    };
    const deleteHandler = () => {
        // setmodalShow(true)
        deleteConfirmationHandler('yes')
    }
    const deleteConfirmationHandler = async (type) => {
        if (type == 'yes') {
            try {
                const interest_ids = selectedInterests.map(interest => interest.id);
                let data = {
                    interest_id: interest_ids
                }
                const response = await dltInterestapi.mutateAsync(data)
                if (response.status) {
                    setmodalShow(false)
                    showToastmsg('success', 'selected interests deleted')
                    queryClient.invalidateQueries({ queryKey: ["profileData"] });
                } else {
                    showToastmsg('error', 'something went wrong..')
                }
            } catch (error) {
                console.log('error============', error)
                showToastmsg('error', 'something went wrong..')
            }

        }
        // else {
        //     setmodalShow(false)
        // }

    }

    const addInteresthandler = () => {
        setaddIntrestStatus(!addIntrestStatus)
    }

    const interestAddhandler = (e) => {
        const { value } = e.target
        setnewIntrests(value)
    }

    const Addnewintrestshandler = async () => {
        try {


            if (selected?.length == 0) {
                setError({
                    ...Error,
                    Status: true,
                    errorMsg: 'Please select your interests'
                })
            } else {
                setError({
                    ...Error,
                    Status: false,
                    errorMsg: ''
                })
                const Newinterests = selected.map(interest => interest.label);
                let data = {
                    interests: Newinterests
                }

                const response = await addnewIntestapi.mutateAsync(data)

                if (response.status) {
                    showToastmsg('success', t('interest_successfully_added'))
                    setaddIntrestStatus(false)
                    setSelected([])
                    queryClient.invalidateQueries({ queryKey: ["profileData"] });
                    setnewIntrests('')
                    setTimeout(() => {
                        toast.dismiss()
                    }, 5000)


                } else {
                    showToastmsg('error', t('something_went_wrong'))
                    setTimeout(() => {
                        toast.dismiss()
                    }, 5000)

                }
            }

        } catch (error) {
            showToastmsg('error', t('something_went_wrong'))
            console.log(error)
        }
    }
    return (
        <div>

            <h5>{t("intrst")}</h5>
            {
                selectedInterests.length > 0 && (
                    <button class="add_new_photo" onClick={deleteHandler}><i class="fa-solid fa-trash-can"></i></button>
                )
            }

            <button class="add_new_photo"
                onClick={addInteresthandler}
            >
                {
                    addIntrestStatus ?
                        <i class="fa-solid fa-minus"></i>
                        : <i class="fa-solid fa-plus"></i>
                }

            </button>
            {
                profile_data?.interests && (
                    <div className="profile_right_intrst_cnt_sc">
                        {
                            addIntrestStatus && (
                                <div className='row mb-4'>
                                    <label htmlFor="">{t('add_ur_new_interest')}</label>
                                    {/* <div className='col-lg-8'>
                                    <input type="text" className='form-control'
                                        placeholder=""
                                        onChange={interestAddhandler}
                                        value={newIntrests}
    
                                    />
                                </div> */}
                                    <div className='col-lg-8'>

                                        <MultiSelect
                                            options={options}
                                            value={selected}
                                            onChange={setSelected}
                                            labelledBy={t("select")}
                                        />
                                        {
                                            Error.errorMsg && (
                                                <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{Error.errorMsg}*</p>
                                            )
                                        }
                                    </div>

                                    <div className='col-lg-3'>
                                        <button className="myaccount_profile_intrestaddbtn" onClick={Addnewintrestshandler}>{t('add')}</button>
                                    </div>


                                </div>

                            )
                        }
                        {
                            profile_data?.interests.length === 0 && (
                                <center><img src={nodataIMg} className='w-50' /></center>
                            )
                        }
                        {
                            profile_data?.interests?.map((item, index) => (

                                <button
                                    key={item.id}
                                    className={`filter_box_intrstbx ${selectedInterests.some((interest) => interest.id === item.id) ? 'active' : ''}`}
                                    onClick={() => toggleInterest(item)}

                                >
                                    {t(item.name)}
                                    {/* &nbsp;
                                <i class="fa-solid fa-xmark"></i> */}
                                </button>
                            ))
                        }

                        {/* {
                        selectedInterests.length > 0 && (
                            <div className="popupButtonArea">
                                <ul>
                                    <li>
                                        <span
                                        // onClick={() => { cancelPopup(true) }}
                                        >
                                            <i className="fa fa-times"></i>
                                        </span>
                                    </li>
                                    <li>
                                        <span className="CountRound">
                                            {selectedInterests.length}
                                        </span>
                                        <span className="spanText">
                                            Items selected
                                        </span>
    
    
                                    </li>
                                    <li>
                                        <button variant="danger" className="CancelButton"
                                        // onClick={() => { ApproveHandler(table.selectedRows) }}
                                        >
                                            <i class="fa-solid fa-trash-can"></i>
                                             &nbsp; 
                                       </button>
                                    </li>
                                </ul>
                            </div>
                        ) 
                   }   */}

                    </div>
                )
            }






        </div>
    )
}

export default Interests