import React from 'react'
import targetImg from '../../assets/images/targeting.png'
import vision from '../../assets/images/vision.png'
function CompanyMission() {
    return (
        <section className="about_vision_mission_sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="about_vision_mission_box">
                            <div className="about_vision_mission_box_ico">
                                <img src={vision} alt="" />
                            </div>
                            <span>
                                <h5>Vision</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="about_vision_mission_box">
                            <div className="about_vision_mission_box_ico">
                                <img src={targetImg} alt="" />
                            </div>
                            <span>
                                <h5>Mission</h5>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CompanyMission