import React from 'react'
import userImg1 from '../../../assets/images/users/user_imag_1.jpg'
import { ProfileApis } from '../../../hooks/apihooks/profileApis'
import { IMG_URL } from '../../../config/config'
import nodataIMg from '../../../assets/images/nodata.jpg'
import defaultProfilepic from '../../../assets/images/user-blue-gradient.png'
import CardSkeleton from '../../skeletons/CardSkeleton'


function WhofavouritedU({t}) {
    const getWhofvrtedU = ProfileApis.GetFavourites()

    return (
        <div>
            <div className="my_account_right_head">
                {t('who_fvrtd_u')}
            </div>
            <div className="my_account_right_box">
                {
                    getWhofvrtedU.isLoading ?
                        <CardSkeleton iconsCount={0} type='whofvrtu' />
                        :
                        getWhofvrtedU?.data?.data?.my_admires_data.length > 0 ?
                            <div className="row">
                                {
                                    getWhofvrtedU?.data?.data?.my_admires_data &&
                                    getWhofvrtedU?.data?.data?.my_admires_data.map((item, index) => {
                                        return (
                                            <div className="col-md-3">
                                                <div className="profile_match_crsl_box">
                                                    <div className="profile_match_crsl_box_img">
                                                        <a className="dlete_action_btn" href={`/singleProfile/${item.user__username}`}>
                                                            <i class="fa-solid fa-eye"></i>
                                                        </a>
                                                        <a>
                                                            {
                                                                item.profile_picture ?
                                                                    <img src={IMG_URL + item.profile_picture} alt="" />
                                                                    : <img src={defaultProfilepic} alt='no image' />

                                                            }

                                                        </a>
                                                    </div>
                                                    <a>
                                                        <div className="profile_cnt_bx">
                                                            {item.user__username
                                                            } - {item.age ?? 0}
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <img src={nodataIMg} alt='nodata' className='w-50' />
                }

            </div>
        </div>
    )
}

export default WhofavouritedU