import API from "../api/api";

export const settingsServices = {
  updateMailContent: async (data) => {
    try {
      const response = await API.put("mail-content", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getMailContent: async (type) => {
    try {
      const response = await API.get(`mail-content`, {
        headers: {
          type,
        },
      });
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getCompanyDetails: async () => {
    try {
      const response = await API.get(`configurations`);
      return response;
    } catch (error) {
      return error;
    }
  },
  postCompanyDetails: async (data) => {
    try {
      const response = await API.post(`configurations`, data);
      return response;
    } catch (error) {
      return error;
    }
  },
  getCompanyPolicy: async () => {
    try {
      const response = await API.get(`company-details`);
      return response;
    } catch (error) {
      return error;
    }
  },
  postCompanyPolicy: async (data) => {
    try {
      const response = await API.post(`company-details`, data);
      return response;
    } catch (error) {
      return error;
    }
  },
};
