import React from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import InnerpageHeader from '../../../common/InnerpageHeader'
import { TravelApis } from '../../../hooks/apihooks/travelApis'
import Travelimg from '../../../assets/images/6101668.jpg'
import { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { showToastmsg } from '../../../common/ToastMsg'
import nodataImg from '../../../assets/images/nodata.jpg'
import { useQueryClient } from '@tanstack/react-query'
import { setIncomingTravelrqsts } from '../../../store/reducers/travelReducer'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomTabPanel from '../../../common/CustomTabPanel'
import ScheduledTripList from './ScheduledTrip'
import MyTrvlRequests from './MyTrvlRequests'

function Mytrips() {
    const getMytrips = TravelApis.GetTraveldata()
    const myRqstedTrips = TravelApis.GetMytravelRqst()
    const [SelectedTab, setSelectedTab] = useState(0)

  
    return (
        <div>
         
            <InnerpageHeader pageTitle="My Trips" />
            <section className="inner_page_main_bx_section">
                <div className="container">
                    <div className="inner_page_main_box_view">
                        <div className="inner_page_main_bx_head">
                            <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/profile/travel"><i class="fa-solid fa-angle-left" /></Link>
                        </div>
                        <div className="best_match_section">

                            <Tabs value={SelectedTab} onChange={(event, newValue) => setSelectedTab(newValue)} aria-label="basic tabs example" >
                                <Tab label="Scheduled Trip" value={0} sx={{ fontFamily: "poppinsregular" }}/>
                                <Tab label="Request Sent" value={1} sx={{ fontFamily: "poppinsregular" }}/>

                            </Tabs>

                            <CustomTabPanel value={SelectedTab} index={0}>
                                <ScheduledTripList TripDetails={getMytrips?.data?.data} isLoading={getMytrips.isLoading}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={SelectedTab} index={1}>
                                <MyTrvlRequests data={myRqstedTrips?.data?.data} isLoading={getMytrips.isLoading}/>
                            </CustomTabPanel>

                        </div>
                    </div>
                </div>
            </section>
           




        </div>
    )
}

export default Mytrips