import API from "../api/api"

export const mlmServices={
    registerTomlm:async(data)=>{
        try {
          const response=await API.post('register-mlm',data)
          if(response.status){
            return response
          }else{
            return response
          }  
        } catch (error) {
           return error
        }
    },
    

}