import API from "../api/api"

export const testiMonialService = {
  postTestimonialdata: async (data) => {
    try {
      const response = await API.post('user-testimonial', data)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  getTestimonialdata: async () => {
    try {
      const response = await API.get('get-testimonials',
        {
          headers: {
            type: 1,
            rowsperpage: 0,
            // page: 0
          }
        })
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  getpendingTestimonialdata: async (rowsperpage, page) => {
    try {
      const response = await API.get('get-testimonials',
        {
          headers: {
            type:4,
            rowsperpage: rowsperpage,
            page: page
          }
        })
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  updateTestimonialstatus: async (data) => {
    try {
      const response = await API.put('user-testimonial', data)

      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },

}