import React, { useEffect } from 'react'
import InnerpageHeader from '../../common/InnerpageHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import NormalFilter from '../../components/matches/Filter/NormalFilter'
import TravelFilter from '../../components/travel/TravelFilter'
import { useState } from 'react'
import { MatchApis } from '../../hooks/apihooks/matchApis'
import { useQueryClient } from '@tanstack/react-query'
import { Carousel } from 'react-bootstrap'
import { IMG_URL, WEB_SOCKET_URL } from '../../config/config'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import nodataImg from '../../assets/images/nodata.jpg'
import { useWebSocket } from '../../hooks/socketContext/WebSocketContext'
import { useSelector } from 'react-redux'
import { showToastmsg } from '../../common/ToastMsg'
import defaultProfilepic from '../../assets/images/user-blue-gradient.png'
import { Link } from 'react-router-dom'
import MatchesSkeleton from '../../components/skeletons/CardSkeleton'
import Backbtn from '../../common/Backbtn'
import { useTranslation } from 'react-i18next'

function Matches() {
    const { t } = useTranslation()
    // const { socket } = useWebSocket();
    const loggeduser = useSelector((state) => state.login.loggedUsername)
    const postMatchpreference = MatchApis.FilterSubmit()
    const getMyprefernce = MatchApis.GetmyPrefernce()
    const getMymatches = MatchApis.Getmatches()
    const postPoke = MatchApis.SendPoke()
    const postLikesandDislike = MatchApis.PostLikedDetail()
    const [selectedTab, setselectedTab] = useState("filter")
    const queryClient = useQueryClient();
    const [likedStatus, setlikedStatus] = useState(false)
    const [likedIds, setlikedIds] = useState([])
    const [fvrtIds, setfvrtIds] = useState([])
    const [blockedIds, setblockedIds] = useState([])
    const [selectedBarIndexe, setSelectedBarIndexe] = useState([]);
    const postFavouriteandUnfvrt = MatchApis.PostFavouritedDetails()
    const blockUsers = MatchApis.BlockUsers()
    const [fullData, setfullData] = useState([])
    const [pokeStatus, setpokeStatus] = useState(false)
    const [loadMore, setloadMore] = useState({
        status: false,
        limit: 8
    })
    const handleBarClick = (itemIndex, barIndex) => {
        const newIndexes = [...selectedBarIndexe];
        newIndexes[itemIndex] = barIndex;
        setSelectedBarIndexe(newIndexes);
    };

    const isLocationaccessAllowed = useSelector((state) => state.location.locationAllowed)
    console.log("isLocationaccessAllowed", isLocationaccessAllowed)

    const tab = [
        {
            id: 'filter_1',
            name: t('Filter'),
            selectedTab: 'filter'
        },
        // {
        //     id: "filter_2",
        //     name: 'Travel Companion',
        //     selectedTab: 'travel'
        // }
    ]
    const selectTabhandler = (tab) => {
        setselectedTab(tab)
    }

    const filterSubmithandler = async (data) => {
        if (data) {

            const response = await postMatchpreference.mutateAsync(data)
            if (response.status) {
                getMyprefernce.refetch();
                getMymatches.refetch();
                const closeButton = document.querySelector('[data-bs-dismiss="modal"]');
                if (closeButton) {
                    closeButton.click();
                }
            }
        }
    }

   
    useEffect(() => {
        setSelectedBarIndexe(getMymatches?.data?.data?.preferences_by_user_id.map(() => 0))
        setfullData(getMymatches?.data?.data?.preferences_by_user_id)
        checkLoadmore()
        const AlreadyLikedIds = getMymatches?.data?.data?.preferences_by_user_id
            .filter((data) => data.like_status)
            .map((data) => data.id);
        setlikedIds(AlreadyLikedIds)
        const AlreadyfvrtIds = getMymatches?.data?.data?.preferences_by_user_id.filter((data) => data.favorite_status).map((data) => data.id);
        setfvrtIds(AlreadyfvrtIds)
    }, [getMymatches?.data])


    const checkLoadmore = () => {

        if (getMymatches?.data?.data?.preferences_by_user_id &&
            getMymatches?.data?.data?.preferences_by_user_id.length > loadMore.limit) {

            setloadMore((prevLoadMore) => ({
                ...prevLoadMore,
                status: true,
            }));
        }
    };
    
    // const data = [
    //     {
    //         id: 1,
    //         coverphotos: [
    //             {
    //                 id: 1,
    //                 img: userImg1
    //             },
    //             {
    //                 id: 2,
    //                 img: userImg2
    //             },
    //             {
    //                 id: 3,
    //                 img: userImg3
    //             },
    //             {
    //                 id: 4,
    //                 img: userImg4
    //             }
    //         ],
    //         username: "user01",
    //         age: "23",

    //     },
    //     {
    //         id: 2,
    //         coverphotos: [
    //             {
    //                 id: 1,
    //                 img: userImg1
    //             },
    //             {
    //                 id: 2,
    //                 img: userImg2
    //             },
    //             {
    //                 id: 3,
    //                 img: userImg3
    //             },
    //             {
    //                 id: 4,
    //                 img: userImg4
    //             }
    //         ],
    //         username: "user02",
    //         age: "23",

    //     },
    //     {
    //         id: 3,
    //         coverphotos: [
    //             {
    //                 id: 1,
    //                 img: userImg1
    //             },
    //             {
    //                 id: 2,
    //                 img: userImg2
    //             },
    //             {
    //                 id: 3,
    //                 img: userImg3
    //             },
    //             {
    //                 id: 4,
    //                 img: userImg4
    //             }
    //         ],
    //         username: "user03",
    //         age: "23",

    //     }
    // ]
    // const [selectedBarIndexes, setSelectedBarIndexes] = useState(data.map(() => 0));

    const handleImageClick = (itemIndex, direction) => {
        const newIndexes = [...selectedBarIndexe];
        let newIndex = newIndexes[itemIndex];

        const preferencesData = getMymatches?.data?.data?.preferences_by_user_id;

        if (!preferencesData) {
            return;
        }

        const coverPhotos = preferencesData[itemIndex]?.cover_photos || [];

        if (direction === 'prev' && coverPhotos.length > 0) {
            newIndex = newIndex > 0 ? newIndex - 1 : coverPhotos.length - 1;
        } else if (direction === 'next' && coverPhotos.length > 0) {
            newIndex = newIndex < coverPhotos.length - 1 ? newIndex + 1 : 0;
        }

        newIndexes[itemIndex] = newIndex;
        setSelectedBarIndexe(newIndexes);
    };

    const likeHandler = async (id, data) => {
        if (likedIds.includes(id)) {        
            let dislikedData = {
                user: id
            }
            const response = await postLikesandDislike.mutateAsync(dislikedData)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-my-matches"] });

            }
            setlikedIds(likedIds.filter((item) => item != id))
        } else {
            let likedata = {
                user: id
            }
            const response = await postLikesandDislike.mutateAsync(likedata)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-my-matches"] });
                const ws = new WebSocket(`${WEB_SOCKET_URL}notification/${id}/`);
                // const ws = new WebSocket(`ws://208.109.189.52:8000/ws/notification/${id}/`);

                ws.onopen = () => {
                    let socketLikedData = {
                        message: `${loggeduser}liked your photo`,
                        username: loggeduser
                    }

                    ws.send(JSON.stringify(socketLikedData));
                };



            }
            setlikedIds([...likedIds, id])

        }

    }

    const FvrtHandler = async (id) => {
        if (fvrtIds.includes(id)) {
            let UnfavrtData = {
                user: id
            }
            const response = await postFavouriteandUnfvrt.mutateAsync(UnfavrtData)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-my-matches"] });
            }
            setfvrtIds(fvrtIds.filter((item) => item != id))
        } else {

            let fvrtdata = {
                user: id
            }
            const response = await postFavouriteandUnfvrt.mutateAsync(fvrtdata)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["get-my-matches"] });
                const ws = new WebSocket(`${WEB_SOCKET_URL}notification/${id}/`);
                // const ws = new WebSocket(`ws://208.109.189.52:8000/ws/notification/${id}/`);

                ws.onopen = () => {
                    let socketLikedData = {
                        message: `${loggeduser} added you to their favourites`,
                        username: loggeduser
                    }

                    ws.send(JSON.stringify(socketLikedData));
                };
            }

            setfvrtIds([...fvrtIds, id])
        }

    }


    const blockHandler = async (id) => {
        if (blockedIds.includes(id)) {
            let unblockuserId = {
                user: id
            }
            const response = await blockUsers.mutateAsync(unblockuserId)
            setblockedIds(blockedIds.filter((item) => item != id))
        } else {
            let blockuserId = {
                user: id
            }
            const response = await blockUsers.mutateAsync(blockuserId)
            setblockedIds([...blockedIds, id])
        }

    }

    const tooltip = (

        <Tooltip id="button-tooltip">
            {t('click_to_unblock')}
        </Tooltip>
    );

    const handleLoadMoreClick = () => {
        const newDisplayLimit = loadMore.limit + 8;
        setloadMore({
            ...loadMore,
            limit: newDisplayLimit,
            status: newDisplayLimit < fullData.length,
        });
    };
    const Pokehandler = async (id) => {
        let pokeData = {
            user: id
        }
        const response = await postPoke.mutateAsync(pokeData)

        if (response.status) {
            showToastmsg('success', response?.data?.description)
            const ws = new WebSocket(`${WEB_SOCKET_URL}notification/${id}/`);
            // const ws = new WebSocket(`ws://208.109.189.52:8000/ws/notification/${id}/`);

            ws.onopen = () => {
                let socketLikedData = {
                    message: `${loggeduser} poked you`,
                    username: loggeduser
                }

                ws.send(JSON.stringify(socketLikedData));
            };
        } else {

        }

    }

    return (
        <div>

            <InnerpageHeader pageTitle="Matches"/>
            <section className="inner_page_main_bx_section">
                <div className="container">
                    <div className="inner_page_main_box_view">
                        <div className="inner_page_main_bx_head">

                            <h4>{t('best_matches_for_u')}</h4>
                            <a href="#/" data-bs-toggle="modal" data-bs-target="#filter_pop" className="inner_page_main_bx_filter_btn">
                                {t("preference")} <i className="fa fa-filter"></i>
                            </a>
                        </div>
                        {
                            getMymatches.isLoading ?
                                <MatchesSkeleton type={"matches"} iconsCount={4} />
                                :
                                <div className="best_match_section">
                                    <div className="row">
                                        {
                                            getMymatches?.data?.data?.preferences_by_user_id.length == 0 && (
                                                <center>
                                                    <img src={nodataImg} alt='nodata' className='w-25' />
                                                </center>
                                            )
                                        }
                                        {
                                            (getMymatches?.data && selectedBarIndexe) &&
                                            fullData.slice(0, loadMore.limit).map((data, dataindex) => {

                                                return (
                                                    <div className="col-md-3">

                                                        <div className="profile_match_crsl_box">

                                                            <div className="profile_match_crsl_box_img">

                                                                <div className="profile_btn_row">
                                                                    {/* <a href="#/"><div className="profile_act_btn prf_btn_1">
                                                                <i className="fa fa-refresh"></i>
                                                            </div></a> */}

                                                                    {
                                                                        blockedIds.includes(data.id) ?
                                                                            <OverlayTrigger placement="top" overlay={tooltip}>
                                                                                <a href="#/"><div className=' profile_act_btn prf_btn_2'>
                                                                                    <i class="fa-solid fa-ban" onClick={() => blockHandler(data.id)}></i>


                                                                                </div></a>
                                                                            </OverlayTrigger>

                                                                            : <OverlayTrigger placement="top" overlay={<Tooltip>{t('block')}</Tooltip>}>
                                                                                <a href="#/"><div className="profile_act_btn prf_btn_2">
                                                                                    <i className="fa fa-close" onClick={() => blockHandler(data.id)}></i>
                                                                                </div></a>
                                                                            </OverlayTrigger>


                                                                    }

                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{t('like')}</Tooltip>}
                                                                    >
                                                                        <a href="#/">
                                                                            <div className={`${data.like_status ? 'profile_act_btn prf_btn_3_liked' : 'profile_act_btn prf_btn_3'}`}>
                                                                                <i className="fa fa-heart" onClick={() => likeHandler(data.id, data)}></i>
                                                                            </div>
                                                                        </a>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{t('fvrt')}</Tooltip>}
                                                                    >
                                                                        <a href="#/"><div className={`${data.favorite_status ? `profile_act_btn prf_btn_4_fvrted` : `profile_act_btn prf_btn_4`}`}>
                                                                            <i className="fa fa-star" onClick={() => FvrtHandler(data.id)}></i>
                                                                        </div></a>
                                                                    </OverlayTrigger>

                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip>{t('poke')}</Tooltip>}
                                                                    >
                                                                        <a href="#/"><div className="profile_act_btn prf_btn_5">

                                                                            <i className="fa fa-hand" onClick={() => Pokehandler(data.id)}></i>
                                                                        </div></a>
                                                                    </OverlayTrigger>

                                                                </div>
                                                                <div className="total_image_bar">

                                                                    {
                                                                        data.cover_photos.map((data, coverIndex) => (
                                                                            console.log("photoss=====", data),
                                                                            <div
                                                                                key={coverIndex}
                                                                                // className={coverIndex === selectedBarIndexes[dataindex] ? 'active' : ''}
                                                                                onClick={() => handleBarClick(dataindex, coverIndex)}
                                                                            ></div>
                                                                        ))
                                                                    }

                                                                </div>

                                                                {/* <a href="profile-single.html"> */}
                                                                <Link to={`/singleProfile/${data.username}`}>
                                                                    <div className="profile_image" >
                                                                        {
                                                                            data.cover_photos.length > 1 && (
                                                                                <div className="arrow-left-arrow" onClick={() => handleImageClick(dataindex, 'prev')}
                                                                                >
                                                                                    <i class="fa-solid fa-left-long fa-xl"></i>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        {
                                                                            data.cover_photos.length == 0 ?
                                                                                <img src={defaultProfilepic} />
                                                                                :
                                                                                <>
                                                                                    {data.cover_photos && selectedBarIndexe && data.cover_photos[selectedBarIndexe[dataindex]] && (
                                                                                        <img src={data?.cover_photos[selectedBarIndexe[dataindex]]?.image ? IMG_URL + data.cover_photos[selectedBarIndexe[dataindex]].image : defaultProfilepic} alt="Profile Image" onClick={(e) => e.stopPropagation()} />
                                                                                    )}</>

                                                                        }




                                                                        {
                                                                            data.cover_photos.length > 1 && (
                                                                                <div className="arrow-right-arrow" onClick={() => handleImageClick(dataindex, 'next')}>
                                                                                    <i class="fa-solid fa-right-long fa-xl" ></i>
                                                                                </div>
                                                                            )
                                                                        }

                                                                    </div>
                                                                </Link>
                                                                {/* </a> */}
                                                                <div className="img_btm_overlay"></div>
                                                            </div>

                                                            <Link to={`/singleProfile/${data.username}`}>
                                                                <div className="profile_cnt_bx">
                                                                    {data.username}- {data.age}
                                                                    <span><i className="fa fa-place"></i> {data.distance} {t('km_away')}</span>
                                                                </div>
                                                            </Link>


                                                        </div>

                                                    </div>
                                                )
                                            })
                                        }


                                    </div>
                                    {
                                        loadMore.status && (
                                            <div className="loadmore_btn_section">
                                                <a href="#" className="loadmore_btn" onClick={handleLoadMoreClick}>{t('load_more')}</a>
                                            </div>
                                        )
                                    }





                                </div>
                        }


                    </div>
                </div>
            </section >


            <div className="modal fade" id="filter_pop" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{t('my_preference')}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="filter_top_btn">

                                <div className="filter_top_btn">
                                    {
                                        tab.map((items, index) => {
                                            return (
                                                <a id={items.id} className={selectedTab == items.selectedTab ? "active" : ''} onClick={() => selectTabhandler(items.selectedTab)}>{items.name}</a>
                                            )
                                        })
                                    }


                                </div>


                            </div>
                            {
                                selectedTab === "filter" ?
                                    <div className="filter_view_box1">
                                        {
                                            getMyprefernce?.data && (
                                                <NormalFilter
                                                    t={t}
                                                    filterSubmit={filterSubmithandler}
                                                    Mypreference={getMyprefernce?.data}
                                                />
                                            )
                                        }

                                    </div>
                                    : <div className='filfilter_view_box2'>
                                        <TravelFilter />
                                    </div>
                            }




                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn regsiter_top_btn">Apply</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Matches