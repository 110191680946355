import React from 'react'
import userIcon from '../../assets/images/simple_use_ico.png'
import smartMatch from '../../assets/images/smart_match_ico.png'
import filterIcon from '../../assets/images/filter_ico.png'
import privacyIcon from '../../assets/images/privacy_ico.png'
import textVideochat from '../../assets/images/text-video-chat.jpg'
function Features() {
    return (
        <section className="our_feature_section">
            <div className="container">
                <div className="home_headding_sec">
                    <h2>Our Features</h2>
                </div>
                <div className="our_feature_cnt_sec">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="our_feature_cnt_sec_box">
                                <div className="our_feature_cnt_sec_box_ico">
                                    <img src={userIcon} alt="" />
                                </div>
                                <h5>Simple to use</h5>
                                <p>Lorem ipsum doler site amet</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="our_feature_cnt_sec_box">
                                <div className="our_feature_cnt_sec_box_ico">
                                    <img src={smartMatch} alt="" />
                                </div>
                                <h5>Smart Matching</h5>
                                <p>Lorem ipsum doler site amet</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="our_feature_cnt_sec_box">
                                <div className="our_feature_cnt_sec_box_ico">
                                    <img src={filterIcon} alt="" />
                                </div>
                                <h5>Filter very fast</h5>
                                <p>Lorem ipsum doler site amet</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="our_feature_cnt_sec_box">
                                <div className="our_feature_cnt_sec_box_ico">
                                    <img src={privacyIcon} alt="" />
                                </div>
                                <h5>100% Privacy</h5>
                                <p>Lorem ipsum doler site amet</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text_video_chat_section">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="text_video_chat_section_cnt">
                                <h3>Text, Voice and Video chat</h3>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type
                                    specimen book.
                                </p>
                                <ul>
                                    <li>Text Chat</li>
                                    <li>Video Chat</li>
                                    <li>Voice Chat</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text_video_chat_section_img">
                                <img src={textVideochat} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Features