import API from "../api/api";
import { BASE_URL } from "../config/config";
import axios from "axios";
export const registerServices = {
  registerSubmit: async (data) => {
    const customAxios = axios.create({
      baseURL: BASE_URL,
    });

    // customAxios.defaults.headers.common["api-key"] = DEFAULT_KEY;
    customAxios.defaults.headers.common["access-token"] =
      customAxios.defaults.headers.common["Content-Type"] =
        "multipart/form-data";
    try {
      const response = await customAxios.post("register", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getInterest: async () => {
    try {
      const response = await API.get("get-interests");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  checkUserexist: async (mail) => {
    try {
      // const response = await API.post("check-email-exists", mail);
      const response = await API.post("check-user-exists", mail);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  sendGmailToken: async (data) => {
    try {
      const response = await API.post("social-auth/google-signup/", data);
      console.log("response service === ",response);

      if(response.status){
        return response;
      }else{
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  sendFacebookData: async (data) => {
    try {
      const response = await API.post("social-auth/facebook-signup/", data);
      console.log("response service === ",response);

      if(response.status){
        return response;
      }else{
        return response;
      }
    } catch (error) {
      return error;
    }
  },
};

