import React, { useEffect } from 'react'
import { ProfileApis } from '../../../hooks/apihooks/profileApis'
import { useDispatch } from 'react-redux'
import { UseSelector, useSelector } from 'react-redux/es/hooks/useSelector'
import { useState } from 'react'
import { RingLoader } from 'react-spinners'
import { useQueryClient } from "@tanstack/react-query";
import { ToastContainer, toast } from 'react-toastify';
import { showToastmsg } from '../../../common/ToastMsg'
import DatePickerComponent from '../../../common/DatePicker'
import moment from 'moment'

function PersonalDetails({ personalDetails, t }) {

    const { data, isError, isLoading } = ProfileApis.GetProfileData()
    const mutateEditpersonaldetails = ProfileApis.EditpersonalDetails()
    const queryClient = useQueryClient();
    let PersonalDetails = useSelector((state) => state.profile.profileData)
    const [updateBtnStatus, setupdateBtnStatus] = useState(false)

    const [fields, setfields] = useState({
        first_name: personalDetails?.data?.user?.first_name ?? '',
        last_name: personalDetails?.data?.user?.last_name ?? '',
        orientation: personalDetails?.data?.user?.orientation ?? '',
        // email: '',
        phone_number: personalDetails?.data?.user?.phone_number ?? '',
        username: personalDetails?.data?.user?.username ?? '',
        password: personalDetails?.data?.user?.password ?? '',
        date_of_birth: personalDetails?.data?.user?.date_of_birth ?? '',
        gender: personalDetails?.data?.user?.gender ?? ''
    })

    useEffect(() => {
        setfields({
            ...fields,
            first_name: personalDetails?.data?.user?.first_name,
            last_name: personalDetails?.data?.user?.last_name,
            phone_number: personalDetails?.data?.user?.phone_number,
            username: personalDetails?.data?.user?.username,
            date_of_birth: personalDetails?.data?.user?.date_of_birth,
            gender: personalDetails?.data?.user?.gender


        })
    }, [personalDetails])

    const [formError, setformError] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        username: '',
        password: '',
        date_of_birth: '',
        gender: '',
        orientation: ''
    })

    const [Isedit, setIsedit] = useState(false)


    // useEffect(() => {
    //     setfields({
    //         ...fields,
    //         first_name: data?.data?.user?.first_name,
    //         last_name: data?.data?.user?.last_name,
    //         // email: data?.data?.user?.email,
    //         phone_number: data?.data?.user?.phone_number,
    //         username: data?.data?.user?.username,
    //         // password: data?.data?.user?.password,
    //         date_of_birth: data?.data?.user.date_of_birth,
    //         gender: data?.data?.user?.gender
    //     })
    // }, [data])


    const changeHandler = async (e) => {
        const { name, value } = e.target
        setfields({
            ...fields,
            [name]: value
        })
        // validateFormHandler(name,value)
    }

    const validateEmail = (mail) => {
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(mail);
        } catch (error) {
            return false
        }
    }
    const validatePhoneno = (phone) => {
        try {
            const PhoneRegex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
            return PhoneRegex.test(phone);
        } catch (error) {
            return false
        }
    }

    const validateDate = (date) => {

        try {
            let dateRegexp = /^(?!0000)[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
            const birthDate = new Date(date);
            const currentDate = new Date();
            const minAgeDate = new Date(currentDate);
            minAgeDate.setFullYear(currentDate.getFullYear() - 18);
            if (!dateRegexp.test(date)) {
                return {
                    status: false,
                    message: t('invalid_date_format')
                }
            }
            else if (birthDate > minAgeDate) {
                return {
                    status: false,
                    message: t('must_be_atleast_18_years_old')
                }
            }
            return {
                status: true,
                message: ''
            }
        } catch (error) {
            return {
                status: false,
                message: 'Error..'
            }
        }
    }

    const blurHandler = async (e) => {
        const { name, value } = e.target
        await validateFormHandler(name, value)

    }
    const validateFormHandler = async (field, value) => {

        let errorMessage = '';


        switch (field) {
            case 'first_name':
                errorMessage = value.trim() === '' ?
                    t('first_name_cannot_be_empty')

                    : '';

                break;
            case 'last_name':
                errorMessage = value.trim() === '' ? t('last_name_cannot_be_empty') : '';
                break;
            case 'email':
                errorMessage = value.trim() === '' ? t('email_cannot_be_empty') : '';
                errorMessage = !validateEmail(value) ? t('invalid_mail') : ''
                break;

            case 'phone_number':
                errorMessage = value.trim() === '' ? t('phone_number_cannot_be_empty') : '';
                errorMessage = !validatePhoneno(value) ? t('invalid_phone') : ''
                break;
            case 'username':
                errorMessage = value.trim() === '' ? t('username_cannot_be_empty') : '';

                break;
            case 'password':
                errorMessage = value.trim() === '' ? t('psw_cannot_be_empty') : '';

                break;
            case 'date_of_birth':
                const dateIsvalid = validateDate(value)
                errorMessage = value.trim() === '' ? t('date_of_birth_cannot_be_empty') : '';
                errorMessage = dateIsvalid.status ? '' : dateIsvalid.message


                break;
            case 'gender':
                errorMessage = value.trim() === '' ? t('please_selet_your_gender') : '';

                break;
            case 'orientation':
                errorMessage = value.trim() === '' ? t('please_select_your_orientation') : '';

                break;

            default:
                errorMessage = '';
        }

        setformError({
            ...formError,
            [field]: errorMessage,
        });

    };

    const updateHandler = async () => {
        try {
            const isFormValid = Object.values(formError).every((error) => error === '');

            if (isFormValid) {

                const response = await mutateEditpersonaldetails.mutateAsync(fields, "json");
                if (response.status) {
                    showToastmsg('success', t('personal_details_updated'));
                    queryClient.invalidateQueries({ queryKey: ["profileData"] });
                    setIsedit(false)
                    setupdateBtnStatus(false)
                    setTimeout(() => {
                        toast.dismiss()
                    }, 5000)


                } else {
                    showToastmsg('error', t('personal_details_updation_failed'));
                    setTimeout(() => {
                        toast.dismiss()
                    }, 5000)


                }
            }
        } catch (error) {
            showToastmsg('error', t('personal_details_updation_failed'));
            setTimeout(() => {
                toast.dismiss()
            }, 5000)

        }
    }
    const editHandler = () => {
        setIsedit(!Isedit)
        setupdateBtnStatus(!updateBtnStatus)
        queryClient.invalidateQueries({ queryKey: ["profileData"] });

    }

    const handleDatechange = (value) => {
        const selectedDate = value.toDate();
        const formatedDate = moment(selectedDate).format('YYYY-MM-DD')
        setformError({
            ...formError,
            date_of_birth: ""
        })
        setfields({
            ...fields,
            date_of_birth: formatedDate
        })
        const dateIsvalid = validateDate(formatedDate)

        if (!dateIsvalid.status) {
            setformError({
                ...formError,
                date_of_birth: dateIsvalid?.message
            })
        }

    }

    return (
        <>

            <h5>{t('personal_details')}</h5>

            <div className="row">
                <div className="col-md-6 mt-4">
                    <label htmlFor="">{t('first_name')}</label>
                    <input type="text" className={`${formError.first_name ? 'erorInput ' : ''}form-control`} name='first_name' placeholder="Name"
                        value={fields.first_name} onChange={changeHandler} disabled={!Isedit ? true : false}
                        onBlur={blurHandler} />
                    {formError?.first_name && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.first_name}</p>
                    )}

                </div>
                <div className="col-md-6 mt-4">
                    <label htmlFor="">{t('last_name')}</label>
                    <input type="text" className={`${formError.last_name ? 'erorInput ' : ''}form-control`} name='last_name' placeholder="Name"
                        value={fields.last_name} onBlur={blurHandler} onChange={changeHandler} disabled={!Isedit ? true : false} />
                    {formError?.last_name && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.last_name}</p>
                    )}
                </div>
            </div>
            <div className="row">
                {/* <div className="col-md-6 mt-4">
                    <label htmlFor="">Email</label>
                    <input type="text" name="email" className={`${formError.email ? 'erorInput ' : ''}form-control`} placeholder="Mail"
                        value={fields.email} onChange={changeHandler} onBlur={blurHandler} disabled={!Isedit ? true : false} />
                    {formError?.email && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.email}</p>
                    )}
                </div> */}
                <div className="col-md-6 mt-4">
                    <label htmlFor="">{t("username")}</label>
                    <input type="text" className={`${formError.username ? 'erorInput ' : ''}form-control`} name="username" placeholder="username"
                        value={fields.username} onChange={changeHandler} onBlur={blurHandler} disabled={!Isedit ? true : false} />
                    {formError?.username && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.username}</p>
                    )}
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="">{t("phone")}</label>
                    <input type="text" name="phone_number" className={`${formError.phone_number ? 'erorInput ' : ''}form-control`}
                        placeholder="Phone" value={fields.phone_number} onChange={changeHandler} onBlur={blurHandler} disabled={!Isedit ? true : false} />
                    {formError?.phone_number && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.phone_number}</p>
                    )}

                </div>

            </div>
            <div className="row">
                {/* <div className="col-md-6 mt-4">
                    <label htmlFor="">Username</label>
                    <input type="text" className={`${formError.username ? 'erorInput ' : ''}form-control`} name="username" placeholder="username"
                        value={fields.username} onChange={changeHandler} onBlur={blurHandler} disabled={!Isedit ? true : false} />
                    {formError?.username && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.username}</p>
                    )}
                </div> */}
                {/* <div className="col-md-6 mt-4">
                    <label htmlFor="">Password</label>
                    <input type="password" name="password" className={`${formError.password ? 'erorInput ' : ''}form-control`}
                        placeholder="*****" value={fields.password} onChange={changeHandler} onBlur={blurHandler} disabled={!Isedit ? true : false} />
                    {formError?.password && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.password}</p>
                    )}
                </div>  */}
            </div>
            <div className="row">
                <div className="col-md-6 mt-4">
                    <label htmlFor="">{t('date_of_birth')}</label>
                    <div className='date-picker'>
                        <DatePickerComponent
                            className={`${formError.date_of_birth ? 'erorInput ' : ''}form-control`
                            }
                            handleDatechange={handleDatechange}
                            value={fields.date_of_birth}
                            disabled={!Isedit ? true : false}
                        />
                    </div>

                    {/* <input type="date" name="date_of_birth" className={`${formError.date_of_birth ? 'erorInput ' : ''}form-control`} placeholder=""
                        value={fields.date_of_birth} onChange={changeHandler} onBlur={blurHandler} disabled={!Isedit ? true : false} /> */}
                    {formError?.date_of_birth && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.date_of_birth}</p>
                    )}
                </div>

                <div className="col-md-6 mt-4">
                    <label htmlFor="" >{t('gender')}</label>
                    <select value={fields.gender} className={`${formError.gender ? 'erorInput ' : ''}form-select`} name="gender" onBlur={blurHandler} onChange={changeHandler} disabled={!fields.gender ? false : true}>

                        <option value="">{t('select')}</option>
                        <option value="M">{t('male')}</option>
                        <option value="F">{t('female')}</option>
                        <option value="TM">{t('transgender_male')}</option>
                        <option value="TF">{t('transgender_female')}</option>
                    </select>
                    {
                        formError.gender && (
                            <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.gender}</p>
                        )
                    }

                </div>

            </div>
            <div className="row">
                <div className="col-md-6 mt-4">
                    <label htmlFor="">{t('orientation')}</label>
                    <select className={`${formError.orientation ? 'erorInput ' : ''}form-select`}
                        aria-label="Default select example"
                        value={fields.orientation}
                        name='orientation'
                        onBlur={blurHandler}
                        onChange={changeHandler}
                        disabled={!fields.orientation?false:true}
                    >
                        <option value="">Select</option>
                        <option value="Hetero">{t('hetero_sexual')}</option>
                        <option value="Homo">{t('home')}</option>
                        <option value="Pan">{t('pan_sexual')}</option>
                        <option value="Bi">{t('bi_sexual')}</option>
                    </select>
                </div>

                {
                    formError.orientation && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{formError.orientation}</p>
                    )
                }
            </div>
            <button className="myaccount_profile_upgrad_btn" onClick={editHandler}>{Isedit ? t('cancel') : t('edit')}</button>
            {
                updateBtnStatus &&
                <button className="myaccount_profile_upgrad_btn" onClick={updateHandler}>{t("update")}</button>
            }







        </>

    )
}

export default PersonalDetails