import React from "react";
import InnerpageHeader from "../../common/InnerpageHeader";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { showToastmsg } from "../../common/ToastMsg";
import Header from "./Header";
import Footer from "../../components/Footer";
import MailConfig from "./MailConfig";
import { settingsApis } from "../../hooks/apihooks/settingsApis";
import CompanyDetails from "./CompanyDetails";
import CompanyPolicy from "./CompanyPolicy";
import TabContentSkeleton from "../../components/skeletons/TabContentSkeleton";
import CompanyDetailsSkeleton from "../../components/skeletons/settings/CompanyDetailsSkeleton";
import Backbtn from "../../common/Backbtn";
import { useTranslation } from "react-i18next";


function Settings() {
  const [selectedTab, setselectedTab] = useState("mail");
  const location = useLocation();
  const {t}=useTranslation()
  const settingsMenus = [
    {
      path: "/settings/mail",
      icon: "fa-regular fa-envelope",
      tab: t("mail_content"),
      selectedTab: "mail",
    },
    {
      path: "/settings/company-details",
      icon: "fa-regular fa-building",
      tab: t('cmpny_dtails'),
      selectedTab: "comapny-details",
    },
    {
      path: "/settings/company-policy",
      icon: "fa-regular fa-file",
      tab: t('cmpny_policy'),
      selectedTab: "comapny-policy",
    },
  ];
  const companyDetails = settingsApis.GetCompanyDetails();
  const companyPolicy = settingsApis.GetCompanyPolicy();
  const mailConentResult = settingsApis.GetMailContent(selectedTab);
  useEffect(() => {
    if (location.pathname == "/settings") {
      setselectedTab("mail");
    } else {
      const pathSegments = location.pathname.split("/");
      const lastPathSegment = pathSegments[pathSegments.length - 1];
      setselectedTab(lastPathSegment);
    }
  }, [location.pathname]);

  return (
    <>

      <InnerpageHeader pageTitle="settings" />
      <section className="inner_page_main_bx_section account_page">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-4">
              <div className="inner_page_main_box_view">
                <div className="myaccount_profile_section">
                  <div className="myaccount_profile_box">
                   
                    <div className="myaccount_profile_left_navigation">
                      <div className="admin_settings_header">
                        <h4>
                          <i class="fa-solid fa-gear"></i> {t('settings')}
                        </h4>
                      </div>
                      <ul>
                        {settingsMenus.map((items, index) => {
                          return (
                            <li
                              className={
                                selectedTab == items.selectedTab ? "active" : ""
                              }
                              key={index}
                            >
                              <NavLink
                                to={items.path}
                                className={({ isActive, isPending }) =>
                                  isPending
                                    ? "pending"
                                    : isActive
                                      ? "active"
                                      : ""
                                }
                              >
                                <div className="prfl_nav_ico">
                                  <i className={items.icon}></i>
                                </div>
                                <span>{t(items.tab)}</span>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-8">
              <div className="my_account_right_sec">
                {selectedTab === "mail" && (
                  <MailConfig t={t}/>
                )}

                {selectedTab === "company-details" &&
                  companyDetails?.data?.data && (
                    companyDetails?.isLoading ?
                      <CompanyDetailsSkeleton />
                      :
                      <CompanyDetails data={companyDetails?.data?.data} t={t} />
                  )}

                {selectedTab === "company-policy" && (
                  companyPolicy.isLoading ? (
                    <TabContentSkeleton tabCount={2} />
                  ) : (
                    companyPolicy?.data?.data && <CompanyPolicy data={companyPolicy.data.data} t={t} />
                  )
                )}

              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}

export default Settings;
