import React, { useEffect } from 'react'
import userImg1 from '../../../assets/images/users/user_imag_1.jpg'
import ReactStars from "react-rating-stars-component";
import { useState } from 'react';
import { BASE,IMG_URL } from '../../../config/config';
import { MatchApis } from '../../../hooks/apihooks/matchApis';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { showToastmsg } from '../../../common/ToastMsg';


function Photos({ coverPhotos,t }) {  
    const {user_name}=useParams();
    const queryClient=useQueryClient()
    const postRating=MatchApis.CoverpicsRating()
    const ratingChanged = async(newRating,coverPicid) => {
        let data={
            username:user_name,
            cover_photo:coverPicid,
            rate_count:newRating
        }
        const response=await postRating.mutateAsync(data)
        if(response.status){
            queryClient.invalidateQueries({queryKey:["get-singlepagedata"]})
        }else{
            showToastmsg("error",t('something_went_wrong')) 
        }
    };

  
    return (
        <div className="prfile_page_info_sec">
            <div className="prfile_page_info_head">{t('photos')}</div>
            <div className="row">
                { coverPhotos.cover_photos.length>0?
                    coverPhotos.cover_photos.map((items, index) => {
                        return (
                            <div className="col-md-3">
                                <div className="prfile_page_photo">

                                    <div id="full-stars-example-two">
                                        <img src={IMG_URL+items.image} />
                                        <div className="rating-group">

                                            <ReactStars
                                                count={5}
                                                onChange={(new_rating)=>ratingChanged(new_rating,items.id)}
                                                size={30}
                                                value={items.rating}
                                                // isHalf={true}
                                                emptyIcon={<i className="rating__icon rating__icon--star fa fa-star"></i>}
                                                // halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa-solid fa-star"></i>}
                                                activeColor="orange"
                                            />,

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    <p style={{textAlign:'center',color:'grey'}}>{t('sry_no_photos_availiable')}</p>
                }


            
            </div>
        </div >
    )
}

export default Photos