import API from "../api/api"

export const locationService={
    postLocation:async(data)=>{
        try {
          const response=await API.post('update-my-location',data)
          if(response.status){
            return response
          }else{
            return response
          }  
        } catch (error) {
           return error
        }
    },
    

}