import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    profileData:{},
    personalDetails:{
        firstName:'',
        lastName:'',
        email:'',
        phone:'',
        username:'',
        pswd:'',
        dob:'',
        gender:''
    }
  }
  export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {  
      setProfiledata: (state, action) => {
        state.profileData = action.payload
      },
      setPersonalDetails:(state,action)=>{
        state.personalDetails={...state.personalDetails,...action.payload}
      }
      
    },
  })

  export const { setProfiledata,setPersonalDetails} = profileSlice.actions

export default profileSlice.reducer