import { useState, useEffect } from "react";
import React from "react";
import Paypal from "../Paypal/Paypal";
import { GetProductsApi, GetClientIdApi } from "../../hooks/apihooks/paypal";
import packageImg from '../../assets/images/package-gold.png'
import PackageSkeleton from '../skeletons/PackageSkeleton'
export default function UpgradePlan({t}) {
  const dummyData = [
    {
      id: 1,
      heading: "Heading1",
      feature: ["feature1", "feature2", "feature3"],
      amount: 100,
      duration: "month",
    },
  ];
  const Products = GetProductsApi.GetProducts();
  const [product, setProduct] = useState({
    price: '',
    product_id: ''
  });
  const [productDetails, setProductDetails] = useState(dummyData);
  const [payment, setPayment] = useState(false);
  const [clientId, setClientId] = useState(false);
  const PaypalClientId = GetClientIdApi.GetClientId();
  useEffect(() => {
    setClientId(PaypalClientId?.data?.data);
  }, [PaypalClientId]);
  // useEffect(() => {
  //   if (Products.status) {
  //     setProductDetails(Products?.data?.data);
  //   }
  // }, [Products]); TO DO (AFTER THE API IS DONE)
  const HandlePayment = (products) => {
    console.log("====products=====", products)
    if (products) {
      setProduct({
        ...product,
        price: products.price,
        product_id: products.id
      })
    }

    setPayment(true);

  };
  console.log("====prdctsss===", product)
  const HandleCancel = () => {
    setPayment(false);
    setProduct(null);
  };

  return (
    <>
      <div className="upgrade_package">
        {
          <div>
            <section className="inner_page_main_bx_section upgrade_page">
              <div className="container">
                {!payment && productDetails && (
                  <>
                    <div className="upgrade_page_head">
                      <h2>{t('pricing_plan')}</h2>
                      <p>{t('upgrade_account')}</p>
                    </div>
                    {
                      Products.isLoading ?
                        <PackageSkeleton />
                        :
                        <div className="plan-options">
                          {Products?.data?.data?.map((item) => {
                            return (
                              <div className="plan">
                                <div className="plan-image">
                                  <img src={item.package_img ? item.package_img : packageImg} />
                                </div>
                                <div className="plan-header">
                                  <h3 className="plan-heading">{item.name}</h3>
                                  {/* <button className="plan-save"> Save $40</button> */}
                                </div>
                                {/* <ul className="plan-feature">
                              {item.feature.map((feature) => {
                                return <li>{feature}</li>;
                              })}
                            </ul> */}
                                <p>
                                  $
                                  <span className="plan-amount">{item.price}</span>{" "}
                                  <span className="plan-duration">{`/${item.validity} ${t("month")}`}</span>
                                </p>
                                <button
                                  className="plan-choose"
                                  onClick={() => {
                                    HandlePayment(item);
                                  }}
                                >
                                  {t('purchase')}
                                </button>
                              </div>
                            );
                          })}{" "}
                        </div>
                    }

                  </>
                )}
              </div>
            </section>
          </div>
        }
        {payment && clientId && (
          <div className="payment_now_section">
            <div className="container">
              <Paypal clientId={clientId} productDetails={product} />
              <button
                type="button"
                className="btn regsiter_top_btn"

                onClick={() => HandleCancel()}
              >
                {t("cancel")}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
