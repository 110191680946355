import React from "react";
import UpgradePlan from "../../components/Upgrade.jsx/UpgradePlan";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import InnerpageHeader from "../../common/InnerpageHeader";
import { useTranslation } from "react-i18next";

export default function Upgrade() {
  const { t } = useTranslation()
  return (
    <>

      <InnerpageHeader pageTitle='upgrade_account' />
      <UpgradePlan t={t} />

    </>
  );
}
