import API from "../api/api"

export const NotificationServices={
    getNotications:async()=>{
        try {
          const response=await API.get('user-notifications')
          if(response.status){
            return response
          }else{
            return response
          }  
        } catch (error) {
           return error
        }
    },
    SeenNotification:async()=>{
        try {
          const response=await API.patch('user-notifications')
          if(response.status){
            return response
          }else{
            return response
          }  
        } catch (error) {
           return error
        }
    },
    ClearallNotification:async()=>{
        try {
            const response=await API.delete('user-notifications')
            if(response.status){
              return response
            }else{
              return response
            }  
          } catch (error) {
             return error
          }
    }
}