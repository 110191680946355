import API from "../api/api"

export const MatchServices = {
  filtersubmit: async (data) => {
    try {
      const response = await API.put('update-profile-preference', data)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  getPreferenceOptions: async (type) => {
    try {
      const response = await API.get('get-preferences?type=' + type)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  getmyPreference: async () => {
    try {
      const response = await API.get('get-my-preferences')
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  getmyMatches: async () => {
    try {
      const response = await API.get('get-profile-matches')
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  postLikedUsersIds: async (id) => {
    try {
      const response = await API.post('follow/user/like-dislike-profile',id)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  postFavouritedUsersIds: async (id) => {
    try {
      const response = await API.post('follow/user/add-remove-favorite',id)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  blockusers:async(id)=>{
    try {
      const response = await API.post('follow/user/block-unblock-user',id)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  getSinglePagedata:async(id)=>{
    try {
      const response = await API.get(`get-profile-data?username=${id}`)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  postRatingdata:async(data)=>{
    try {
      const response = await API.post('follow/user/rate-user-cover-photo',data)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  },
  pokeSending:async(data)=>{
    try {
      const response = await API.post('follow/user/poke-user',data)
      if (response.status) {
        return response
      } else {
        return response
      }
    } catch (error) {
      return error
    }
  }
}