import React from 'react';
// import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
// import './index.css';
import '../src/assets/css/custom.css';
import '../src/assets/css/responsive.css'
import { ToastContainer, toast } from 'react-toastify';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './i18n';
import { WebSocketProvider } from './hooks/socketContext/WebSocketContext';
// const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()
ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="354036188238-f5uh703c8dvpgjo6g17160o217u9gq6l.apps.googleusercontent.com">
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          {/* Uncomment the following line if you want to use WebSocketProvider */}
          {/* <WebSocketProvider> */}
          <ToastContainer />
          <App />
          {/* </WebSocketProvider> */}
        </QueryClientProvider>
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
