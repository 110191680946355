import React from 'react'
import { useSelector } from 'react-redux'
import { BASE,IMG_URL } from '../../../config/config'
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useState } from 'react'
import { ProfileApis } from '../../../hooks/apihooks/profileApis'
import { useQueryClient } from "@tanstack/react-query";
import { showToastmsg } from '../../../common/ToastMsg'
import {toast} from 'react-toastify';
import nodataIMg from '../../../assets/images/nodata.jpg'


function PersonalPhotos({t}) {
    const profile_data = useSelector((state) => state.profile.profileData)
    const [modalShow, setmodalShow] = useState(false)
    const [selectedPic, setselectedPic] = useState(null)
    const [selectedFilename, setselectedFilename] = useState("")
    const [uploadedPics, setuploadedPics] = useState([])
    const mutateCoverphotos = ProfileApis.Uploadcoverphoto()
    const mutateDltcoverphoto = ProfileApis.Dltcoverphoto()
    const queryClient = useQueryClient();
    const AddPichandler = () => {
        setmodalShow(true)
    }

    const handleClose = () => {
        setmodalShow(false)
    }

    const photoSelecthandler = (e) => {
        setselectedPic(e.target.files[0])
        setselectedFilename(e.target.files[0].name)
    }


    const uploadHandler = async () => {
        try {

            let formData = new FormData();
            formData.append("cover_photos", selectedPic)
            const response = await mutateCoverphotos.mutateAsync(formData)
            if (response.status) {
                queryClient.invalidateQueries({ queryKey: ["profileData"] });
                setmodalShow(false)
                setselectedFilename('')
                setselectedPic(null)
                setTimeout(() => {
                    toast.dismiss()
                }, 5000)

            } else {
                setmodalShow(false)
                showToastmsg('error', t('file_upload_failed'))
                setTimeout(() => {
                    toast.dismiss()
                }, 5000)
            }
        } catch (error) {
            console.log(error)
        }

    }

    const deleteHandler = async (id) => {
        try {
            const data = {
                image_id: id
            }
            const response = await mutateDltcoverphoto.mutateAsync(data)
            if (response.status) {
                showToastmsg('success', t('img_dlt_successfully'));
                queryClient.invalidateQueries({ queryKey: ["profileData"] });
                setTimeout(() => {
                    toast.dismiss()
                }, 5000)

            } else {
                showToastmsg('error', t('something_went_wrong'));
                setTimeout(() => {
                    toast.dismiss()
                }, 5000)
            }
        } catch (error) {

        }

    }

    return (
        <div>
           
            <h5>{t('photos')}</h5>
            <div className="upload_photo_sec">
                <div className="register_login_box_cnt_subhead">
                    {t('uplod_photo')}
                    <button
                        // data-bs-toggle="modal"
                        // data-bs-target="#uploadphoto"
                        className="add_new_photo"
                        onClick={AddPichandler}
                    >{t('add_new')}</button>
                </div>
                {
                    profile_data?.cover_photos && (
                        <div className="upload_photo_list">
                            {
                                profile_data?.cover_photos.length === 0 && (
                                    <center><img src={nodataIMg} className='w-50' /></center>
                                )
                            }
                            <div className="row">
                                {
                                    profile_data && (
                                        profile_data?.cover_photos?.map((items, index) => {
                                            return (
                                                <div className="col-md-3">
                                                    <div className="upload_photo_box">
                                                        <button className="dlete_phot" onClick={() => deleteHandler(items.id)}><i className="fa fa-close"></i></button>
                                                        <img src={IMG_URL + items.image} alt="" />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                }


                            </div>
                        </div>
                    )
                }

            </div>


            <Modal
                show={modalShow}
                onHide={handleClose}
            >
                <ModalHeader closeButton>
                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                </ModalHeader>
                <ModalBody>
                    <form className="form-container" encType='multipart/form-data'>
                        <div className="upload-files-container">
                            <div className="drag-file-area">
                                <span className="material-icons-outlined upload-icon">{t('file_upload')}</span>
                                {/* <h3 className="dynamic-message">Drag & drop any file here</h3> */}
                                <label className="label"> or
                                    <span className="browse-files">
                                        <input type="file" className="default-file-input"
                                            onChange={photoSelecthandler}
                                        />
                                        <span className="browse-files-text">{t('browse_file')}</span>
                                        <span>{t('from_device')}</span>
                                    </span><br />
                                    {
                                        selectedFilename && (
                                            <span className='selectedFile'>
                                                {selectedFilename}
                                            </span>
                                        )
                                    }

                                </label>
                            </div>
                            <span className="cannot-upload-message"> <span className="material-icons-outlined">{t('error')}</span> {t('pls_select_a_file_first')}<span className="material-icons-outlined cancel-alert-button">{t('cancel')}</span> </span>
                            <div className="file-block">
                                <div className="file-info"> <span className="material-icons-outlined file-icon">{t('description')}</span> <span className="file-name"> </span> | <span className="file-size"> </span> </div>
                                <span className="material-icons remove-file-icon">{t('dlt')}</span>
                                <div className="progress-bar"> </div>
                            </div>

                            <button type="button" className="upload-button"

                                onClick={
                                    () => uploadHandler(selectedPic)}
                            >{t('upload')}</button>



                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default PersonalPhotos