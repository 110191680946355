import React from 'react'
import Header from '../../Header'
import Footer from '../../Footer'
import InnerpageHeader from '../../../common/InnerpageHeader'
import resetpSWD from "../../../assets/images/resetPswd.jpg";
import { useForm } from "react-hook-form"
import { ForgotpaswdApis } from '../../../hooks/apihooks/forgotPswdApis';
import { showToastmsg } from '../../../common/ToastMsg';
import { useState } from 'react';
import { useSelector } from 'react-redux'
import AdminHeader from '../../../../src/views/Admin/Header';
import UserHeader from '../../Header';
import { useTranslation } from 'react-i18next';

function Changepassword() {
    const { t } = useTranslation();
    const { isAdmin } = useSelector((state) => state.login)
    const postChangepswdApis = ForgotpaswdApis.Changepassword()
    const { register, handleSubmit, formState: { errors }, reset, trigger, watch,
    } = useForm({
        defaultValues: {
            password: '',
            new_password1: '',
            new_password2: ''
        }
    })
    const [loader, setloader] = useState(false)
    const sendData = async (data) => {
        try {
            setloader(true)
            const isValid = await trigger()
            if (isValid) {
                const response = await postChangepswdApis.mutateAsync(data)
                if (response.status) {
                    setloader(false)
                    showToastmsg("success", t('data_sent_successfully'))
                    reset({
                        password: '',
                        new_password1: '',
                        new_password2: ''
                    })
                } else {
                    setloader(false)
                    showToastmsg("error", t('data_sent_failed'))
                }
            }
        } catch (error) {

        }
    }
    const formValues = watch()
    return (
        <div>
          
            <InnerpageHeader pageTitle="change_pswd"/>
            <section className="inner_page_main_bx_section">
                <div className="container">
                    <div className="inner_page_main_box_view">
                        <div className="inner_page_main_bx_head">
                            {/* <h4></h4>
                            <a href="#/" className="inner_page_main_bx_filter_btn">
                                <i className="fa fa-add"></i> Add new
                            </a> */}
                        </div>

                        <div className="best_match_section">
                            <div className="row">
                                <div className='col-lg-6'>
                                    <div className="register_login_box_head">
                                        {t('pswd_reset')}
                                        <p>{t('nte_ur_new_pswd_and_snd')}</p>
                                    </div>

                                    <div className='mt-2'>
                                        <label htmlFor="">{t('current_pswd')}</label>
                                        <input type="password" className={`${errors.password ? 'erorInput ' : ''}form-control mb-2`}
                                            {...register("password",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: "feild is required*"
                                                    },
                                                    onChange: async (e) => {
                                                        await trigger(e.target.name)
                                                    }
                                                })} />
                                        {errors.password && (
                                            <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.password?.message}</p>
                                        )}

                                        <label htmlFor="">{t('new_pswd')}</label>
                                        <input type="password" className={`${errors.new_password1 ? 'erorInput ' : ''}form-control mb-2`}
                                            {...register("new_password1",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: "feild is required*"
                                                    },
                                                    onChange: async (e) => {
                                                        await trigger(e.target.name)
                                                    }
                                                })} />
                                        {errors.new_password1 && (
                                            <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.new_password1?.message}</p>
                                        )}
                                        <label htmlFor="">{t('confirm_pswd')}</label>
                                        <input type="password" className={`${errors.new_password2 ? 'erorInput ' : ''}form-control`}
                                            {...register("new_password2",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: "feild is required*"
                                                    },
                                                    validate: (value) => {
                                                        if (value !== formValues.new_password1) {
                                                            return "Password is not matching";
                                                        }
                                                        return true;
                                                    },
                                                    onChange: async (e) => {
                                                        await trigger(e.target.name)
                                                    }
                                                })} />
                                        {errors.new_password2 && (
                                            <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.new_password2?.message}</p>
                                        )}
                                        <button
                                            className="reg_login_sub_btn mt-3"
                                            onClick={handleSubmit(sendData)}
                                            disabled={loader}
                                        >
                                            {
                                                loader ?
                                                    <span>{t('sending')}.&nbsp;<i class="fa-solid fa-spinner fa-spin-pulse"></i></span>
                                                    : t('send')
                                            }

                                        </button>
                                    </div>


                                </div>

                                <div className='col-lg-6'> <img src={resetpSWD} className='w-75' /></div>




                            </div>


                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Changepassword