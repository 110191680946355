import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    locationAllowed:false,
    locationData:{
        latitude:'',
        longitude:''
    }
    
  }

  export const locationSlice=createSlice({
    name:'location',
    initialState,
    reducers:{
        setLocationAccess:(state,action)=>{        
            state.locationAllowed=action.payload
        },
        setLocationdata:(state,action)=>{        
          state.locationData=action.payload
      },
    }
  })

  export const {setLocationAccess,setLocationdata} = locationSlice.actions
  export default locationSlice.reducer
  