import { useMutation } from "@tanstack/react-query"
import { useQuery } from "@tanstack/react-query"
import { getCategories,uploadKyc,getMykyc,Dlthandler,getUploadedkyc,UpdateKycstatus } from "../../actions/Kyc/kyc"
import { useDispatch,useSelector } from "react-redux"
import { setPagination } from "../../store/reducers/paginationReducer"

export const kycApis={
    Getcategories:()=>{
        
        const response = useQuery({
            queryKey:["get-categories"],
            queryFn: ()=> getCategories(),
          
        })
        return response
    },
    Kycupload:()=>{
        const response = useMutation({
            mutationKey:["upload-kyc"],
            mutationFn: (data)=> uploadKyc(data)
        })
        return response
    },
    Getmykycs:()=>{
        const response = useQuery({
            queryKey:["get-mykyc"],
            queryFn: ()=> getMykyc(),
            // enabled:username?true:false
        })
        return response
    },
    Dltkyc:()=>{
        const response = useMutation({
            mutationKey:["dlt-mykyc"],
            mutationFn: (data)=> Dlthandler(data)
        })
        return response
    },
    GetUploadedKycs:(rowsperpage,page,searchedUsername,status)=>{
        const dispatch=useDispatch()
        const {currentPage,totalPages,RowsperPage}=useSelector((state)=>state.pagination.pagination)
        const response = useQuery({
            queryKey:["get-uploaded-kycs",rowsperpage,page,searchedUsername,status],
            queryFn: ()=> getUploadedkyc(rowsperpage,page,searchedUsername,status),
            onSuccess:(response)=>{
                const totalPages = Math.ceil(response?.data?.kyc_count /RowsperPage)
                dispatch(setPagination({totalPages:Number(totalPages)}))
                
            }
        })
        return response
    },
    UpdateKycstatus:()=>{
        const response = useMutation({
            mutationKey:["update-kyc-status"],
            mutationFn: (data)=> UpdateKycstatus(data)
        })
        return response
    }
}