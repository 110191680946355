
import React from 'react'
import userImg from '../../assets/images/users/user_imag_1.jpg'
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
function Matches() {

    const responsiveSettings = {
        0: {
            items: '1',
        },
        900: {
            items: '3',
        },
        1200: {
            items: '3',
        },
        1400: {
            items: '5',
        },
    };
    let idx;
    function coverFlowEfx(e) {
        console.log("e======================", e)
        if ($('.owl-dots')) {
            $('.owl-dots').remove();
        }
        idx = e.item.index;
        $('.owl-item.big').removeClass('big');
        $('.owl-item.medium').removeClass('medium');
        $('.owl-item.mdright').removeClass('mdright');
        $('.owl-item.mdleft').removeClass('mdleft');
        $('.owl-item.smallRight').removeClass('smallRight');
        $('.owl-item.smallLeft').removeClass('smallLeft');
        $('.owl-item').eq(idx - 1).addClass('medium mdleft');
        $('.owl-item').eq(idx).addClass('big');
        $('.owl-item').eq(idx + 1).addClass('medium mdright');
        $('.owl-item').eq(idx + 2).addClass('smallRight');
        $('.owl-item').eq(idx - 2).addClass('smallLeft');

    }
    var click = false


    return (
        <div>


            <section className="best_matches_section">
                <div className="container">
                    <div id="carrousel">
                        <div className="container">
                            <div className="home_headding_sec">
                                <h2>Best Matches</h2>
                                <p>For your preference</p>
                            </div>
                            <OwlCarousel className='owl-theme'
                                center={true}
                                loop={true}
                                items={5}
                                nav={true}
                                responsive={responsiveSettings}
                                navText={['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>']}
                                onInitialized={coverFlowEfx}
                                onTranslate={coverFlowEfx}
                            >




                                <div className="item">
                                    <div className="profile_match_crsl_box">
                                        <div className="profile_match_crsl_box_img">
                                            <div className="profile_btn_row">
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_1">
                                                        <i className="fa fa-refresh"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_2">
                                                        <i className="fa fa-close"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_3">
                                                        <i className="fa fa-heart"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_4">
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_5">
                                                        <i className="fa fa-hand"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="total_image_bar">
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <img src={userImg} alt="" />
                                            <div className="img_btm_overlay"></div>
                                        </div>
                                        <div className="profile_cnt_bx">
                                            Emma - 26
                                            <span><i className="fa fa-place"></i> 3 Km Away</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="profile_match_crsl_box">
                                        <div className="profile_match_crsl_box_img">
                                            <div className="profile_btn_row">
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_1">
                                                        <i className="fa fa-refresh"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_2">
                                                        <i className="fa fa-close"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_3">
                                                        <i className="fa fa-heart"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_4">
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_5">
                                                        <i className="fa fa-hand"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="total_image_bar">
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <img src={userImg} alt="" />
                                            <div className="img_btm_overlay"></div>
                                        </div>
                                        <div className="profile_cnt_bx">
                                            Emma - 26
                                            <span><i className="fa fa-place"></i> 3 Km Away</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="profile_match_crsl_box">
                                        <div className="profile_match_crsl_box_img">
                                            <div className="profile_btn_row">
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_1">
                                                        <i className="fa fa-refresh"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_2">
                                                        <i className="fa fa-close"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_3">
                                                        <i className="fa fa-heart"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_4">
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_5">
                                                        <i className="fa fa-hand"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="total_image_bar">
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <img src={userImg} alt="" />
                                            <div className="img_btm_overlay"></div>
                                        </div>
                                        <div className="profile_cnt_bx">
                                            Emma - 26
                                            <span><i className="fa fa-place"></i> 3 Km Away</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="profile_match_crsl_box">
                                        <div className="profile_match_crsl_box_img">
                                            <div className="profile_btn_row">
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_1">
                                                        <i className="fa fa-refresh"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_2">
                                                        <i className="fa fa-close"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_3">
                                                        <i className="fa fa-heart"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_4">
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_5">
                                                        <i className="fa fa-hand"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="total_image_bar">
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <img src={userImg} alt="" />
                                            <div className="img_btm_overlay"></div>
                                        </div>
                                        <div className="profile_cnt_bx">
                                            Emma - 26
                                            <span><i className="fa fa-place"></i> 3 Km Away</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="profile_match_crsl_box">
                                        <div className="profile_match_crsl_box_img">
                                            <div className="profile_btn_row">
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_1">
                                                        <i className="fa fa-refresh"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_2">
                                                        <i className="fa fa-close"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_3">
                                                        <i className="fa fa-heart"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_4">
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_5">
                                                        <i className="fa fa-hand"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="total_image_bar">
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <img src={userImg} alt="" />
                                            <div className="img_btm_overlay"></div>
                                        </div>
                                        <div className="profile_cnt_bx">
                                            Emma - 26
                                            <span><i className="fa fa-place"></i> 3 Km Away</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="profile_match_crsl_box">
                                        <div className="profile_match_crsl_box_img">
                                            <div className="profile_btn_row">
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_1">
                                                        <i className="fa fa-refresh"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_2">
                                                        <i className="fa fa-close"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_3">
                                                        <i className="fa fa-heart"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_4">
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_5">
                                                        <i className="fa fa-hand"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="total_image_bar">
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <img src={userImg} alt="" />
                                            <div className="img_btm_overlay"></div>
                                        </div>
                                        <div className="profile_cnt_bx">
                                            Emma - 26
                                            <span><i className="fa fa-place"></i> 3 Km Away</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="profile_match_crsl_box">
                                        <div className="profile_match_crsl_box_img">
                                            <div className="profile_btn_row">
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_1">
                                                        <i className="fa fa-refresh"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_2">
                                                        <i className="fa fa-close"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_3">
                                                        <i className="fa fa-heart"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_4">
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_5">
                                                        <i className="fa fa-hand"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="total_image_bar">
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <img src={userImg} alt="" />
                                            <div className="img_btm_overlay"></div>
                                        </div>
                                        <div className="profile_cnt_bx">
                                            Emma - 26
                                            <span><i className="fa fa-place"></i> 3 Km Away</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="profile_match_crsl_box">
                                        <div className="profile_match_crsl_box_img">
                                            <div className="profile_btn_row">
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_1">
                                                        <i className="fa fa-refresh"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_2">
                                                        <i className="fa fa-close"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_3">
                                                        <i className="fa fa-heart"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_4">
                                                        <i className="fa fa-star"></i>
                                                    </div>
                                                </a>
                                                <a href="#/">
                                                    <div className="profile_act_btn prf_btn_5">
                                                        <i className="fa fa-hand"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="total_image_bar">
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <img src={userImg} alt="" />
                                            <div className="img_btm_overlay"></div>
                                        </div>
                                        <div className="profile_cnt_bx">
                                            Emma - 26
                                            <span><i className="fa fa-place"></i> 3 Km Away</span>
                                        </div>
                                    </div>
                                </div>



                            </OwlCarousel>



                        </div>
                    </div>
                </div>
            </section >

        </div >
    )
}

export default Matches
