import { settingsServices } from "../../services/settingsServices";

export const updateMailContent = async (data) => {
  try {
    const response = await settingsServices.updateMailContent(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getMailContent = async (type) => {
  try {
    const response = await settingsServices.getMailContent(type);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyDetails = async () => {
  try {
    const response = await settingsServices.getCompanyDetails();
    return response;
  } catch (error) {
    return error;
  }
};
export const postCompanyDetails = async (data) => {
  try {
    const response = await settingsServices.postCompanyDetails(data);
    return response;
  } catch (error) {
    return error;
  }
};

export const getCompanyPolicy = async () => {
  try {
    const response = await settingsServices.getCompanyPolicy();
    return response;
  } catch (error) {
    return error;
  }
};
export const postCompanyPolicy = async (data) => {
  try {
    const response = await settingsServices.postCompanyPolicy(data);
    return response;
  } catch (error) {
    return error;
  }
};
