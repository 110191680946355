import API from "../api/api";

export const contactusService={
    contactusPost:async(data)=>{
        try {
          const response=await API.post('contact-us',data)
          if(response.status){
            return response
          }else{
            return response
          }  
        } catch (error) {
           return error
        }
    },
    getCompanyDetails:async()=>{
      try {
        const response=await API.get('get-company-details')
        if(response.status){
          return response
        }else{
          return response
        }  
      } catch (error) {
         return error
      }
    }
    

}