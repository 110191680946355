import { useQuery, useMutation } from "@tanstack/react-query";
import {
  LoginHandler,
  getLoginuserdata,
  Logout,
} from "../../actions/Login/userLogin";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setLoggeduserId, setLoggeduserdetails, setIsadmin } from "../../store/reducers/authReducer"
export const LoginApis = {
  Login: () => {
    const dispatch = useDispatch()
    const response = useMutation({
      mutationFn: (data) => LoginHandler(data),
      onSuccess: (response) => {
        dispatch(setIsadmin(response?.data?.is_admin))
        localStorage.setItem("userType",response?.data?.is_admin?'admin':'user')
      }
    });
    return response;
  },
  LoginUserdata: () => {
    const { isLogged } = useSelector((state) => state.login)
    const dispatch = useDispatch()
    const response = useQuery({
      queryKey: ["get-logindata"],
      queryFn: () => getLoginuserdata(),
      enabled: isLogged,
      onSuccess: (data) => {
        dispatch(setLoggeduserId(data?.data?.username))
        dispatch(setLoggeduserdetails(data?.data))

      }
    })
    return response
  },
  Logout: () => {
    const response = useMutation({
      mutationFn: () => Logout(),
    });
    return response;
  },
};
