import { loginServices } from "../../services/loginServices"


export const LoginHandler = async (data) => {
    try {
        const response = await loginServices.login(data)
        return response
    } catch (error) {
        return error
    }
}
export const getLoginuserdata = async () => {
    try {
        const response = await loginServices.getLoginuserdata()
        return response
    } catch (error) {
        return error
    }
}
export const Logout= async()=>{
    try {
        const response = await loginServices.logout()
        return response
    } catch (error) {
        return error
    }
}