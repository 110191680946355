import { NotificationServices } from "../../services/notificationService"
export const getNotification = async () => {
    try {
        const response = await NotificationServices.getNotications()
        return response
    } catch (error) {
        return error
    }
}

export const SeenNotification = async () => {
    try {
        const response = await NotificationServices.SeenNotification()
        return response
    } catch (error) {
        return error
    }
}

export const ClearallNotification = async () => {
    try {
        const response = await NotificationServices.ClearallNotification()
        return response
    } catch (error) {
        return error
    }
}