import API from "../api/api";

export const loginServices = {
  login: async (data) => {
    try {
      const response = await API.post("login", data);
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  logout: async () => {
    try {
      const response = API.put("logout");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
  getLoginuserdata: async () => {
    try {
      const response = await API.get("get-login-user-data");
      if (response.status) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  },
};
