import React from 'react'
import { useForm } from "react-hook-form"
function ChangePassword() {

  const { register, handleSubmit, formState: { errors }, reset, trigger, watch,
  } = useForm({
    defaultValues: {
      password: '',
      new_password1: '',
      new_password2: ''
    }
  })
  return (
    <div>
      <h5>Change Password</h5>

      <div className="row">
        <div className="col-md-6 mt-4">
          <label htmlFor="">Password</label>
          <input type="text" className={`${errors.password ? 'erorInput ' : ''}form-control`}
            {...register("password",
              {
                required: {
                  value: true,
                  message: "feild is required*"
                },
                onChange: async (e) => {
                  await trigger(e.target.name)
                }
              })} />
          {errors.password && (
            <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.password?.message}</p>
          )}
        </div>
        <div className="col-md-6 mt-4">
          <label htmlFor="">New Password</label>
          <input type="" className={`${errors.password ? 'erorInput ' : ''}form-control`}
            {...register("new_password1",
              {
                required: {
                  value: true,
                  message: "feild is required*"
                },
                onChange: async (e) => {
                  await trigger(e.target.name)
                }
              })} />
          {errors.new_password1 && (
            <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.new_password1?.message}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default ChangePassword