import React from 'react'
import { Form } from 'react-bootstrap'
import { useForm } from "react-hook-form"
import { kycApis } from '../../../hooks/apihooks/kycApis'
import { showToastmsg } from '../../../common/ToastMsg'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
function Kycupload({ category,t }) {
  
    const queryClient=useQueryClient();
    const kycupload=kycApis.Kycupload();
    const { register, handleSubmit, formState: { errors }, reset, trigger, watch,setValue
    } = useForm({
        defaultValues: {
            category: '',
            image: ''

        }
    })


    const onSubmit = async (data) => {
       
        const isValid = await trigger()
        if (isValid) {
            
            const formdata=new FormData()
            formdata.append("document",data.image[0])
            formdata.append("type",data.category)
            const response=await kycupload.mutateAsync(formdata)
            if(response.status){
                showToastmsg("success",t("file_uploaded_successfully"))
                queryClient.invalidateQueries({queryKey:["get-mykyc"]})
                reset({
                    image: "",
                    category:""
                  })
            }else{
                showToastmsg("error",t("fail_uploaded_failed")) 
            }
        }
    }

    return (
        <div className='row'>
            <div className='col-lg-4'>
                <Form.Label>{t('category')}</Form.Label>
                <Form.Select aria-label="Default select example"
                    className={`${errors.category ? 'erorInput ' : ''}form-control`}
                    {...register("category",
                        {
                            required: {
                                value: true,
                                message: t("select_a_category")
                            },


                            onChange: async (e) => {
                                await trigger(e.target.name)
                            }
                        })}>
                    <option value="">{t('select_category')}</option>
                    {
                        category && category?.data?.map((items, index) => (

                            <option value={items.id}>{t(items.name)}</option>


                        ))
                    }

                </Form.Select>
                {errors.category && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.category?.message}</p>
                    )}
            </div>
            <div className='col-lg-7'>
                <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>{t('kyc_document')}</Form.Label>
                    <Form.Control type="file" className={`${errors.image ? 'erorInput ' : ''}form-control`}
                      {...register("image", {
                        required: {
                          value: true,
                          
                          message: t("choose_a_file")
                        },
                        
                    
                        onChange:async(e)=>{
                            await trigger(e.target.name)
                        }

                      })} 
                        />
                    {errors.image && (
                        <p role="alert" className='formError'><i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>&nbsp;{errors?.image?.message}</p>
                    )}
                </Form.Group>
            </div>
            <div className='col-lg-1'>
                <button className="myaccount_profile_upgrad_btn mt-4" onClick={handleSubmit(onSubmit)}>{t('upload')}</button>
            </div>

        </div>
    )
}

export default Kycupload