import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { getNotification, SeenNotification, ClearallNotification } from "../../actions/Notification/Notification";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setNotificationCount, setNotiseenStatus } from "../../store/reducers/notificationReducer";
export const NotificationApis = {
    GetNotification: () => {
        const { isLogged } = useSelector((state) => state.login)
        const dispatch = useDispatch()
        const response = useQuery({
            queryKey: ["get-notifications"],
            queryFn: () => getNotification(),
            enabled: isLogged,
            onSuccess: (data) => {
                dispatch(setNotificationCount(data?.data?.unseen_notifications_count))
                dispatch(setNotiseenStatus(data?.data?.seen_status))
            }
        })
        return response
    },
    SeenNotifications: () => {
        const response = useMutation({
            mutationKey: ["seen-notification"],
            mutationFn: () => SeenNotification()
        })
        return response
    },
    ClearAllNotifications: () => {
        const response = useMutation({
            mutationKey: ["clear-notification"],
            mutationFn: () => ClearallNotification()
        })
        return response
    }

}
