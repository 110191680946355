import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducer'
import { combineReducers } from '@reduxjs/toolkit';
import profileReducer from './reducers/profileReducer';
import forgotPswdReducer from './reducers/forgotPswdReducer';
import resetPswdReducer from './reducers/resetPswdReducer';
import matchReducer from './reducers/matchReducer';
import notificationReducer from './reducers/notificationReducer';
import chatReducer from './reducers/chatReducer';
import locationReducer from './reducers/locationReducer';
import travelReducer from './reducers/travelReducer';
import testimonialReducer from './reducers/testimonialReducer';
import paginationReducer from './reducers/paginationReducer';
import languageReducer from './reducers/languageReducer';
const rootReducer = combineReducers({
  login: authReducer,
  profile: profileReducer,
  forgotPswd: forgotPswdReducer,
  resetPswd: resetPswdReducer,
  match: matchReducer,
  notification:notificationReducer,
  chat:chatReducer,
  location:locationReducer,
  travel:travelReducer,
  testimoni:testimonialReducer,
  pagination:paginationReducer,
  languages:languageReducer

});

export const store = configureStore({
  reducer: rootReducer
})