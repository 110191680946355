import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   language:{}
  }
  export const languageSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {  
      setSelectedLanguage: (state, action) => {
        state.language = action.payload
      },
      
      
    },
  })

  export const { setSelectedLanguage} = languageSlice.actions

export default languageSlice.reducer