import React, { useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Profile from '../views/Profile/Profile'
import { useEffect } from 'react'
import { GetUserlocation } from '../common/GetuserLocation'
import { setLocationAccess, setLocationdata } from '../store/reducers/locationReducer'
import { useDispatch } from 'react-redux'
import { locationApis } from '../hooks/apihooks/locationApis'
import { useSelector } from 'react-redux'
import AdminProfile from '../views/Profile/AdminProfile'
import { Outlet } from "react-router";
import { languageApis } from '../hooks/apihooks/languageApis'


function MainLayout() {
    const dispatch = useDispatch()
    const { isAdmin } = useSelector((state) => state.login)
    const userType = localStorage.getItem("userType")
    const userLOcationDetails = locationApis.PostLocation()
    const getLanguage = languageApis.GetLanguages()

    useEffect(() => {
        userLocation()

    }, [])


    const userLocation = async () => {
        await GetUserlocation().then((position) => {
            dispatch(setLocationAccess(true))
            dispatch(setLocationdata({ latitude: position.coords.latitude, longitude: position.coords.longitude }))
            const response = userLOcationDetails.mutateAsync({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        }).catch(err => {
            dispatch(setLocationAccess(false))
            console.log("==err==", err)
        })
    }

    return (
        <div>
            <Header languages={getLanguage?.data} />
            <Outlet />
            <Footer />
        </div>
    )
}

export default MainLayout