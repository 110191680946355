import { Hearts, Bars, ThreeDots } from "react-loader-spinner";

import React from "react";

export default function Loader({ type, H, W }) {
  let loader;
  const color = "#179ad7";
  let height = H ?? "150";
  let width = W ?? "150";
  switch (type) {
    case "heart":
      loader = (
        <Hearts
          height={height}
          width={width}
          color={color}
          ariaLabel="hearts-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      );
      break;
    case "bars":
      loader = (
        <Bars
          height={height}
          width={width}
          color={color}
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      );
      break;
    case "dots":
      loader = (
        <ThreeDots
          height={height}
          width={width}
          radius="15"
          color={color}
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      );
      break;
    default:
      loader = (
        <Hearts
          height={height}
          width={width}
          color={color}
          ariaLabel="hearts-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loader}
    </div>
  );
}
