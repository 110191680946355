import React from 'react'
import InnerpageHeader from '../../common/InnerpageHeader'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import girlPic from '../../assets/images/about-image.png'
import { Features, TestimonialSection } from '../../components/homepage'
import CompanyMission from '../../components/Company/CompanyMission'
function Aboutcmpany() {
    return (
        <div>
          
            <InnerpageHeader pageTitle="Who we are" />
            {/* l */}
            <section className="inner_page_main_bx_section about_page_cnt">

                <div className="inner_page_cnt_option_two">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6">
                                <div className="earnpoint_box about_box">
                                    <h3>
                                        <span>About Company</span>
                                        Who we are
                                    </h3>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    </p>
                                    <div className="earnpoint_box_option">
                                        <ul>
                                            <li>
                                                Lorem ipusm doler
                                            </li>
                                            <li>
                                                Lorem ipusm doler
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 text-center">
                                <div className="earnpoint_img">
                                    <img src={girlPic} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Features />
                <TestimonialSection />
                <CompanyMission />
            </section>
            
        </div>
    )
}

export default Aboutcmpany