import React from 'react'
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { testimonialApis } from '../../hooks/apihooks/testimonialApis';
function TestimonialSection() {
    const responsiveSettings = {
        0: {
            items: 1,
        },
        900: {
            items: 2,
        }
    };
    const getTestimonialdata = testimonialApis.GetTestimonialdata()
    console.log("getTestimonialdata", getTestimonialdata?.data?.data)
    return (
        <section className="testimonial_section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <div className="testimonial_section_left_head">
                            <h5>
                                <span>Testimonials</span>
                                Success stories
                            </h5>
                        </div>
                    </div>
                    {
                         getTestimonialdata?.data?.data?.data&&
                         <div class="col-md-8">
                        <div id="testimonial">
                            <OwlCarousel className='owl-theme'
                                loop={true}
                                margin={30}
                                items={2}
                                nav={false}
                                dots={true}
                                responsive={responsiveSettings}
                            >
                                {
                                   
                                    getTestimonialdata?.data?.data?.data?.map((items, index) => {
                                        return (
                                            <div className="item">
                                                <div className="testimonial_section_box">
                                                    <h5>
                                                        {items.user}
                                                        <span>{items.location??'location'}</span>
                                                    </h5>
                                                    <p>
                                                        {items.description}
                                                    </p>
                                                </div>


                                            </div>
                                        )
                                    })
                                }

                              
                            </OwlCarousel>
                        </div>

                    </div>
                    }
                    


                </div>
            </div>
        </section>
    )
}

export default TestimonialSection