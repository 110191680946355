import React, { useEffect } from 'react'
import FormControlLabel from '@mui/material/FormControlLabel';
import Pagination from '@mui/material/Pagination';
import Checkbox from '@mui/material/Checkbox';
import { testimonialApis } from '../../hooks/apihooks/testimonialApis';
import { IMG_URL } from '../../config/config';
import defaultProfilepic from '../../assets/images/user-blue-gradient.png'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setPagination } from '../../store/reducers/testimonialReducer';
import { showToastmsg } from '../../common/ToastMsg';
import { Modal } from 'react-bootstrap';
import TableSkeleton from '../skeletons/TableSkeleton';
import nodataImg from "../../assets/images/nodata.jpg";

function TestimonialApproval({t}) {
    const { currentPage, totalPages, RowsperPage } = useSelector((state) => state.testimoni.pagination)
    const getpendingTestimonial = testimonialApis.GetPendingtestimonialData(RowsperPage, currentPage)
    const [showPopup, setshowPopup] = useState(false)
    const [checkedRows, setCheckedRows] = useState([]);
    const updateTestimonialstatus = testimonialApis.UpdateTsetimonialdata()
    const dispatch = useDispatch()
    const [headerCheckboxIndeterminate, setHeaderCheckboxIndeterminate] = useState(false);
    const [modal, setmodal] = useState({
        show: false,
        data: ''
    })


    const handleHeaderCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        const allRows = getpendingTestimonial?.data?.data?.data;
        console.log("isChecked,headerCheckboxIndeterminate", isChecked, headerCheckboxIndeterminate)
        if (isChecked && !headerCheckboxIndeterminate) {

            setCheckedRows(allRows.map((item) => item.id));
            setshowPopup(true)
        } else if (isChecked && headerCheckboxIndeterminate) {
            setCheckedRows([]);
        } else {
            setCheckedRows([]);


        }

        setHeaderCheckboxIndeterminate(false);
    };
   
    const handleRowCheckboxChange = (event, id) => {
        const isChecked = event.target.checked;

        setCheckedRows((prevCheckedRows) => {
            if (isChecked) {
                setshowPopup(true)
                setHeaderCheckboxIndeterminate(true)
                return [...prevCheckedRows, id];

            } else {
                return prevCheckedRows.filter((rowId) => rowId !== id);
            }
        });

    };

    const handleChange = (event, value) => {
        console.log(`Selected Page: ${value}`);
        dispatch(setPagination({ currentPage: value }))
    };
    const rowPerpagehandler = (e) => {
        const totalPages = Math.ceil(getpendingTestimonial?.data?.data?.count / e.target.value)
        dispatch(setPagination({ totalPages: totalPages, RowsperPage: e.target.value }))
    }

    const updateStatus = async (action) => {
        try {
            const response = await updateTestimonialstatus.mutateAsync({ status: action, testimonial_id: checkedRows })
            if (response.status) {
                setCheckedRows([])
                setHeaderCheckboxIndeterminate(false)
                setshowPopup(false)
                getpendingTestimonial.refetch({ queryKey: ["get-pending-data", RowsperPage,] })
                showToastmsg("success", "Status updated susccessfully")
            } else {
                showToastmsg("error", "Failed")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleViewAllClick = (data) => {
      
        setmodal({
            ...modal,
            show: true,
            data: data.description
        })
    }
    const handleClose = () => {
        setmodal({
            ...modal,
            show: false
        })
    }
    return (
        <div>
            <div className="my_account_right_head">
                {t('testimonial_approve')}
            </div>

            <div className="my_account_right_box">
                
                   
                    <>
                        <table>
                            <thead>
                                <th>
                                    <FormControlLabel

                                        control={
                                            <Checkbox
                                                checked={checkedRows.length > 0 && checkedRows.length === getpendingTestimonial?.data?.data?.data?.length}
                                                indeterminate={headerCheckboxIndeterminate}
                                                onChange={handleHeaderCheckboxChange}
                                            />
                                        }
                                    />
                                </th>
                                <th>{t('username')}</th>
                                <th>{t('testimonial_data')}</th>
                                <th>{t('location')}</th>
                                <th>{t('status')}</th>

                            </thead>
                            {
                                getpendingTestimonial.isLoading ?
                                    <TableSkeleton colCount={5} rowCount={4} />
                                    :
                                    <tbody>
                                        {
                                            getpendingTestimonial?.data?.data?.data.length>0?
                                            getpendingTestimonial?.data?.data?.data?.map((items, index) => {
                                               
                                                return (
                                                    <tr>
                                                        <td>
                                                            <FormControlLabel

                                                                control={
                                                                    <Checkbox
                                                                        checked={checkedRows.includes(items.id)}
                                                                        onChange={(event) => handleRowCheckboxChange(event, items.id)}


                                                                    />
                                                                }
                                                            />
                                                        </td>

                                                        <td>
                                                            <img className='testimonial-profilePic' src={items.profile_picture ? IMG_URL + items.profile_picture : defaultProfilepic} />
                                                            {" "}{items.user}
                                                        </td>
                                                        <td>
                                                            {items.description.length > 50 ? (
                                                                <>
                                                                    {items.description.substring(0, 50)}...
                                                                    <a href="#"
                                                                        onClick={() => handleViewAllClick(items)}
                                                                    >{t('view_all')}</a>
                                                                </>
                                                            ) : (
                                                                items.description
                                                            )}
                                                        </td>
                                                        <td>{items.location ? items.location : 'NA'}</td>
                                                        <td style={{ color: items.status === 1 ? 'green' : items.status === 2 ? '#d32f2f' : '' }}>{items.status === 0 ? 'Pending' : items.status === 1 ? 'Approved' : 'Rejected'}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                       <tr>
                                        <td colSpan={4}>
                                            <center><img src={nodataImg} className='w-50'/></center>
                                            
                                        </td>
                                       </tr>
                                        }
                                        

                                    </tbody>
                            }



                        </table>
                        <div className="row table_ftr_pagination">
                            <div className="col-md-4">
                                {t('rows_per_page')} : <select onChange={rowPerpagehandler} value={RowsperPage}>
                                    <option>5</option>
                                    <option>10</option>
                                    <option>20</option>
                                </select>
                            </div>
                            <div className="col-md-8">
                                <Pagination count={totalPages} page={currentPage} color="primary" onChange={handleChange} />
                            </div>
                        </div>

                    </>

                


                {
                    (checkedRows.length > 0 && showPopup) > 0 && (
                        <div className="popupButtonArea">
                            <ul>
                                <li>
                                    <span

                                    >

                                    </span>
                                </li>
                                <li>
                                    <span className="CountRound">
                                        {checkedRows.length}
                                    </span>
                                    <span className="spanText">
                                       {t('items_selected')}
                                    </span>


                                </li>
                                <li>
                                    <button variant="danger" className="CancelButton"
                                        onClick={() => updateStatus(1)}
                                    >
                                        {t("approve")}
                                    </button>
                                    <button variant="danger" className="CancelButton"
                                        onClick={() => updateStatus(2)}
                                    >
                                        {t("reject")}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    )
                }

            </div>

            <Modal show={modal.show} onHide={handleClose} centered>
                <Modal.Header closeButton />
                <Modal.Body>
                    <div style={{ fontFamily: 'auto' }}>
                        {modal.data}
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default TestimonialApproval