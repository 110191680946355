import { registerServices } from "../../services/registerServices";

export const getINtersts = async () => {

    try {
        const response = await registerServices.getInterest()
        return response
    } catch (error) {
        return error
    }
}
export const checkUserexist = async (data) => {

    try {
        const response = await registerServices.checkUserexist(data)
        return response
    } catch (error) {
        return error
    }
}
export const sendGmailToken = async(data)=>{
    try {
        const response = await registerServices.sendGmailToken(data)
        return response
    } catch (error) {
        return error
    }
}
export const sendFacebookData = async(data)=>{
    try {
        const response = await registerServices.sendFacebookData(data)
        return response
    } catch (error) {
        return error
    }
}
