import React from 'react'
import PersonalDetails from './PersonalDetails'
import PersonalPhotos from './PersonalPhotos'
import Interests from './Interests'
import About from './About'
import Settings from './Settings'
import { useEffect, useState } from 'react'

function Myaccount({ profileData, t }) {
    const [AboutContent, setAboutContent] = useState('')
    useEffect(() => {
        const aboutCntent = profileData?.data?.about_me
        if (aboutCntent) {
            const cleanedText = aboutCntent?.substring(1, aboutCntent?.length - 1);
            setAboutContent(cleanedText)
        } else {
            setAboutContent('<p><br></p>')
        }

    }, [profileData.data])
    const userType = localStorage.getItem("userType")

    return (
        <>
            <div className="my_account_right_head">
                {t('my_account')}
            </div>
            <div className="my_account_right_box">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-personal" type="button" role="tab" aria-controls="nav-home" aria-selected="true">{t('personal_details')}</button>

                        {
                            userType === "user" &&
                            <>

                                <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-photos" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">{t('photos')}</button>
                                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-intrest" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">{t('interest')}</button>
                                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-about" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">{t('about')}</button>
                                <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-settings" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">{t("settings")}</button></>

                        }


                    </div>
                </nav>
                <div className="tab-content p-3 border" id="nav-tabContent">
                    <div className="tab-pane fade active show" id="nav-personal" role="tabpanel" aria-labelledby="nav-home-tab">
                        <PersonalDetails
                            personalDetails={profileData}
                            t={t}
                        />
                    </div>

                    <div className="tab-pane fade" id="nav-photos" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <PersonalPhotos t={t} />
                    </div>
                    <div className="tab-pane fade" id="nav-intrest" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <Interests t={t} />
                    </div>

                    <div className="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-contact-tab">
                        {
                            AboutContent &&
                            <About
                                aboutData={AboutContent}
                                t={t}
                            />
                        }



                    </div>
                    <div className="tab-pane fade" id="nav-settings" role="tabpanel" aria-labelledby="nav-contact-tab">
                        {
                            profileData && (
                                <Settings
                                    settingsDatas={profileData}
                                    packageExpired={profileData?.data?.package_expired}
                                    t={t} />
                            )
                        }

                    </div>
                </div>
            </div>
        </>

    )
}

export default Myaccount