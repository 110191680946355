import React from 'react'
import companyLogo from '../assets/images/logo.png';
import fb from '../assets/images/facebook.svg';
import twitter from '../assets/images/twitter.svg';
import insta from '../assets/images/instagram.svg';
import telegram from '../assets/images/telgram.svg';
import linkedin from '../assets/images/linkedin.svg';
import appStore from '../assets/images/appstore.png';
import googlePlay from '../assets/images/google_play.png'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
function Footer() {
    const { isLogged } = useSelector((state) => state.login);
    return (
        <footer className="main_footer_section">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="footer_Box">
                            <div className="footer_Box_head">
                                <img src={companyLogo} alt="" />
                            </div>
                            <div className="footer_Box_cnt">
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text....
                                </p>
                            </div>
                            <div className="footer_Box_social_sec">
                                <a href="#">
                                    <div className="footer_Box_social_sec_ico">
                                        <img src={fb} alt="" />
                                    </div>
                                </a>
                                <a href="#">
                                    <div className="footer_Box_social_sec_ico">
                                        <img src={twitter} alt="" />
                                    </div>
                                </a>
                                <a href="#">
                                    <div className="footer_Box_social_sec_ico">
                                        <img src={linkedin} alt="" />
                                    </div>
                                </a>
                                <a href="#">
                                    <div className="footer_Box_social_sec_ico">
                                        <img src={insta} alt="" />
                                    </div>
                                </a>
                                <a href="#">
                                    <div className="footer_Box_social_sec_ico">
                                        <img src={telegram}alt="" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="footer_Box">
                            <div className="footer_Box_head">COMPANY</div>
                            <div className="footer_Box_cnt">
                                <ul>
                                    <li>
                                        <Link to={`${isLogged?'/profile':'/'}`}>Home</Link>
                                        </li>
                                    <li><Link to="/about-company">Who we are</Link></li>
                                    <li><Link to="/contact-us">Contact us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div className="footer_Box">
                            <div className="footer_Box_head">HELP CENTER</div>
                            <div className="footer_Box_cnt">
                                <ul>
                                    {/* <li><a href="/help">Help Center</a></li> */}
                                    <li><Link to="/faq">FAQ</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="footer_Box">
                            <div className="footer_Box_head">LEGAL</div>
                            <div className="footer_Box_cnt">
                                <ul>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li><Link to="/privacy-policy">Terms and conditions</Link></li>
                                    <li><Link to="/privacy-policy">Cookie Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_copyright_setion">
                <div className="container">
                    <div className="footer_copyright_cnt">
                        <div className="footer_copyright_setion_download_btn">
                            <a href="#"><img style={{ width: '200px' }} src={googlePlay}alt="" /></a>
                            <a href="#"><img src={appStore} alt="" /></a>
                        </div>
                        <span>
                            © 2023 Company Name, All Rights Reserved
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer