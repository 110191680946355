import { useMutation } from "@tanstack/react-query"
import { registerTomlm } from "../../actions/mlm/registerTomlm"
import { checkSponsorExist } from "../../actions/Profile/getProfile"
export const mlmApis={
    RegisterTomlm:()=>{
        const response = useMutation({
            mutationKey:["regIn-mlm"],
            mutationFn: (data)=> registerTomlm(data)
        })
        return response
    },
    ChecksponsorExist:()=>{
      console.log("===mutatated")
        const response = useMutation({
          mutationKey: ["check-sponsor-exist"],
          mutationFn: (data) => checkSponsorExist(data),
        });
        return response;
      }
}