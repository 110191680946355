import { createSlice } from '@reduxjs/toolkit'


const initialState = {
    pagination: {
        totalPages: 0,
        currentPage: 1,
        RowsperPage: 5
    }


}

export const TestimonialSlice = createSlice({
    name: 'testimoni',
    initialState,
    reducers: {
        setPagination: (state, action) => {
            state.pagination = {
                ...state.pagination,
                ...action.payload
            };
        },


    }
})

export const { setPagination } = TestimonialSlice.actions
export default TestimonialSlice.reducer
