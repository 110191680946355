import AuthLayout from "../layouts/AuthLayout"
import Login from "../views/Auth/Login"
import Register from "../views/Auth/Register"
import RegisterForm from "../components/register/RegisterForm"
import MainLayout from "../layouts/MainLayout"
import { Navigate } from 'react-router-dom';
import Matches from "../views/Matches/Matches"
import SingleProfile from "../views/Matches/SingleProfile"
import Aboutcmpany from "../views/Company/Aboutcmpany"
import Contactus from "../views/Company/Contactus"
import Help from "../views/HelpCenter/Help"
import Faq from "../views/HelpCenter/Faq"
import PrivacypPolicy from "../views/PrivacyPolicy/PrivacypPolicy"
import Download from "../views/Download/Download"
import EarnPoint from "../views/EarnPoint/EarnPoint"
import ForgotPassword from "../views/Auth/ForgotPassword"
import ResetPassword from "../views/Auth/ResetPassword"
import Chat from "../components/profile/Messages/Chat"
import Myaccount from "../components/profile/Myaccount/Myaccount"
import Favourite from "../components/profile/Favourite/Favourite"
import Liked from "../components/profile/Liked/Liked"
import WhofavouritedU from "../components/profile/WhofavtedU/WhofavouritedU"
import WholikedU from "../components/profile/WholikedU/WholikedU"
import Notification from "../components/profile/Myaccount/Notification"
import Travel from "../components/profile/Travel/Travel"
import TravelList from "../views/Travel/Travel"
import Mytrips from "../components/profile/Travel/Mytrips"
import Testimonial from "../components/profile/Testimonial/Testimonial"
import Upgrade from "../views/Upgrade/Upgrade"
import TwoFactorAuthentication from "../views/Auth/TwoFactorAuthentication"
import UserReports from "../components/adminprofile/UserReports"
import AdminProfile from "../views/Profile/AdminProfile"
import AdminLayout from "../layouts/AdminLayout"
import Packages from "../components/admin/Packages"
import TestimonialApproval from "../components/adminprofile/TestimonialApproval"
import RequestedTrips from "../components/profile/Travel/RequestedTrips"
import BlockedusersList from "../components/profile/Blockedusers/BlockedusersList"
import Kyc from "../components/profile/Kyc/Kyc"
import KycApproval from "../components/adminprofile/KycApproval";
import Settings from "../views/Admin/Settings"
import MailConfig from "../views/Admin/MailConfig"
import CompanyDetails from "../views/Admin/CompanyDetails"
import Changepassword from "../components/profile/Changepassword/Changepassword"
import Profile from "../views/Profile/Profile"
import HomePage from "../views/Home/HomePage"

const publicRoutes =
    [
        {
            path: "/",
            element: <HomePage/>,
        },
       
        {
            path: '/login',
            element: <Login />
        },
        {
            path: 'two-factor-authentication',
            element: <TwoFactorAuthentication />
        },
        {
            path: '/register',
            element: <Register />
        },
        {
            path: '/register-form',
            element: <RegisterForm />
        },
        {
            path: '/about-company',
            element: <Aboutcmpany />
        },
        {
            path: '/contact-us',
            element: <Contactus />
        },
        {
            path: '/help',
            element: <Help />
        },
        {
            path: '/faq',
            element: <Faq />
        },
        {
            path: '/privacy-policy',
            element: <PrivacypPolicy />
        },
        {
            path: '/download',
            element: <Download />
        },
        {
            path: '/earn-point',
            element: <EarnPoint />
        },
        {
            path: '/logout',
            element: <Navigate to='/login' replace />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        },
        {
            path: '/reset-password/:uidb/:token',
            element: <ResetPassword />
        },



    ]

const privateRoutes = [
    {
        path: "/",
        element: <Navigate to="/profile" replace />,
    },
    {
        path: "/profile",
        element: <Profile />,
        children: [
            {
                index: true,
                path: "my-account",
                element: <Myaccount />,
            },
            {
                path: "chat",
                element: <Chat />,
            },
            {
                path: "favourites",
                element: <Favourite />,
            },
            {
                path: "liked",
                element: <Liked />,
            },
            {
                path: "who-favourite-me",
                element: <WhofavouritedU />,
            },
            {
                path: "who-liked-me",
                element: <WholikedU />,
            },
            {
                path: "notification",
                element: <Notification />,
            },
            {
                path: 'travel',
                element: <Travel />,
            },
            {
                path: 'testimonial',
                element: <Testimonial />,
            },
            {
                path: 'user-reports',
                element: <UserReports />,
            },
            {
                path:'blockedusers',
                element:<BlockedusersList/>
            },
           
        ],
    },
    {
        path:'/kyc',
        element:<Kyc/>
    },
    {
        path:'change-password',
        element:<Changepassword/>
    },
    
    {
        path: '/logout',
        element: <Navigate to='/login' replace />
    },
    {
        path: '/matches',
        element: <Matches />
    },
    {
        path: '/upgrade',
        element: <Upgrade />
    },
    {
        path: '/singleProfile/:user_name',
        element: <SingleProfile />
    },
    
    {
        path: '/download',
        element: <Download />
    },
    {
        path: '/earn-point',
        element: <EarnPoint />
    },
    {
        path: '/about-company',
        element: <Aboutcmpany />
    },
    {
        path: '/contact-us',
        element: <Contactus />
    },
    {
        path: '/help',
        element: <Help />
    },
    {
        path: '/faq',
        element: <Faq />
    },
    {
        path: '/privacy-policy',
        element: <PrivacypPolicy />
    },
    {
        path: '/travel',
        element: <TravelList />
    },
    {
        path: '/my-trips',
        element: <Mytrips />
    },
    {
        path: '/incoming-request-travels',
        element: <RequestedTrips />   
    }

]

const adminRoutes=[
    {
        path: "/",
        element: <Navigate to="/profile" replace />,
    },
    {
        path: "/profile",
        element: <AdminProfile />,
        children: [
            {
                index: true,
                path: "my-account",
                element: <Myaccount />,
            },
            {
                path: "chat",
                element: <Chat />,
            },
            {
                path: "favourites",
                element: <Favourite />,
            },
            {
                path: "liked",
                element: <Liked />,
            },
            {
                path: "who-favourite-me",
                element: <WhofavouritedU />,
            },
            {
                path: "who-liked-me",
                element: <WholikedU />,
            },
            {
                path: "notification",
                element: <Notification />,
            },
           
            {
                path: 'testimonial-approval',
                element: <TestimonialApproval />,
            },
            {
                path: 'user-reports',
                element: <UserReports />,
            },
            {
                path:'kyc-approval',
                element:<KycApproval/>
            },
           
        ],
    },
    {
        path:'change-password',
        element:<Changepassword/>
    },
   
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/logout',
        element: <Navigate to='/login' replace />
    },
    {
        path: '/package',
        element: <Packages />
    },
    {
        path: '/settings',
        element: <Settings />,
        children: [
            {
                index: true,
                path: "mail",
                element: <MailConfig />,
            },
            {
                index: true,
                path: "company-details",
                element: <CompanyDetails />,
            },
            {
                index: true,
                path: "company-policy",
                element: <CompanyDetails />,
            },
        ]
    },
    {
        path: '/upgrade',
        element: <Upgrade />
    },
    {
        path: '/singleProfile/:user_name',
        element: <SingleProfile />
    },
    {
        path: '/download',
        element: <Download />
    },
    {
        path: '/earn-point',
        element: <EarnPoint />
    },
    {
        path: '/about-company',
        element: <Aboutcmpany />
    },
    {
        path: '/contact-us',
        element: <Contactus />
    },
    {
        path: '/help',
        element: <Help />
    },
    {
        path: '/faq',
        element: <Faq />
    },
    {
        path: '/privacy-policy',
        element: <PrivacypPolicy />
    },
   
    {
        path: '/my-trips',
        element: <Mytrips />
    }

]
export { privateRoutes, publicRoutes,adminRoutes }
