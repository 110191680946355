import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import google from "../../assets/images/google.svg";
import fb from "../../assets/images/facebook_reg.svg";
import mob from "../../assets/images/phone.svg";
import mail from "../../assets/images/7115264_new_logo_gmail_icon-svg (1).svg";
import { GoogleLogin } from "@react-oauth/google";
import { setIslogged } from "../../store/reducers/authReducer";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { registerApis } from "../../hooks/apihooks/registerApis";
import { showToastmsg } from "../../common/ToastMsg";
import Loader from "../../common/Loader";
import { useDispatch } from "react-redux";
import { useNavigate,Link } from "react-router-dom";
import { FACEBOOK_APP_ID } from "../../config/config";

function Register() {
  const dispatch = useDispatch();
  const naviage = useNavigate();
  const [loader, setLoader] = useState(false);
  const SendGmailToken = registerApis.SendGmailToken();
  const SendFacebookData = registerApis.SendfacebookVerification();

  const login = useGoogleLogin({
    onSuccess: async (cridentials) => {
      console.log("cridentials", cridentials);
      let response = await SendGmailToken.mutateAsync({
        auth_token: cridentials.access_token,
      });
      console.log(" response === ", response);
      if (response.status) {
        console.log("success ---- login ");
        dispatch(setIslogged(true));
        localStorage.setItem("userType",'user')
        localStorage.setItem("logingstatus", true);
        localStorage.setItem("access-token", response.data.access_token);
        localStorage.setItem("refersh-token", response.data.refresh_token);
        
        naviage("/profile");
      } else {
        showToastmsg("error", "Registration Failed");
      }
    },
    onError: (error) => {
      console.log("errir===", error);
      showToastmsg("error", "some error occured");
    },
  });

  const responseFacebook = async (response) => {
    if (response.error) {
      showToastmsg("error", "Registration Failed");
    } else {
      let result = await SendFacebookData.mutateAsync({
        auth_token: JSON.stringify(response),
      });
      console.log("fb result === ",result);
      if (
        result.status &&
        result.data.data.access_token &&
        result.data.data.refresh_token
      ) {
        dispatch(setIslogged(true));
        localStorage.setItem("userType",'user');
        localStorage.setItem("logingstatus", true);
        localStorage.setItem("access-token", result.data.data.access_token);
        localStorage.setItem("refersh-token", result.data.data.refresh_token);
        
        naviage("/profile");
      } else {
        showToastmsg("error", "Registration Failed");
      }
    }
  };
  return (
   
      <section className="register_login_section">
        <div className="container text-center">
          <div className="register_login_box">
            {SendGmailToken.isLoading || loader ? (
              <div>
                Loading... <Loader type="heart" />
              </div>
            ) : (
              <>
                <div className="register_login_box_head">Register</div>
                <div className="register_login_box_cnt text-center">
                  <Link
                    to="/register-form"
                    className="register_login_box_regbtn"
                  >
                    <div className="register_login_box_regbtn_ico">
                      <img src={mail} alt="" />
                    </div>
                    <strong>Register with Mail</strong>
                  </Link>
                  <button
                    className="register_login_box_regbtn"
                    onClick={() => login()}
                  >
                    <div className="register_login_box_regbtn_ico">
                      <img src={google} alt="" />
                    </div>
                    <strong>Register with Google</strong>
                  </button>

                  <FacebookLogin
                    appId={FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    render={(renderProps) => (
                      <button
                        className="register_login_box_regbtn"
                        onClick={renderProps.onClick}
                      >
                        <div className="register_login_box_regbtn_ico">
                          <img src={fb} alt="" />
                        </div>
                        <strong>Register with Facebook</strong>
                      </button>
                    )}
                  />
                  {/* <FacebookLogin
                    appId="639260604956887" //APP ID NOT CREATED YET
                    fields="name,email,picture"
                    callback={responseFacebook}
                  /> */}

                  <p className="already_registrd_reg">
                    Already registered? <Link to ="/login">Login Now</Link>
                  </p>
                </div>{" "}
              </>
            )}
          </div>
        </div>
      </section>
   
  );
}

export default Register;
