import React from 'react'
import { motion } from 'framer-motion';
import Skeleton from 'react-loading-skeleton'
import "react-loading-skeleton/dist/skeleton.css";
function ProfileSkeleton() {

    const skeletonVariants = {
        shimmer: {
            x: [0, 100],
            transition: {
                x: {
                    repeat: Infinity,
                    repeatType: 'loop',
                    duration: 1.5,
                    ease: 'linear',
                },
            },
        },
    };
    return (

        <div className="skeleton-container">
            <div className="my_account_right_head">
                <Skeleton width={200} />
            </div>

            <div className="skeleton-navigation">
                {Array(5).fill('').map((_, index) => (
                    <div key={index} className="skeleton-nav-item">
                        <motion.div className="shimmer" variants={skeletonVariants} animate="shimmer" />
                    </div>
                ))}
            </div>
            <h5> <Skeleton width={200} /></h5>
            <div className="row">
                {Array(7).fill('').map((_, index) => (
                    <div className="col-md-6 mt-4">
                        <Skeleton width="100%" height="38px" />
                    </div>
                ))}

            </div>
        </div>

    )
}

export default ProfileSkeleton