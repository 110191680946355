import { registerServices } from "../../services/registerServices";

    export const registerSubmithandler=async(data)=>{
        console.log("===registerSubmithandler",data)
        try {
            const response=await registerServices.registerSubmit(data)
            return response
        } catch (error) {
            return error
        }
    }
