import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import resetpSWD from "../../assets/images/resetPswd.jpg";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setResetdata } from "../../store/reducers/resetPswdReducer";
import { setResetdataError } from "../../store/reducers/resetPswdReducer";
import { useParams } from "react-router-dom";
import { ResetpaswdApis } from "../../hooks/apihooks/resetPswdApis";
import { showToastmsg } from "../../common/ToastMsg";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const resetData = useSelector((state) => state.resetPswd.resetData);
  const resetDataErr = useSelector((state) => state.resetPswd.resetDataError);
  const params = useParams();
  const resetPasswordSubmit = ResetpaswdApis.SubmitResetdatas();
  const changeHandler = (e) => {
    const { name, value } = e.target;
    dispatch(
      setResetdata({
        [name]: value,
      })
    );
    dispatch(
      setResetdataError({
        [name]: "",
      })
    );
  };
  const validation = async () => {
    if (!resetData.password) {
      dispatch(
        setResetdataError({
          password: "enter your password",
        })
      );
      return false;
    } else if (!resetData.confirmPswd) {
      dispatch(
        setResetdataError({
          confirmPswd: "confirm your password",
        })
      );
      return false;
    } else if (resetData.password != resetData.confirmPswd) {
      dispatch(
        setResetdataError({
          confirmPswd: "password is not matching",
        })
      );
      return false;
    }
    return true;
  };
  const resetSubmithandler = async () => {
    try {
      const isValid = await validation();
      if (isValid) {
        let response = await resetPasswordSubmit.mutateAsync({
          password: resetData.confirmPswd,
          uidb64: params.uidb,
          token: params.token,
        });
        if (response.status) {
          showToastmsg(
            "success",
            response?.data?.success ?? "password changed"
          );
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        } else {
          showToastmsg("error", response?.data?.error ?? "some error occured");
        }
      }
    } catch (error) {}
  };

  return (
    <div>
      <ToastContainer />
      <section className="register_login_section">
        <div className="container text-center">
          <div className="register_login_box text-start login_box">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="register_login_box_head">
                  Reset Password
                  <p>Enter your new password and send</p>
                </div>
                <div className="register_login_box_cnt">
                  <div className="register_login_box_form">
                    <div className="row">
                      <div className="col-md-12 mb-2">
                        <label htmlFor="">New password</label>
                        <input
                          type="password"
                          className={`${
                            resetDataErr.password ? "erorInput " : ""
                          }form-control`}
                          name="password"
                          onChange={changeHandler}
                        />
                        {resetDataErr.password && (
                          <p className="formError">
                            <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                            &nbsp;{resetDataErr.password}*{" "}
                          </p>
                        )}
                      </div>
                      <div className="col-md-12 mb-2">
                        <label htmlFor="">Confirm password</label>
                        <input
                          type="password"
                          className={`${
                            resetDataErr.confirmPswd ? "erorInput " : ""
                          }form-control`}
                          name="confirmPswd"
                          onChange={changeHandler}
                        />
                        {resetDataErr.confirmPswd && (
                          <p className="formError">
                            <i class="fa-solid fa-circle-exclamation fa-beat fa-sm"></i>
                            &nbsp;{resetDataErr.confirmPswd}*{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <a>
                    <button
                      className="reg_login_sub_btn mt-3"
                      onClick={resetSubmithandler}
                      disabled={resetPasswordSubmit.isLoading ? true : false}
                    >
                      {resetPasswordSubmit.isLoading ? (
                        <div>
                          sending <i class="fa-solid fa-spinner fa-spin"></i>
                        </div>
                      ) : (
                        "send"
                      )}
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="login_right_sec">
                  {/* <h2>Best Ways to Find Your True Soul Mate</h2> */}
                  <img src={resetpSWD} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
    </div>
  );
}

export default ResetPassword;
